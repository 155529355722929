// lodash is imported from create-react-app, but we don't have it on our project dependecies.

import {
  forEach, camelCase, isPlainObject, isArray,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from 'lodash';

const objectKeysToCamelCase = (objectToCamelize) => {
  const camelCaseObject = {};
  forEach(
    objectToCamelize,
    (value, key) => {
      let newValue = value;
      if (isPlainObject(newValue)) {
        newValue = objectKeysToCamelCase(newValue);
      }
      if (isArray(newValue)) {
        newValue = newValue.map((element) => {
          if (!isPlainObject(element) && !isArray(element)) {
            return element;
          }
          return objectKeysToCamelCase(element);
        });
      }
      if (key) {
        camelCaseObject[camelCase(key)] = newValue;
      } else {
        camelCaseObject.push(newValue);
      }
    },
  );
  return camelCaseObject;
};

export default objectKeysToCamelCase;
