import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  TextField,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {
  format,
  // isPast
} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import dateRegex from '../../../utils/dateRegex';
import getFirstErrorMessage from '../../../utils/getFirstErrorMessage';
import SnackbarAlert from '../../../components/SnackbarAlert';
import * as ReservationsApi from '../../../api/restaurants/reservations';
import emojiRegex from '../../../utils/emojiRegex';

import './ReservationsEditFormOne.scss';

const ReservationsEditFormOne = ({
  nextStep, currentReservationData, setNewReservationData, setNumberPeople, setAvaliabilityData,
}) => {
  const [isFormTouched, setFormIsTouched] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [fields, setFields] = useState({
    date: '',
    time: '',
    numberPeople: '',
    comments: '',
    tableNumber: '',
    type: '',
  });
  const [validations, setValidations] = useState({
    date: true,
    time: true,
    numberPeople: true,
    comments: true,
    tableNumber: true,
    type: true,
  });

  const [status, setStatus] = useState({
    error: null,
    isLoading: false,
    isValid: false,
  });

  const [selectedDate, setSelectedDate] = useState();
  const maxReservationDate = moment().add(1, 'year').format('yyyy-MM-DD');

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  useEffect(() => {
    if (fields.numberPeople.length > 0) {
      setFormIsTouched(true);
    }
  }, [fields]);

  useEffect(() => {
    console.log('acaaa');
    console.log(currentReservationData);
    const arrayDate = currentReservationData.date.split('-');
    setFields({
      date: new Date(arrayDate[0], arrayDate[1] - 1, arrayDate[2]),
      // time: '00:00',
      time: currentReservationData.hour24,
      numberPeople: currentReservationData.numberPeople,
      comments: currentReservationData.comments,
      tableNumber: currentReservationData.tableNumber,
      currentReservationData: currentReservationData.type,
      type: currentReservationData.type,
    });
    setSelectedDate(new Date(arrayDate[0], arrayDate[1] - 1, arrayDate[2]));
  }, [currentReservationData]);

  const areFieldsValid = () => (
    Object.values(validations).every((item) => item)
  );

  const handleValidations = (field, value) => {
    const mapValidations = {
      date: (date) => date.length > 0,
      time: (time) => time.length > 0,
      numberPeople: (numberPeople) => Number(numberPeople) > 0,
      comments: (comments) => comments.length < 250,
      tableNumber: () => true,
      type: () => true,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

  const updateFields = (field, value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...fields,
    };
    newFields[field] = cleanString;
    setFields(newFields);
    handleValidations(field, cleanString);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (dateRegex.test(format(new Date(date), 'yyyy-MM-dd'))) {
      updateFields('date', format(date, 'yyyy-MM-dd'));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (areFieldsValid && !status.isLoading) {
      setStatus({
        ...status,
        isLoading: true,
        error: null,
      });
      const time = moment(`1988-01-08 ${fields.time}`, 'YYYY-MM-DD hh:mm a').format('HH:mm');
      const date = `${dateRegex.test(fields.date) ? fields.date : format(fields.date, 'yyyy-MM-dd')} ${time}`;
      try {
        setStatus({
          ...status,
          isLoading: true,
        });
        const { success, data, message } = await ReservationsApi
          .getAvaliability(date, fields.numberPeople);
        if (success === 0) {
          setStatus({
            ...status,
            isLoading: false,
            error: true,
            message,
            success: false,
          });
          setSnackbar({
            open: true,
            severity: 'error',
            text: getFirstErrorMessage(message),
          });
        }
        if (success === 1) {
          setStatus({
            ...status,
            isLoading: false,
            error: false,
            message: data,
            success: false,
          });
          setAvaliabilityData(data);
          setNewReservationData({
            numberPeople: fields.numberPeople,
            date: format(new Date(fields.date), 'yyyy-MM-dd'),
            time: fields.time,
            comments: fields.comments,
            dateRaw: date,
            tableNumber: fields.tableNumber,
            type: fields.type,
          });
          setNumberPeople(fields.numberPeople);
          nextStep(2);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   nextStep(3);
  // };
  // if type is 'event' then changes the value to '' in the fields
  const updatecb = () => {
    if (fields.type === 'event') {
      updateFields('type', '');
    } else {
      updateFields('type', 'event');
    }
  };

  return (
    <Box
      component="div"
      className="mt-5"
    >
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      {' '}
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
        <form className="w100 dFlex flex-column mb-4 reservationsCreateForm" onSubmit={handleSubmit} autoComplete="off">
          <div className="w100">
            <TextField
              className="w100"
              error={!isFormTouched ? false : !validations.numberPeople}
              id="numberPeople"
              label="¿Cuántas personas asisten?*"
              onChange={(e) => updateFields('numberPeople', e.target.value)}
              value={fields.numberPeople}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="w100">
            <KeyboardDatePicker
              onClick={() => { setIsCalendarOpen(true); }}
              onClose={() => setIsCalendarOpen(false)}
              open={isCalendarOpen}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="¿Cuándo quieren reservar?"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              autoOk
              invalidDateMessage="No es un formato válido"
              maxDate={maxReservationDate}
              maxDateMessage="No puedes escoger una fecha mayor a un año"
            />
          </div>
          <div className="w100 mt-1">
            <TextField
              className="w100"
              id="comments"
              label="Comentarios"
              onChange={(e) => updateFields('comments', e.target.value)}
              value={fields.comments}
            />
          </div>
          <div className="w100 mt-1">
            <TextField
              className="w100"
              id="tableNumber"
              label="Mesa"
              onChange={(e) => updateFields('tableNumber', e.target.value)}
              value={fields.tableNumber}
            />
          </div>
          <FormGroup>
            <FormControlLabel label="Evento (ejemplo: graduación, cumpleaños, cena de empresa)" className="event" control={<Checkbox onChange={() => updatecb()} color="primary" id="event" name="event" checked={fields.type === 'event'} value={fields.type} />} />
          </FormGroup>
          <div>
            <b>ATENCION</b>
            : Dar clic a “Evento” solamente si desea bloquear
            toda la zona (ejemplo: terraza, interior)
            para el evento y no quiere que distintos
            clientes compartan la misma zona.
          </div>
          <Box
            component="div"
            className="mt-3 dFlex flex-column align-center justify-center"
          >
            {status.isLoading && (
              <CircularProgress color="primary" className="mb-2" />
            )}
            {/* {status.error === true && (
          <Box
            component="div"
            className="mt-2 dFlex flex-column align-center justify-center color-error"
          >
            {status.message}
          </Box>
          )} */}
            <div className="reservationsCreateForm__button-group">
              <Button
                variant="contained"
                color="secondary"
                type="button"
                onClick={() => { navigate('/reservaciones'); }}
              >
                <span className="underline">
                  CANCELAR
                </span>
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!areFieldsValid() && isFormTouched}
              >
                SIGUIENTE
              </Button>
            </div>
          </Box>
        </form>
      </MuiPickersUtilsProvider>
    </Box>
  );
};

ReservationsEditFormOne.propTypes = {
  nextStep: PropTypes.func.isRequired,
  currentReservationData: PropTypes.shape({
    id: PropTypes.string,
    numberPeople: PropTypes.number,
    date: PropTypes.string,
    hour: PropTypes.string,
    hour24: PropTypes.string,
    comments: PropTypes.string,
    tableNumber: PropTypes.string,
    tableId: PropTypes.string,
    cliendId: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  setNewReservationData: PropTypes.func.isRequired,
  setNumberPeople: PropTypes.func.isRequired,
  setAvaliabilityData: PropTypes.func.isRequired,
};
export default ReservationsEditFormOne;
