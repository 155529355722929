/* eslint-disable */
import React from 'react';

import PropTypes from 'prop-types';
import './DialogImage.scss';
import ReactModal from 'react-modal';
import {
  Button,
} from '@material-ui/core/';

ReactModal.setAppElement('#root');

const DialogEmail = ({
  isOpen, onNo, data,
}) => (
  <ReactModal
    isOpen={isOpen}
    className="dialog-email-modal"
    overlayClassName="dialog-email-modal__overlay"
    shouldCloseOnOverlayClick
    onRequestClose={onNo}
  >
    <div className="dialog-email-modal__content ">
      <div className="imagen">
        <img src={data} alt="" className='photo' />
      </div>
    </div>
  </ReactModal>
);

DialogEmail.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  data: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
};
DialogEmail.defaultProps = {
  isOpen: false,
  onNo: '',
  data: '',
};
export default DialogEmail;
