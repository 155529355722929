import React, { useState, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { isEmpty, throttle } from 'lodash';
import {
  Box,
  Button,
  Container,
  // Grid,
  // Hidden,
  IconButton,
  TextField,
  Typography,
  Hidden,
  Popper,
  Backdrop,
  CircularProgress,
  makeStyles,
} from '@material-ui/core/';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import 'moment/locale/es';

import chargesTableFilter from '../../utils/chargesTableFilter';
import ChargesContext from './ChargesContext/ChargesContext';
import * as ChargesApi from '../../api/restaurants/charges';
import TableErrors from '../../components/TableErrors';
import prepareChargesTable from '../../utils/prepareChargesTable';
import SnackbarAlert from '../../components/SnackbarAlert';
import isTruthy from '../../utils/isTruthy';

import ChargesTable from '../../components/ChargesTable';
import ChargesAccordion from '../../components/ChargesAccordion';
import TablePaginationCustom from '../../components/TablePaginationCustom';
import TheMenu from '../../components/TheMenu';
import chargesIcon from '../../images/errorIcons/charges.png';

import './Charges.scss';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const currentCallConfig = () => {
  const retrieveChargesConfig = localStorage.getItem('ChargesConfig');
  let config = {
    lastPage: 1,
    currentPage: 1,
    perPage: 5,
    total: 0,
    from: 1,
    to: 5,
    options: [5, 25, 100],
    date: moment().format('yyyy-MM'),
  };
  if (retrieveChargesConfig !== null) {
    config = {
      lastPage: JSON.parse(retrieveChargesConfig).lastPage,
      currentPage: JSON.parse(retrieveChargesConfig).currentPage,
      perPage: Number(JSON.parse(retrieveChargesConfig).perPage),
      total: JSON.parse(retrieveChargesConfig).total,
      from: JSON.parse(retrieveChargesConfig).from,
      to: JSON.parse(retrieveChargesConfig).to,
      options: [5, 25, 100],
      date: JSON.parse(retrieveChargesConfig).date,
    };
  }
  return config;
};

const Charges = () => {
  const throttleTime = 200;

  const [state, setState] = useState({
    loading: true,
    error: null,
    success: false,
    charges: [],
    income: {},
    paginator: {},
  });

  const [reload, setReload] = useState(false);
  const [apiCallConfig, setApiCallConfig] = useState(currentCallConfig());

  // Los siguientes son usados para configurar el buscador de la página
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopper = Boolean(anchorEl);
  const [nameForFilter, setNameForFilter] = useState('');

  const [charges, setCharges] = useState(state.charges);
  const [filteredCharges, setFilteredCharges] = useState(state.charges);
  const [income, setIncome] = useState(state.income);

  // Configuración para el nackbar alert
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  // Configuracion para el backdrop/loader
  const classes = useStyles();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  // Configuracion para el popper de búsqueda
  const handlePopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useEffect(() => {
    setState({
      loading: true,
      error: null,
      success: false,
      charges: [],
      income: {},
      paginator: {},
    });
    const fetchCharges = async () => {
      const newConfig = currentCallConfig();
      try {
        const { success, data } = await ChargesApi
          .showAllCharges(newConfig);
        if (isTruthy(success)) {
          await prepareChargesTable(data.data).then((tableData) => {
            setCharges(tableData);
            setFilteredCharges(tableData);
            setIncome(data.income);
            const newCallConfig = {
              currentPage: data.currentPage,
              from: data.from,
              perPage: data.perPage,
              lastPage: data.lastPage,
              to: data.to,
              total: data.total,
              date: data.path.substr(data.path.length - 7),
              options: [5, 25, 100],
            };
            setState({
              loading: false,
              error: null,
              success: true,
              charges: tableData,
              income: data.income,
              paginator: newCallConfig,
            });
            localStorage.setItem('ChargesConfig', JSON.stringify(newCallConfig));
          });
          setReload(false);
        } else {
          setState({
            loading: false,
            error:
              data.error,
            success: false,
            charges: [],
            income: {},
            paginator: {},
          });
        }
      } catch (error) {
        console.log(error);
        setState({
          loading: false,
          error: error.message,
          success: false,
          charges: [],
          income: {},
          paginator: {},
        });
      }
    };

    fetchCharges();
  }, [apiCallConfig, reload]);

  useEffect(() => {
    const newFilteredCharges = isEmpty(nameForFilter)
      ? charges
      : charges.filter((charge) => chargesTableFilter(charge, nameForFilter));
    setFilteredCharges(newFilteredCharges);
  }, [nameForFilter, charges]);

  const renderLoading = () => (
    <div>Loading...</div>
  );

  const renderError = () => {
    const errorData = {
      message: state.error,
      action: () => true,
      buttonText: 'Intentar de nuevo',
      image: 'broken',
    };
    return (
      <TableErrors data={errorData} action={setReload} />
    );
  };

  const renderTablePagination = () => (
    <Box
      component="div"
      className="charges-table__box w100 mb-5 dFlex flex-row flex-row align-center justify-end"
    >
      <ChargesContext.Provider value={{ apiCallConfig, state, setApiCallConfig }}>
        <TablePaginationCustom context="ChargesContext" />
      </ChargesContext.Provider>
    </Box>
  );

  const renderTable = () => (
    <>
      <Hidden xsDown>
        <Box
          component="div"
          className="charges-table__box w100 dFlex flex-row align-center justify-between"
        >
          <ChargesContext.Provider value={
            { filteredCharges }
          }
          >
            <ChargesTable />
          </ChargesContext.Provider>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box
          component="div"
          className="charges-table__box w100 dFlex flex-row align-center justify-between"
        >
          <ChargesContext.Provider value={
            { filteredCharges }
          }
          >
            <ChargesAccordion />
          </ChargesContext.Provider>
        </Box>
      </Hidden>
    </>
  );

  const renderNoEntries = () => (
    <Box
      component="div"
      className="w100 mb-5 dFlex flex-column flex-row align-center justify-center"
    >
      <div>
        <img
          alt="Error. Aún no tienes ningún cobro."
          className="img-fuid error-icon"
          src={chargesIcon}
        />
      </div>
      <div>
        Aún no tienes ningún cobro.
      </div>
    </Box>
  );

  const downloadReport = async () => {
    setOpenBackdrop(true);
    try {
      const { status, data } = await ChargesApi
        .exportCharges(apiCallConfig.date);
      // Pasos a segir para generar el xlsx
      // https://medium.com/@drevets/you-cant-prompt-a-file-download-with-the-content-disposition-header-using-axios-xhr-sorry-56577aa706d6
      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'reporte_cobros.xlsx');
        document.body.appendChild(link);
        link.click();
        setSnackbar({
          open: true,
          severity: 'success',
          text: 'Archivo generado correctamente',
        });
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: data.data.error,
        });
      }
      handleCloseBackdrop();
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error.toJSON().message,
      });
      handleCloseBackdrop();
    }
  };

  const changeMonth = (operation) => {
    const retrieveChargesConfig = JSON.parse(localStorage.getItem('ChargesConfig'));
    let newDate;
    if (operation === 'substract') {
      newDate = moment(retrieveChargesConfig.date).subtract(1, 'month').format('yyyy-MM');
    } else {
      newDate = moment(retrieveChargesConfig.date).add(1, 'month').format('yyyy-MM');
    }
    retrieveChargesConfig.date = newDate;
    localStorage.setItem('ChargesConfig', JSON.stringify(retrieveChargesConfig));
    setApiCallConfig(retrieveChargesConfig);
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <TheMenu setIsCalendarOpen={() => { }} />
      <Container
        className="charges layout px-0"
      >
        <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />

        <Box
          component="div"
          className="charges__header pr-1 pl-1 dFlex flex-row align-center justify-between"
        >
          <Hidden smUp>
            <IconButton
              onClick={handlePopper}
              edge="start"
              color="primary"
              aria-label="menu"
              size="small"
            >
              <SearchIcon />
            </IconButton>
          </Hidden>
          <div className="dFlex flex-row">
            <Hidden xsDown>
              <IconButton
                onClick={handlePopper}
                edge="start"
                color="primary"
                aria-label="menu"
                size="small"
                className="search-icon__button"
              >
                <SearchIcon />
              </IconButton>
            </Hidden>
            <Hidden xsDown>
              <Typography variant="h5" className="charges__title font__commutter">
                {`COBROS ${moment(apiCallConfig.date).locale('es').format('MMMM')}`}
              </Typography>
              <div className="ml-2">
                <IconButton
                  onClick={() => { changeMonth('substract'); }}
                  edge="start"
                  color="primary"
                  aria-label="menu"
                  size="small"
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </div>
              <div>
                <IconButton
                  onClick={() => { changeMonth('add'); }}
                  edge="start"
                  color="primary"
                  aria-label="menu"
                  size="small"
                  disabled={moment(moment(apiCallConfig.date).add(1, 'month')).isAfter(moment())}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </div>
            </Hidden>
            <Hidden smUp>
              <Typography variant="h5" className="charges__title font__commutter">
                COBROS
              </Typography>
            </Hidden>
          </div>
          <Popper
            className="charges__searchBar"
            open={openPopper}
            anchorEl={anchorEl}
            placement="right"
            disablePortal={false}
            transition
            modifiers={{
              flip: {
                enabled: true,
              },
              preventOverflow: {
                enabled: false,
                boundariesElement: 'scrollParent',
                hide: false,
              },
            }}
          >
            <div>
              <TextField
                id="standard-basic"
                placeholder="Buscar"
                onChange={throttle((e) => setNameForFilter(e.target.value), throttleTime)}
                value={nameForFilter}
              />
              <IconButton
                onClick={() => { handlePopper(); setNameForFilter(''); }}
                edge="start"
                color="primary"
                aria-label="menu"
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Popper>
          <Hidden xsDown>
            <Box
              component="div"
              className="dFlex flex-column align-end justify-end font__commutter"
            >
              <div>
                {`INGRESO TOTAL: $${income.totalMonth} USD`}
              </div>
              <div className="color-grey">
                {`COMISIONES TOTALES: $${income.monthCommission} USD`}
              </div>
            </Box>
          </Hidden>
          <Hidden smUp>
            <IconButton
              onClick={() => { downloadReport(); }}
              edge="start"
              color="primary"
              aria-label="menu"
              size="small"
            >
              <SaveAltIcon />
            </IconButton>
          </Hidden>
        </Box>
        <Box
          component="div"
          className="charges__header pr-1 pl-1 dFlex flex-row align-center justify-end"
        >
          <Hidden xsDown>
            <Button
              onClick={() => { downloadReport(); }}
              variant="contained"
              color="primary"
            >
              DESCARGAR REPORTE
            </Button>
          </Hidden>
          <Hidden smUp>
            <Box
              component="div"
              className="dFlex flex-column align-center justify-center font__commutter w100"
            >
              <div className="charges__change-month-mobile font__commutter mb-1">
                <div>
                  <IconButton
                    onClick={() => { changeMonth('substract'); }}
                    edge="start"
                    color="primary"
                    aria-label="menu"
                    size="small"
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                </div>
                {`${moment(apiCallConfig.date).locale('es').format('MMMM')}`}
                <div>
                  <IconButton
                    onClick={() => { changeMonth('add'); }}
                    edge="start"
                    color="primary"
                    aria-label="menu"
                    size="small"
                    disabled={moment(moment(apiCallConfig.date).add(1, 'month')).isAfter(moment())}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </div>
              </div>
              <div className="dFlex flex-row justify-between w100">
                <div>
                  INGRESO TOTAL:
                </div>
                <div>
                  {` $${income.totalMonth} USD`}
                </div>
              </div>
              <div className="dFlex flex-row justify-between w100 color-grey">
                <div>
                  COMISIONES TOTALES:
                </div>
                <div>
                  {`$${income.monthCommission} USD`}
                </div>
              </div>
            </Box>
          </Hidden>
        </Box>

        <Box
          component="div"
          className="charges__tabs-container mt-2 dFlex flex-column align-center justify-between"
        >
          {!state.loading && state.error && renderError()}
          {!state.loading && filteredCharges.length > 0 && renderTable()}
          {!state.loading && filteredCharges.length > 0 && renderTablePagination()}
          {!state.loading && filteredCharges.length <= 0 && renderNoEntries()}
          {state.loading && renderLoading()}
        </Box>
      </Container>
    </>
  );
};

export default Charges;
