import React from 'react';
import './NotFound.scss';

const NotFound = () => (
  <div className="not-found layout">
    <h2>La página que buscas no ha sido encontrada</h2>
  </div>
);

export default NotFound;
