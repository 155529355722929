import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import {
  Typography,
  Button,
} from '@material-ui/core/';

import './DialogLogout.scss';

ReactModal.setAppElement('#root');

const DialogLogout = ({ isOpen, onYes, onNo }) => (
  <ReactModal
    isOpen={isOpen}
    className="dialog-logout-modal"
    overlayClassName="dialog-logout-modal__overlay"
  >
    <div className="dialog-logout-modal__content ">
      <div className="dialog-logout-modal__text-container">
        <div className="dialog-logout-modal__title">
          <Typography variant="h5" gutterBottom>
            ¿Estás seguro de cerrar
            tu sesión?
          </Typography>
        </div>
        <div className="dialog-logout-modal__body" />
      </div>
      <div className="dialog-logout-modal__cta-container">
        <Button
          variant="contained"
          color="secondary"
          type="button"
          onClick={onNo}
        >
          <span className="underline">
            Mantener
          </span>
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={onYes}
        >
          Cerrar sesión
        </Button>
      </div>
    </div>
  </ReactModal>
);

DialogLogout.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  onYes: PropTypes.any,
};
DialogLogout.defaultProps = {
  isOpen: false,
  onNo: '',
  onYes: '',
};

export default DialogLogout;
