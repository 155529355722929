import client from '../utils/client';
import clientUploadFile from '../utils/clientUploadFile';

const getRestaurantData = () => client('restaurant').then((response: any) => response);

const CreateZone = (data) => client('restaurant/createzone', {
  body: data,
  method: 'POST',
}).then((response: any) => response);
const CreateRestaurant = (data) => client('restaurant/createrestaurant', {
  body: data,
  method: 'POST',
}).then((response: any) => response);
const storePhotos = (data) => client('restaurant/photos', {
  body: data,
  method: 'PUT',
}).then((response: any) => response);

const tabletypes = (data) => client('restaurant/tabletypes', {
  body: data,
  method: 'POST',
}).then((response: any) => response);

const editMenu = (data) => client('restaurant/menu', {
  body: data,
  method: 'PUT',
}).then((response: any) => response);

const editDescription = (data) => client('restaurant/description', {
  body: data,
  method: 'PUT',
}).then((response: any) => response);

const editCapacity = (data) => client('restaurant/capacity', {
  body: data,
  method: 'PUT',
}).then((response: any) => response);

const extraService = (data) => client('restaurant/extra-service', {
  body: data,
  method: 'PUT',
}).then((response: any) => response);

const editServiceDay = (data) => client('restaurant/service-day', {
  body: data,
  method: 'PUT',
}).then((response: any) => response);

const editKitchen = (data) => client('restaurant/kitchen', {
  body: data,
  method: 'PUT',
}).then((response: any) => response);

const editCost = (data) => client('restaurant/cost', {
  body: data,
  method: 'PUT',
}).then((response: any) => response);

const editSector = (data) => client('restaurant/sector', {
  body: data,
  method: 'PUT',
}).then((response: any) => response);

const editAutomatic = (data) => client('restaurant/automatic', {
  body: data,
  method: 'PUT',
}).then((response: any) => response);

const editOffer = (data) => client('restaurant/offer', {
  body: data,
  method: 'PUT',
}).then((response: any) => response);

const deletePhoto = (data) => client(`restaurant/photos?photo_eid=${data.photo_eid}`, {
  // body: data,
  method: 'DELETE',
}).then((response: any) => response);

const uploadOnePhoto = (data) => clientUploadFile('restaurant/photos', {
  body: data,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}).then((response: any) => response);
const uploadCover = (data) => clientUploadFile('restaurant/cover', {
  body: data,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}).then((response: any) => response);
export {
  CreateRestaurant,
  uploadCover,
  CreateZone,
  deletePhoto,
  editMenu,
  editDescription,
  editCapacity,
  editKitchen,
  editAutomatic,
  extraService,
  editServiceDay,
  editCost,
  editSector,
  getRestaurantData,
  storePhotos,
  uploadOnePhoto,
  tabletypes,
  editOffer,
};
