import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@material-ui/core/';
import moment from 'moment';
import broken from '../../images/errorIcons/broken.png';
import comments from '../../images/errorIcons/comments.png';
import notFound from '../../images/errorIcons/notFound.png';
import reservations from '../../images/errorIcons/reservations.png';
import filter from '../../images/errorIcons/filter2.png';
import reservationsblocked from '../../images/errorIcons/reservationsblocked.png';

import './TableErrors.scss';

const TableErrors = ({ data, action }) => {
  const [errorData] = useState(data);
  const handleChange = () => {
    if (errorData.action !== null) {
      errorData.action();
    }
    if (action !== null) {
      action(true);
    }
  };
  let icon;
  switch (errorData.image) {
    case 'broken':
      icon = broken;
      break;

    case 'comments':
      icon = comments;
      break;
    case 'notFound':
      icon = notFound;
      break;
    case 'noresults':
      icon = filter;
      break;
    case 'reservations':
      icon = reservations;
      break;
    case 'reservationsblocked':
      icon = reservationsblocked;
      break;

    default:
      icon = broken;
      break;
  }
  return (
    <div
      className="table-error"
    >
      <img
        alt={`Error ${errorData.message}`}
        className="img-fuid error-icon"
        src={icon}
      />
      {errorData.image !== 'reservationsblocked' && (
        <div className="Este-da-ha-sido-bloqueado-POR">
          {errorData.message}
        </div>
      )}
      {errorData.image === 'noresults' && (
        <div className="mb-1 mt-1 pb-5 message2">
          <b>{errorData.message2}</b>
        </div>
      )}
      {errorData.buttonText !== null && (
        <div>
          <Button onClick={handleChange} variant="contained" color="secondary">
            {errorData.buttonText}
          </Button>
        </div>
      )}
      {errorData.image === 'reservationsblocked' && (
        <div>
          <div className="Este-da-ha-sido-bloqueado-POR">
            Este día ha sido bloqueado POR:
          </div>
          <div className="tableErrorTitle">
            {errorData.reason}
          </div>
          <div className="tableErrorTitle">
            <span>
              <b>Nombre del cliente: </b>
            </span>
            {errorData.name}
          </div>
          <div className="tableErrorTitle">
            <span>
              <b>Contacto: </b>
            </span>
            {errorData.email}
            {' '}
            {errorData.phone}
          </div>
          <div className="tableErrorTitle">
            <span>
              <b>Número de asistentes: </b>
            </span>
            {errorData.people}
          </div>
          <div className="tableErrorTitle">
            <span>
              <b>Hora de llegada: </b>
            </span>
            {moment(errorData.checkIn).locale('es').format('hh:mm A')}
          </div>
        </div>
      )}
    </div>
  );
};

TableErrors.propTypes = {
  data: PropTypes.shape({
    message: PropTypes.string,
    message2: PropTypes.string,
    action: PropTypes.func,
    buttonText: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  action: PropTypes.func,
};

TableErrors.defaultProps = {
  action: null,
};

export default TableErrors;
