import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  IconButton,
} from '@material-ui/core/';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ReservationsContext from '../../layouts/Reservations/ReservationsContext/ReservationsContext';
import ClientsContext from '../../layouts/Clients/ClientsContext/ClientsContext';
import ChargesContext from '../../layouts/Charges/ChargesContext/ChargesContext';
import CommentsContext from '../../layouts/Comments/CommentsContext/CommentsContext';
import StaffContext from '../../layouts/Staff/StaffContext/StaffContext';
import './TablePaginationCustom.scss';

const TablePaginationCustom = ({ context }) => {
  const currentContext = () => {
    switch (context) {
      case 'ReservationsContext':
        return ReservationsContext;
      case 'ClientsContext':
        return ClientsContext;
      case 'ChargesContext':
        return ChargesContext;
      case 'StaffContext':
        return StaffContext;
      case 'CommentsContext':
        return CommentsContext;
      default:
        return ReservationsContext;
    }
  };
  const currentContextStorage = () => {
    switch (context) {
      case 'ReservationsContext':
        return 'ReservationsConfig';
      case 'ClientsContext':
        return 'ClientsConfig';
      case 'ChargesContext':
        return 'ChargesConfig';
      case 'StaffContext':
        return 'StaffConfig';
      case 'CommentsContext':
        return 'CommentsConfig';
      default:
        return 'ReservationsConfig';
    }
  };
  const { apiCallConfig, state, setApiCallConfig } = useContext(currentContext());
  const [perPageState, setPerPageState] = useState(state.paginator.perPage);

  const handlePerPageChange = (event) => {
    console.log(event.target.value);
    const updateConfig = JSON.parse(localStorage.getItem(currentContextStorage()));
    updateConfig.currentPage = 1;
    updateConfig.lastPage = null;
    updateConfig.from = 1;
    updateConfig.total = null;
    updateConfig.perPage = Number(event.target.value);
    localStorage.setItem(currentContextStorage(), JSON.stringify(updateConfig));
    setPerPageState(event.target.value);
    setApiCallConfig({
      ...apiCallConfig,
      perPage: Number(event.target.value),
      currentPage: 1,
      from: 1,
      total: null,
      lastPage: null,
    });
  };

  const changePage = (change: boolean) => {
    const { currentPage, lastPage } = state.paginator;
    let newPage;
    if (change && currentPage + 1 <= lastPage) {
      newPage = currentPage + 1;
    }
    if (!change && currentPage - 1 >= 1) {
      newPage = currentPage - 1;
    }
    const updateConfig = JSON.parse(localStorage.getItem(currentContextStorage()));
    updateConfig.currentPage = newPage;
    updateConfig.from = 1;
    updateConfig.lastPage = null;
    updateConfig.total = null;
    localStorage.setItem(currentContextStorage(), JSON.stringify(updateConfig));
    setApiCallConfig({
      ...apiCallConfig,
      currentPage: newPage,
      from: 1,
      total: null,
      lastPage: null,
    });
  };

  return (
    <div className="table-Pagination__container">
      <div className="pageSize__buttons mr-2">
        <span className="pr-1">
          Filas:
        </span>
        <FormControl>
          <Select
            labelId="pageSize__select"
            id="pageSize__select"
            className="pageSize__select"
            value={perPageState}
            onChange={handlePerPageChange}
          >
            {apiCallConfig.options.map((option: number) => (
              <MenuItem key={uuid()} value={option} className="font__commutter">{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="paginationFromTo">
        <span className="font__commutter">
          {state.paginator.from}
          {' '}
        </span>
        -
        <span className="font__commutter">
          {' '}
          {state.paginator.to}
        </span>
        {' '}
        de
        {' '}
        <span className="font__commutter">{state.paginator.total}</span>
      </div>
      <div className="change-age__button">
        <IconButton
          onClick={() => { changePage(false); }}
          disabled={(state.paginator.currentPage
            <= state.paginator.lastPage)
            && state.paginator.currentPage === 1}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton
          onClick={() => { changePage(true); }}
          disabled={state.paginator.currentPage >= state.paginator.lastPage}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </div>
  );
};

TablePaginationCustom.propTypes = {
  context: PropTypes.string.isRequired,
};

export default TablePaginationCustom;
