/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { Router, Location } from '@reach/router';
import './FadeTransitionRouter.scss';

const FadeTransitionRouter = (props) => (
  <Location>
    {({ location }) => (
      <Router location={location}>
        {props.children}
      </Router>
    )}
  </Location>
);

FadeTransitionRouter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default FadeTransitionRouter;
