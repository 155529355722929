/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
//import offers.scss 

import {
  Button,
  Box,
  Container,
  Grid,
  TextField,
  CircularProgress,
  Hidden,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from '@material-ui/core';

import { format, isPast } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import Autosuggest from 'react-autosuggest';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import dateRegex from '../../../utils/dateRegex';
import emojiRegex from '../../../utils/emojiRegex';

import emailRegex from '../../../utils/emailRegex';
import timeRegex from '../../../utils/timeRegex12H';
import getFirstErrorMessage from '../../../utils/getFirstErrorMessage';

import SnackbarAlert from '../../../components/SnackbarAlert';
import * as ReservationsApi from '../../../api/restaurants/reservations';

import './ReservationsCreateFormTwo.scss';
import * as ClientsApi from '../../../api/restaurants/clients';

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    // Add your custom styles here
  },
}));
const ReservationsCreateFormTwo = ({ nextStep, setAvaliabilityData, setNumberPeople, stepOneValues }) => {
  const [isFormTouched, setFormIsTouched] = useState(false);
  const [validateFields, setValidateFields] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [data, setData] = useState([
    { cellPhone: '7979686492', firstName: 'Maria', middleName: 'Abellan', lastName: null, email: 'MARIA.AADT@HOTMAIL.COM' },
    { cellPhone: '', firstName: 'chuck', middleName: null, lastName: 'norris', email: 'joshuamoises1995@gmail.com' },
  ]);

  //boolean to see if clients has been searched
  const [emailSearched, setEmailSearched] = useState(false);

  const [fields, setFields] = useState({
    date: format(new Date(), 'yyyy-MM-dd'),
    time: format(new Date(), 'hh:mm a'),
    numberPeople: '',
    email: '',
    name: '',
    middleName: '',
    lastName: '',
    phone: '',
    comments: '',
    categories: null,
    tableNumber: '',
    waitingList: false,
    event: false,
    startDate: format(new Date(), 'yyyy-MM-dd'),
    startTime: format(new Date(), 'hh:mm a'),
    endTime: format(new Date(), 'hh:mm a'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  });
  const [validations, setValidations] = useState({
    date: true,
    time: true,
    name: true,
    numberPeople: false,
    email: true,
    numberPeople: false,
    phone: true,
    startDate: true,
    startTime: true,
    endTime: true,
    endDate: true,
    // categories: false,
    // comments: true,
  });

  const [status, setStatus] = useState({
    error: null,
    isLoading: false,
    isValid: false,
  });



  const classes = useStyles();
  const [clientes, setclientes] = useState(data);
  const [value, setValue] = useState("");
  const [clienteSeleccionado, setclienteSeleccionado] = useState({});
  const onSuggestionsFetchRequested = ({ value }) => {

    setclientes(filtrarclientes(value));
  }

  const filtrarclientes = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    var filtrado = data.filter((cliente) => {
      var textoCompleto = cliente.cellPhone + " " + cliente.firstName + " " + cliente.middleName + " " + cliente.lastName + " " + cliente.email;

      if (textoCompleto.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(inputValue)) {
        return cliente;
      }
    });

    //limitar a 5 resultados
    filtrado = filtrado.slice(0, 8);
    return inputLength === 0 ? [] : filtrado;
  }

  const onSuggestionsClearRequested = () => {
    setclientes([]);
  }

  const getSuggestionValue = (suggestion) => {
    var emailorphone = "";
    //check if email is valid
    if (suggestion.email !== null && suggestion.email !== undefined && suggestion.email !== "") {
      emailorphone = suggestion.email;
    } else if (suggestion.cellPhone !== null && suggestion.cellPhone !== undefined && suggestion.cellPhone !== "") {
      emailorphone = suggestion.cellPhone;
    }
    return `${emailorphone}`;
  }

  const renderSuggestion = (suggestion) => (
    <div className='sugerencia' onClick={() => seleccionarcliente(suggestion)}>
      {`${suggestion.cellPhone ? suggestion.cellPhone : 'Sin teléfono'} - ${suggestion.firstName ? suggestion.firstName : 'Sin Nombre'} ${suggestion.middleName} ${suggestion.lastName} - ${suggestion.email ? suggestion.email : 'Sin correo'}`}
    </div>
  );

  const seleccionarcliente = (cliente) => {
    setclienteSeleccionado(cliente);
    var email = "";
    var phone = "";
    //check if email is valid

    if (cliente.email !== null && cliente.email !== undefined && cliente.email !== "") {
      email = cliente.email;
    } else if (cliente.cellPhone !== null && cliente.cellPhone !== undefined && cliente.cellPhone !== "") {
      phone = cliente.cellPhone;
    }
    console.log("MAIL OR PHONE");



    //update name with first and last name and middle name concatenated
    var name = "";
    if (cliente.firstName !== null && cliente.firstName !== undefined && cliente.firstName !== "") {
      name = cliente.firstName;
    }
    if (cliente.middleName !== null && cliente.middleName !== undefined && cliente.middleName !== "") {
      name = name + " " + cliente.middleName;
    }
    if (cliente.lastName !== null && cliente.lastName !== undefined && cliente.lastName !== "") {
      name = name + " " + cliente.lastName;
    }
    console.log({ email, name, phone });
    setFields({
      ...fields,
      email: email,
      name: name,
      phone: phone,
    });

    console.log("SELECCIONAR CLIENTE");
    console.log(fields);
  }
  useEffect(() => {
    console.log("Updated Fields:", fields);
  }, [fields]);
  const onChange = (e, { newValue }) => {
    //if the event was the user typing
    if (e.type === "change") {
      //check if the value is a valid email
      const cleanString = newValue.replace(emojiRegex, '').replace('  ', ' ');
      console.log('se llama a on change');
      updateFields('email', cleanString);
    }
    setValue(newValue);
  }
  const inputProps = {
    placeholder: "",
    value,
    id: 'email',
    'aria-describedby': 'email-helper-text',
    onChange
  };
  const eventEnter = (e) => {
    if (e.key == "Enter") {
      var split = e.target.value.split('-');
      var cliente = {
        cellPhone: split[0],
        firstName: split[1],
        middleName: split[2],
        lastName: split[3],
        email: split[4],
      };
      console.log("UPDATE FIELDS from evententer");


      seleccionarcliente(cliente);
    }
  }
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(format(new Date(), 'hh:mm a'));

  const [endDate, setEndDate] = useState(new Date());
  const [endTime, setEndTime] = useState(format(new Date(), 'hh:mm a'));
  const [validTimes, setValidTimes] = useState(false);


  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isCalendarStartDateOpen, setIsCalendarStartDateOpen] = useState(false);
  const [isCalendarStartTimeOpen, setIsCalendarStartTimeOpen] = useState(false);

  const [isCalendarEndDateOpen, setIsCalendarEndDateOpen] = useState(false);
  const [isCalendarEndTimeOpen, setIsCalendarEndTimeOpen] = useState(false);

  const [isCalendarEndOpen, setIsCalendarEndOpen] = useState(false);

  //const time equals 8am using moment
  const [selectedTime, setSelectedTime] = useState(moment().hour(8).minute(0).second(0).millisecond(0).toDate());
  // const [selectedTime, setSelectedTime] = useState(moment().utc());
  const maxReservationDate = moment().add(1, 'year').format('yyyy-MM-DD');

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  async function fetchData() {
    setEmailSearched(true);
    try {
      // ClientsApi.SHOWALLCLIENTS
      const { success, data, message } = await ClientsApi.showAllClientsForSearch();
      console.log(data);
      if (success === 1) {
        setData(data);
        console.log("DATA LOADED");

      }
    }
    catch (error) {

    }
  }
  useEffect(() => {
    if (emailSearched === false) {
      fetchData();
    }
    if (fields.numberPeople.length > 0) {
      setFormIsTouched(true);
    }

  }, [fields]);

  const areFieldsValid = () => (
    Object.values(validations).every((item) => item)
  );
  const updateCategoryField = (field, value) => {
    const newFields = {
      ...fields,
    };
    newFields[field] = value;
    setFields(newFields);
    // handleValidations(field, value);
  };

  const getCategoriesSelected = (cats) => {
    const categoriesNames = Object.keys(cats);
    const selectedCategories = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < categoriesNames.length; index++) {
      const element = cats[categoriesNames[index]];
      if (element) {
        // eslint-disable-next-line no-self-assign
        selectedCategories.push(categoriesNames[index]);
      }
    }
    return selectedCategories;
  };
  const handleCategoriesSelection = (event) => {
    setCategories({ ...categories, [event.target.name]: event.target.checked });
    updateCategoryField('categories', getCategoriesSelected({ ...categories, [event.target.name]: event.target.checked }));
  };

  const setDataIfEmailExists = (emailData) => {
    const userPhone = emailData.cellPhone;
    const userCategories = emailData.categories;
    const categoriesValues = categories;
    // eslint-disable-next-line no-plusplus

    for (let index = 0; index < userCategories.length; index++) {
      const element = userCategories[index].name;
      console.log(element);
      categoriesValues[element] = userCategories[index].status === 1;
    }

    setFields({
      ...fields,
      email: emailData.email,
      name: emailData.firstName,
      middleName: emailData.middleName !== undefined ? emailData.middleName : '',
      lastName: emailData.lastName !== undefined ? emailData.lastName : '',
      phone: userPhone ? userPhone.replace(/\D/g, '') : '',
      categories: emailData.categories,
      comments: emailData.comments !== undefined ? emailData.comments : '',
      clientId: emailData.id,
    });
    setEmailExists(true);
    // setCategories(categoriesValues);
    setValidations({
      ...validations,
      email: true,
      name: true,
      middleName: true,
      lastName: true,
      phone: true,

      // comments: true,
      // categories: true,
    });
  };

  const removeDataIfEmailNotExists = () => {
    setCategories(prepareCategories);
    setFields({
      ...fields,
      name: '',
      middleName: '',
      lastName: '',
      categories: null,
      comments: '',
      waitingList: false,
      event: false,

    });
    setEmailExists(false);
    setValidations({
      ...validations,
      email: true,
      name: false,
      middleName: true,
      lastName: true,
      phone: true,
      numberPeople: false,


      // comments: true,
      // categories: false,
    });
  };

  const handleEmailSearch = async () => {

    if ((isEmailValid || isPhoneValid) && !status.isLoading) {
      setStatus({
        ...status,
        isLoading: true,
        error: null,
      });

      try {
        setStatus({
          ...status,
          isLoading: true,
        });
        const {
          success, data, message, isStaff,
        } = await ClientsApi.searchClientByEmailOrPhone((
          isEmailValid ? fields.email : fields.phone));
        if (success === 0 && !isStaff) {
          setStatus({
            ...status,
            isLoading: false,
            error: true,
            message,
            success: false,
          });
          removeDataIfEmailNotExists();
          setHasCheckedEmail(true);
        }
        if (success === 1) {
          setStatus({
            ...status,
            isLoading: false,
            error: false,
            message: data,
            success: false,
          });
          setDataIfEmailExists(data);
          setHasCheckedEmail(true);
        }
        if (success === 0 && isStaff) {
          setStatus({
            ...status,
            isLoading: false,
            error: true,
            message,
            success: false,
          });
          removeDataIfEmailNotExists();
          setHasCheckedEmail(false);
          setSnackbar({
            open: true,
            severity: 'error',
            text: message,
          });
        }
        // // setStatus({
        // //   ...status,
        // //   isLoading: false,
        // //   error: message,
        // // });
      } catch (error) {
        console.log(error);
        setStatus({
          ...status,
          isLoading: false,
          error: false,
          message: null,
          success: false,
        });
        removeDataIfEmailNotExists();
        setHasCheckedEmail(false);
      }
    }
  };
  const updateSearchResults = (value) => {
    const results = searchResults.filter((result) => result.toLowerCase().includes(value.toLowerCase()));
    setSearchResults(results);
  }
  const checkIfEmailChanges = (value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    console.log(cleanString);
    updateFields('email', cleanString);

  };
  const checkIfPhoneChanges = (value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    if (hasCheckedEmail && fields.phone !== cleanString) {
      removeDataIfEmailNotExists();
      setHasCheckedEmail(false);
    }
    updateFields('phone', cleanString);
  };

  const checkifvaliddate = () => {
    return true;
  }
  const handleValidations = (field, value) => {
    const mapValidations = {
      date: (date) => date.length > 0,
      time: (time) => time.length > 0,
      numberPeople: (numberPeople) => Number(numberPeople) > 0,
      email: (email) => true,
      name: (name) => true,
      middleName: () => true,
      lastName: () => true,
      tableNumber: () => true,
      phone: () => true,
      comments: (comments) => comments.length < 250,
      waitingList: () => true,
      event: () => true,
      //check startdate is a valid date
      startDate: (startDate) => true,
      //check if startTime is a valid time
      startTime: (time) => true,
      endDate: (ddate) => true,
      endTime: (time) => true,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

  const updateFields = (field, value) => {
    const newFields = {
      ...fields,
    };
    if (field === 'event' && value === true) {
      newFields.waitingList = false;
    }
    if (field === 'waitingList' && value === true) {
      newFields.event = false;
    }
    newFields[field] = value;
    setFields(newFields);
    handleValidations(field, value);

  };


  const handleDateChange = (date) => {
    setSelectedDate(date);
    // if (dateRegex.test(date)) {
    // }
    updateFields('date', format(new Date(date), 'yyyy-MM-dd'));
  };

  const validatedates = (sdate, stime, edate, etime) => {

    const choosenDateTime = `${sdate} ${stime}`;
    const endDateTime = `${edate} ${etime}`;
    const startDate = new Date(choosenDateTime);
    const endDate = new Date(endDateTime);

    if (endDate > startDate) {
      console.log("end date is greater than start date");
      setValidations({ ...validations, endDate: true, endTime: true });
      return true;

    } else {
      setValidations({ ...validations, endDate: false, endTime: false });

      return false;
    }
  }
  const handleDateStartDateChange = (date) => {
    setStartDate(date);
    const target = format(new Date(date), 'yyyy-MM-dd');
    // if (dateRegex.test(date)) {
    // }
    updateFields('startDate', target);
    //validatedates(target, fields.startTime, fields.endDate, fields.endTime);
  };
  const handleDateEndDateChange = (date) => {
    setEndDate(date);
    // if (dateRegex.test(date)) {
    // }
    const target = format(new Date(date), 'yyyy-MM-dd');

    updateFields('endDate', target);
    //validatedates(fields.startDate, fields.startTime, target, fields.endTime);
  };
  const handleDateStartTimeChange = (date) => {
    setStartTime(date);
    const target = format(new Date(date), 'HH:mm:ss');

    updateFields('startTime', target);
    //validatedates(fields.startDate, target, fields.endDate, fields.endTime);
  };

  const handleDateEndTimeChange = (date) => {
    setEndTime(date);
    const target = format(new Date(date), 'HH:mm:ss');

    updateFields('endTime', target);
    //validatedates(fields.startDate, fields.startTime, fields.endDate, target);
  };
  const handleTimeChange = (time, value) => {
    console.log(timeRegex.test(value));
    if (timeRegex.test(value)) {
      const choosenTime = format(time, 'hh:mm a');
      const choosenDate = fields.date;
      const choosenDateTime = `${choosenDate} ${choosenTime}`;
      if (isPast(new Date(choosenDateTime))) {
        alert('no puedes escoger una hora antes de la hora actual');
      } else {
        setSelectedTime(time);
        updateFields('time', choosenTime);
      }
    } else {
      setValidations({ ...validations, time: false });
      console.log(time);
    }
  };

  const previousStep = () => {
    nextStep(1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (areFieldsValid && !status.isLoading) {
      setStatus({
        ...status,
        isLoading: true,
        error: null,
      });
      const time = moment(`1988-01-08 08:00 am`, 'YYYY-MM-DD hh:mm a').format('HH:mm');
      const date = `${format(new Date(selectedDate), 'yyyy-MM-dd')} ${time}`;
      try {
        setStatus({
          ...status,
          isLoading: true,
        });
        const { success, data, message } = await ReservationsApi
          .getAvaliability(date, fields.numberPeople, fields.event, fields.startDate, fields.startTime, fields.endDate, fields.endTime);
        if (success === 0) {
          setStatus({
            ...status,
            isLoading: false,
            error: true,
            message,
            success: false,
          });
          setSnackbar({
            open: true,
            severity: 'error',
            text: getFirstErrorMessage(message),
          });
        }
        if (success === 1) {
          setStatus({
            ...status,
            isLoading: false,
            error: false,
            message: data,
            success: false,
          });
          setAvaliabilityData(data);
          setNumberPeople(fields.numberPeople);
          if (fields.comments === ' ') {
            const fieldsNoComments = fields;
            delete fieldsNoComments.comments;
            stepOneValues(fieldsNoComments);
          } else {
            stepOneValues({ ...fields });
          }
          console.log(fields);
          nextStep(3);
        }
      } catch (error) {
        console.log(error.message);
        setSnackbar({
          open: true,
          severity: 'error',
          text: error.message,
        });
      }
    }
  };

  const catalogCategory = JSON.parse(localStorage.getItem('catalogCategory'));
  const prepareCategories = () => {
    const categoriesList = {};
    catalogCategory.forEach((element) => {
      categoriesList[element.name] = false;
    });
    return categoriesList;
  };

  //handle phone change
  const HandlePhoneChange = (value) => {
    console.log(value);

  };
  const [categories, setCategories] = useState(prepareCategories);
  const isEmailValid = (validations.email);
  const isPhoneValid = (validations.phone) && isFormTouched;
  const [hasCheckedEmail, setHasCheckedEmail] = useState(false);
  const [emailExists, setEmailExists] = useState(false);

  useEffect(() => {
    if (fields.email.length > 0 || fields.phone.length > 0) {
      setFormIsTouched(true);
    }
    if (hasCheckedEmail && isFormTouched) {
      setValidateFields(true);
    }
  }, [fields, hasCheckedEmail, isFormTouched]);

  return (
    <Box
      component="div"
      className="mt-1"
    >
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      {' '}
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
        <form className="w100 dFlex flex-column mb-4 reservationsCreateForm" onSubmit={handleSubmit} autoComplete="off">
          <div className="w100">
            <InputLabel htmlFor="email" className="email-label">
              Correo electrónico o Teléfono (opcional)
            </InputLabel>
            <Autosuggest
              classes={{ root: classes.autocomplete }}
              className="w100 searchInput"
              suggestions={clientes}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={{
                ...inputProps,
                id: 'email',
                'aria-describedby': 'email-helper-text',
              }}
              onSuggestionSelected={eventEnter}
              value={fields.email}
            />
          </div>
          <div>
            <p>
              <b>Nota:</b> No es necesario apuntar el número de teléfono o e-mail
            </p>
          </div>
          <div className="w100 mb-1">
            <TextField
              className="w100"
              error={!validations.name}
              id="name"
              label={<span className={!validations.name ? 'error-label' : ''}>Nombres y Apellidos <b className='textRed'>*</b></span>}
              onChange={(e) => updateFields('name', e.target.value)}
              value={fields.name}
            />
          </div>
          <div className="w100">
            <TextField
              className="w100"
              error={!isFormTouched ? false : !validations.numberPeople}
              id="numberPeople"
              label={<span>¿Cuántas personas asisten? <b className='textRed'>*</b></span>}
              onChange={(e) => updateFields('numberPeople', e.target.value)}
              value={fields.numberPeople}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="w100 mb-1">
            <KeyboardDatePicker
              onClick={() => { setIsCalendarOpen(true); }}
              onClose={() => setIsCalendarOpen(false)}
              open={isCalendarOpen}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label={ <span>¿Cuándo quieren reservar? <b className='textRed'>*</b></span> }
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              autoOk
              invalidDateMessage="No es un formato válido"
              maxDate={maxReservationDate}
              maxDateMessage="No puedes escoger una fecha mayor a un año"
              disablePast
            />
          </div>
          <div className="w100 mb-1">
            <TextField
              className="w100"
              id="comments"
              label="Comentarios (opcional)"
              onChange={(e) => updateFields('comments', e.target.value)}
              value={fields.comments}
            />
          </div>
          <div className="w100 mt-1">
            <FormControl component="fieldset">
              <FormLabel component="legend">Categoría (opcional)</FormLabel>
              <FormGroup className="dFlex flex-row">
                {catalogCategory.map((category) => (
                  <FormControlLabel
                    key={uuid()}
                    label={(category.name === 'Blacklist') ? 'No deseado' : category.name}
                    control={(
                      <Checkbox
                        checked={categories[category.name]}
                        name={category.name}
                        disableRipple
                        color="primary"
                        onChange={(e) => { handleCategoriesSelection(e); }}
                      />
                    )}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
          <div className="w100 mb-1">
            <TextField
              className="w100"
              id="tableNumber"
              label="Mesa (opcional)"
              onChange={(e) => updateFields('tableNumber', e.target.value)}
              value={fields.tableNumber}

            />
          </div>
          <FormGroup>
            <FormControlLabel label="Añadir a la lista de espera (opcional, este botón sirve para anotar a aquellos clientes que llegan al restaurante y no hay mesas disponible. De esta manera se les puede notificar tan pronto haya una mesa disponible)" className="redy" control={<Checkbox onChange={(e) => updateFields('waitingList', !fields.waitingList)} color="primary" id="waitingList" name="waitingList" checked={fields.waitingList} value={fields.waitingList} />} />
          </FormGroup>
          <FormGroup>
            <FormControlLabel label="BLOQUEAR AREA POR EVENTO. No se podrán hacer mas reservas en este área. (opcional)" className="event bold" control={<Checkbox onChange={(e) => updateFields('event', !fields.event)} color="primary" id="event" name="event" checked={fields.event} value={fields.event} />} />
          </FormGroup>
          <div>
            <div>Ejemplo de eventos: cumpleaños, graduación, cena de empresa. </div>
            <div className='text-black'>SI lo desea, siempre se puede quitar la categoría ”Evento” en Lista de reservas en ”Editar mesa”</div>
          </div>
          {fields.event && (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <div className="w100">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <KeyboardDatePicker
                      onClick={() => { setIsCalendarStartDateOpen(true); }}
                      onClose={() => setIsCalendarStartDateOpen(false)}
                      open={isCalendarStartDateOpen}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Fecha de inicio del evento"
                      invalidLabel='Fecha invalida'
                      invalidDateMessage="No es un formato válido"
                      invalidformatMessage="No es un formato válido"
                      value={startDate}
                      onChange={handleDateStartDateChange}
                      //header disables
                      disableToolbar
                      disablePast
                      autoOk
                      error={!validations.startDate}

                    >

                    </KeyboardDatePicker>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <KeyboardTimePicker
                      onClick={() => { setIsCalendarStartTimeOpen(true); }}
                      onClose={() => setIsCalendarStartTimeOpen(false)}
                      open={isCalendarStartTimeOpen}
                      variant="inline"
                      format="hh:mm a"
                      margin="normal"
                      id="date-picker-inline"
                      label="Hora de inicio del evento"
                      invalidDateMessage="Por favor ingrese una hora válida"
                      invalidLabel='Por favor ingrese una hora válida'
                      invalidformatMessage="Por favor ingrese una hora válida"
                      value={startTime}
                      onChange={handleDateStartTimeChange}
                      disablePast
                      autoOk
                      error={!validations.startTime}
                      shrink
                      InputLabelProps={{ shrink: true }}
                    >
                    </KeyboardTimePicker>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} md={6}>
                    <KeyboardDatePicker
                      onClick={() => { setIsCalendarEndDateOpen(true); }}
                      onClose={() => setIsCalendarEndDateOpen(false)}
                      open={isCalendarEndDateOpen}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Fecha de finalización del evento"
                      value={endDate}
                      onChange={handleDateEndDateChange}
                      //header disables
                      disableToolbar
                      disablePast
                      autoOk
                      error={!validations.endDate}
                    >

                    </KeyboardDatePicker>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <KeyboardTimePicker
                      onClick={() => { setIsCalendarEndTimeOpen(true); }}
                      onClose={() => setIsCalendarEndTimeOpen(false)}
                      open={isCalendarEndTimeOpen}
                      variant="inline"
                      format="hh:mm a"
                      margin="normal"
                      id="endtime-picker-inline"
                      label="Hora de Finalización del evento"
                      invalidDateMessage="Por favor ingrese una hora válida"
                      invalidLabel='Por favor ingrese una hora válida'
                      invalidformatMessage="Por favor ingrese una hora válida"
                      value={endTime}
                      onChange={handleDateEndTimeChange}
                      disablePast
                      shrink
                      autoOk
                      InputLabelProps={{ shrink: true }}
                      error={!validations.endTime}
                    >
                    </KeyboardTimePicker>
                  </Grid>
                </Grid>
              </div>
            </MuiPickersUtilsProvider>

          )}

          <Box
            component="div"
            className="mt-3 dFlex flex-column align-center justify-center"
          >
            {status.isLoading && (
              <CircularProgress color="primary" className="mb-2" />
            )}
            {/* {status.error === true && (
          <Box
            component="div"
            className="mt-2 dFlex flex-column align-center justify-center color-error"
          >
            {status.message}
          </Box>
          )} */}
            <Hidden xsDown>
              <div className="reservationsCreateForm__button-group">
                <Button
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={() => { navigate('/reservaciones'); }}
                >
                  <span className="underline">
                    CANCELAR
                  </span>
                </Button>
                <div className="dFlex">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!areFieldsValid()}
                  >
                    SIGUIENTE
                  </Button>
                </div>
              </div>
            </Hidden>
            <Hidden smUp>
              <div className="reservationsCreateForm__button-group-mobile mt-1">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!areFieldsValid()}
                  className="w100"
                >
                  SIGUIENTE
                </Button>
                <div className="dFlex justify-between">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={() => { previousStep(); }}
                  >
                    <span className="underline">
                      PASO ANTERIOR
                    </span>
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={() => { navigate('/reservaciones'); }}
                  >
                    <span className="underline">
                      CANCELAR
                    </span>
                  </Button>
                </div>
              </div>
            </Hidden>
          </Box>
        </form>
      </MuiPickersUtilsProvider>
    </Box>
  );
};

ReservationsCreateFormTwo.propTypes = {
  nextStep: PropTypes.func.isRequired,
  setAvaliabilityData: PropTypes.func.isRequired,
  setNumberPeople: PropTypes.func.isRequired,
  stepOneValues: PropTypes.func.isRequired,
};
export default ReservationsCreateFormTwo;
