import React, { useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { navigate } from '@reach/router';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import StaffContext from '../../layouts/Staff/StaffContext/StaffContext';
import MenuTableOptions from '../MenuTableOptions';
import DialogDeleteStaff from '../DialogDeleteStaff';
import SnackbarAlert from '../SnackbarAlert';
import * as StaffApi from '../../api/restaurants/staff';
import isTruthy from '../../utils/isTruthy';
import './StaffAccordion.scss';

const StaffAccordion = () => {
  const { filteredStaff, setReload } = useContext(StaffContext);
  const [deleteStaffDialog, setDeleteStaffDialog] = useState(false);
  const [deleteStaffDialogData, setDeleteStaffDialogData] = useState({ name: '', id: null });
  // Configuración para el snackbar alert
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const closeDeleteStaffDialog = () => {
    setDeleteStaffDialog(false);
  };
  const openDeleteStaffDialog = (id, email) => {
    setDeleteStaffDialogData({ email, id });
    setDeleteStaffDialog(true);
  };

  const menuOptions = (id, email) => [
    { key: 1, string: 'EDITAR', action: () => navigate(`/editar-staff/${id}`) },
    { key: 2, string: 'ELIMINAR', action: () => { openDeleteStaffDialog(id, email); } },
  ];

  const deleteStaff = async () => {
    console.log(deleteStaffDialogData);
    try {
      const { success, message, data } = await StaffApi
        .deleteStaff(deleteStaffDialogData.id);
      if (isTruthy(success)) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
        closeDeleteStaffDialog();
        setTimeout(() => {
          setReload();
        }, 1000);
      } else {
        console.log(data);
        setSnackbar({
          open: true,
          severity: 'error',
          text: data.data,
        });
        closeDeleteStaffDialog();
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
      closeDeleteStaffDialog();
    }
  };
  const renderAccordion = () => (
    <div className="staff-accordion__container mb-3">
      <DialogDeleteStaff
        isOpen={deleteStaffDialog}
        onYes={deleteStaff}
        onNo={closeDeleteStaffDialog}
        data={deleteStaffDialogData}
      />
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      {filteredStaff.map((item) => (
        <Accordion key={uuid()} className="staff-accordion">
          <AccordionSummary
            className="staff-accordion__summary"
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="staff-accordion__info">
              <Typography className="staff-accordion__name ">
                {item.email}
              </Typography>
              <div>
                <div className="font__roboto">
                  {item.role}
                  {' • '}
                  {item.createdAt}
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="staff-accordion__expansion" />
            <MenuTableOptions id={item.id} options={menuOptions(item.id, item.email)} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );

  return (
    <>
      {renderAccordion()}
    </>
  );
};

export default StaffAccordion;
