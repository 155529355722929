import React from 'react';
import PropTypes from 'prop-types';

import './AddressSuggestions.scss';

const AddressSuggestions = ({ suggestions, onSelect }) => (
  <ul className="address-suggestions">
    {suggestions.map((suggestion) => (
      <li className="address-suggestion" key={suggestion.id}>
        <button className="address-suggestion__button" onClick={() => { onSelect(suggestion); }} type="button">
          {suggestion.description}
        </button>
      </li>
    ))}
  </ul>
);

AddressSuggestions.propTypes = {
  suggestions: PropTypes.array, //eslint-disable-line
  onSelect: PropTypes.func,
};

AddressSuggestions.defaultProps = {
  onSelect: () => {},
};

export default AddressSuggestions;
