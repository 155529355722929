/* eslint-disable import/no-named-as-default-member */
// eslint-disable-next-line import/no-named-as-default
import client from './utils/client';
// eslint-disable-next-line import/no-named-as-default
// import TokenManager from './utils/TokenManager';

const catalogCategory = () => client('catalogs/category')
  .then((response) => response);
const catalogClientStatus = () => client('catalogs/client-status')
  .then((response) => response);
const catalogCollaborator = () => client('catalogs/collaborator')
  .then((response) => response);
const catalogCountry = () => client('catalogs/country')
  .then((response) => response);
const catalogDay = () => client('catalogs/day')
  .then((response) => response);
const catalogGender = () => client('catalogs/gender')
  .then((response) => response);
const catalogProfession = () => client('catalogs/profession')
  .then((response) => response);
const catalogReservationStatus = () => client('catalogs/reservation-status')
  .then((response) => response);
const catalogRole = () => client('catalogs/role')
  .then((response) => response);
const catalogSocialMedia = () => client('catalogs/social-media')
  .then((response) => response);
const catalogTableType = () => client('catalogs/table-type')
  .then((response) => response);
const catalogTableType2 = (data) => client('catalogs/table-type', {
  body: data,
  method: 'GET',
}).then((response: any) => response);

const catalogCost = () => client('catalogs/cost')
  .then((response) => response);
const catalogKitchen = () => client('catalogs/kitchen')
  .then((response) => response);
const catalogFood = () => client('catalogs/food')
  .then((response) => response);
const catalogExtraService = () => client('catalogs/extra-service')
  .then((response) => response);
const catalogAllergy = () => client('catalogs/allergy')
  .then((response) => response);
const catalogDrink = () => client('catalogs/drink')
  .then((response) => response);
const catalogIndustry = () => client('catalogs/industry')
  .then((response) => response);
const catalogNutritionalRequirement = () => client('catalogs/nutritional-requirement')
  .then((response) => response);
const catalogTable = () => client('catalogs/table')
  .then((response) => response);

export {
  catalogAllergy,
  catalogCategory,
  catalogClientStatus,
  catalogCollaborator,
  catalogCost,
  catalogCountry,
  catalogDay,
  catalogDrink,
  catalogExtraService,
  catalogFood,
  catalogGender,
  catalogIndustry,
  catalogKitchen,
  catalogNutritionalRequirement,
  catalogProfession,
  catalogReservationStatus,
  catalogRole,
  catalogSocialMedia,
  catalogTable,
  catalogTableType,
  catalogTableType2,
};
