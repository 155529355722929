/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { navigate } from '@reach/router';
// import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/es';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import AddIcon from '@material-ui/icons/Add';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import { Tooltip, withStyles } from '@material-ui/core/';
import Zoom from '@material-ui/core/Zoom';
import MuiAlert from '@material-ui/lab/Alert';
import * as CatalogsApi from '../../api/catalogs';
import * as ReservationsApi from '../../api/restaurants/reservations';
import MenuTableOptions from '../MenuTableOptions';
import MenuReservationStatus from '../MenuReservationStatus';
import prepareCategoryList from '../../utils/prepareCategoryList';
import ReservationsContext from '../../layouts/Reservations/ReservationsContext/ReservationsContext';
import DialogCancelReservation from '../DialogCancelReservation';
import DialogEditClient from '../DialogEditClient';
import DialogImage from '../DialogImage';
import DialogHistory from '../DialogHistory';

import Iconcake from '../../images/errorIcons/cake.png';
import Iconstar from '../../images/categoryIcons/estrella.png';
import Iconwaiting from '../../images/categoryIcons/waiting.png';
import Iconevent from '../../images/categoryIcons/evento.png';
import IconPeople from '../../images/categoryIcons/people.png';
import './ReservationsTable.scss';
import isTruthy from '../../utils/isTruthy';
import DefaulImage from '../../images/default.jpeg';

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'black !important',
    color: 'rgba(255, 255, 255, 1)',
    border: 'solid 1px rgb(0,0,0)',
    boxShadow: 'none',
    fontSize: 11,
  },
}))(Tooltip);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    key: '1',
    id: 'photo',
    disablePadding: false,
    label: 'FOTO',
    sortable: true,
  },
  {
    key: '2',
    id: 'tableNumber',
    disablePadding: false,
    label: 'CONTACTO',
    sortable: true,
  }, {
    key: '3',
    id: 'categoria',
    label: 'CATEGORÍA',
    disablePadding: false,
    sortable: false,
  }, {
    key: '4',
    id: 'nombre',
    label: 'NOMBRE',
    disablePadding: false,
    sortable: true,
  },
  {
    key: '5',
    id: 'mesa',
    label: 'MESA',
    disablePadding: false,
    sortable: true,
  }, {
    key: '6',
    id: 'zona',
    label: 'ZONA',
    disablePadding: false,
    sortable: true,
  }, {
    key: '7',
    id: 'fecha',
    label: 'HORA',
    disablePadding: false,
    sortable: true,
  }, {
    key: '8',
    id: 'asistentes',
    label: 'ASISTENTES',
    disablePadding: false,
    sortable: true,
  }, {
    key: '9',
    id: 'status',
    label: 'ESTATUS',
    disablePadding: false,
    sortable: true,
  }, {
    key: '10',
    id: 'comments',
    label: 'COMENTARIOS',
    disablePadding: false,
    sortable: false,
  }, 
  {
    key: '11',
    id: 'visitas',
    label: 'VISITAS',
    disablePadding: false,
    sortable: false,
  },
  {
    key: '12',
    id: 'menu',
    label: 'EDITAR',
    disablePadding: false,
    sortable: false,
  },
  {
    key: '13',
    id: 'modalhistory',
    label: ' ',
    disablePadding: false,
    sortable: false,
  },
];

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={uuid()}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >

                {(headCell.label !== 'ASISTENTES') ? headCell.label
                  : (
                    <LightTooltip title="Esta es la cantidad de asistentes" TransitionComponent={Zoom} placement="top-center">
                      <span className="clientname capitalized">
                        <img src={IconPeople} alt="asistentes" className="iconpeople" />
                      </span>
                    </LightTooltip>
                  )}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
            {!headCell.sortable && (
              <div>
                {headCell.label}
              </div>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    borderBottom: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const EnhancedTable = () => {
  const classes = useStyles();
  const {
    apiCallConfig,
    filterdReservations, setApiCallConfig,
    title,
  } = useContext(ReservationsContext);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = React.useState(apiCallConfig.sortDirection);
  const [orderBy, setOrderBy] = React.useState(apiCallConfig.sortCategory);
  const [pagination] = useState(apiCallConfig);
  const [catalogReservationStatus, setCatalogReservationStatus] = useState({});
  const [cancelDialog, setCancelDialog] = useState(false);
  const [imageDialog, setImageDialog] = useState(false);
  const [historyDialog, setHistoryDialog] = useState(false);
  const [historyDialogData, setHistoryDialogData] = useState({ created_by: '', status_modified_by: '', modified_by: '', deleted_by: ''  });

  const [EditClientDialog, setEditClientDialog] = useState(false);
  const [editClientDialogData, setEditClientDialogData] = useState({ name: '', clientId: null });
  const [imageDialogData, setImageDialogData] = useState('');

  const [cancelDialogData, setCancelDialogData] = useState({ name: '', reservationId: null });
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  const snackbarHandleClose = () => {
    setSnackbar({ open: false });
  };
  const div = document.getElementById('scrollingdiv');
  // const hasHorizontalScrollbar = div.scrollWidth > div.clientWidth;
  const btncontainer = document.getElementById('buttonsContainer');

  const scroll = (scrollOffset) => {
    div.scrollLeft += scrollOffset;
  };

  useEffect(() => {
    const calatogReservationStatus = localStorage.getItem('catalogReservationStatus');

    const getcatalogs = async () => {
      try {
        const { success, data } = await CatalogsApi
          .catalogReservationStatus();
        if (isTruthy(success)) {
          setCatalogReservationStatus(data);
          localStorage.setItem('catalogReservationStatus', JSON.stringify(data));
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (calatogReservationStatus === null || calatogReservationStatus === 'undefined') {
      getcatalogs();
    } else {
      setCatalogReservationStatus(JSON.parse(calatogReservationStatus));
      setLoading(false);
    }
  }, [div, btncontainer]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    localStorage.setItem('ReservationsConfigSortCategory', property);
    localStorage.setItem('ReservationsConfigSortDirection', isAsc ? 'desc' : 'asc');
    setApiCallConfig({
      ...pagination,
      sortCategory: property,
      sortDirection: isAsc ? 'desc' : 'asc',
    });
  };

  const closeCancelDialog = () => {
    setCancelDialog(false);
  };
  const closeImageDialog = () => {
    setImageDialog(false);
  };
  const closeHistoryDialog = () => {
    setHistoryDialog(false);
  };
  const closeEditClientDialog = () => {
    setEditClientDialog(false);
  };
  const cancelReservation = async () => {
    try {
      const { success, message } = await ReservationsApi
        .cancelReservation(cancelDialogData.reservationId);
      if (success === undefined && message === undefined) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: 'Ok',
        });
        setCancelDialog(false);
        setApiCallConfig({
          ...pagination,
        });
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
        setCancelDialog(false);
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
      setCancelDialog(false);
    }
  };

  const openCancelDialog = (reservationId, name) => {
    setCancelDialogData({ name, reservationId });
    setCancelDialog(true);
  };
  const openImageDialog = (image) => {
    setImageDialogData(image);
    setImageDialog(true);
  };
  const openHistoryDialog = (created_by, status_modified_by, modified_by, deleted_by) => {
    setHistoryDialogData({ created_by, status_modified_by, modified_by, deleted_by });
    setHistoryDialog(true);
  };
  const openEditClientDialog = (name, clientId) => {
    setEditClientDialogData({ name, clientId });
    setEditClientDialog(true);
  };
/*
  const menuOptions = (reservationId, name, table, date, clientId) => [
    { key: 1, string: 'EDITAR RESERVA', action: () => navigate(`/editar-reservacion/${reservationId}/${table}/${date}`) },
    { key: 2, string: 'CANCELAR', action: () => { openCancelDialog(reservationId, name); } },
    { key: 3, string: 'EDITAR CLIENTE', action: () => { openEditClientDialog(name, clientId); } },
  ];*/

  //rewrite menuOptions to edit table variable before returning the array
  const menuOptions = (reservationId, name, table, date, clientId) => {
    //escape the '/' character from table url
    const tableNumber = table.replace(/\//g, '%2F');
    return [
      { key: 1, string: 'EDITAR RESERVA', action: () => navigate(`/editar-reservacion/${reservationId}/${tableNumber}/${date}`) },
      { key: 2, string: 'CANCELAR', action: () => { openCancelDialog(reservationId, name); } },
      { key: 3, string: 'EDITAR CLIENTE', action: () => { openEditClientDialog(name, clientId); } },
    ];
  };


  const showMenuOptions = () => true;
  const renderDayRow = () => (
    <TableRow>
      <TableCell colSpan={13} align="left" className="limiterRow">
        DÍA
        {' '}
        <span className="guion">-</span>
        {' '}
        <span className="fontAndaleMono">
          {title}
        </span>
      </TableCell>
    </TableRow>

  );
  const renderNightRow = () => (
    <TableRow>
      <TableCell colSpan={13} align="left" className="limiterRow">
        NOCHE
        {' '}
        <span className="guion">-</span>
        {' '}
        <span className="fontAndaleMono">
          {title}
        </span>
      </TableCell>
    </TableRow>

  );
  const renderBirthday = (value) => {
    const bday = moment(value);
    const iscurrentDate = bday.isSame(new Date(), 'day');
    const iscurrentMonth = bday.isSame(new Date(), 'month'); // false, different year
    return iscurrentDate && iscurrentMonth && (
      <>
        <div className="cakespan">
          <img src={Iconcake} alt="cake icon" className="iconcake" />
          <span className="cakeText">Hoy cumple años</span>
        </div>
      </>
    );
  };
  const renderpardepan = () => (
    <>
      <div className="cakespan">
        <img src={Iconstar} style={{ width: '17px', height: '17px', marginRight: '4px' }} alt="cake icon" />
        <span style={{ paddingTop: '1px', marginRight: '4px' }}>Reserva Ya!</span>
      </div>
    </>
  );

  const renderwaitinglist = () => (
    <>
      <div className="cakespan">
        <img src={Iconwaiting} style={{ width: '17px', height: '17px', marginRight: '4px' }} alt="cake icon" />
        <span style={{ paddingTop: '1px', marginRight: '4px' }}>Lista de espera</span>
      </div>
    </>
  );
  const renderevent = (event) => (
    <>
      <LightTooltip title={`La zona ha sido reservada por un evento que se celebra el ${moment(event.dateRaw).format('LL hh:mm A')}. La zona ha sido bloqueada desde el ${moment(event.start_date).format('LL hh:mm A')}, hasta el  ${moment(event.end_date).format('LL hh:mm A')} `} TransitionComponent={Zoom} placement="top-center">
        <div className="cakespan">
          <img src={Iconevent} style={{ width: '17px', height: '17px', marginRight: '4px' }} alt="cake icon" />
          <span style={{ paddingTop: '1px', marginRight: '4px' }}>
            <b>Evento</b>
          </span>
        </div>
      </LightTooltip>
    </>
  );
  const renderTable = () => (
    <div className={classes.root}>
      <DialogImage
        isOpen={imageDialog}
        onNo={closeImageDialog}
        data={imageDialogData}
      />
      <DialogHistory
        isOpen={historyDialog}
        onNo={closeHistoryDialog}
        data={historyDialogData}
      />
      <DialogCancelReservation
        isOpen={cancelDialog}
        onYes={cancelReservation}
        onNo={closeCancelDialog}
        data={cancelDialogData}
      />
      <DialogEditClient
        isOpen={EditClientDialog}
        onNo={closeEditClientDialog}
        data={editClientDialogData}
      />
      <Snackbar
        open={snackbar.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={snackbarHandleClose}
      >
        <MuiAlert severity={snackbar.severity}>
          {snackbar.text}
        </MuiAlert>
      </Snackbar>
      <Paper className={classes.paper} elevation={0}>
        <div className="buttonsContainer" id="buttonsContainer">
          <IconButton
            onClick={() => scroll(-20)}
            edge="start"
            color="primary"
            aria-label="menu"
            size="small"
          >
            <ArrowBackIos />
          </IconButton>
          <IconButton
            onClick={() => scroll(+20)}
            edge="start"
            color="primary"
            aria-label="menu"
            size="small"
          >
            <ArrowForwardIos />
          </IconButton>
        </div>
        <TableContainer className="scrollingdiv" id="scrollingdiv">
          <Table
            className={`${classes.table} reservations-table`}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filterdReservations.length}
            />
            <TableBody>
              {stableSort(filterdReservations, getComparator(order, orderBy))
                .map((row, index, arr) => (
                  <>
                    {((moment('05:00 pm', 'hh:mm A') > moment(arr[index].time, 'hh:mm A'))) ? index === 0 && renderDayRow() : index === 0 && renderNightRow()}
                    {(orderBy === 'fecha' && order === 'asc' && index !== 0 && ((moment('05:00 pm', 'hh:mm A') <= moment(arr[index].time, 'hh:mm A')) && (moment('05:00 pm', 'hh:mm A') > moment(arr[index - 1].time, 'hh:mm A')))) ? renderNightRow() : ''}

                    {(orderBy === 'fecha' && order === 'desc' && index !== 0 && ((moment('05:00 pm', 'hh:mm A') >= moment(arr[index].time, 'hh:mm A')) && (moment('05:00 pm', 'hh:mm A') < moment(arr[index - 1].time, 'hh:mm A')))) ? renderDayRow() : ''}

                    <TableRow
                      hover
                      tabIndex={-1}
                      key={uuid()}
                    >
                      <TableCell
                        key={uuid()}
                        align="left"
                      >
                        <button type="button" onClick={() => openImageDialog((row.photo === null || row.photo === '') ? DefaulImage : row.photo)} tabIndex={-1} key={uuid()} className="photocontainer" width="100px">
                          {(row.photo === null || row.photo === '') ? (
                            <>
                              <img src={DefaulImage} alt="" width="100%" />
                            </>
                          ) : (
                            <>
                              <img src={row.photo} alt="" width="100%" />
                            </>
                          )}

                        </button>
                      </TableCell>
                      <TableCell
                        key={uuid()}
                        align="left"
                        className="reducido"
                      >
                        <div>
                          {(row.phone === 'Sin datos' || row.email === 'client@kokonutstudio.com') ? '' : row.phone}
                        </div>
                        <div>
                          {(row.email === 'Sin datos' || row.email === 'client@kokonutstudio.com') ? '' : row.email}
                        </div>
                      </TableCell>
                      <TableCell key={uuid()} align="left">
                        <div className="row-categories">
                          {row.categories.length > 0 ? row.categories.map((category) => prepareCategoryList(category)) : 'Sin datos'}
                        </div>
                        {row.birthday !== null && row.birthday !== '' && renderBirthday(row.birthday)}
                        {row.type === 'pardepan' && title === 'Reservas Confirmadas' && renderpardepan()}
                        {row.type === 'waitinglist' && title === 'Reservas Confirmadas' && renderwaitinglist()}
                        {row.type === 'event' && title === 'Reservas Confirmadas' && renderevent(row)}
                      </TableCell>
                      <TableCell className="reducido" key={uuid()} align="left">
                        {row.email !== 'client@kokonutstudio.com' && row.name !== null && (row.clientStatus === 'Aceptado' || row.clientStatus === 'Invitado') && (
                          <>
                            <button
                              className="clients-table__name-button"
                              type="button"
                              onClick={() => navigate(`/cliente/puerta21/${row.linkid}`)}
                            >
                              <span className="vip capitalized">
                                {row.name}
                              </span>
                            </button>
                          </>
                        )}
                        {row.email !== 'client@kokonutstudio.com' && row.name !== null && row.clientStatus !== 'Aceptado' && row.clientStatus !== 'Invitado' && row.clientStatus !== 'Pendiente' && (
                          <>
                            <button
                              className="clients-table__name-button"
                              type="button"
                              onClick={() => navigate(`/cliente/general-public/${row.linkid}`)}
                            >
                              <span className="clientname capitalized">
                                {row.name}
                              </span>
                            </button>
                          </>
                        )}
                        {row.name === null && (
                          'Sin datos'
                        )}
                        {row.name !== null && row.clientStatus === 'Pendiente' && (
                          <>
                            <LightTooltip title="Esta persona no tiene perfil pues es la primera vez que nos visita" TransitionComponent={Zoom} placement="top-center">
                              <span className="clientname capitalized">
                                {row.name}
                              </span>
                            </LightTooltip>
                          </>
                        )}
                        {row.email === 'client@kokonutstudio.com' && (
                          <>
                            <LightTooltip title="Este tipo de anotación no almacena datos" TransitionComponent={Zoom} placement="top-center">
                              <span className="walkin capitalized">
                                {row.name}
                              </span>
                            </LightTooltip>
                          </>
                        )}
                      </TableCell>
                      <TableCell key={uuid()} align="left">{row.tableNumber}</TableCell>
                      <TableCell key={uuid()} align="left">{row.table}</TableCell>
                      <TableCell key={uuid()} align="left">{row.time}</TableCell>
                      <TableCell className="reducido2" key={uuid()} align="left">
                        <LightTooltip title="Esta es la cantidad de asistentes" TransitionComponent={Zoom} placement="top-center">
                          <span className="clientname capitalized">
                            {row.people}
                          </span>
                        </LightTooltip>
                      </TableCell>
                      <TableCell key={uuid()} align="left">
                        {(row.status !== 'CANCELADO POR EL CLIENTE') ? (
                          <MenuReservationStatus
                            id={row.id}
                            type={row.type}
                            status={(row.status === 'EN ESPERA') ? 'ANOTADA POR EL RESTAURANTE' : (row.status === 'LISTA DE ESPERA') ? 'PENDIENTE POR CONTESTAR' : row.status}
                            catalog={catalogReservationStatus}
                            reservationDate={row.dateRaw}
                            setNewStatus={() => {
                              setApiCallConfig({
                                ...pagination,
                              });
                            }}
                          >
                          </MenuReservationStatus>
                        ) : (
                          <>
                            <span className="canceled">
                              {row.status}
                            </span>
                          </>
                        )}
                      </TableCell>
                      <TableCell key={uuid()} align="left">{row.comments !== '' ? row.comments : 'Sin comentarios'}</TableCell>
                      <TableCell key={uuid()} align="left">
                        {row.visits !== null && row.visits !== '' && row.visits !== '0' ? row.visits : 'Sin datos'}
                      </TableCell>
                      <TableCell key={uuid()} align="left">
                        {
                          showMenuOptions(row) && (
                            <MenuTableOptions
                              id={row.id}
                              options={
                                menuOptions(row.id, row.name, row.table, row.dateRaw, row.linkid)
                              }
                            />
                          )
                        }
                      </TableCell>
                      <TableCell key={uuid()} align="left">
                        <IconButton
                          onClick={() => openHistoryDialog(row.created_by, row.status_modified_by, row.modified_by, row.deleted_by)}
                          edge="start"
                          color="primary"
                          aria-label="menu"
                          size="small"
                        >
                          <AddIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  return (
    <>
      {!loading && renderTable()}

    </>
  );
};

// EnhancedTable.propTypes = {
//   tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   newPagination: PropTypes.shape({
//     currentPage: PropTypes.number,
//     perPage: PropTypes.number,
//     total: PropTypes.number,
//     from: PropTypes.number,
//     to: PropTypes.number,
//     options: PropTypes.arrayOf(PropTypes.number),
//     date: PropTypes.string,
//     zone: PropTypes.string,
//     sortCategory: PropTypes.string,
//     sortDirection: PropTypes.string,
//   }).isRequired,
//   setApiCallConfig: PropTypes.func.isRequired,
// };

export default EnhancedTable;
