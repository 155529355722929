/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SnackbarAlert from '../SnackbarAlert';
import * as ReservationsApi from '../../api/restaurants/reservations';
import isTruthy from '../../utils/isTruthy';

import './MenuReservationStatus.scss';

const ITEM_HEIGHT = 48;

const MenuReservationStatus = (
  {
    id, status, catalog, setNewStatus, reservationDate, type
  },
) => {
  const [currentStatus, setCurrentStatus] = useState(status);
  const [currentType, setCurrentType] = useState(type);
  const buttonClass = currentStatus.replace(/\s+/g, '').toLowerCase();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  const [isMenuDisabled, setIsMenuDisabled] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeStatus = async (newStatusId, newStatusString) => {
    setAnchorEl(null);
    const body = {
      reservation_id: id,
      status_id: newStatusId,
    };
    setCurrentStatus(newStatusString);
    try {
      const { success, data, message } = await ReservationsApi
        .changeReservationStatus(body);

      if (isTruthy(success)) {
        console.log(data);
        setSnackbar({
          open: true,
          severity: 'success',
          text: 'El estado de la reserva se ha cambiado correctamente',
        });
        window.location.reload();
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
        setNewStatus();
      }
    } catch (error) {

      console.log(error);
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
      setNewStatus();
    }
  };

  // const isEditActive = () => {
  //   const showMenu = moment(reservationDate).isSameOrAfter(moment()
  // .format('YYYY-MM-DD HH:mm:ss'));
  //   if (showMenu) {
  //     return true;
  //   }
  //   return false;
  // };

  useEffect(() => {
    const isButtonDisabled = () => false;
    setIsMenuDisabled(isButtonDisabled());
  }, [reservationDate, currentStatus]);

  return (
    <div className="menu-table-options">
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <button
        className={`${buttonClass} menu-status-button`}
        type="button"
        aria-label="more"
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={isMenuDisabled}
      >
        {(currentStatus === 'NO SHOW') ? 'NO VINO' : currentStatus}
        {!isMenuDisabled && (<ExpandMoreIcon />)}
      </button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            border: '1px solid #000',
            boxShadow: 'none',
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '25ch',
          },
        }}
      > 
        {catalog.map((item) => {
          /* en espera */
          if (currentStatus === 'ANOTADA POR EL RESTAURANTE' && (item.name === 'HA ASISTIDO')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }
          if (currentStatus === 'ANOTADA POR EL RESTAURANTE' && (item.name === 'NO SHOW')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                NO VINO
              </MenuItem>
            );
          }
          if (currentStatus === 'ANOTADA POR EL RESTAURANTE' && item.name === 'SE HA IDO') {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }
          if (currentStatus === 'ANOTADA POR EL RESTAURANTE' && item.name === 'RECHAZADO') {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item rechazado`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                RECHAZADO
              </MenuItem>
            );
          }
          if (currentStatus === 'ANOTADA POR EL RESTAURANTE' && (item.name === 'CANCELADO')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                CANCELADO
              </MenuItem>
            );
          }
          /* en espera */
          /* CANCELADO */
          if (currentStatus === 'CANCELADO' && (item.name === 'HA ASISTIDO')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }
          if (currentStatus === 'CANCELADO' && (item.name === 'NO SHOW')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                NO VINO
              </MenuItem>
            );
          }

          if (currentStatus === 'CANCELADO' && item.name === 'SE HA IDO') {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }
          /* CANCELADO */
          /* SE HA IDO */
          if (currentStatus === 'SE HA IDO' && (item.name === 'HA ASISTIDO')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }
          if (currentStatus === 'SE HA IDO' && (item.name === 'NO SHOW')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                NO VINO
              </MenuItem>
            );
          }

          if (currentStatus === 'SE HA IDO' && (item.name === 'CANCELADO')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                CANCELADO
              </MenuItem>
            );
          }
          /* NO SHOW */
          if (currentStatus === 'NO SHOW' && (item.name === 'HA ASISTIDO')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }

          if (currentStatus === 'NO SHOW' && (item.name === 'CANCELADO')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                CANCELADO
              </MenuItem>
            );
          }
          if (currentStatus === 'NO SHOW' && item.name === 'SE HA IDO') {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }
          if (currentStatus === 'HA ASISTIDO' && (item.name === 'SE HA IDO')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }
          if (currentStatus === 'PENDIENTE POR CONTESTAR' && (item.name === 'ACEPTADO')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item sehaido`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }
          if (currentStatus === 'PENDIENTE POR CONTESTAR' && (item.name === 'RECHAZADO')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item rechazado`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }
          if (currentStatus === 'RECHAZADO' && (item.name === 'PENDIENTE POR CONTESTAR')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item sinrespuesta`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                SIN RESPUESTA
              </MenuItem>
            );
          }
          if (currentStatus === 'RECHAZADO' && (item.name === 'ACEPTADO')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item sehaido`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }
          if (currentStatus === 'ACEPTADO' && (item.name === 'PENDIENTE POR CONTESTAR')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item listadeespera`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                PENDIENTE POR CONTESTAR
              </MenuItem>
            );
          }
          if (currentStatus === 'ACEPTADO' && (item.name === 'RECHAZADO')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item rechazado`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }
          if (currentStatus === 'ACEPTADO' && (item.name === 'ANOTADA POR EL RESTAURANTE')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item enespera`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }
          if (currentStatus === 'ACEPTADO' && (item.name === 'HA ASISTIDO')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item haasistido`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }
          if (currentStatus === 'ACEPTADO' && (item.name === 'CANCELADO')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item cancelado`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }
          if (currentStatus === 'ACEPTADO' && (item.name === 'HA ASISTIDO')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item `}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }
          if (currentStatus === 'ACEPTADO' && (item.name === 'HA ASISTIDO')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                {item.name}
              </MenuItem>
            );
          }
          if (currentStatus === 'ACEPTADO' && (item.name === 'NO SHOW')) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                NO VINO
              </MenuItem>
            );
          }
          if ((item.name === 'ELIMINADO' && currentStatus !== 'ELIMINADO'   &&  currentType!== 'pardepan' )) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item eliminado`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                ELIMINADO
              </MenuItem>
            );
          }
          if (currentStatus === 'ELIMINADO' && (item.name === 'ANOTADA POR EL RESTAURANTE') && currentType === null) {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item  sehaido`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                RECUPERAR
              </MenuItem>
            );
          }

          if (currentStatus === 'ELIMINADO' && (item.name === 'PENDIENTE POR CONTESTAR') &&  currentType=== 'waitinglist') {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item sehaido `}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                RECUPERAR
              </MenuItem>
            );
          }
          if (currentStatus === 'ELIMINADO' && (item.name === 'PENDIENTE POR CONTESTAR') &&  currentType=== 'pardepan') {
            return (
              <MenuItem
                className={`${item.name.replace(/\s+/g, '').toLowerCase()} menu-status-options__menu-item sehaido`}
                key={item.encryptId}
                onClick={() => { changeStatus(item.encryptId, item.name); }}
              >
                RECUPERAR
              </MenuItem>
            );
          }

       
          return null;
        })}
      </Menu>
    </div>
  );
};

MenuReservationStatus.propTypes = {
  id: PropTypes.string.isRequired,
  type : PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  catalog: PropTypes.arrayOf(PropTypes.shape({
    encryptId: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  setNewStatus: PropTypes.func.isRequired,
  reservationDate: PropTypes.string.isRequired,
};

export default MenuReservationStatus;
