import client from '../utils/client';
import clientDownloadFile from '../utils/clientDownloadFile';

// Muestra a todos los cobros
const showAllCharges = (config) => client(`restaurant/charges/${config.date}?page=${config.currentPage ? config.currentPage : 1}&pagesize=${config.perPage ? config.perPage : 10}`)
  .then((response) => response);

const exportCharges = (date) => clientDownloadFile(`restaurant/charges/${date}/export`,
  {
    method: 'GET',
    headers: {
      'Content-Disposition': 'attachment; filename="filename.xlsx"; filename*="filename.xlsx"',
      'Content-Type': 'application/octet-stream',
    },
  })
  .then((response) => response);

export {
  showAllCharges,
  exportCharges,
};
