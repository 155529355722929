// lodash is imported from create-react-app, but we don't have it on our project dependecies.
// eslint-disable-next-line import/no-extraneous-dependencies
import { escapeRegExp } from 'lodash';
import removeAccents from 'remove-accents';

const clientsTableFilter = (user, word) => {
  const regexToCheck = new RegExp(escapeRegExp(removeAccents(word)), 'i');
  return (
    removeAccents((user.firstName ?? '')).match(regexToCheck)
    || removeAccents((user.middleName ?? '')).match(regexToCheck)
    || removeAccents((user.lastName ?? '')).match(regexToCheck)
    || user.visits.toString().match(regexToCheck)
    || user.totalSpend.toString().match(regexToCheck)
  );
};

export default clientsTableFilter;
