/* eslint-disable */
import { React, useState } from 'react';
import PropTypes from 'prop-types';
import './DialogBlockPardepan.scss';
import ReactModal from 'react-modal';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import { format, isPast } from 'date-fns';
import ReportIcon from '@material-ui/icons/Report';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
  Button,
  Box,
  TextField,
  Typography,
} from '@material-ui/core/';
import emojiRegex from '../../utils/emojiRegex';
import emailRegex from '../../utils/emailRegex';
import phoneRegex from '../../utils/phoneRegex';
import timeRegex from '../../utils/timeRegex12H';
import namesRegex from '../../utils/namesRegex';
import * as ReservationsApi from '../../api/restaurants/reservations';
import SnackbarAlert from '../SnackbarAlert';

ReactModal.setAppElement('#root');
const DialogBlockPardepan = ({
  isOpen, onNo, date, setReload
}) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  var eightAM = new Date();
  eightAM.setHours(8, 0, 0, 0);
  var twelvePM = new Date();
  twelvePM.setHours(23, 59, 0, 0);

  const [fields, setFields] = useState({
    date: date,
    time: format(new Date(), 'hh:mm a'),
    //set startTime and endTime from date, time from 8am to 12pm
    startTime: format(eightAM, 'hh:mm a'),
    endTime: format(twelvePM, 'hh:mm a'),

  });
  const [validations, setValidations] = useState({

    date: true,
    startTime: true,
    endTime: true,
  });
  const areFieldsValid = () => {
    const fieldsValidation = Object.values(validations);
    const isEveryFieldValid = fieldsValidation.every((field) => field);
    return isEveryFieldValid;


  };
  const handleValidations = (field, value) => {
    const mapValidations = {

      date: (date) => date.length > 0,
      startTime: (startTime) => true,
      //endtime must be greater than startTime
      endTime: (endTime) => true,
      // categories: (listCategories) => listCategories.length > 0,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

 //set selectedStartTime as startTime
  const [selectedStartTime, setSelectedStartTime] = useState(eightAM);
  //set selectedEndTime as endTime
  const [selectedEndTime, setSelectedEndTime] = useState(twelvePM);

  const updateFields = (field, value) => {
    console.log(field, value);
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...fields,
    };
    newFields[field] = cleanString;
    setFields(newFields);
    handleValidations(field, cleanString);
  };
  const cleanUpBeforeClose = () => {
    setFields({

      date: date,
      time: format(new Date(), 'hh:mm a'),
      //set startTime and endTime from date, time from 8am to 12pm
      startTime: format(eightAM, 'hh:mm a'),
      endTime: format(twelvePM, 'hh:mm a'),

    });
    setValidations({
      date: true,
      time: true,
      startTime: true,
      endTime: true,
    });
    setSnackbar({
      open: false,
      severity: 'success',
      text: "Dia Bloqueado",
    });
    setReload(true);
    onNo();
  };
  //handle start time
  const handleStartTimeChange = (time, value) => {
    console.log('time', time);
    console.log(fields.endTime);
    if (timeRegex.test(value)) {
      const choosenTime = format(time, 'hh:mm a');
      const choosenDate = fields.date;
      console.log('choosenTime', choosenTime);
      //starttime 'YYYY-MM-DD hh:mm a'
      var startdatetime= `${choosenDate} ${choosenTime}`;
      //endtime 'YYYY-MM-DD hh:mm a'
      var endDatetime = `${choosenDate} ${fields.endTime}`;
      endDatetime = moment(endDatetime, 'YYYY-MM-DD hh:mm a');
      startdatetime = moment(startdatetime, 'YYYY-MM-DD hh:mm a');

      //if start time is less than end time
      if (moment(startdatetime).isBefore(endDatetime)) {
        setSelectedStartTime(time);
        updateFields('startTime', choosenTime);
      }else{
        alert('La hora de inicio debe ser menor que la hora de finalización');
      }


    }
  };
  //handle end time
  const handleEndTimeChange = (time, value) => {


    if (timeRegex.test(value)) {
      const choosenTime = format(time, 'hh:mm a');
      const choosenDate = fields.date;
      console.log('choosenTime', choosenTime);
      //starttime 'YYYY-MM-DD hh:mm a'

      var endDatetime = `${choosenDate} ${choosenTime}`;
      var startDatetime = `${choosenDate} ${fields.startTime}`;

      console.log('startDatetime', startDatetime);
      console.log('endDatetime', endDatetime);
      //conver endDateTime to moment to work on IOS
      endDatetime = moment(endDatetime, 'YYYY-MM-DD hh:mm a');
      startDatetime = moment(startDatetime, 'YYYY-MM-DD hh:mm a');
      console.log(moment(endDatetime).isAfter(startDatetime));    //if end time is greater than start time
      if (moment(endDatetime).isAfter(startDatetime)) {
        setSelectedEndTime(time);
        updateFields('endTime', choosenTime);
      }else{
        alert('La hora de finalización debe ser mayor que la hora de inicio');
        setValidations({ ...validations, endTime: false });
      }

    }
    //check if end time is greater than start time

  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const timet = moment(`1988-01-08 ${fields.time}`, 'YYYY-MM-DD hh:mm a').format('HH:mm');
    const datet = `${format(new Date(date), 'yyyy-MM-dd')} ${timet}`;
    const startDatetime = `${format(new Date(date), 'yyyy-MM-dd')} ${fields.startTime}`;
    const endDatetime = `${format(new Date(date), 'yyyy-MM-dd')} ${fields.endTime}`;
    console.log('startDatetime', startDatetime);
    console.log('endDatetime', endDatetime);

    const body = {
      date: date,
      time: timet,
      start_time: fields.startTime,
      end_time: fields.endTime,
    };
    console.log(body);
    try {
      const { success, message, data } = await ReservationsApi
        .savePardepanBlock(body);
      if (success == 1) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: "DIA BLOQUEADO",
        });
        setTimeout(() => {
          cleanUpBeforeClose();
        }, 1500);
      } else {

      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }

  };
  const [isFormTouched, setFormIsTouched] = useState(false);
  const isEmailValid = (validations.email) && isFormTouched;
  const isPhoneValid = (validations.phone) && isFormTouched;
  const [hasCheckedEmail, setHasCheckedEmail] = useState(false);

  const checkIfEmailChanges = (value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    updateFields('email', cleanString);
  };
  const checkIfPhoneChanges = (value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    updateFields('phone', cleanString);
  };
  return (
    <ReactModal
      isOpen={isOpen}
      className="dialog-base-modal"
      overlayClassName="dialog-base-modal__overlay"
      shouldCloseOnOverlayClick
      onRequestClose={onNo}
    >
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
        <form onSubmit={handleSubmit}>

          <div className="dialog-base-modal__content ">
            <Box>
              <div className="w100 marginTop20">
                <h3 className='TextCenter title'>Bloquear Reservas</h3>
              </div>
              <div className="w100 marginTop20">
                <p className='text-red paragraph'>
                  Se bloquea solamente el día seleccionado.
                </p>
              </div>
              <div className="w100 marginTop20">
                <TextField
                  className="w100"
                  error={!validations.date}
                  id="date"
                  label="Fecha a bloquear:"
                  value={date}
                  readOnly={true}
                />
              </div>
              <div className="reservations-alerts__badge dFlex flex-row align-center justify-between">
                <ReportIcon />
                <Typography
                  variant="body2"
                  className="pl-1"
                >
                  <b>Nota:</b> Ir a calendario para modificar la fecha del bloqueo.
                </Typography>
              </div>
              <div className="w100 marginTop20">
                <KeyboardTimePicker
                  className="w100"
                  error={!validations.startTime}
                  id="startTime"
                  label="Hora de inicio:"
                  value={selectedStartTime}
                  onChange={handleStartTimeChange}>
                </KeyboardTimePicker>
              </div>
              <div className="w100 marginTop20">
                <KeyboardTimePicker
                  className="w100"
                  error={!validations.endTime}
                  id="endTime"
                  label="Hora de fin:"
                  value={selectedEndTime}
                  onChange={handleEndTimeChange}>
                </KeyboardTimePicker>
              </div>

            </Box>
            <div className="flexend">
              <Button
                className="cancelBtn"
                variant="contained"
                color="secondary"
                type="button"
                onClick={onNo}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={handleSubmit}
                disabled={!areFieldsValid()}
              >
                Bloquear
              </Button>
            </div>
            <div className="w100 marginTop20">
                <p className=' paragraph'>
                  En el caso de que ya esté el restaurante completo y no haya más
                  disponibilidad en el día de hoy, puede bloquear las reservas
                  realizadas vía ¡Reserva Ya! pan para que los clientes no puedan seguir
                  haciendo reservas a través de dicha plataforma
                </p>
              </div>
              <div className="w100 marginTop20">
                <p className=' paragraph'>
                  En el caso de que lo desee, siempre puede desbloquear este botón
                  y volver a recibir las reservas de clientes realizadas vía ¡Reserva Ya!.
                </p>
              </div>
          </div>
        </form>
      </MuiPickersUtilsProvider>
    </ReactModal>
  );
};
DialogBlockPardepan.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  date: PropTypes.instanceOf(String).isRequired,
  setReload: PropTypes.func,

  // eslint-disable-next-line react/forbid-prop-types

};
DialogBlockPardepan.defaultProps = {
  isOpen: false,
  onNo: '',
  setReload: () => { },
};

export default DialogBlockPardepan;
