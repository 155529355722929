import React from 'react';
import { Box } from '@material-ui/core/';

import Icon from '../../images/icon-black-bg.png';

import './SplashScreen.scss';

const SplashScreen = () => (
  <div
    className="splash-screen"
    width="100"
  >
    <div
      className="splash__left-half"
    />
    <div
      className="splash__right-half"
    />

    <Box
      component="div"
      className="splash__logo-group"
    >
      <div className="splash__logo-container">
        <div className="line-container">
          <div className="line top" />
          <div className="line bottom" />
        </div>
        <div className="icon-container">
          <img className="icon img-fluid" src={Icon} alt="Puerta 21 icon" />
        </div>
      </div>
    </Box>
  </div>
);

export default SplashScreen;
