/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Hidden,
  Typography,
  Checkbox,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core/';
import { capitalize } from '@material-ui/core';

import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Rating from '@material-ui/lab/Rating';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { v4 as uuid } from 'uuid';
import TableErrors from '../../components/TableErrors';
import SnackbarAlert from '../../components/SnackbarAlert';
import * as ClientsApi from '../../api/restaurants/clients';
import * as CatalogsApi from '../../api/catalogs';

import isTruthy from '../../utils/isTruthy';
import TheMenu from '../../components/TheMenu';
import Placeholder from '../../images/default.jpeg';

import DialogClientImage from '../../components/DialogClientImage';

import './ClientsEdit.scss';
import namesRegex from '../../utils/namesRegex';
import emojiRegex from '../../utils/emojiRegex';
import dateRegex from '../../utils/dateRegex';
import DialogEditNutritionalReq from '../../components/DialogEditNutritionalReq';
import DialogEditAllergies from '../../components/DialogEditAllergies';
import DialogEditDrinks from '../../components/DialogEditDrinks';
import DialogEditTables from '../../components/DialogEditTables';
import DialogEditClient from '../../components/DialogEditClient';

const prepareCategories = () => {
  const catalogCategory = JSON.parse(localStorage.getItem('catalogCategory'));
  const categoriesList = {};
  if (catalogCategory !== null || catalogCategory !== 'undefined') {
    catalogCategory.forEach((element) => {
      categoriesList[element.name] = false;
    });
  }
  return categoriesList;
};

const ClientsEdit = ({ typeOfClient, id }) => {
  const CategoryList = JSON.parse(localStorage.getItem('catalogCategory'));
  const [state, setState] = useState({
    loading: true,
    error: false,
    success: false,
    message: '',
    clientData: {},
  });

  const [categories, setCategories] = useState(prepareCategories());
  const [drinksCatalogs, setDrinksCatalogs] = useState([]);
  const [tablesCatalogs, setTablesCatalogs] = useState([]);
  const [reqsCatalogs, setReqsCatalogs] = useState([]);
  const [allergiesCatalogs, setAllergiesCatalogs] = useState([]);
  const [clientData, setClientData] = useState({});
  const [fields, setFields] = useState({
    friendOf: '',
    drink: '',
    food: '',
    table: '',
    rating: '',
    extra_comments: '',
    birthday_date: '',
  });
  const [validations, setValidations] = useState({
    friendOf: true,
    drink: true,
    food: true,
    table: true,
    rating: true,
    extra_comments: true,
    birthday_date: true,
  });
  const [fieldIsDisabled, setFieldIsDisabled] = useState({
    friendOf: true,
    drink: true,
    food: true,
    table: true,
    rating: true,
    extra_comments: true,
    birthday_date: true,
  });
  const [isEditingAField, setIsEditingAField] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const [imageDialog, setImageDialog] = useState(false);
  const [professionData, setProfessionData] = useState({});
  const [reload, setReload] = useState(false);
  const [tabData, setTabData] = useState({});
  const [nutritionalDialog, setNutritionalDialog] = useState(false);
  const [drinksDialog, setDrinksDialog] = useState(false);
  const [allergiesDialog, setAllergiesDialog] = useState(false);
  const [tablesDialog, setTablesDialog] = useState(false);
  const [EditClientDialog, setEditClientDialog] = useState(false);
  const [editClientDialogData, setEditClientDialogData] = useState({ name: '', clientId: null });
  const openNutritionalDialog = () => {
    setNutritionalDialog(true);
  };
  const closeNutritionalDialog = () => {
    setNutritionalDialog(false);
  };
  const openDrinksDialog = () => {
    setDrinksDialog(true);
  };
  const closeDrinksDialog = () => {
    setDrinksDialog(false);
  };
  const openTablesDialog = () => {
    setTablesDialog(true);
  };
  const closeTablesDialog = () => {
    setTablesDialog(false);
  };
  const openAllergiesDialog = () => {
    setAllergiesDialog(true);
  };
  const closeAllergiesDialog = () => {
    setAllergiesDialog(false);
  };
  useEffect(() => {
    setState({
      loading: true,
      error: false,
      success: false,
      message: '',
      clientData: {},
    });
    const fetchDrinkCatalog = async () => {
      try {
        const Response = await CatalogsApi
          .catalogDrink();
        if (isTruthy(Response.success)) {
          console.log(Response.data);
          setDrinksCatalogs(Response.data);
        }
      } catch (error) {
        console.log(error);
        setState({
          loading: false, error: true, success: false, message: error.message, clientData: {},
        });
      }
    };
    const fetchTableCatalog = async () => {
      try {
        const Response = await CatalogsApi
          .catalogTable();
        if (isTruthy(Response.success)) {
          console.log(Response.data);
          setTablesCatalogs(Response.data);
        }
      } catch (error) {
        console.log(error);
        setState({
          loading: false, error: true, success: false, message: error.message, clientData: {},
        });
      }
    };
    const fetchReqCatalog = async () => {
      try {
        const Response = await CatalogsApi
          .catalogNutritionalRequirement();
        if (isTruthy(Response.success)) {
          console.log(Response.data);
          setReqsCatalogs(Response.data);
        }
      } catch (error) {
        console.log(error);
        setState({
          loading: false, error: true, success: false, message: error.message, clientData: {},
        });
      }
    };
    const fetchAllergyCatalog = async () => {
      try {
        const Response = await CatalogsApi
          .catalogAllergy();
        if (isTruthy(Response.success)) {
          console.log(Response.data);
          setAllergiesCatalogs(Response.data);
        }
      } catch (error) {
        console.log(error);
        setState({
          loading: false, error: true, success: false, message: error.message, clientData: {},
        });
      }
    };
    const fetchClientData = async () => {
      try {
        const { success, data } = await ClientsApi
          .showClient(typeOfClient, id);
        if (isTruthy(success)) {
          const userCategories = data.category;
          const categoriesValues = prepareCategories();
          if (userCategories.length > 0) {
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < userCategories.length; index++) {
              const element = userCategories[index].name;
              if (userCategories[index].status === 1) {
                categoriesValues[element] = true;
              }
            }
            setCategories(categoriesValues);
          }
          setClientData(data);
          const surveyData = {
            nutritionalRequirements: data.survey.nutritionalRequirements,
            allergies: data.survey.allergies,
            drinks: data.survey.drinks,
            tables: data.survey.tables,
          };
          const { specifications, professions } = data.survey;
          if (specifications?.allergy) {
            surveyData.allergies.push({ name: specifications.allergy.toUpperCase() });
          }
          if (specifications?.drink) {
            surveyData.drinks.push({ name: specifications.drink.toUpperCase() });
          }
          if (specifications?.table) {
            surveyData.tables.push({ name: specifications.table.toUpperCase() });
          }
          if (specifications?.drink) {
            surveyData.nutritionalRequirements
              .push({ name: specifications.nutritionalRequirement.toUpperCase() });
          }

          if (specifications?.profession) {
            professions.push({ name: specifications.profession.toUpperCase() });
          }
          setTabData(surveyData);
          setProfessionData(professions);
          console.log(data);
          setFields({
            friendOf: data.friendOf,
            drink: data.drink,
            food: data.food,
            table: data.table,
            rating: data.rating,
            extra_comments: data.extraComments,
            birthday_date: data.birthdayDate,
          });
          setState({
            loading: false,
            error: false,
            success: true,
            message: '',
            clientData: data,
          });
        } else {
          setState({
            loading: false, error: true, success: false, message: data.error, clientData: {},

          });
        }
      } catch (error) {
        console.log(error);
        setState({
          loading: false, error: true, success: false, message: error.message, clientData: {},

        });
      }
    };
    fetchDrinkCatalog();
    fetchTableCatalog();
    fetchAllergyCatalog();
    fetchReqCatalog();
    fetchClientData();
  }, [id, typeOfClient, reload]);

  const handleValidations = (field, value) => {
    const mapValidations = {
      friendOf: (friendOf) => namesRegex.test(friendOf)
        && friendOf.length > 2
        && friendOf.length < 240,
      food: (food) => food.length > 2 && food.length < 240,
      table: (table) => table.length > 2 && table.length < 240,
      drink: (drink) => drink.length > 2 && drink.length < 240,
      extra_comments: (extraComments) => extraComments.length > 2 && extraComments.length < 240,
      birthday_date: () => true,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

  const updateFields = (field, value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...fields,
    };
    newFields[field] = cleanString;
    setFields(newFields);
    handleValidations(field, cleanString);
  };

  const handleDateChange = (date) => {
    if (dateRegex.test(format(new Date(date), 'yyyy-MM-dd'))) {
      const fecha = new Date(date)
      console.log(fecha);
      const newFields = {
        ...fields,
      };
      newFields['birthday_date'] = fecha;
      setFields(newFields);
    }
  };
  const toggleTextfield = (field) => {
    const newFieldsState = {
      ...fieldIsDisabled,
    };
    newFieldsState[field] = !newFieldsState[field];
    setFieldIsDisabled(newFieldsState);
    // Si el campo se editó, al cancelar debe regresar al valor anterior
    if (newFieldsState[field]) {
      setFields({
        ...fields,
        [field]: state.clientData[field],
      });
      setValidations({
        ...validations,
        [field]: true,
      });
      setIsEditingAField(false);
    } else {
      setIsEditingAField(true);
    }
  };

  const openDialog = () => {
    setState({ ...state, top: false });
    setImageDialog(true);
  };
  const closeDialog = () => {
    setState({ ...state, top: false });
    setImageDialog(false);
  };
  const closeEditClientDialog = () => {
    setEditClientDialog(false);
  };
  const openEditClientDialog = (name, clientId) => {
    setEditClientDialogData({ name, clientId });
    setEditClientDialog(true);
  };
  const handleSubmit = async (e, field) => {
    e.preventDefault();
    const body = {
      [field === 'friendOf' ? 'friend' : field]: fields[field],
    };
    try {
      const { success, message } = await ClientsApi.editPreferences(id, body);
      if (success) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
        setIsEditingAField(false);
        setFieldIsDisabled(
          { ...fieldIsDisabled, [field]: true },
        );
        setReload(true);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };
  const handleRatingSubmit = async (e, value) => {
    e.preventDefault();
    const body = {
      rating: value,
    };
    try {
      const { success, message } = await ClientsApi.editPreferences(id, body);
      if (success) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };

  const handleCategorySubmit = async (category, status) => {
    const body = {
      client_eid: clientData.id,
      category_eid: category.encryptId,
      status,
    };
    try {
      const { success, message } = await ClientsApi.editCategories(body);
      if (success) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };
  const handleCategoriesSelection = (event, category) => {
    // Se comenta validación de tener a fuerza una categoría seleccionada
    // let checkedCategoriesCount = 0;
    // CategoryList.forEach((element) => {
    //   if (categories[element.name] === true) {
    //     checkedCategoriesCount += checkedCategoriesCount + 1;
    //   }
    // });
    // if (checkedCategoriesCount === 1 && !event.target.checked) {
    //   setSnackbar({
    //     open: true,
    //     severity: 'error',
    //     text: 'Debes tener al menos una categoría seleccionada',
    //   });
    // } else {
    // }
    setCategories({ ...categories, [event.target.name]: event.target.checked });
    // updateFields('categories', getCategoriesSelected(
    //  { ...categories, [event.target.name]: event.target.checked }));
    handleCategorySubmit(category, event.target.checked);
  };

  const renderLoading = () => (
    <div>Loading...</div>
  );
  const renderTabsData = () => (
    <div>
      <div className="accordionContainer">
        <Accordion defaultExpanded>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="dflex spacebetween encabezado">
              <span className="accordionTitle">Requisito alimenticio</span>
              <Button
                disabled={isEditingAField && fieldIsDisabled.friendOf}
                variant="contained"
                color={!fieldIsDisabled.friendOf ? 'secondary' : 'primary'}
                type="button"
                size="sm"
                onClick={() => { openNutritionalDialog(); }}
              >
                {fieldIsDisabled.friendOf ? 'EDITAR' : 'CANCELAR'}
              </Button>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className='encabezado'>
              {tabData.nutritionalRequirements.map((element) => {
                if (element.name !== 'OTROS') {
                  return (
                    <div key={uuid()} className="badge-item">
                      {element.name}
                    </div>
                  );
                }
                return '';
              })}
              {
                ((tabData.nutritionalRequirements.length <= 0)
                ) && (
                  <div className="color-grey">
                    Dale clic a &quot;Editar&quot; para añadir información acerca de este cliente.
                  </div>
                )
              }
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <div className="dflex spacebetween encabezado">
              <span className="accordionTitle">Alergias</span>
              <Button
                disabled={isEditingAField && fieldIsDisabled.friendOf}
                variant="contained"
                color={!fieldIsDisabled.friendOf ? 'secondary' : 'primary'}
                type="button"
                size="sm"
                onClick={() => { openAllergiesDialog(); }}
              >
                {fieldIsDisabled.friendOf ? 'EDITAR' : 'CANCELAR'}
              </Button>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className='encabezado'>
              {tabData.allergies.map((element) => {
                if (element.name !== 'OTROS') {
                  return (
                    <div key={uuid()} className="badge-item">
                      {element.name}
                    </div>
                  );
                }
                return '';
              })}
              {
                ((tabData.allergies.length <= 0)
                ) && (
                  <div className="color-grey">
                    Dale clic a &quot;Editar&quot; para añadir información acerca de este cliente.
                  </div>
                )
              }
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <div className="dflex spacebetween encabezado">
              <span className="accordionTitle">Preferencia de bebidas</span>
              <Button
                disabled={isEditingAField && fieldIsDisabled.friendOf}
                variant="contained"
                color={!fieldIsDisabled.friendOf ? 'secondary' : 'primary'}
                type="button"
                size="sm"
                onClick={() => { openDrinksDialog(); }}
              >
                {fieldIsDisabled.friendOf ? 'EDITAR' : 'CANCELAR'}
              </Button>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className='encabezado'>
              {tabData.drinks.map((element) => {
                if (element.name !== 'OTROS') {
                  return (
                    <div key={uuid()} className="badge-item">
                      {element.name}
                    </div>
                  );
                }
                return '';
              })}
              {
                ((tabData.drinks.length <= 0)
                ) && (
                  <div className="color-grey">
                    Dale clic a &quot;Editar&quot; para añadir información acerca de este cliente.
                  </div>
                )
              }
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <div className="dflex spacebetween encabezado">
              <span className="accordionTitle">Requisito de Mesa</span>
              <Button
                disabled={isEditingAField && fieldIsDisabled.friendOf}
                variant="contained"
                color={!fieldIsDisabled.friendOf ? 'secondary' : 'primary'}
                type="button"
                size="sm"
                onClick={() => { openTablesDialog(); }}
              >
                {fieldIsDisabled.friendOf ? 'EDITAR' : 'CANCELAR'}
              </Button>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className='encabezado'>
              {tabData.tables.map((element) => {
                if (element.name !== 'OTROS') {
                  return (
                    <div key={uuid()} className="badge-item">
                      {element.name}
                    </div>
                  );
                }
                return '';
              })}
              {
                ((tabData.tables.length <= 0)
                ) && (
                  <div className="color-grey">
                    Dale clic a &quot;Editar&quot; para añadir información acerca de este cliente.
                  </div>
                )
              }
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
  const renderView = () => (
    <>
      {/* <div>{JSON.stringify(clientData)}</div> */}
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5} className="clientEdit-left-grid dFlex flex-column align-center justify-start">
            <Box component="div" className="">
              <Grid container spacing={2}>
                <Hidden smUp>
                  <Grid item xs={12} >
                    <FormControl component="fieldset" className='lista'>
                      <FormGroup className="clientsEdit__categories-checkbox">
                        {CategoryList.map((category) => (
                          <FormControlLabel
                            key={uuid()}
                            label={category.name}
                            control={(
                              <Checkbox
                                checked={categories[category.name]}
                                name={category.name}
                                disableRipple
                                color="primary"
                                onChange={(e) => { handleCategoriesSelection(e, category); }}
                              />
                            )}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Hidden>
                <div className="dflex headerrow">

                  <Grid item xs={5} sm={4} className="clientDataLeft__container">
                    <Box onClick={() => { openDialog(); }} component="div" className="mask-container">
                      <svg viewBox="0 0 400 300">
                        <defs>
                          <mask id="mask">
                            <circle fill="#FFFFFF" cx="205" cy="100" r="100" />
                            <rect fill="#ffffff" x="105" y="87" width="200" height="200" />
                          </mask>
                        </defs>
                        <image mask="url(#mask)" xlinkHref={clientData.photo ? `${clientData.photo}` : Placeholder} width="400" height="300" />
                      </svg>
                    </Box>
                    <Hidden xsDown>
                      <Rating
                        name="simple-controlled"
                        value={fields.rating}
                        onChange={(event, newValue) => {
                          setFields({
                            ...fields, rating: newValue,
                          });
                          handleRatingSubmit(event, newValue);
                        }}
                      />
                    </Hidden>
                  </Grid>
                  <Grid item xs={7} sm={12} className="clientDataRight__container dFlex flex-column align-start justify-center">
                    <Hidden xsDown>
                      <Button type="button" onClick={() => { navigate('/clientes'); }} className="back-button">
                        <IconButton
                          className="back-button__icon"
                        >
                          <ArrowBackIosIcon />
                        </IconButton>
                        <div>
                          Volver
                        </div>
                      </Button>
                    </Hidden>
                    {professionData && (
                      <div className="professions">
                        {professionData.map((profession) => {
                          if (profession.name !== 'OTROS') {
                            return (
                              <div key={uuid()} className="badge-item">
                                {profession.name}
                              </div>
                            );
                          }
                          return '';
                        })}
                      </div>
                    )}
                    <div className="dflex spacebetween">
                      <div className="name">
                      {(clientData.firstName != null) && capitalize(clientData.firstName)}
                          {' '}
                          {(clientData.middleName != null) && capitalize(clientData.middleName)}
                          {' '}
                          {(clientData.lastName != null) && capitalize(clientData.lastName)}
                      </div>
                      <Button
                        disabled={isEditingAField}
                        variant="contained"
                        color={!fieldIsDisabled.friendOf ? 'secondary' : 'primary'}
                        type="button"
                        size="sm"
                        onClick={() => { openEditClientDialog('maria abellan', (clientData.id !== null) ? clientData.id : ''); }}
                        className="mr32"
                      >
                        EDITAR
                      </Button>
                    </div>
                    <div className="email">
                      {clientData.email}
                    </div>
                    <div className="phone">
                      {clientData.cellPhone}
                    </div>
                    <div className="rating">
                      <Hidden smUp>
                        <Rating
                          name="simple-controlled"
                          value={fields.rating}
                          onChange={(event, newValue) => {
                            setFields({
                              ...fields, rating: newValue,
                            });
                            handleRatingSubmit(event, newValue);
                          }}
                        />
                      </Hidden>
                    </div>
                    <Hidden xsDown>
                      <div className="w100 mt-1">
                        <FormControl component="fieldset">
                          <FormGroup className="clientsEdit__categories-checkbox">
                            {CategoryList.map((category) => (
                              <FormControlLabel
                                key={uuid()}
                                label={(category.name === 'Blacklist' ? 'No deseado' : category.name)}
                                control={(
                                  <Checkbox
                                    checked={categories[category.name]}
                                    name={category.name}
                                    disableRipple
                                    color="primary"
                                    onChange={(e) => { handleCategoriesSelection(e, category); }}
                                  />
                                )}
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </div>
                    </Hidden>
                  </Grid>
                </div>
                <Grid item xs={12}>
                  {/* {JSON.stringify(tabData)} */}
                  {!state.isLoading && renderTabsData()}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={7} className="clientEdit-right-grid dFlex flex-column align-center justify-center">
            <Box component="div" className="clientsEdit-totals__container">
              <div className="clientsEdit-totals__item">
                <div>
                  Gasto promedio
                </div>
                <Typography variant="h6">
                  {`$${clientData.averageSpend}`}
                </Typography>
              </div>
              <div className="clientsEdit-totals__item">
                <div>
                  Visitas totales
                </div>
                <Typography variant="h6">
                  {clientData.visits}
                </Typography>
              </div>
              <div className="clientsEdit-totals__item">
                <div>
                  Gasto total
                </div>
                <Typography variant="h6">
                  {`$${clientData.totalSpend}`}
                </Typography>
              </div>
              <div className="clientsEdit-totals__item">
                <div>
                  No vino
                </div>
                <Typography variant="h6">
                  {clientData.noShows}
                </Typography>
              </div>
              <div className="clientsEdit-totals__item">
                <div>
                  Cancelaciones
                </div>
                <Typography variant="h6">
                  {clientData.cancellations}
                </Typography>
              </div>
            </Box>
            <Box component="div" className="clientsEdit-form__container">
              <form className="w100" autoComplete="off">
                <div className="clientsEdit-form__field-container">
                  <TextField
                    className="w100"
                    disabled={fieldIsDisabled.friendOf}
                    error={!validations.friendOf}
                    id="friendOf"
                    label="Amigo o conocido de"
                    onChange={(e) => updateFields('friendOf', e.target.value)}
                    value={fields.friendOf}
                  />
                  <div className="clientsEdit-form__buttonGroup">
                    <Button
                      disabled={isEditingAField && fieldIsDisabled.friendOf}
                      variant="contained"
                      color={!fieldIsDisabled.friendOf ? 'secondary' : 'primary'}
                      type="button"
                      onClick={() => { toggleTextfield('friendOf'); }}
                    >
                      {fieldIsDisabled.friendOf ? 'EDITAR' : 'CANCELAR'}
                    </Button>
                    {!fieldIsDisabled.friendOf && validations.friendOf && (
                      <Button
                        disabled={fields.friendOf === clientData.friendOf}
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={(e) => { handleSubmit(e, 'friendOf'); }}
                      >
                        GUARDAR
                      </Button>
                    )}
                  </div>
                </div>
                <div className="clientsEdit-form__field-container">
                  <TextField
                    className="w100"
                    disabled={fieldIsDisabled.drink}
                    error={!validations.drink}
                    id="drink"
                    label="Preferencia de bebida"
                    onChange={(e) => updateFields('drink', e.target.value)}
                    value={fields.drink}
                  />
                  <div className="clientsEdit-form__buttonGroup">
                    <Button
                      disabled={isEditingAField && fieldIsDisabled.drink}
                      variant="contained"
                      color={!fieldIsDisabled.drink ? 'secondary' : 'primary'}
                      type="button"
                      onClick={() => { toggleTextfield('drink'); }}
                    >
                      {fieldIsDisabled.drink ? 'EDITAR' : 'CANCELAR'}
                    </Button>
                    {!fieldIsDisabled.drink && validations.drink && (
                      <Button
                        disabled={fields.drink === clientData.drink}
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={(e) => { handleSubmit(e, 'drink'); }}
                      >
                        GUARDAR
                      </Button>
                    )}
                  </div>
                </div>
                <div className="clientsEdit-form__field-container">
                  <TextField
                    className="w100"
                    disabled={fieldIsDisabled.food}
                    error={!validations.food}
                    id="food"
                    label="Preferencia de comida"
                    onChange={(e) => updateFields('food', e.target.value)}
                    value={fields.food}
                  />
                  <div className="clientsEdit-form__buttonGroup">
                    <Button
                      disabled={isEditingAField && fieldIsDisabled.food}
                      variant="contained"
                      color={!fieldIsDisabled.food ? 'secondary' : 'primary'}
                      type="button"
                      onClick={() => { toggleTextfield('food'); }}
                    >
                      {fieldIsDisabled.food ? 'EDITAR' : 'CANCELAR'}
                    </Button>
                    {!fieldIsDisabled.food && validations.food && (
                      <Button
                        disabled={fields.food === clientData.food}
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={(e) => { handleSubmit(e, 'food'); }}
                      >
                        GUARDAR
                      </Button>
                    )}
                  </div>
                </div>
                <div className="clientsEdit-form__field-container">
                  <TextField
                    className="w100"
                    disabled={fieldIsDisabled.table}
                    error={!validations.table}
                    id="table"
                    label="Preferencia de mesa"
                    onChange={(e) => updateFields('table', e.target.value)}
                    value={fields.table}
                  />
                  <div className="clientsEdit-form__buttonGroup">
                    <Button
                      disabled={isEditingAField && fieldIsDisabled.table}
                      variant="contained"
                      color={!fieldIsDisabled.table ? 'secondary' : 'primary'}
                      type="button"
                      onClick={() => { toggleTextfield('table'); }}
                    >
                      {fieldIsDisabled.table ? 'EDITAR' : 'CANCELAR'}
                    </Button>
                    {!fieldIsDisabled.table && validations.table && (
                      <Button
                        disabled={fields.table === clientData.table}
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={(e) => { handleSubmit(e, 'table'); }}
                      >
                        GUARDAR
                      </Button>
                    )}
                  </div>
                </div>
                <div className="clientsEdit-form__field-container">
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                    <DatePicker
                      format="yyyy-MM-dd"
                      value={fields.birthday_date}
                      onChange={handleDateChange}
                      label="fecha de cumpleaños"
                      disableFuture
                    />
                  </MuiPickersUtilsProvider>
                  <div className="clientsEdit-form__buttonGroup">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={(e) => { handleSubmit(e, 'birthday_date'); }}
                    >
                      GUARDAR
                    </Button>
                  </div>
                </div>
                <div className="clientsEdit-form__field-container">
                  <TextField
                    className="w100 noanim"
                    disabled={fieldIsDisabled.extra_comments}
                    error={!validations.extra_comments}
                    id="extra_comments"
                    label="Comentarios adicionales"
                    onChange={(e) => updateFields('extra_comments', e.target.value)}
                    value={fields.extra_comments}
                    multiline
                    rows={3}
                    margin="dense"
                  />
                  <div className="clientsEdit-form__buttonGroup">
                    <Button
                      disabled={isEditingAField && fieldIsDisabled.extra_comments}
                      variant="contained"
                      color={!fieldIsDisabled.extra_comments ? 'secondary' : 'primary'}
                      type="button"
                      onClick={() => { toggleTextfield('extra_comments'); }}
                    >
                      {fieldIsDisabled.extra_comments ? 'EDITAR' : 'CANCELAR'}
                    </Button>
                    {!fieldIsDisabled.extra_comments && validations.extra_comments && (
                      <Button
                        disabled={fields.extra_comments === clientData.extra_comments}
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={(e) => { handleSubmit(e, 'extra_comments'); }}
                      >
                        GUARDAR
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <DialogEditClient
        isOpen={EditClientDialog}
        onNo={closeEditClientDialog}
        data={editClientDialogData}
      />
      <DialogEditNutritionalReq
        isOpen={nutritionalDialog}
        onNo={closeNutritionalDialog}
        data={reqsCatalogs}
        current={tabData.nutritionalRequirements}
        setReload={setReload}
        clientId={id}
      />
      <DialogEditDrinks
        isOpen={drinksDialog}
        onNo={closeDrinksDialog}
        data={drinksCatalogs}
        current={tabData.drinks}
        setReload={setReload}
        clientId={id}
      />
      <DialogEditAllergies
        isOpen={allergiesDialog}
        onNo={closeAllergiesDialog}
        data={allergiesCatalogs}
        current={tabData.allergies}
        setReload={setReload}
        clientId={id}
      />
      <DialogEditTables
        isOpen={tablesDialog}
        onNo={closeTablesDialog}
        data={tablesCatalogs}
        current={tabData.tables}
        setReload={setReload}
        clientId={id}
      />
    </>
  );

  const renderError = () => {
    const errorData = {
      message: state.message,
      action: () => { navigate('/clientes'); },
      buttonText: 'Regresar',
      image: 'broken',
    };
    return (
      <TableErrors data={errorData} action={() => navigate('/clientes')} />
    );
  };
  return (
    <>
      <DialogClientImage
        isOpen={imageDialog}
        onNo={closeDialog}
        image={clientData.photo ? `${clientData.photo}` : Placeholder}
      />
      <TheMenu setIsCalendarOpen={() => { }} />
      <Container
        className="clients-edit layout px-0"
      >
        <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
        <Hidden smUp>
          <Box
            component="div"
            className="clients-edit__header pr-1 pl-1 dFlex flex-row align-center justify-between"
          >
            <div className="dFlex flex-row align-center justify-start">
              <IconButton
                onClick={() => { navigate('/clientes'); }}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Typography variant="h6" className="clients-edit__title font__commutter">
                CLIENTES
              </Typography>
            </div>
          </Box>
        </Hidden>

        <Box
          component="div"
          className="w100 dFlex flex-column align-center justify-between"
        >
          {state.loading && renderLoading()}
          {!state.loading && state.error && renderError()}
          {!state.loading
            && !state.error
            && renderView()}
        </Box>
      </Container>
    </>
  );
};

ClientsEdit.propTypes = {
  id: PropTypes.string,
  typeOfClient: PropTypes.string,
};
ClientsEdit.defaultProps = {
  id: '',
  typeOfClient: '',
};

export default ClientsEdit;
