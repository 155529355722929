/* eslint-disable */
import { React, useState } from 'react';
import PropTypes from 'prop-types';
import './DialogFilterStatus.scss';
import ReactModal from 'react-modal';
import {
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core/';

import SnackbarAlert from '../SnackbarAlert';

ReactModal.setAppElement('#root');
const DialogFilterStatus = ({
  isOpen, onNo, filtering,filterbystatus
}) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  const createBooleansArray = () => {
    let booleans = [false, false, false, false, false,false];
 
    return booleans;
  };

  const [checkedState, setCheckedState] = useState({
    booleans: createBooleansArray(),
  });
  const filter = () => {

      onNo();
      filterbystatus(checkedState.booleans);

  };
  const handleOnChange = (position) => {
    const aux = checkedState.booleans;
    
    aux[0]=false;
    aux[1]=false;
    aux[2]=false;
    aux[3]=false;
    aux[4]=false;
    aux[5]=false;
    aux[position] = true;
    setCheckedState({ booleans: aux });
  };

  return (
    <ReactModal
      isOpen={isOpen}
      className="dialog-base-modal"
      overlayClassName="dialog-base-modal__overlay"
      shouldCloseOnOverlayClick
      onRequestClose={onNo}
    >
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <div className="dialog-base-modal__content ">
        <div className="titulo">
          FILTRAR CLIENTES POR: 
        </div>
        <FormGroup>
          <FormControlLabel label="No vino" className="yellowy" control={<Checkbox color="primary" name={'en espera'} value={'en espera'} checked={checkedState.booleans[0]} onChange={() => handleOnChange(0)} />} />
        </FormGroup>
        <FormGroup>
          <FormControlLabel label="Cancelado" className="redy" control={<Checkbox color="primary" name={'no show'} value={'no show'} checked={checkedState.booleans[1]} onChange={() => handleOnChange(1)} />} />
        </FormGroup>
        <FormGroup>
          <FormControlLabel label="Ha asistido" className="greeny" control={<Checkbox color="primary" name={'cancelado'} value={'cancelado'} checked={checkedState.booleans[2]} onChange={() => handleOnChange(2)} />} />
        </FormGroup>
        <FormGroup>
          <FormControlLabel label="Se ha ido" control={<Checkbox color="primary" name={'ha asistido'} value={'ha asistido'} checked={checkedState.booleans[3]} onChange={() => handleOnChange(3)} />} />
        </FormGroup>
        <FormGroup>
          <FormControlLabel label="Anotada por el restaurante" className="greyy" control={<Checkbox color="primary" name={'se ha ido'} value={'se ha ido'} checked={checkedState.booleans[4]} onChange={() => handleOnChange(4)} />} />
        </FormGroup>
        <FormGroup>
          <FormControlLabel label="Quitar filtros, volver a ver todos los estatus" className="pinky" control={<Checkbox color="primary" name={'all'} value={'all'} checked={checkedState.booleans[5]} onChange={() => handleOnChange(5)} />} />
        </FormGroup>
        <div className="flexend">
          <Button
            className="cancelBtn"
            variant="contained"
            color="secondary"
            type="button"
            onClick={onNo}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={filter}
          >
            Filtrar
          </Button>
        </div>
      </div>
    </ReactModal>
  );
};

DialogFilterStatus.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  filtering: PropTypes.any,
  filterbystatus: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
};
DialogFilterStatus.defaultProps = {
  isOpen: false,
  onNo: '',
  filtering: '',
  filterbystatus: '',
};

export default DialogFilterStatus;
