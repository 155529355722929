import { catalogCategory } from '../../api/catalogs';

const prepareCategories = (categories) => {
  if (categories.length > 0) {
    const categoriesUserList = [];
    categories.forEach((element) => {
      if (element.status === 1) { // Status === 1 es que si lo tiene asignado
        const categorie = {
          string: element.name,
        };
        categoriesUserList.push(categorie);
      }
    });
    return (categoriesUserList);
  }
  return [];
};

let categoriesCatalogList;

const prepareCatalogsCategories = async () => {
  console.log('cargo');
  const localCategoriesCatalogList = localStorage.getItem('catalogCategory');
  if (localCategoriesCatalogList === null || localCategoriesCatalogList === 'undefined') {
    await catalogCategory().then((response) => {
      categoriesCatalogList = response.data;
      localStorage.setItem('catalogCategory', JSON.stringify(response.data));
    });
  } else {
    categoriesCatalogList = JSON.parse(localCategoriesCatalogList);
  }
  console.log(categoriesCatalogList);
};

const prepareClientsTable = async (data: any) => {
  const rawData = data;
  const preparedData = [];
  await prepareCatalogsCategories().then(() => {
    rawData.forEach((element) => {
      const tableData = {
        image: element.photo,
        fullName: `${element.firstName} ${element.middleName} ${element.lastName}`,
        firstName: element.firstName,
        middleName: element.middleName,
        lastName: element.lastName,
        visits: element.visits,
        totalSpend: element.totalSpend,
        phone: element.cellPhone,
        email: element.email,
        id: element.id,
        categories: prepareCategories(element.categories),
      };
      preparedData.push(tableData);
    });
    return preparedData;
  });
  return preparedData;
};
export default prepareClientsTable;
