const getFirstErrorMessage = (errorMessages) => {
  const keys = Object.keys(errorMessages);
  const firstError = errorMessages[keys[0]][0];
  if (firstError.includes('The email has already')) {
    return 'El correo ya está registrado.';
  }
  if (firstError.includes('date must be greater or equals than today')) {
    return 'Elige una fecha u hora posterior a la actual.';
  }
  return firstError;
};

export default getFirstErrorMessage;
