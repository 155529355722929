import getAvaliabilityPercentage from '../getAvaliabilityPercentage';

const prepareReservationTabs = (data: any) => {
  const preparedData = [];
  data.forEach((element) => {
    const tabData = {
      ...element,
      percentage: getAvaliabilityPercentage(element.capacity, element.people),
    };
    preparedData.push(tabData);
  });
  return preparedData;
};

export default prepareReservationTabs;
