import moment from 'moment';

const prepareReservationEditData = (data: any) => {
  const preparedData = {
    id: data.id,
    numberPeople: data.numberPeople,
    date: moment(data.date).format('yyyy-MM-DD'),
    hour: moment(data.date, 'YYYY-MM-DD hh:mm:ss').format('hh:mm a'),
    hour24: moment(data.date, 'YYYY-MM-DD hh:mm:ss').format('HH:mm'),
    comments: data.comments.length > 0 ? data.comments : '',
    tableNumber: data.tableNumber,
    tableId: data.tableId,
    cliendId: data.clientId,
    type: data.type,
  };
  console.log(preparedData);
  return preparedData;
};

export default prepareReservationEditData;
