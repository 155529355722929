/* eslint-disable */

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { navigate } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import MenuTableOptions from '../MenuTableOptions';
import OffersContext from '../../layouts/Offers/OffersContext/OffersContext';
import DialogDeleteOffers from '../DialogDeleteOffers';
import SnackbarAlert from '../SnackbarAlert';
import * as OffersApi from '../../api/restaurants/offers';
import isTruthy from '../../utils/isTruthy';
import moment from 'moment';


import './OffersTable.scss';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [{
  key: '1',
  id: 'id',
  label: 'ID',
  disablePadding: false,
  sortable: true,
}, {
  key: '2',
  id: 'titulo',
  label: 'TITULO',
  disablePadding: false,
  sortable: true,
}, {
  key: '3',
  id: 'descripcion',
  label: 'DESCRIPCION',
  disablePadding: false,
  sortable: true,
}, {
  key: '4',
  id: 'tipo',
  label: 'TIPO',
  disablePadding: false,
  sortable: true,
}, 
, {
  key: '5',
  id: 'status',
  label: 'STATUS',
  disablePadding: false,
  sortable: true,
}, {
  key: '6',
  id: 'fecha',
  label: 'FECHA',
  disablePadding: true,
  sortable: false,
}, {
  key: '7',
  id: 'menu',
  disablePadding: true,
  sortable: false,
},
];

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={uuid()}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
            {!headCell.sortable && (
              <div>
                {headCell.label}
              </div>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    borderBottom: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const EnhancedTable = () => {
  const classes = useStyles();
  const { filteredOffers, setReload } = useContext(OffersContext);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('firstName');
  // const [pagination] = useState(apiCallConfig);
  const [deleteOffersDialog, setDeleteOffersDialog] = useState(false);
  const [deleteOffersDialogData, setDeleteOffersDialogData] = useState({ name: '', id: null });
  // Configuración para el snackbar alert
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    localStorage.setItem('ReservationsConfigSortCategory', property);
    localStorage.setItem('ReservationsConfigSortDirection', isAsc ? 'desc' : 'asc');
    // setApiCallConfig({
    //   ...pagination,
    //   sortCategory: property,
    //   sortDirection: isAsc ? 'desc' : 'asc',
    // });
  };

  const closeDeleteOffersDialog = () => {
    setDeleteOffersDialog(false);
  };
  function getDate(date) {
    
    let mont =["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ]
    let data = new Date(date);
    return ( (data.getDate() >= 10 ? data.getDate():"0" + data.getDate()) + " " + mont[data.getMonth()] + " " + data.getFullYear() )
}
  const openDeleteOffersDialog = (id, email) => {
    setDeleteOffersDialogData({ email, id });
    setDeleteOffersDialog(true);
  };

  const menuOptions = (id, email) => [
    { key: 1, string: 'EDITAR', action: () => navigate(`/editar-oferta/${id}`) },
    { key: 2, string: 'ELIMINAR', action: () => { openDeleteOffersDialog(id, email); } },
  ];

  const deleteOffers = async () => {
    console.log(deleteOffersDialogData);
    try {
      const { success, message, data } = await OffersApi
        .deleteOffers(deleteOffersDialogData.id);
      if (isTruthy(success)) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
        closeDeleteOffersDialog();
        setTimeout(() => {
          setReload();
        }, 1000);
      } else {
        console.log(data);
        setSnackbar({
          open: true,
          severity: 'error',
          text: data.data,
        });
        closeDeleteOffersDialog();
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
      closeDeleteOffersDialog();
    }
  };
  const renderdates = (dates) => {

    const datesarray = [];
    //fill fates array with da

    const parsedjson = JSON.parse(dates);

    for (let i = 0; i < parsedjson.length; i += 1) {
      const date = parsedjson[i];
      datesarray.push(date);
    } 
 
    console.log(datesarray);
    return <>
      {datesarray.map((date) => (
        <span className='blackbadge'>
          {date}
        </span>
      ))} 
    </>
  };
  const renderTable = () => (
    <div className={classes.root}>
      <DialogDeleteOffers
        isOpen={deleteOffersDialog}
        onYes={deleteOffers}
        onNo={closeDeleteOffersDialog}
        data={deleteOffersDialogData}
      />
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <Paper className={classes.paper} elevation={0}>
        <TableContainer>
          <Table
            className={`${classes.table} offers-table`}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredOffers.length}
            />
            <TableBody>
              {stableSort(filteredOffers, getComparator(order, orderBy))
                .map((row) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={uuid()}
                  >
                    <TableCell key={uuid()} align="left">{row.id}</TableCell>
                    <TableCell key={uuid()} align="left">{row.title}</TableCell>
                    <TableCell key={uuid()} align="left">{row.description}</TableCell>
                    <TableCell key={uuid()} align="left">{row.type}</TableCell>
                    <TableCell key={uuid()} align="left">{(row.status==1)? 'Activo': 'Deshabilitado'}</TableCell>

                    <TableCell key={uuid()} align="left">{(row.repeats) ? getDate(row.start_date) + ' - ' + getDate(row.end_date) : renderdates(row.dates)}</TableCell>
                    <TableCell key={uuid()} align="left">
                      <MenuTableOptions id={row.id} options={menuOptions(row.id, row.email)} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  return (
    <>
      {renderTable()}
    </>
  );
};

// EnhancedTable.propTypes = {
//   tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   newPagination: PropTypes.shape({
//     currentPage: PropTypes.number,
//     perPage: PropTypes.number,
//     total: PropTypes.number,
//     from: PropTypes.number,
//     to: PropTypes.number,
//     options: PropTypes.arrayOf(PropTypes.number),
//     date: PropTypes.string,
//     zone: PropTypes.string,
//     sortCategory: PropTypes.string,
//     sortDirection: PropTypes.string,
//   }).isRequired,
//   setApiCallConfig: PropTypes.func.isRequired,
// };

export default EnhancedTable;
