import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
} from '@material-ui/core/';
import PropTypes from 'prop-types';

import SnackbarAlert from '../SnackbarAlert';

import * as RestaurantsApi from '../../api/restaurants/restaurants';
import RestaurantContext from '../../layouts/Restaurant/RestaurantContext/RestaurantContext';
import isTruthy from '../../utils/isTruthy';
import websiteRegex from '../../utils/websiteRegex';
import emojiRegex from '../../utils/emojiRegex';

import './RestaurantMenu.scss';

const RestaurantMenu = ({ setReload }) => {
  // const [value, setValue] = useState(0);
  const { menuData, linkData, specialmenuData } = useContext(RestaurantContext);
  const [fields, setFields] = useState({ menu: '', link: '', specialmenu: '' });

  const [validations, setValidations] = useState({
    menu: true,
    link: true,
    specialmenu: true,

  });

  const [hasEditedField, setHasEditedField] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  useEffect(() => {
    setFields({ menu: menuData !== null && menuData.length > 0 ? menuData : '', link: linkData !== null && linkData.length > 0 ? linkData : '', specialmenu: specialmenuData });
  }, [menuData, linkData, specialmenuData]);

  const handleValidations = (field, value) => {
    const mapValidations = {
      menu: (menu) => websiteRegex.test(menu),
      link: () => true,
      specialmenu: () => true,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

  const updateFields = (field, value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...fields,
    };
    newFields[field] = cleanString;
    setFields(newFields);
    setHasEditedField(true);
    handleValidations(field, cleanString);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validations.menu) {
      try {
        const { success, message } = await RestaurantsApi.editMenu({
          menu_url: fields.menu,
          link_url: fields.link,
          specialmenu_url: fields.specialmenu,

        });
        if (isTruthy(success)) {
          setSnackbar({
            open: true,
            severity: 'success',
            text: message,
          });
          setTimeout(() => {
            setReload(true);
          }, 1500);
        } else {
          setSnackbar({
            open: true,
            severity: 'error',
            text: message,
          });
        }
      } catch (error) {
        console.log(error);
        setSnackbar({
          open: true,
          severity: 'error',
          text: error.toJSON().message,
        });
      }
    }
  };

  const renderForm = () => (
    <form onSubmit={validations.menu && hasEditedField && handleSubmit} className="w100 dFlex flex-column align-center justify-center" autoComplete="off">
      <h3>Link del menú</h3>
      <div className="restaurantMenu-form__field-container">
        <TextField
          className="w100"
          error={!validations.menu}
          id="menu"
          onChange={(e) => updateFields('menu', e.target.value)}
          value={fields.menu}
        />
      </div>
      <h3 className="mt-2">Link de los salones de eventos</h3>
      <div className="restaurantMenu-form__field-container">
        <TextField
          className="w100"
          error={!validations.link}
          id="link"
          onChange={(e) => updateFields('link', e.target.value)}
          value={fields.link}
        />
      </div>
      <h3 className="mt-2">Link del menú especial (Navidad, Restaurant Week, etc.)</h3>
      <div className="restaurantMenu-form__field-container">
        <TextField
          className="w100"
          error={!validations.specialmenu}
          id="specialmenu"
          onChange={(e) => updateFields('specialmenu', e.target.value)}
          value={fields.specialmenu}
        />
      </div>
      <div className="restaurantMenu-form__buttonGroup mt-2">
        <Button
          variant="contained"
          color="primary"
          type="submit"
        >
          ACTUALIZAR MENÚ
        </Button>
      </div>
    </form>
  );

  return (
    <>
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <Box
        component="div"
        className="dFlex flex-column w100"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} />
          <Grid item xs={12} md={6}>
            {renderForm()}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

RestaurantMenu.propTypes = {
  setReload: PropTypes.func,
};

RestaurantMenu.defaultProps = {
  setReload: () => { },
};

export default RestaurantMenu;
