import React, { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import TokenManager from '../../../api/utils/TokenManager';
import SnackbarAlert from '../../../components/SnackbarAlert';

const NotificationHandler = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  useEffect(() => {
    const token = new TokenManager('access').getToken();
    const restaurantEid = new TokenManager('restaurant_eid').getToken();
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: 'us2',
      encrypted: true,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      },
      forceTLS: true,
    });
    pusher.logToConsole = true;
    console.log('pusher', pusher);

    pusher.connection.bind('connected', ({ socket_id: socketId }) => {
      console.log(`Connected to ${socketId}!`);
    });

    pusher.connection.bind('state_change', (states) => {
      console.log(`Channels previous state is ${states.previous}`);
      console.log(`Channels current state is ${states.current}`);
    });

    pusher.connection.bind('error', (err) => {
      console.error('pusher error', err);
    });

    pusher.bind_global((eventName, data) => {
      if (restaurantEid === data.restaurant_eid) {
        console.log(`bind global: Event ${eventName} was triggered with ${JSON.stringify(data)}`);
        const snackmessage = {
          NewReservation: 'Nueva reservación recibida',
          CancelReservation: 'Una reservación fue cancelada',
          UpdateReservation: 'Una reservación fue modificada',
        };
        setSnackbar({
          open: true,
          severity: 'info',
          text: snackmessage[eventName],
          navigateTo: '/reservaciones',
        });
      }
    });

    const channel = pusher.subscribe('Reservations');

    channel.bind('Reservations', () => {
      console.log('No se manda sin esto');
    });
  }, []);

  return (
    <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
  );
};

export default NotificationHandler;
