import moment from 'moment';
import 'moment/locale/es';

const prepareStaffTable = async (data: any) => {
  const rawData = data;
  const preparedData = [];
  rawData.forEach((element) => {
    const tableData = {
      id: element.id,
      email: element.email,
      role: element.role,
      createdAt: moment(element.createdAt).locale('es').format('DD MMMM yyyy'),
      lastLogin: element.lastLogin ? moment(element.lastLogin).locale('es').format('DD MMMM yyyy | hh:mm') : 'Sin datos',
    };
    preparedData.push(tableData);
  });
  return preparedData;
};

export default prepareStaffTable;
