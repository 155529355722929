import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  // FormLabel,
  FormGroup,
  FormControlLabel,
  Hidden,
  IconButton,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

import { v4 as uuid } from 'uuid';

import SnackbarAlert from '../../../components/SnackbarAlert';

import './ReservationsEditFormTwo.scss';

const ReservationsEditFormTwo = ({
  nextStep,
  prevStep,
  avaliabilityData,
  numberPeople,
  setSelectedOption,
  table,
  previousDateRaw,
  currentDateRaw,
}) => {
  const [fields, setFields] = useState({
    selectedOption: '',
  });
  const [validations, setValidations] = useState({
    selectedOption: false,
  });

  const [categoriesList, setCategoriesList] = useState();
  const [firstRender, setFirstRender] = useState(true);
  const [previousDate, setPreviousDate] = useState();
  const [previousHour, setPreviousHour] = useState();

  const [status, setStatus] = useState({
    error: null,
    isLoading: false,
    isValid: false,
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  useEffect(() => {
    setPreviousDate(previousDateRaw.substring(0, 10));
    setPreviousHour(previousDateRaw.substring(11, 16));
    console.log('hora');
    console.log(previousDateRaw.substring(11, 16));
    console.log('fecha');
    console.log(previousDateRaw.substring(0, 10));
    const localCategoriesCatalogList = JSON.parse(localStorage.getItem('catalogCategory'));
    const ProcesedCategories = [];
    localCategoriesCatalogList.forEach((element) => {
      ProcesedCategories[element.name] = element.encryptId;
    });
    setCategoriesList(ProcesedCategories);
  }, [previousDateRaw]);

  console.log(categoriesList);
  const prepareScrollCount = () => {
    const count = {};
    avaliabilityData.forEach((element) => {
      count[element.table] = 0;
    });
    return count;
  };
  const prepareAccordeonExpanse = () => {
    const accordionState = {};
    avaliabilityData.forEach((element) => {
      accordionState[element.table] = false;
    });
    return accordionState;
  };

  const [scrollCount, setScrollCount] = useState(prepareScrollCount);
  const [accordeonExpansed, setAccordeonExpansed] = useState(prepareAccordeonExpanse);

  const areFieldsValid = () => (
    Object.values(validations).every((item) => item)
  );

  const handleValidations = (field, value) => {
    const mapValidations = {
      selectedOption: (option) => option.avalable === true,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

  const updateFields = (field, value, id) => {
    const newFields = {
      ...fields,
    };
    newFields[field] = value;
    setFields(newFields);
    handleValidations(field, value);
    if (id && !firstRender) {
      setTimeout(() => {
        document.getElementById(`${id}-options`).scrollLeft += scrollCount[id];
      }, 10);
    }
  };

  const scrollHorizontal = (id, direction) => {
    const updateCount = scrollCount;
    if (direction === 'right') {
      document.getElementById(`${id}-options`).scrollLeft += 250;
      // eslint-disable-next-line operator-assignment
      updateCount[id] = updateCount[id] + 250;
    }
    if (direction === 'left') {
      document.getElementById(`${id}-options`).scrollLeft += -250;
      // eslint-disable-next-line operator-assignment
      updateCount[id] = updateCount[id] - 250 < 0 ? 0 : updateCount[id] - 250;
    }
    console.log(updateCount);
    setScrollCount(updateCount);
  };

  const manageAccordionExpansion = (expanded, areaName) => {
    const area = areaName;
    const currentValues = prepareAccordeonExpanse();
    setAccordeonExpansed({ ...currentValues, [area]: expanded });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ ...status, isLoading: true });
    if (areFieldsValid && !status.isLoading) {
      setSelectedOption(fields.selectedOption);
      nextStep(fields.selectedOption);
    }
  };

  const previousStep = () => {
    prevStep(1);
  };

  const areaHasAvaliability = (schedules) => {
    let avaliableHours = 0;
    schedules.forEach((element) => {
      if (element.avalable === true) {
        avaliableHours += 1;
      }
    });
    if (avaliableHours > 0) {
      return true;
    }
    return false;
  };

  const checkIfSelected = (option, area) => {
    console.log('check if selected');
    console.log(option);
    console.log(previousDateRaw.substring(0, 16));
    console.log(currentDateRaw);

    if (
      option.hour === previousHour
      //  && option.date === previousDate
      && area.table === table
      && firstRender
      // && currentDateRaw === previousDateRaw.substring(0, 16)
    ) {
      setFirstRender(false);
      updateFields('selectedOption', { ...option, table: area.table, tableId: area.tableTypeId }, area.table);
      return true;
    }
    return false;
  };

  const renderNoData = () => <div className="font__roboto">No hay lugares disponibles en esta área</div>;

  const renderLoading = () => (
    <CircularProgress color="primary" className="mb-2" />
  );

  const renderData = (area) => (
    <>
      <div>
        <IconButton
          onClick={() => { scrollHorizontal(area.table, 'left'); }}
        >
          <ArrowBackIosIcon />
        </IconButton>
      </div>
      <FormGroup id={`${area.table}-options`} className="reservationsCreateForm__select-column-container">
        <div className="dFlex flex-row align-center justify-start">
          <div className="dFlex flex-row">
            {area.schedule.map((option) => {
              if (option.avalable) {
                // if (
                //   option.hour === previousHour
                //   && option.date === previousDate
                //   && area.table === table
                // ) {
                //   updateFields('selectedOption', {
                //  ...option, table: area.table, tableId: area.tableTypeId }, area.table
                //  );
                // }
                return (
                  <div key={uuid()} className="reservationsCreateForm__checkbox-container">
                    <FormControlLabel
                      label={moment(`1988-01-08 ${option.hour}`).format('hh:mm a')}
                      control={(
                        <Checkbox
                          id={`${option.hour}-${area.table}`}
                          key={uuid()}
                          checked={
                            (fields.selectedOption.hour === option.hour
                              && fields.selectedOption.table === area.table)
                            || checkIfSelected(option, area)
                          }
                          name={option.hour}
                          disableRipple
                          color="primary"
                          onChange={() => {
                            updateFields('selectedOption', { ...option, table: area.table, tableId: area.tableTypeId }, area.table);
                          }}
                        />
                      )}
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </FormGroup>
      <div>
        <IconButton
          onClick={() => { scrollHorizontal(area.table, 'right'); }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </>
  );

  const renderDataAccordion = (area) => {
    console.log(area);
    console.log([previousDate, previousHour]);
    return (
      <Accordion
        key={uuid()}
        expanded={accordeonExpansed[area.table]}
        onChange={(event, expanded) => { manageAccordionExpansion(expanded, area.table); }}
      >
        <AccordionSummary
          expandIcon={areaHasAvaliability(area.schedule) && <ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="reservations-accordion__header">
            <div className="dFlex flex-row font__roboto">
              {area.table}
            </div>
            {!areaHasAvaliability(area.schedule) && renderNoData()}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="reservations-accordion__expansion">
            {area.schedule.map((option) => {
              if (option.avalable) {
                return (
                  <div key={uuid()} className="reservationsCreateForm__accordion-container">
                    <FormControlLabel
                      label={option.hour}
                      control={(
                        <Checkbox
                          id={`${option.hour}-${area.table}`}
                          key={uuid()}
                          checked={
                            (fields.selectedOption.hour === option.hour
                              && fields.selectedOption.table === area.table)
                            || checkIfSelected(option, area)
                          }
                          name={option.hour}
                          disableRipple
                          color="primary"
                          onChange={() => { updateFields('selectedOption', { ...option, table: area.table, tableId: area.tableTypeId }); }}
                        />
                      )}
                    />
                  </div>
                );
              }
              return null;
            })}
            {numberPeople > area.totalCapacity && renderNoData()}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Box
      component="div"
    >
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <form className="w100 dFlex flex-column mb-4 reservationsCreateForm" onSubmit={handleSubmit} autoComplete="off">
        <div className="w100 mt-1 dFlex flex-column">
          <FormControl className="w-100" component="fieldset">
            {/* <FormLabel component="legend">Categoría</FormLabel> */}
            {avaliabilityData.map((area) => (
              <div key={uuid()}>
                <Hidden xsDown>
                  <div className="reservationsCreateForm__areas dFlex flex-row align-center">
                    <div className="reservationsCreateForm__area-column font__commutter">
                      {area.table === 'Barra' ? 'Barra de bar' : area.table}
                    </div>
                    {!areaHasAvaliability(area.schedule)
                      && renderNoData()}
                    {areaHasAvaliability(area.schedule) && renderData(area)}
                  </div>
                </Hidden>
                <Hidden smUp>
                  {renderDataAccordion(area)}
                </Hidden>
              </div>
            ))}
          </FormControl>
        </div>
        <div className="dFlex align-end justify-end">
          {status.isLoading && renderLoading()}
        </div>
        <Hidden xsDown>
          <div className="reservationsCreateForm__button-group mt-1">
            <Button
              variant="contained"
              color="secondary"
              type="button"
              onClick={() => { navigate('/reservaciones'); }}
            >
              <span className="underline">
                CANCELAR
              </span>
            </Button>
            <div className="dFlex">
              <Button
                variant="contained"
                color="secondary"
                type="button"
                onClick={() => { previousStep(); }}
              >
                <span className="underline">
                  PASO ANTERIOR
                </span>
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!validations.selectedOption || status.isLoading}
              >
                SIGUIENTE
              </Button>
            </div>
          </div>
        </Hidden>
        <Hidden smUp>
          <div className="reservationsCreateForm__button-group-mobile mt-1">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!validations.selectedOption || status.isLoading}
              className="w100"
            >
              SIGUIENTE
            </Button>
            <div className="dFlex justify-between">
              <Button
                variant="contained"
                color="secondary"
                type="button"
                onClick={() => { previousStep(); }}
              >
                <span className="underline">
                  PASO ANTERIOR
                </span>
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="button"
                onClick={() => { navigate('/reservaciones'); }}
              >
                <span className="underline">
                  CANCELAR
                </span>
              </Button>
            </div>
          </div>
        </Hidden>

      </form>
    </Box>
  );
};

ReservationsEditFormTwo.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  table: PropTypes.string.isRequired,
  previousDateRaw: PropTypes.string.isRequired,
  currentDateRaw: PropTypes.string.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  avaliabilityData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  numberPeople: PropTypes.number.isRequired,
  stepOneValues: PropTypes.shape({
    name: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    comments: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
export default ReservationsEditFormTwo;
