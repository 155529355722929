/* eslint-disable prefer-template */
import React, { useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { navigate } from '@reach/router';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import OffersContext from '../../layouts/Offers/OffersContext/OffersContext';
import MenuTableOptions from '../MenuTableOptions';
import DialogDeleteOffers from '../DialogDeleteOffers';
import SnackbarAlert from '../SnackbarAlert';
import * as OffersApi from '../../api/restaurants/offers';
import isTruthy from '../../utils/isTruthy';
import './OffersAccordion.scss';

const OffersAccordion = () => {
  const { filteredOffers, setReload } = useContext(OffersContext);
  const [deleteOffersDialog, setDeleteOffersDialog] = useState(false);
  const [deleteOffersDialogData, setDeleteOffersDialogData] = useState({ name: '', id: null });
  // Configuración para el snackbar alert
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const closeDeleteOffersDialog = () => {
    setDeleteOffersDialog(false);
  };
  const openDeleteOffersDialog = (id, email) => {
    setDeleteOffersDialogData({ email, id });
    setDeleteOffersDialog(true);
  };

  const menuOptions = (id, email) => [
    { key: 1, string: 'EDITAR', action: () => navigate(`/editar-oferta/${id}`) },
    { key: 2, string: 'ELIMINAR', action: () => { openDeleteOffersDialog(id, email); } },
  ];

  const deleteOffers = async () => {
    console.log(deleteOffersDialogData);
    try {
      const { success, message, data } = await OffersApi
        .deleteOffers(deleteOffersDialogData.id);
      if (isTruthy(success)) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
        closeDeleteOffersDialog();
        setTimeout(() => {
          setReload();
        }, 1000);
      } else {
        console.log(data);
        setSnackbar({
          open: true,
          severity: 'error',
          text: data.data,
        });
        closeDeleteOffersDialog();
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
      closeDeleteOffersDialog();
    }
  };

  const renderdates = (dates) => {
    const datesarray = [];
    const parsedjson = JSON.parse(dates);
    for (let i = 0; i < parsedjson.length; i += 1) {
      const date = new Date(parsedjson[i]);
      datesarray.push(date);
    }
    return (
      <>
        {datesarray.map((date) => (
          <span className="blackbadge">
            {date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear()}
          </span>
        ))}
      </>
    );
  };
  function getDate(date) {
    const mont = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const data = new Date(date);
    return ((data.getDate() >= 10 ? data.getDate() : '0' + data.getDate()) + ' ' + mont[data.getMonth()] + ' ' + data.getFullYear());
  }
  const renderAccordion = () => (
    <div className="offers-accordion__container mb-3">
      <DialogDeleteOffers
        isOpen={deleteOffersDialog}
        onYes={deleteOffers}
        onNo={closeDeleteOffersDialog}
        data={deleteOffersDialogData}
      />
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      {filteredOffers.map((item) => (
        <Accordion key={uuid()} className="offers-accordion">
          <AccordionSummary
            className="offers-accordion__summary"
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="offers-accordion__info">
              <h3>Detalles de la oferta</h3>
              <Typography className="offers-accordion__name ">
                {item.title}
              </Typography>
              <Typography className="offers-accordion__description">
                {item.description}
              </Typography>
              <Typography className="offers-accordion__description">
                Status:
                {(item.status === 1) ? 'Activo' : 'Deshabilitado'}
              </Typography>
              <h5>{item.type}</h5>
              <div>
                <div className="font__roboto">
                  {(item.repeats) ? getDate(item.start_date) + ' - ' + getDate(item.end_date) : renderdates(item.dates)}
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="offers-accordion__expansion" />
            <MenuTableOptions id={item.id} options={menuOptions(item.id, item.email)} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );

  return (
    <>
      {renderAccordion()}
    </>
  );
};

export default OffersAccordion;
