import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import './MenuTableOptions.scss';

const ITEM_HEIGHT = 48;

const MenuTableOptions = ({ options }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="menu-table-options">
      <IconButton
        aria-label="more"
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            border: '1px solid #000',
            boxShadow: 'none',
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '25ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem className="menu-table-options__menu-item" key={option.key} onClick={option.action ? option.action : handleClose}>
            {option.string}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

MenuTableOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    string: PropTypes.string,
    action: PropTypes.func,
  })).isRequired,
};

export default MenuTableOptions;
