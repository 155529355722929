/* eslint max-len: 0 */
import React from 'react';

import {
  Box,
  Container,
  Typography,
} from '@material-ui/core/';

import * as AuthApi from '../../api/auth';

import TheMenu from '../../components/TheMenu';

import './Terms.scss';

const Terms = () => (
  <>
    { AuthApi.getAccessToken() && (<TheMenu setIsCalendarOpen={() => { }} />)}
    <Container
      className="restaurant layout px-0"
    >
      <Box
        component="div"
        className="terms__header pr-1 pl-1 dFlex flex-row align-center justify-between"
      >
        <Typography variant="h5" className="font__commutter" style={{ color: 'black', textDecoration: 'underline' }}>
          TÉRMINOS Y CONDICIONES PARA LOS RESTAURANTEROS
        </Typography>
      </Box>
      <Box
        component="div"
        className="terms__text-header pr-1 pl-1 dFlex flex-row align-center justify-between"
      >
        <div className="dFlex flex-column">
          <Typography variant="body1" className="restaurant__title font__roboto pb-4">
            Estas Condiciones de Uso de Reserva Ya
            {' '}
            <strong>
              (el “Contrato”)
            </strong>
            {' '}
            regulan el acceso a los sitios web, sitios
            móviles y aplicaciones de Reserva Ya
            {' '}
            <strong>
              (conjuntamente, los “Sitios Web de Reserva Ya”)
            </strong>
            {' '}
            y el uso
            de los mismos por parte de usted y son asimismo aplicables al contenido, a las características y
            a los servicios (
            <strong>
              los “Servicios”
            </strong>
            ) ofrecidos por Reserva Ya.
            <br />
            <br />
            El presente Contrato incorpora las políticas, los procedimientos y los términos y condiciones generales de Reserva Ya en lo que respecta al uso de los Servicios y a los que se hace referencia por su nombre o mediante los vínculos incluidos en el presente Contrato (
            <strong>
              conjuntamente, las “Políticas de Reserva Ya”
            </strong>
            ).
            <br />
            <br />
            Al acceder a los Servicios o hacer uso de los mismos o al hacer clic en “acepto” para aceptar el
            presente Contrato, usted (1) reconoce que ha leído, entiende y se obliga a cumplir el presente
            Contrato y (2) manifiesta y garantiza que es mayor de edad y no tiene legalmente prohibido
            acceder a los Servicios o hacer uso de los mismos (incluyendo, en su caso, comprar, entregar,
            usar o canjear Tarjetas-Regalo de Reserva Ya o Tarjetas-Regalo de Establecimiento (conforme
            dichos términos se definen en el presente Contrato Reserva Ya podrá actualizar o modificar el
            presente Contrato (incluyendo cualesquiera Políticas de Reserva Ya) en cualquier momento. (3)
            Usted se compromete a leer el presente Contrato periódicamente. (4) Usted decidirá libremente
            si acepta o no cualquier versión modificada del presente Contrato, pero reconoce que para
            seguir usando los Servicios deberá aceptar cualquier versión modificada. Si usted no acepta las
            condiciones del presente Contrato o cualquier versión modificada del mismo, deberá dejar de
            usar los Servicios, en cuyo caso se revocará el acceso a su Cuenta (conforme dicho término se
            define más adelante).
            <br />
            <br />
            <strong>
              1. Restricciones de uso.
            </strong>
            {' '}
            Los Servicios y los Contenidos de Reserva Ya se ofrecen (1) únicamente
            para el uso personal del Usuario y para los fines descritos en el presente Contrato. (2) Queda
            prohibido su uso para cualquier otro fin. (3) Reserva Ya se reserva expresamente todos los
            derechos y acciones que le asistan en virtud de la legislación aplicable. (4) Se prohíbe el uso de
            los Servicios o Contenidos de Reserva Ya para cualquier fin ilegal; o (5) divulgar información
            acerca del rendimiento de los Servicios o Contenidos de Reserva Ya o (6) acceder a los Servicios
            o Contenidos de Reserva Ya o usarlos para realizar análisis competitivos o comparativos. (7) El
            Restaurante se compromete a utilizar todos los datos e imágenes de los usuarios
            proporcionados por Reserva Ya exclusivamente para la finalización y mejora de la experiencia
            de reserva y servicio al cliente. Este compromiso incluye la garantía de que dichos datos e
            imágenes no serán utilizados para tomar decisiones discriminatorias hacia los usuarios basadas
            en raza, color, origen étnico, género, orientación sexual, edad, religión, discapacidad, o
            cualquier otra característica personal protegida por las leyes aplicables. (8) El Restaurante
            asegura que el uso de estos datos estará en conformidad con todas las normativas de protección
            de datos vigentes, promoviendo un entorno de respeto y equidad. (9) El incumplimiento de esta
            cláusula dará lugar a sanciones contractuales y legales pertinentes, además de la inmediata
            revocación del acceso a los datos e imágenes proporcionados por Reserva Ya. Reserva Ya no
            permite (10) utilizar cualquier dispositivo o software que interfiera o trate de interferir en la
            funcionalidad de los Servicios; (11) alquilar, arrendar, copiar, sublicenciar o facilitar a un tercero
            el acceso a cualquier parte de los Servicios o Contenidos de Reserva Ya; (12) usar cualquier parte
            de los Servicios o Contenidos de Reserva Ya para facilitar cualquier producto o servicio a un
            tercero, o incorporar cualquier parte de los Servicios o Contenidos de Reserva Ya a cualquier
            producto o servicio facilitado a un tercero; (13) descompilar, desensamblar o tratar de obtener
            el código fuente o APIs no públicas de los Servicios o realizar trabajos de ingeniería inversa sobre
            los mismos. usar los Servicios o Contenidos de Reserva Ya para cualquier fin ilegal; o (14) divulgar
            información acerca del rendimiento de los Servicios o Contenidos de Reserva Ya o acceder a los
            Servicios o Contenidos de Reserva Ya o usarlos para realizar análisis competitivos o
            comparativos. Reserva YA se reserva el derecho a limitar, a su entera discreción, la puesta a
            disposición y la cantidad de cualquier funcionalidad o servicio a cualquier persona o zona
            geográfica.
            <br />
            <br />
            <strong>
              2. Resolución/cancelación.
            </strong>
            {' '}
            Reserva Ya podrá suspender su capacidad para usar la totalidad o
            cualquiera de los elementos de los Servicios o podrá resolver el presente Contrato con efectos
            inmediatos y sin notificación o justificación alguna. Sin limitación de lo anterior, podremos
            suspender su acceso a los Servicios si consideramos que ha incumplido cualquier disposición del
            presente Contrato (incluyendo cualesquiera Políticas de Reserva YA). Tras cualquier suspensión
            o resolución, Reserva Ya podrá o no concederle permiso para usar los Servicios o para volver a
            crear una Cuenta. Usted podrá dejar de usar los Servicios libremente en cualquier momento.
            Usted reconoce que la cancelación de su Cuenta podrá implicar la eliminación del contenido que
            pueda estar almacenado en la misma, en cuyo caso, Reserva Ya no asumirá ninguna
            responsabilidad frente a usted.
            <br />
            <br />
            <br />
            Usted y Reserva Ya reconocen y aceptan las clausulas del presente Contrato
            <br />
            <br />
            <strong>
              Ley aplicable.
            </strong>
            {' '}
            El presente Contrato se celebra y se regirá e interpretará de conformidad con las
            leyes de La República Dominicana.
          </Typography>
        </div>
      </Box>
    </Container>
  </>
);

export default Terms;
