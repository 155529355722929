import React, { useContext } from 'react';
import { v4 as uuid } from 'uuid';
import { navigate } from '@reach/router';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import prepareCategoryList from '../../utils/prepareCategoryList';
import ClientsContext from '../../layouts/Clients/ClientsContext/ClientsContext';
import './ClientsAccordion.scss';

const ClientsAccordion = () => {
  const { filteredClients, typeOfClient } = useContext(ClientsContext);
  const renderAccordion = () => (
    <div className="clients-accordion__container mb-3">
      {filteredClients.map((item) => (
        <Accordion key={uuid()}>
          <AccordionSummary
            className="clients-accordion__summary"
            expandIcon={<ArrowForwardIosIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => { navigate(`/cliente/${typeOfClient}/${item.id}`); }}
          >
            {item.image !== null && (
              <div className="clients-accordion__picture">
                <div>
                  <svg viewBox="50 50 75px 75px" width="75" height="75">
                    <defs>
                      <mask id="mask">
                        <circle fill="#FFFFFF" cx="40%" cy="58%" r="23" />
                        <rect fill="#ffffff" x="9.7%" y="65%" width="45.5" height="40" />
                      </mask>
                    </defs>
                    <image mask="url(#mask)" xlinkHref={`${item.image}`} width="50" height="100" />
                  </svg>
                </div>
              </div>
            )}
            <div className="clients-accordion__info">
              <div className="clients-accordion__categories dFlex flex-row font__roboto">
                {item.categories.map((category) => prepareCategoryList(category))}
                {item.categories.length <= 0 && 'Sin categorías'}

              </div>
              <Typography className="clients-accordion__name ">
                {(item.firstName != null) && item.firstName.toUpperCase()}
                {' '}
                {(item.middleName != null) && item.middleName.toUpperCase()}
                {' '}
                {(item.lastName != null) && item.lastName.toUpperCase()}
              </Typography>
              <div>
                <div className="font__roboto">
                  {item.visits}
                  {' '}
                  visita(s)
                </div>
                <div className="font__roboto mb-1">
                  $
                  {item.totalSpend}
                </div>
              </div>
            </div>
          </AccordionSummary>
        </Accordion>
      ))}
    </div>
  );

  return (
    <>
      {renderAccordion()}
    </>
  );
};

export default ClientsAccordion;
