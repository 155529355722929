import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  InputLabel,
  Input,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  TableCell,
} from '@material-ui/core';

// import { initializeApp } from 'firebase/app';
// import { getMessaging, getToken } from 'firebase/messaging';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// eslint-disable-next-line import/named
import { useAuth } from '../../../context/auth-context';
import './LoginForm.scss';
import emailRegex from '../../../utils/emailRegex';

// const firebaseApp = initializeApp(firebaseConfig);
// const messaging = firebase.messaging.isSupported() ? firebase.messaging(firebaseApp) : null;
/*
export const requestForToken = () => {
  getToken(messaging, { vapidKey: 'AIzaSyBNC0Mz88mDjZT9TBHFVlUL732HJj9vZOc' })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });

}; */
const LoginForm = () => {
  const auth = useAuth();
  const [isFormTouched, setFormIsTouched] = useState(false);
  const [fields, setFields] = useState({
    email: '',
    password: '',
    checkbox: true,
    showPassword: false,
    firebase_token: '',
    acceptTerms: false,
  });
  const [validations, setValidations] = useState({
    email: false,
    password: false,
    checkbox: true,
    acceptTerms: true,
  });

  const [status, setStatus] = useState({
    error: null,
    isLoading: false,
    isValid: false,
  });

  useEffect(() => {
    if (fields.email.length > 0 || fields.password.length > 0) {
      setFormIsTouched(true);
    }
  }, [fields]);

  const handleClickShowPassword = () => {
    setFields({ ...fields, showPassword: !fields.showPassword });
  };

  const areValidFields = (validations.email && validations.password) && isFormTouched;

  const handleValidations = (field, value) => {
    const mapValidations = {
      email: (email) => emailRegex.test(email),
      password: (password) => password.length > 0,
      checkbox: (checkbox) => checkbox === true || checkbox === false,
      acceptTerms: (acceptTerms) => acceptTerms === true || acceptTerms === false,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

  const updateFields = (field, value) => {
    const newFields = {
      ...fields,
    };
    newFields[field] = value;
    setFields(newFields);
    handleValidations(field, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (areValidFields && !status.isLoading) {
      // if (!fields.acceptTerms) {
      //   setStatus({
      //     ...status,
      //   error: true,
      // message: 'Por favor, acepta los términos y condiciones.',
      // });
      // return;
      // }
      setStatus({
        ...status,
        isLoading: true,
        error: null,
      });

      try {
        setStatus({
          ...status,
          isLoading: true,
        });

        const response = await auth.login(fields);
        console.log(response);
        if (response.success === 0) {
          setStatus({
            ...status,
            isLoading: false,
            error: true,
            message: response.message,
            success: false,
          });
        }
        // // setStatus({
        // //   ...status,
        // //   isLoading: false,
        // //   error: message,
        // // });
      } catch (error) {
        console.log(error);
        setStatus({
          ...status,
          isLoading: false,
          error: true,
        });
      }
    }
  };

  return (
    <Box
      component="div"
      className="mt-5"
    >
      <form className="w100 dFlex flex-column" onSubmit={handleSubmit}>
        <TextField
          error={!isFormTouched ? false : !validations.email}
          id="email"
          label="Correo electrónico"
          onChange={(e) => updateFields('email', e.target.value)}
          value={fields.email}
        />
        <InputLabel
          className="mt-2"
          htmlFor="password"
        >
          Contraseña
        </InputLabel>
        <Input
          error={!isFormTouched ? false : !validations.password}
          id="password"
          label="Contraseña"
          onChange={(e) => updateFields('password', e.target.value)}
          type={fields.showPassword ? 'text' : 'password'}
          value={fields.password}
          endAdornment={
            (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {fields.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }
        />
        <FormControlLabel
          control={
            (
              <Checkbox
                checked={fields.checkbox}
                onClick={(e) => {
                  console.log(e);
                  updateFields('checkbox', !fields.checkbox);
                }}
                name="checkbox"
                color="primary"
              />
            )
          }
          label="Mantener iniciada la sesión"
        />
        <TableCell colSpan={2} style={{ textAlign: 'center', color: 'black', fontSize: 'smaller' }}>
          Al darle &quot;Ingresar&quot; acepto los términos y condiciones
        </TableCell>
        {status.error === true && (
          <Box
            component="div"
            className="mt-2 dFlex flex-column align-center justify-center color-error"
          >
            {status.message}
          </Box>
        )}
        <Box
          component="div"
          className="mt-3 dFlex flex-column align-center justify-center"
        >
          {status.isLoading && (
            <CircularProgress color="primary" className="mb-2" />
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!areValidFields}
          >
            Ingresar
          </Button>
        </Box>
      </form>
    </Box>
  );
};
export default LoginForm;
