import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import {
  Typography,
  Button,
} from '@material-ui/core/';

import './DialogDeleteStaff.scss';

ReactModal.setAppElement('#root');

const DialogDeleteStaff = ({
  isOpen, onYes, onNo, data,
}) => (
  <ReactModal
    isOpen={isOpen}
    className="dialog-delete-staff-modal"
    overlayClassName="dialog-delete-staff-modal__overlay"
  >
    <div className="dialog-delete-staff-modal__content ">
      <div className="dialog-delete-staff-modal__text-container">
        <div className="dialog-delete-staff-modal__title">
          <Typography variant="h6" gutterBottom>
            ¿Estás seguro de borrar al usuario
            {' '}
            {data.email}
            ?
          </Typography>
        </div>
        <div className="dialog-delete-staff-modal__body" />
      </div>
      <div className="dialog-delete-staff-modal__cta-container">
        <Button
          variant="contained"
          color="secondary"
          type="button"
          onClick={onNo}
        >
          <span className="underline">
            Mantener
          </span>
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={onYes}
        >
          Si, eliminar
        </Button>
      </div>
    </div>
  </ReactModal>
);

DialogDeleteStaff.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  onYes: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.string,
  }),
};
DialogDeleteStaff.defaultProps = {
  isOpen: false,
  onNo: '',
  onYes: '',
  data: {
    email: '',
    id: '',
  },
};

export default DialogDeleteStaff;
