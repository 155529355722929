import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import {
  Button,
  Typography,
} from '@material-ui/core/';
import Rating from '@material-ui/lab/Rating';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import * as CatalogsApi from '../../api/catalogs';
import * as RestaurantsApi from '../../api/restaurants/restaurants';
import isTruthy from '../../utils/isTruthy';
import SnackbarAlert from '../SnackbarAlert';

import './DialogEditRestaurantCost.scss';

ReactModal.setAppElement('#root');

const DialogEditRestaurantCost = ({
  isOpen, onNo, data,
}) => {
  const [title] = useState('RANGO DE PRECIO');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);
  const [costCatalogs, setCostCatalogs] = useState([]);
  const [cost, setCost] = useState();
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  useEffect(() => {
    if (isOpen) {
      setIsLoading(false);
      const fetchCatalogs = async () => {
        try {
          const Response = await CatalogsApi
            .catalogCost();
          if (isTruthy(Response.success)) {
            setCostCatalogs(Response.data);
            setCost(data.value.length);
            setIsLoadingCatalogs(false);
          }
        } catch (error) {
          alert(error);
        }
      };
      fetchCatalogs();
    }
  }, [data, isOpen]);

  const submitChange = async (e, value) => {
    const body = {
      cost_eid: costCatalogs[value - 1].encryptId,
    };
    try {
      const { success, message } = await RestaurantsApi.editCost(body);
      if (isTruthy(success)) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
        setCost(value);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };

  const renderModal = () => (
    <ReactModal
      isOpen={isOpen}
      className="dialog-edit-restaurant-cost-modal"
      overlayClassName="dialog-edit-restaurant-cost-modal__overlay"
    >
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <div className="dialog-edit-restaurant-cost-modal__content ">
        <div className="dialog-edit-restaurant-cost-modal__text-container">
          <div className="dialog-edit-restaurant-cost-modal__title">
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </div>
          <div className="dialog-edit-restaurant-cost-modal__body">
            {isLoadingCatalogs && (
              <>
                Loading...
              </>
            )}
            {!isLoadingCatalogs && (
              <>
                <Rating
                  name="simple-controlled"
                  icon={<AttachMoneyIcon />}
                  value={cost}
                  onChange={(event, newValue) => {
                    submitChange(event, newValue);
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className="dialog-edit-restaurant-cost-modal__cta-container">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={onNo}
          >
            CERRAR
          </Button>
        </div>
      </div>
    </ReactModal>
  );

  return (
    <>
      {!isLoading && renderModal()}
    </>
  );
};

DialogEditRestaurantCost.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.shape({
    field: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  }),
};
DialogEditRestaurantCost.defaultProps = {
  isOpen: false,
  onNo: '',
  data: {
    field: '',
    value: {},
  },
};

export default DialogEditRestaurantCost;
