import React from 'react';

import PropTypes from 'prop-types';
import './DialogTotal.scss';
import ReactModal from 'react-modal';
import moment from 'moment';
import 'moment/locale/es';
import {
  Button,
} from '@material-ui/core/';

ReactModal.setAppElement('#root');

const DialogTotal = ({
  isOpen, onNo, data,
}) => (
  <ReactModal
    isOpen={isOpen}
    className="dialog-email-modal"
    overlayClassName="dialog-email-modal__overlay"
    shouldCloseOnOverlayClick
    onRequestClose={onNo}
  >
    <div className="dialog-email-modal__content ">
      <div className="titulo">
        TOTAL ASISTIDOS
        {' '}
        {moment(new Date(), 'YYYY-MM-DD').format('DD MMMM')}
      </div>
      <div className="contenido">
        Suma total de las reservas cuyo estatus es &lsquo;asistido&rsquo; o &lsquo;se ha ido&rsquo;
      </div>
      <span className="line">
        <span className="labeltitle">CON RESERVA: </span>
        <span className="numbersquare">{data.total}</span>
      </span>
      <span className="line">
        <span className="labeltitle">SIN RESERVA / WALK IN: </span>
        <span className="numbersquare">{data.walkin}</span>
      </span>
      <span className="line">
        <span className="labeltitle">SUMA TOTAL: </span>
        <span className="numbersquare">{data.total + data.walkin}</span>
      </span>
      <div className="flexcenter">
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={onNo}
        >
          ACEPTAR
        </Button>
      </div>
    </div>
  </ReactModal>
);

DialogTotal.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.shape({
    total: PropTypes.number,
    walkin: PropTypes.number,
    asistido: PropTypes.number,
  }),

};
DialogTotal.defaultProps = {
  isOpen: false,
  onNo: '',
  data: {
    total: '',
    walkin: '',
    asistido: '',
  },
};
export default DialogTotal;
