import React, { useContext, useEffect } from 'react';

import {
  Box,
  Tabs,
  Tab,
} from '@material-ui/core/';

import './ClientsTabs.scss';
import ClientsContext from '../../layouts/Clients/ClientsContext/ClientsContext';

const ClientsTabs = () => {
  const { typeOfClient, setTypeOfClient } = useContext(ClientsContext);

  const handleChange = (event, newValue) => {
    console.log(event, newValue);
    localStorage.setItem('clientsTypeOfClient', newValue);
    setTypeOfClient(newValue);
  };
  useEffect(() => {
    setTypeOfClient('general-public'); // Establecer el valor 'puerta21' al iniciar el componente
  }, [setTypeOfClient]); // Agregar setTypeOfClient como dependencia

  return (
    <Box
      component="div"
      className="clients__tabs-box  dFlex flex-column w100"
    >
      <Box
        component="div"
        className="w100"
      >
        <Tabs
          className="w100"
          variant="scrollable"
          scrollButtons="off"
          value={typeOfClient === null ? 'general-public' : typeOfClient}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          hidden
        >
          <Tab
            value="puerta21"
            key={1}
            label="RESERVA YA"
            hidden
          />
          <Tab
            value="general-public"
            key={2}
            label="PÚBLICO GENEReeAL"
            hidden
          />
        </Tabs>
      </Box>
    </Box>
  );
};
// ReservationsTabs.propTypes = {
//   // tabData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   // currentZone: PropTypes.shape({}).isRequired,
//   // setCurrentZone: PropTypes.func.isRequired,
// };
export default ClientsTabs;
