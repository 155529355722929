import React, { useState } from 'react';
import { navigate } from '@reach/router';
// import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Logo from '../../images/logo-svg2.svg';
import './Login.scss';
import UnauthenticatedSidebar from '../../components/UnauthenticatedSidebar';
import SplashScreen from '../SplashScreen';
import LoginForm from './LoginForm';

// const useStyles = makeStyles({
//   image: {
//     height: 'auto',
//     maxWidth: '100%',
//   },
// });
// const classes = useStyles();
// { /* <Typography>
//   <Button variant="contained" color="primary">
//     Primary contained
//   </Button>
//   <Button variant="contained" color="secondary">
//     Secondary contained
//   </Button>
//   <Button variant="outlined" color="primary">
//     Primary outlined
//   </Button>
// </Typography> */ }

const Login = () => {
  const [showSplash, setShowsplash] = useState(true);
  setTimeout(() => {
    setShowsplash(false);
  }, 1300);
  return (
    <>
      <Box component="div" className="login layout">
        <Container fixed height="100">
          <Grid container spacing={2}>
            <UnauthenticatedSidebar />
            <Hidden smDown>
              <Grid item xs={12} md={2} />
            </Hidden>
            <Grid item xs={12} md={4} className="dFlex flex-column align-center justify-center">
              <Box component="div">
                <div className="logotitulo">
                  <img className="img-fluid" src={Logo} alt="Logo" style={{ width: '100%' }} />
                </div>
                <div className="logosubtitulo">
                  Luxury Lifestyle Club
                </div>
              </Box>
              <Box
                component="div"
                className="w100"
              >
                <LoginForm />
              </Box>
              <Box
                component="div"
                className="mt-2 mb-2"
              >
                <Button onClick={() => { navigate('/olvide-password'); }} variant="contained" color="secondary">
                  ¿Olvidaste tu contraseña?
                </Button>
              </Box>
              <Box component="div" className="font__roboto">
                <button
                  className="login__buttons-legals"
                  type="button"
                  onClick={() => navigate('terms')}
                >
                  Términos y condiciones
                </button>
                {' '}
                |
                <button
                  className="login__buttons-legals"
                  type="button"
                  onClick={() => navigate('privacy')}
                >
                  Aviso de privacidad
                </button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Hidden smDown>
        <div className="confidencial-tag">
          <div className="text font__roboto">
            RESTAURANTES | CONFIDENCIAL
            {' '}
            <span className="line">_________________________________________</span>
          </div>
        </div>
      </Hidden>
      {showSplash && (
        <SplashScreen />
      )}
    </>
  );
};
export default Login;
