/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { navigate } from '@reach/router';
// import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/es';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import AddIcon from '@material-ui/icons/Add';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import { Tooltip, withStyles, Button } from '@material-ui/core/';
import Zoom from '@material-ui/core/Zoom';
import MuiAlert from '@material-ui/lab/Alert';
import * as CatalogsApi from '../../api/catalogs';
import * as ReservationsApi from '../../api/restaurants/reservations';
import MenuTableOptions from '../MenuTableOptions';
import MenuReservationStatus from '../MenuReservationStatus';
import prepareCategoryList from '../../utils/prepareCategoryList';
import ReservationsContext from '../../layouts/Reservations/ReservationsContext/ReservationsContext';
import DialogCancelReservation from '../DialogCancelReservation';
import DialogEditClient from '../DialogEditClient';
import DialogImage from '../DialogImage';
import DialogHistory from '../DialogComment';
import * as CommentsApi from '../../api/restaurants/comments';
import Iconcake from '../../images/errorIcons/cake.png';
import Iconstar from '../../images/categoryIcons/estrella.png';
import Iconwaiting from '../../images/categoryIcons/waiting.png';
import Iconevent from '../../images/categoryIcons/evento.png';
import IconPeople from '../../images/categoryIcons/people.png';
import './ReservationsTable.scss';
import isTruthy from '../../utils/isTruthy';
import DefaulImage from '../../images/default.jpeg';
import { IoStar } from "react-icons/io5";

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'black !important',
    color: 'rgba(255, 255, 255, 1)',
    border: 'solid 1px rgb(0,0,0)',
    boxShadow: 'none',
    fontSize: 11,
  },
}))(Tooltip);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const renderStars = (rating, starSize) => {
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      const starStyle = {
        color: '#ffd51e',
        fontSize: starSize,
        marginRight: '2px',
        lineHeight: '0.8'
      };
      stars.push(<IoStar key={i} style={starStyle} />);
    } else {
      // Estrella inactiva
      const starStyle = {
        color: '#d8d8d8',
        fontSize: starSize,
        marginRight: '2px',
        lineHeight: '0.8'
      };
      stars.push(<IoStar key={i} style={starStyle} />);
    }
  }

  return stars;
};


const headCells = [
  {
    key: '1',
    id: 'photo',
    disablePadding: false,
    label: 'FOTO',
    sortable: true,
  },
  {
    key: '2',
    id: 'tableNumber',
    disablePadding: false,
    label: 'CONTACTO',
    sortable: true,
  }, {
    key: '3',
    id: 'categoria',
    label: 'CATEGORÍA',
    disablePadding: false,
    sortable: false,
  }, {
    key: '4',
    id: 'nombre',
    label: 'NOMBRE',
    disablePadding: false,
    sortable: true,
  },
  {
    key: '5',
    id: 'mesa',
    label: 'MESA',
    disablePadding: false,
    sortable: true,
  }, {
    key: '6',
    id: 'zona',
    label: 'ZONA',
    disablePadding: false,
    sortable: true,
  }, {
    key: '7',
    id: 'fecha',
    label: 'HORA',
    disablePadding: false,
    sortable: true,
  }, {
    key: '8',
    id: 'asistentes',
    label: 'ASISTENTES',
    disablePadding: false,
    sortable: true,
  }, {
    key: '9',
    id: 'status',
    label: 'ESTATUS',
    disablePadding: false,
    sortable: true,
  }, {
    key: '10',
    id: 'comments',
    label: 'COMENTARIOS',
    disablePadding: false,
    sortable: false,
  }, 
  {
    key: '11',
    id: 'visitas',
    label: 'VISITAS',
    disablePadding: false,
    sortable: false,
  },
  {
    key: '12',
    id: 'menu',
    label: 'EDITAR',
    disablePadding: false,
    sortable: false,
  },
  {
    key: '13',
    id: 'modalhistory',
    label: ' ',
    disablePadding: false,
    sortable: false,
  },
];

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={uuid()}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >

                {(headCell.label !== 'ASISTENTES') ? headCell.label
                  : (
                    <LightTooltip title="Esta es la cantidad de asistentes" TransitionComponent={Zoom} placement="top-center">
                      <span className="clientname capitalized">
                        <img src={IconPeople} alt="asistentes" className="iconpeople" />
                      </span>
                    </LightTooltip>
                  )}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
            {!headCell.sortable && (
              <div>
                {headCell.label}
              </div>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    borderBottom: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));
const Modal = ({  data }) => {
  return (
    <div className={`modal  'open' : ''}`}>
      <div className="modal-content">
        <h2>Modal Title</h2>
        <p>{data}</p>
       
      </div>
    </div>
  );
};

const EnhancedTable = () => {
  const classes = useStyles();
  const {
    apiCallConfig,
    filterdReservations, setApiCallConfig,
    title,
  } = useContext(ReservationsContext);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = React.useState(apiCallConfig.sortDirection);
  const [orderBy, setOrderBy] = React.useState(apiCallConfig.sortCategory);
  const [pagination] = useState(apiCallConfig);
  const [catalogReservationStatus, setCatalogReservationStatus] = useState({});
  const [cancelDialog, setCancelDialog] = useState(false);
  const [imageDialog, setImageDialog] = useState(false);
  const [historyDialog, setHistoryDialog] = useState(false);
  const [historyDialogData, setHistoryDialogData] = useState({ created_by: '', status_modified_by: '', modified_by: '', deleted_by: ''  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState('');
  const [EditClientDialog, setEditClientDialog] = useState(false);
  const [editClientDialogData, setEditClientDialogData] = useState({ name: '', clientId: null });
  const [imageDialogData, setImageDialogData] = useState('');

  const openModal = (data) => {
    setModalData(data);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalData('');
  };

  const openPopup = (data,data2,id) => {
    CommentsApi.updateseen(id)
    .then((response) => {
      console.log(response);
      // Abre la ventana emergente después de actualizar isSeen
      const popupWindow = window.open('', '_blank', 'width=400,height=300');
      popupWindow.document.write(`
        <html>
          <head>
            <title>Popup Window</title>
          </head>
          <body>
            <h2>COMENTARIO ${data2}</h2>
            <p>${data}</p>
            <button onClick="window.close()">Close</button>
          </body>
        </html>
      `);
      popupWindow.document.close();
    })
    .catch((error) => {
      console.error('Error:', error);
      // Maneja el error aquí si es necesario
    });
  };


  
  const [cancelDialogData, setCancelDialogData] = useState({ name: '', reservationId: null });
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  const snackbarHandleClose = () => {
    setSnackbar({ open: false });
  };
  const div = document.getElementById('scrollingdiv');
  // const hasHorizontalScrollbar = div.scrollWidth > div.clientWidth;
  const btncontainer = document.getElementById('buttonsContainer');

  const scroll = (scrollOffset) => {
    div.scrollLeft += scrollOffset;
  };
  const [isMounted, setIsMounted] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  useEffect(() => {
    CommentsApi.listClientSurvey()
    .then((response) => {
      if (isMounted) {
        console.log('Response:', response);
        console.log('Data:', response.data);
        console.log('Data2:', response.data.reviewsid);
        setReviews({
          reviews: response.data.reviews,
          reviewsid: response.data.reviewsid,
          infoClient2: response.data.infoClient2,
          combinedData: response.data.combinedData,
        });
        console.log('herde');
        console.log(setReviews);
        setAverageRating(response.data.averageRating);
        setLoading(false);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      setLoading(false); // Actualiza el estado de carga en caso de error
    });

  return () => {
    isMounted = false;
  };
   
  }, []);

  const closeCancelDialog = () => {
    setCancelDialog(false);
  };
  const closeImageDialog = () => {
    setImageDialog(false);
  };
  const closeHistoryDialog = () => {
    setHistoryDialog(false);
    window.location.reload(); // Actualizar la página
  };
  const closeEditClientDialog = () => {
    setEditClientDialog(false);
  };

  const openCancelDialog = (reservationId, name) => {
    setCancelDialogData({ name, reservationId });
    setCancelDialog(true);
  };
  const openImageDialog = (image) => {
    setImageDialogData(image);
    setImageDialog(true);
  };
  const openHistoryDialog = (created_by, status_modified_by, modified_by, deleted_by, id) => {
    // Llamada a la función API para actualizar el parámetro
    CommentsApi.updateseen(id)
      .then((response) => {
        console.log(response);
        setHistoryDialogData({ created_by, status_modified_by, modified_by, deleted_by });
        setHistoryDialog(true);
  
      })
      .catch((error) => {
        console.error('Error:', error);
        // Maneja el error aquí si es necesario
      });
  };
  
  const handleCloseModal = () => {
    // Cerrar el modal
    setHistoryDialog(false);
    // Actualizar la página
    window.location.reload();
  };
  
  // ...
  
  // Dentro del componente que renderiza el modal
  <Modal onClose={handleCloseModal}>
    // Contenido del modal
  </Modal>
  const openEditClientDialog = (name, clientId) => {
    setEditClientDialogData({ name, clientId });
    setEditClientDialog(true);
  };
  const renderNightRow = () => (
    <TableRow>
      <TableCell colSpan={13} align="left" className="limiterRow">
        NOCHE
        {' '}
        <span className="guion">-</span>
        {' '}
        <span className="fontAndaleMono">
          {title}
        </span>
      </TableCell>
    </TableRow>
   );
  const renderTable2 = () => (
    <div className="table-wrapper">
      <div className="clients-table">
          <DialogImage
          isOpen={imageDialog}
          onNo={closeImageDialog}
          data={imageDialogData}
        />
        <DialogHistory
          isOpen={historyDialog}
          onNo={closeHistoryDialog}
          data={historyDialogData}
        />
        <DialogEditClient
          isOpen={EditClientDialog}
          onNo={closeEditClientDialog}
          data={editClientDialogData}
        />
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
              <TableRow>
                <TableCell colSpan={13} align="left" className="limiterRow">
                
                  {' '}
                  <span className="guion"></span>
                  {' '}
                  <span className="fontAndaleMono">
                    {title}
                  </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={13} align="left" style={{ fontWeight: 'bold', fontFamily: 'CommutersSans, sans-serif' }}>
                  <span className="guion"></span>
                  {' '}
                  <span >
                    Promedio de las calificaciones de usuarios : {renderStars( averageRating, '27px')}
                  </span>
                </TableCell>
              </TableRow>
                <TableRow>
                  <TableCell>FOTO</TableCell>
                  <TableCell>CONTACTO</TableCell>
                  <TableCell>NOMBRE</TableCell>
                  <TableCell>DÍA DE LA RESERVA</TableCell>
                  <TableCell>HORA DE LA RESERVA</TableCell>
                  <TableCell>CLASIFICACIÓN</TableCell>
                  <TableCell>COMENTARIO</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {reviews.reviews.slice().reverse().map((row, index) => {
                  const reversedIndex = reviews.reviews.length - 1 - index;
                  return (
                      <TableRow key={row.id}>
                          <TableCell align="left">
                              <button type="button" onClick={() => openImageDialog((reviews.combinedData[reversedIndex].photo === null || reviews.combinedData[reversedIndex].photo === '') ? DefaulImage : reviews.combinedData[reversedIndex].photo)} tabIndex={-1} key={uuid()} className="photocontainer" width="100px">
                                  {(reviews.combinedData[reversedIndex].photo === null || reviews.combinedData[reversedIndex].photo === '') ? (
                                      <>
                                          <img src={DefaulImage} alt="" width="100%" />
                                         
                                      </>
                                  ) : (
                                      <>
                                         <img src={reviews.combinedData[reversedIndex].photo ? reviews.combinedData[reversedIndex].photo : DefaulImage} alt="" width="100%" />
                                      </>
                                  )}
                              </button>
                          </TableCell>
                          <TableCell>
                            {reviews.combinedData.length > 0 ? (
                              <> 
                                {reviews.combinedData[reversedIndex]?.cellPhone || reviews.combinedData[reversedIndex]?.cellPhoneData || 'SIN DATO'}{' '}
                                {reviews.combinedData[reversedIndex]?.email}
                              </>
                            ) : reviews.reviewsid.length > 0 ? (
                              'SIN DATO'
                            ) : (
                              'SIN DATO'
                            )}
                          </TableCell>
                          <TableCell>
                            {reviews.combinedData.length > 0 ? (
                              ((reviews.combinedData[reversedIndex]?.firstName || 'SIN DATO').toUpperCase() + ' ')
                            ) : reviews.reviewsid.length > 0 ? (
                              'SIN DATO'
                            ) : (
                              'SIN DATO'
                            )}
                            
                            {reviews.combinedData[reversedIndex]?.last_name ? (
                              (reviews.combinedData[reversedIndex]?.last_name || '').toUpperCase()
                            ) : (
                              (reviews.combinedData[reversedIndex]?.middleName || '').toUpperCase()
                            )}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {new Date(row.createdAt).toLocaleDateString()}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {new Date(row.createdAt).toLocaleTimeString()}
                          </TableCell>
                          <TableCell style={{ padding: '0px' }}>{renderStars(row.rating, '26px')}</TableCell>
                          <TableCell align="left">
                              {(row.comment === null || row.comment === '') ? (
                                  <>
                                      <Button
                                          variant="contained"
                                          color="default"
                                          type="submit"
                                          className="btnBorrar"
                                          style={{ borderRadius: 50, backgroundColor: "#888888", color: "white", width: 150, height: 37 }}
                                      >
                                          NO HAY
                                      </Button>
                                  </>
                              ) : (
                                  <>
                                      <Button
                                          onClick={() => openHistoryDialog(row.comment, reviews.combinedData[reversedIndex]?.firstName ? reviews.combinedData[reversedIndex]?.firstName.toUpperCase() : "SIN NOMBRE", reviews.combinedData[reversedIndex]?.firstName, reviews.combinedData[reversedIndex]?.firstName, row.id)}                                          variant="contained"
                                          color={row.isSeen === 0 ? "white" : "primary"}
                                          type="submit"
                                          className="btnBorrar"
                                          style={{
                                              borderRadius: 50,
                                              backgroundColor: row.isSeen === 0 ? "white" : undefined,
                                              border: row.isSeen === 0 ? "1px solid black" : undefined,
                                              width: 150,
                                              height: 37
                                          }}
                                      >
                                          {row.isSeen === 0 ? "Leído" : "CLIC PARA LEER"}
                                      </Button>
                                  </>
                              )}
                          </TableCell>
                      </TableRow>
                  );
              })}
          </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );

  return (
    <>
      {!loading && renderTable2()}
    </>
  );
    
};

// EnhancedTable.propTypes = {
//   tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   newPagination: PropTypes.shape({
//     currentPage: PropTypes.number,
//     perPage: PropTypes.number,
//     total: PropTypes.number,
//     from: PropTypes.number,
//     to: PropTypes.number,
//     options: PropTypes.arrayOf(PropTypes.number),
//     date: PropTypes.string,
//     zone: PropTypes.string,
//     sortCategory: PropTypes.string,
//     sortDirection: PropTypes.string,
//   }).isRequired,
//   setApiCallConfig: PropTypes.func.isRequired,
// };

export default EnhancedTable;
