// eslint-disable-next-line import/no-named-as-default
import { navigate } from '@reach/router';
import axios from 'axios';

import TokenManager from './TokenManager';

import objectKeysToCamelCase from '../../utils/objectKeysToCamelCase';

const client = async (endpoint, { body, ...customConfig } = {}) => {
  const token = new TokenManager('access').getToken();
  const headers = { 'content-type': 'application/json' };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (endpoint === 'login') {
    headers.tokenFirebase = localStorage.getItem('firebase_token');
  }
  const method = customConfig.method ?? body ? 'POST' : 'GET';
  const config = {
    method,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  axios.interceptors.response.use((response) => response,
    (error) => {
      console.log(error.toJSON().message);
      // Si en respuesta 401 y el endpoint no es login
      // if (error.response.status === 401 && endpoint !== 'login') {
      //   new TokenManager('access').removeToken();
      //   new TokenManager('refresh').removeToken();
      //   window.localStorage.removeItem(process.env.REACT_APP_EXPIRES_IN_KEY);
      //   window.localStorage.setItem('__puerta21_expired_token', true);
      //   window.location.reload();
      // }
      if (error.toJSON().message === 'Network Error') {
        return {
          data: {
            data: {
              error: 'Verifica tu conexión a internet',
            },
            success: 0,
          },
        };
      }
      if (error.response.status === 400) {
        return error.response;
      }
      if (error.response.status === 401) {
        // Si el token no es válido se ejecuta un logout,
        // excepto en el servicio de 'login'
        if (error.response.data.message === 'No autorizado' && endpoint !== 'login') {
          alert(error.response.data.message);
          new TokenManager('access').removeToken();
          new TokenManager('token_type').removeToken();
          new TokenManager('role').removeToken();
          new TokenManager('maintain_session').removeToken();
          new TokenManager('expired_token').removeToken();
          localStorage.clear();
          navigate('/');
          window.location.reload();
        }
        return error.response;
      }
      if (error.response.status === 404) {
        return error.response;
      }
      if (error.response.status === 500) {
        return Promise.reject(error);
      }
      return Promise.reject(error);
    });

  console.log(process.env.REACT_APP_API_URL);
  let apiResponse;
  switch (config.method) {
    case 'POST':
      apiResponse = axios.post(`${process.env.REACT_APP_API_URL}/${endpoint}`, config.body, config).then((res) => {
        console.log(res.data);
        if (res.data.success === 0
          && (res.data.message.includes('El horario no esta dentro de las horas laborales, o es muy cercano a la hora de cierre')
            || res.data.message.includes('El horario que seleccionó, es muy cercano a la hora de cierre del restaurante')
          )) {
          return { success: 0, data: { error: res.data.message }, message: res.data.message };
        }
        if (res.data.success === 0
          && endpoint !== 'restaurant/reservations'
          && endpoint !== 'login'
          && res.data.data.error
        ) {
          return { success: 0, message: res.data.data.error };
        }
        if (res.data.success === 0
          && endpoint !== 'restaurant/reservations'
          && endpoint !== 'login'
          && res.data.data.errors
        ) {
          return { success: 0, message: res.data.data.errors };
        }
        return res.data;
      });

      break;
    case 'GET':
      apiResponse = axios.get(`${process.env.REACT_APP_API_URL}/${endpoint}`, config).then((res) => {
        console.log(res.data);
        if (res.data.success === 0 && !res.data.message) {
          return { success: 0, message: res.data.data.error };
        }
        // Se ocupa para obtener si existe o no mail y no es del staff al crear reservaciónes
        if (res.data.success === 0 && res.data.message.includes('Elemento no encontrado')) {
          return { success: 0, message: res.data.message, isStaff: false };
        }
        // Se ocupa para obtener si existe o no mail y no es del staff al crear reservaciónes
        if (res.data.success === 0 && res.data.message.includes('usuarios del sistema no se le')) {
          return { success: 0, message: res.data.message, isStaff: true };
        }
        return res.data;
      });

      break;
    case 'DELETE':
      apiResponse = axios.delete(`${process.env.REACT_APP_API_URL}/${endpoint}`, config).then((res) => {
        console.log(res.data);
        if (res.data.success === 0) {
          return { success: 0, message: res.data.data.error, data: res.data };
        }
        return res.data;
      });

      break;
    case 'PUT':
      apiResponse = axios.put(`${process.env.REACT_APP_API_URL}/${endpoint}`, config.body, config).then((res) => {
        console.log(res.data);
        if (res.data.success === 0 && typeof res.data.data === 'string') {
          return { success: 0, message: res.data.data };
        }
        if (res.data.success === 0 && typeof res.data.message === 'string' && !res.data.data) {
          return { success: 0, message: res.data.message };
        }
        if (res.data.success === 0 && res.data.data.error) {
          return { success: 0, message: res.data.data.error };
        }
        return res.data;
      });

      break;

    default:
      apiResponse = axios.post(`${process.env.REACT_APP_API_URL}/${endpoint}`, config.body, config).then((res) => {
        console.log(res.data);
        if (res.data.success === 0) {
          return { success: 0, message: res.data.data.error };
        }
        return res.data;
      });
      break;
  }

  const jsonApiResponse = await apiResponse;

  return objectKeysToCamelCase(jsonApiResponse);
};

export default client;
