import React from 'react';
import { navigate } from '@reach/router';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import './ForgotPassword.scss';

import UnauthenticatedSidebar from '../../components/UnauthenticatedSidebar';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = () => {
  const [email] = React.useState('email@email.com');
  // eslint-disable-next-line no-console
  console.log(email);
  return (
    <>
      <Box component="div" className="forgot-password layout">
        <Container fixed height="100">
          <Grid container spacing={2}>
            <UnauthenticatedSidebar />
            <Hidden smDown>
              <Grid item xs={12} md={2} />
            </Hidden>

            <Grid item xs={12} md={4} className="dFlex flex-column align-center justify-center">
              <Box component="div">
                <div className="logotitulo">
                  Puerta 2I
                </div>
                <div className="logosubtitulo">
                  Luxury Lifestyle Club
                </div>
              </Box>
              <Box
                component="div"
                className="mt-4 text-center"
              >
                <Typography variant="h5" gutterBottom>
                  Recupera tu contraseña
                  {' '}

                </Typography>
              </Box>
              <Box
                component="div"
                className="w100"
              >
                <ForgotPasswordForm />
              </Box>
              <Box
                component="div"
                className="mt-2"
              >
                <Button onClick={() => { navigate('/'); }} variant="contained" color="secondary">
                  Volver al inicio de sesión
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Hidden smDown>
        <div className="confidencial-tag">
          <div className="text font__roboto">
            RESTAURANTES | CONFIDENCIAL
            {' '}
            <span className="line">_________________________________________</span>
          </div>
        </div>
      </Hidden>
    </>
  );
};

export default ForgotPassword;
