/* eslint-disable */

import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import {
  Button,
  Box,
  Grid,
  Hidden,
  Typography,
  ImageList,
  ImageListItem,
  Backdrop,
  CircularProgress,
  makeStyles,
} from '@material-ui/core/';
import PropTypes from 'prop-types';
import isTruthy from '../../utils/isTruthy';
import RestaurantContext from '../../layouts/Restaurant/RestaurantContext/RestaurantContext';
import * as RestaurantsApi from '../../api/restaurants/restaurants';
import './RestaurantCover.scss';
import SnackbarAlert from '../SnackbarAlert';
import AddIcon from '@material-ui/icons/Add';

const RestaurantCover = ({ setReload }) => {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));
  const inputFile = useRef(null);
  const classes = useStyles();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const { coverData } = useContext(RestaurantContext);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const uploadOneImage = async (event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    if (event.target.files[0].size > 10000000) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: 'El archivo no debe exceder 10Mb',
      });
    }
    if (
      (event.target.files[0].type === 'image/jpg'
        || event.target.files[0].type === 'image/jpeg'
        || event.target.files[0].type === 'image/png')
      && event.target.files[0].size <= 10000000
    ) {
      const formData = new FormData();
      formData.append('image', file, 'image');
      console.log(formData.values());
      setOpenBackdrop(true);
      try {
        const { success, message } = await RestaurantsApi
          .uploadCover(formData);
        if (isTruthy(success) && success === 1) {
          setIsLoading(false);
          setSnackbar({
            open: true,
            severity: 'success',
            text: message,
          });
          setTimeout(() => {
            handleCloseBackdrop();
            setReload(true);
          }, 1000);
        } else {
          setIsLoading(false);
          setSnackbar({
            open: true,
            severity: 'error',
            text: message,
          });
          handleCloseBackdrop();
        }
      } catch (error) {
        setIsLoading(false);
        setSnackbar({
          open: true,
          severity: 'error',
          text: error,
        });
        handleCloseBackdrop();
      }
    }
  };

  const onAddImageClick = () => {

    inputFile.current.click();
  };
  const PlaceholderImageThumbnail = () => (
    <div className="placeholder-thumbnail dFlex flex-column justify-center align-center pointer cajagris" onClick={() => onAddImageClick()}>
      <AddIcon />
      <small className="pt-1">Cargar fotografía</small>
    </div>
  );
  const renderLoading = () => (
    <div>Loading...</div>
  );

  const renderView = () => (
    <>
      {coverData !== null && coverData !== '' && (
        <div className="restaurantCover__files-container">

          <img src={coverData} alt="cover" width="100%" height="auto" />
        </div>
      )}
      {(coverData === null || coverData === '' || coverData === undefined) && PlaceholderImageThumbnail()}
      <div className="warning">
        El tamaño recomendado para la imagen de portada es de 1400x500 pixeles.
      </div>
      {coverData !== null && coverData !== '' && (
        <div className="btnHolder">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => onAddImageClick()}
          >
            Cargar nueva fotografía
          </Button>
        </div>)
      }


      <input type="file" accept=".jpg, .jpeg, .png" reject=".svg" onChange={uploadOneImage} id="file" ref={inputFile} style={{ display: 'none' }} />
    </>
  );

  return (
    <>
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <Box
        component="div"
        className="dFlex flex-column w100 mb-3"
      >
        <Grid container>
          <Grid item xs={12} md={4} className="restaurantCover-left-grid">
            <div className="pr-3 ml-1">
              <Typography variant="h6">
                AGREGA LA FOTO DE PORTADA DEL RESTAURANTE
              </Typography>
              <div>
                <Typography variant="body1" className="justified">
                  <br />
                  Por favor, añada la foto de perfil del restaurante. Para ello,
                  puedes o darle clic a  &quot;+
                  Agregar Fotografía&quot;
                  |o arrastrar la foto desde su ordenador hasta la cuadricula.
                  {/* <br />
                  <small>
                    Acepta archivos tipo .jpg, .jpeg o .png. Cada archivo debe ser menor a 1MB.
                  </small> */}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={8} className="restaurantCover-right-grid">
            {!isLoading && renderView()}
            {isLoading && renderLoading()}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

RestaurantCover.propTypes = {
  setReload: PropTypes.func,
};

RestaurantCover.defaultProps = {
  setReload: () => { },
};

export default RestaurantCover;
