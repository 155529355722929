import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import SplashImage from '../../images/unaunthenticated-image.png';

const UnauthenticatedSidebar = () => (
  <Hidden smDown>
    <Grid item xs={12} md={6}>
      <img className="img-fluid" src={SplashImage} alt="Splash" />
    </Grid>
  </Hidden>
);

export default UnauthenticatedSidebar;
