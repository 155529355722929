import { useState, useRef, useEffect } from 'react';

const useGooglePlaces = (query) => {
  const [suggestions, setSuggestions] = useState({
    status: '',
    data: [],
  });

  const placesRef = useRef(null);

  const init = () => {
    const placesLibrary = window.google?.maps?.places;
    if (!placesLibrary) return;
    placesRef.current = new placesLibrary.AutocompleteService();
  };

  const clearSuggestions = () => {
    setSuggestions({ status: '', data: [] });
  };

  useEffect(() => {
    if (!placesRef.current) {
      init();
    }

    const getPredictions = (input) => {
      if (!input) {
        clearSuggestions();
        return;
      }

      placesRef.current.getPlacePredictions({
        input,
        language: 'es-419',
        componentRestrictions: { /* country: 'mx' */ },
      }, (predictions, status) => {
        setSuggestions({ status, data: predictions || [] });
      });
    };

    getPredictions(query);
  }, [query]); //eslint-disable-line

  return { suggestions: suggestions.data, status: suggestions.status, clearSuggestions };
};

export default useGooglePlaces;
