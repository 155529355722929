/* eslint-disable import/no-named-as-default-member */
// lodash is imported from create-react-app, but we don't have it on our project dependecies.
// eslint-disable-next-line import/no-extraneous-dependencies
import { has } from 'lodash';

// eslint-disable-next-line import/no-named-as-default

import { navigate } from '@reach/router';
import client from './utils/client';
// eslint-disable-next-line import/no-named-as-default
import TokenManager from './utils/TokenManager';

// import * as UsersApi from './users';

const login = ({ email, password, checkbox }) => client('login', { body: { email, password, token_firebase: localStorage.getItem('firebase_token') } }).then((response) => {
  const responseHasRequiredData = (loginResponse) => has(loginResponse, 'data.token');
  console.log(has(response, 'data.token'));
  if (responseHasRequiredData(response)) {
    const {
      data: {
        token, tokenType, role, restaurantEid, restaurantName,
        extras,
      },
    } = response;
    new TokenManager('access').setToken(token);
    new TokenManager('email').setToken(email);
    new TokenManager('token_type').setToken(tokenType);
    new TokenManager('restaurant_eid').setToken(restaurantEid);
    new TokenManager('restaurant_name').setToken(restaurantName);
    new TokenManager('role').setToken(role);
    new TokenManager('maintain_session').setToken(checkbox);
    sessionStorage.setItem('hasSameSession', true);
    sessionStorage.setItem('extras', extras);
    localStorage.setItem('extras', JSON.stringify(extras));
    new TokenManager('expired_token').removeToken();
  }
  return response;
});

const forgotPassword = ({ email }) => client('forgot-password', { body: { email } }).then((response) => response);

const resetPassword = ({
  password,
  passwordConfirmation,
  token,
}) => client('reset-password', {
  body: {
    reset_token: token,
    password,
    password_confirmation: passwordConfirmation,
  },
}).then((response) => response);

const logout = () => client('logout', { body: {} })
  .then((response) => response)
  .finally(() => {
    new TokenManager('access').removeToken();
    new TokenManager('token_type').removeToken();
    new TokenManager('role').removeToken();
    new TokenManager('maintain_session').removeToken();
    new TokenManager('expired_token').removeToken();
    localStorage.clear();
    navigate('/');
    window.location.reload();
  });

const getAccessToken = () => {
  const token = new TokenManager('access').getToken();
  if (!token) {
    return false;
  }
  return true;
};

const getCurrentToken = () => {
  const token = new TokenManager('access').getToken();
  const maintainSession = new TokenManager('maintain_session').getToken();
  const hasSameSession = sessionStorage.getItem('hasSameSession');
  return ({ token, maintainSession, hasSameSession });
};

export {
  login, logout, getCurrentToken, forgotPassword, resetPassword, getAccessToken,
};
