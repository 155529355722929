import React, { useCallback, useEffect, useState } from 'react';

import * as AuthApi from '../../api/auth';

import dictionary from '../../utils/dictionary';

const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const {
    login,
    logout,
    getCurrentToken,
  } = AuthApi;
  const [data, setData] = useState({
    error: null,
    status: 'pending',
    token: null,
    maintainSession: null,
    hasSameSession: null,
  });

  const setSuccessfulLoginData = useCallback(async () => {
    const { token, maintainSession, hasSameSession } = await getCurrentToken();
    if (token) {
      setData({
        error: null,
        status: 'success',
        token,
        maintainSession,
        hasSameSession,
      });
    } else {
      setData({
        error: 'no token',
        status: 'error',
        token: null,
        maintainSession: null,
        hasSameSession: null,
      });
    }
  }, [getCurrentToken]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        await setSuccessfulLoginData();
      } catch (error) {
        await logout();
      }
    };

    fetchCurrentUser();
  }, [setSuccessfulLoginData, logout]);

  const isValidCMSUser = (catTypeUserId) => {
    if (
      dictionary.userType[2] === catTypeUserId
      || dictionary.userType[3] === catTypeUserId
    ) {
      return true;
    }
    return false;
  };

  const authLogin = async ({ email, password, checkbox }) => {
    const tok = 'test';
    const response = await login(
      {
        test: tok, email, password, checkbox,
      },
    );
    console.log(response);
    console.log('se mando');
    if (response.success) {
      if (isValidCMSUser(response.data.role)) {
        try {
          await setSuccessfulLoginData();
        } catch (error) {
          await logout();
        }
      } else {
        console.log(response);
        localStorage.clear();
        return {
          data: null,
          success: 0,
          message: !isValidCMSUser(response.data.role) ? 'El usuario no cuenta con acceso a este recurso.' : response.message,
        };
      }
    }

    return response;
  };

  const authLogout = async () => {
    await logout();
  };

  if (data.status === 'pending') {
    // TODO: implement FullPageLoader
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider
      value={{
        data,
        login: authLogin,
        logout: authLogout,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
