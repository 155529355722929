/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
<link href="https://fonts.googleapis.com/css2?family=Commuters+Sans&display=swap" rel="stylesheet"></link>
import { navigate } from '@reach/router';
import {
  Box,
  Container,
  Grid,
  Button,
  Hidden,
  // IconButton,
  Typography,
} from '@material-ui/core/';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TheMenu from '../../components/TheMenu';
import './Estadisticas.scss';
import { format, isPast, set } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Chart from 'chart.js/auto';
import * as ReservationsApi from '../../api/restaurants/reservations';

import 'chartjs-plugin-datalabels';
//pie chart

const Estadisticas = () => {
  const [diasbloqueados, setDiasbloqueados] = useState([]);

  const chartRef = useRef(null);
  let chartInstance; // Variable to hold the chart instance

  const [chartData, setChartData] = useState([0,0,0,0]);

  // Define myChart outside of useEffect

  const alwaysShowTooltip = {
    id: 'alwaysShowTooltip',
    afterDraw(chart, args, options) {
      const { ctx } = chart;
      ctx.save();
      console.log(chart);
      chart.data.datasets.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
          const { x, y } = datapoint.tooltipPosition();
          //console.log(datapoint.tooltipPosition());
          //console.log(chart.data.label  s);
          const text = chart.data.datasets[i].data[index];
         const width = 20;
          const textWidth = ctx.measureText(text).width;
          console.log(textWidth);
          ctx.fillStyle = 'rgba(0,0,0,0.8)';
          ctx.fillRect(x-((textWidth +10) /2),y-25,textWidth +10 ,30);
          ctx.restore();
  
          ctx.beginPath();
          ctx.moveTo(x,y);
          ctx.lineTo(x - 5, y-5);
          ctx.lineTo(x-5,y-5);
          ctx.fill();
          ctx.restore();
          
         ctx.font = '15px Arial';
         ctx.fillStyle = 'white';
         ctx.fillText(text,x-(textWidth /2) ,y-10);
         ctx.restore();
  
  
        
        });
      });
      ctx.restore();
    },
  };
  const updatechart = (newData) => {
    let canvasholder = document.getElementById('canvasholder');
    let thecanvas = document.getElementById('thecanvas');
    //empty the canvas
    canvasholder.removeChild(thecanvas); 
    //create new canvas
    let canvas = document.createElement('canvas');
    canvas.id = 'thecanvas';
    canvasholder.appendChild(canvas);

    //create new chart
    const ctx = canvas.getContext('2d');
    chartInstance = new Chart(ctx, {
     type: 'pie',
     data: {
       labels: [   
         'RECHAZADA POR EL RESTAURANTE',
         'CANCELADA POR CLIENTE',
         'ACEPTADAS',
         'PENDIENTE, No contestadas por el restaurante.',],
       datasets: [{
   
         data: newData,
         backgroundColor: [ '#af1d2a', '#FFBF05' , '#094d34', '#1e3853'],

         borderWidth:  2
       }]
     },
     options: {
      
      plugins: {
        legend: {
          position: window.innerWidth > 496
           ? 'right' : 'bottom', // 
          maxWidth: 320, // Set the maximum width of the legend
          itemWrap: true ,
          display: true,
    
        },
        tooltip: {
          enabled: false,
        },
        datalabels: {
          datalabels: {
            display: 'auto',
            color: '#fff',
            font: {
              weight: 'bold'
            },
            formatter: (value, context) => {
              return context.chart.data.labels[context.dataIndex];
            }
          }
        },
    },
   
     } ,  plugins: [alwaysShowTooltip]
    });
   

  };
//make useeffect to call the api with todays date

  useEffect(() => {
    //start date mudst be 02-01-2021
    const start='2021-01-02';

    const end = format(new Date(), 'yyyy-MM-dd');
    fetchnumberreservations(start, end);
  }, []);

  
  const [state, setState] = useState({
    loading: true,
    error: null,
    success: false,
  });
  const [step, setStep] = useState(2);
  const [stepOneValues, setStepOneValues] = useState({});
  const [avaliabilityData, setAvaliabilityData] = useState({});
  const [isCalendarStartDateOpen, setIsCalendarStartDateOpen] = useState(false);

  const [isCalendarEndDateOpen, setIsCalendarEndDateOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [numberPeople, setNumberPeople] = useState();
  const [reservations, setReservations] = useState(0);


  const [fields, setFields] = useState({

    startDate: format(new Date(), 'yyyy-MM-dd'),
  
    endDate: format(new Date(), 'yyyy-MM-dd'),
    //pendiente, aceptadas, canceladas, rechazadas
    pending: 0,
    accepted: 0,
    cancelled: 0,
    rejected: 0,


  });
  const [validations, setValidations] = useState({

    startDate: true,

    endDate: true,
    // categories: false,
    // comments: true,
    pending: true,
    accepted: true,
    cancelled: true,
    rejected: true,

  });

  const handleValidations = (field, value) => {
    const mapValidations = {
      startDate: (startDate) => true,
      endDate: (ddate) => true,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

  const renderLoading = () => (
    <div>Loading...</div>
  );
  const updateFields = (field, value) => {
    const newFields = {
      ...fields,
    };
    if (field === 'event' && value === true) {
      newFields.waitingList = false;
    }
    if (field === 'waitingList' && value === true) {
      newFields.event = false;
    }
    newFields[field] = value;
    setFields(newFields);
    handleValidations(field, value);

  };
  const userplataform = () => {
    navigate('/grapreservas');
  };
  const borrardates = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setFields({
      startDate: format(new Date(), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
    });
    fetchnumberreservations(format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd'));


  };
  const updatediasbloqueados = (dias) => {
    let diasbloqueados = [];
    var contenedora = document.getElementById('diasbloqueadosholder');
    contenedora.innerHTML = '';
    if(dias.length > 0){
      diasbloqueados = dias;
      diasbloqueados.forEach((dia) => {
        console.log(dia);
        let newdiv = document.createElement('div');
        //add class badgedate 
        newdiv.classList.add('badgedate');
        newdiv.innerHTML = dia.reservationDate;
        contenedora.appendChild(newdiv);
      });
    }else{
      let newdiv = document.createElement('div');
      newdiv.classList.add('font__commutter');
      newdiv.style.fontSize = '18px'; // Agrega la propiedad fontSize
      newdiv.innerHTML = 'No hay DÍAS bloqueados';
      contenedora.appendChild(newdiv);
      newdiv.style.fontWeight = 'bold';
    }
  };
  const handleDateStartDateChange = (date) => {
    setStartDate(date);
    const target = format(new Date(date), 'yyyy-MM-dd');
    const end = format(new Date(endDate), 'yyyy-MM-dd');
    const start = format(new Date(date), 'yyyy-MM-dd');

    // if (dateRegex.test(date)) {
    // }
    updateFields('startDate', target);
    //if the date is before the end date
    if(date < endDate){
      fetchnumberreservations( start, end);
    }else{
      alert('La fecha de inicio no puede ser mayor a la fecha de fin');
    }
    //validatedates(target, fields.startTime, fields.endDate, fields.endTime);
  };
  const handleDateEndDateChange = (date) => {
    setEndDate(date);
    // if (dateRegex.test(date)) {
    // }
    const target = format(new Date(date), 'yyyy-MM-dd');
    const start = format(new Date(startDate), 'yyyy-MM-dd');
    const end = format(new Date(date), 'yyyy-MM-dd');


    if(startDate < date){
      fetchnumberreservations( start, end);
    }else{
      alert('La fecha de fin no puede ser menor a la fecha de inicio');
    }

    updateFields('endDate', target);
    //validatedates(fields.startDate, fields.startTime, target, fields.endTime);
  };
  const fetchnumberreservations = async (start,end) => {
    try {
      const info = {
        start_date: start,
        end_date: end,
        currentPage: 1,
        perPage: 100,
      };
      let { success, data } = await ReservationsApi
        .getReservesGraph (info);
        console.log(data);
        console.log(data.restaurant);
        console.log(data.restaurant.reservations);
        console.log(data.restaurant.reservations.pending);
        //canceled_client
        console.log(data.restaurant.reservations.canceled_client);
        //completed
        console.log(data.restaurant.reservations.completed);
        //canceled
        console.log(data.restaurant.reservations.canceled);
        //set filds 
        setFields({
          startDate: start,
          endDate: end,
          pending: data.restaurant.reservations.pending,
          accepted: data.restaurant.reservations.completed,
          cancelled: data.restaurant.reservations.canceled_client,
          rejected: data.restaurant.reservations.canceled,
        });
        setReservations(data.restaurant.reservations.total );
        console.log(data.restaurant.cancelledCount);
        updatediasbloqueados(data.restaurant.cancelledCount);
//
        const newChartData = [data.restaurant.reservations.canceled, data.restaurant.reservations.canceledClient, data.restaurant.reservations.completed, data.restaurant.reservations.pending];
        updatechart(newChartData);
        console.log(newChartData);




      }catch (error) {
        console.log(error);
      
        setState({
          loading: false, error: error.message, reservationsVisible: true, reservationsBlocked: { reservations: [], cancelled: [] }, reservations: [], pardepanBlocked: [], capacity: [], paginator: {}
        });
      }
    }




  const renderEstadistica = () => (

    <Container className='' fixed height="100">
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      <Grid container height="100" spacing={2} className='align-center'>
      <Grid item xs={12} md={3}>
                    <KeyboardDatePicker
                      onClick={() => { setIsCalendarStartDateOpen(true); }}
                      onClose={() => setIsCalendarStartDateOpen(false)}
                      open={isCalendarStartDateOpen}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Fecha de inicio"
                      invalidLabel='Fecha invalida'
                      invalidDateMessage="No es un formato válido"
                      invalidformatMessage="No es un formato válido"
                      value={startDate}
                      onChange={handleDateStartDateChange}
                      //header disables
                      disableToolbar
                      autoOk
                      error={!validations.startDate}

                    >

                    </KeyboardDatePicker>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <KeyboardDatePicker
                      onClick={() => { setIsCalendarEndDateOpen(true); }}
                      onClose={() => setIsCalendarEndDateOpen(false)}
                      open={isCalendarEndDateOpen}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Fecha de finalización"
                      value={endDate}
                      onChange={handleDateEndDateChange}
                      //header disables
                      disableToolbar
                      autoOk
                      error={!validations.endDate}
                    >

                    </KeyboardDatePicker>
                    </Grid>
                    <Grid item xs={12} md={3}>
      <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="btnBorrar"
                    onClick={borrardates}
                  
                  >
                    Borrar
                  </Button>
        </Grid>
              
      </Grid>
      <Grid container height="100" spacing={2}  >
      <Grid item xs={12} md={7} id='canvasholder' style={{ marginTop: window.innerWidth > 496 ? '-110px' : '0' }}>
      <canvas id='thecanvas' ref={chartRef} />

    </Grid>                                               
    <Grid item xs={12} md={5} > 
    <div className="contenedora" style={{ fontWeight: "bold", textTransform: "uppercase", fontFamily:"CommutersSans, sans-serif !important", fontSize: "12px" }}>
    <h2 id='text'>
        <p class="font__commutter"  style = {{fontFamily: "CommutersSans, sans-serif !important", fontWeight: 'bold' }} >Número total de reservas: {reservations}</p>
      </h2>
      <div id="diasbloquadoscontainer"  style={{ fontWeight: "bold", textTransform: "uppercase", fontFamily: "CommutersSans, sans-serif !important", fontSize: "13px"  }} >
          <h2 class="font__commutter"  style = {{fontFamily: "CommutersSans, sans-serif !important" , fontWeight: 'bold' }}>DÍAS  bloqueados</h2>
       <div  id="diasbloqueadosholder" className='diasbloqueadosholder' style={{ fontWeight: "bold", textTransform: "uppercase", fontFamily: "CommutersSans, sans-serif !important",   }}>

       </div>
      </div>
    
    </div>


    </Grid>
        </Grid>

      </MuiPickersUtilsProvider>
    </Container>
  );
  return (
    <>
      <TheMenu setIsCalendarOpen={() => { }} />
      <Container
        className="reservations-create layout px-0"
      >
        <Box
          component="div"
          className="reservations-create__header pr-1 pl-1 dFlex flex-row justify-between align-center"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          
          <Grid container spacing={2}>
      <Grid item xs={12}>
      <Typography variant="h6" className="reservations-create__title font__commutter">
            ESTADÍSTICAS
          </Typography>
      </Grid>
      <Grid item xs={12}>
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
      <Grid item>
          <Button
            onClick={() => { userplataform(); }}
            variant="contained"
            className="custom-button"
            style={{
              backgroundColor: '#ffffff',
              borderColor: '#000000',
              color: 'black',
            }}
          >
            Gráfica USUARIOS EN LA PLATAFORMA
          </Button>
          </Grid>
      <Grid item>
            <Button
            
              variant="contained"
              color="primary"
            >
              Gráfica de reservas
            </Button>
            </Grid>
          
         
        </Grid>
      </Grid>
    </Grid>
        </Box>
     
        <Box
          component="div"
          className="dFlex flex-column align-center justify-between"
        >
         {renderEstadistica()}
        </Box>
      </Container>
    </>
  );
};

export default Estadisticas;
