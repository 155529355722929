/* eslint-disable */
import { React, useState } from 'react';
import PropTypes from 'prop-types';
import './DialogEditClient.scss';
import ReactModal from 'react-modal';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import emojiRegex from '../../utils/emojiRegex';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  Button,
  Box,
  TextField,
  Typography,
} from '@material-ui/core/';
import namesRegex from '../../utils/namesRegex';
import * as ClientsApi from '../../api/restaurants/clients';
import SnackbarAlert from '../SnackbarAlert';
import { Row } from 'antd';

ReactModal.setAppElement('#root');
const DialogEditClient = ({
  isOpen, onNo, data, setReload,
}) => {
  const [validations, setValidations] = useState({
    name: false,
    mname: false,
    lname: false,
    phone: false,
  });
  const [fields, setFields] = useState({
    name: '',
    mname: '',
    lname: '',
    phone: '',
  });
  const areFieldsValid = () => (
    Object.values(validations).every((item) => item)
  );
  const handleValidations = (field, value) => {
    const mapValidations = {
      name: (name) => name.length >= 3 && name.length < 30,
      mname: (mname) => true,
      lname: (lname) => lname.length >= 3 && lname.length < 30,
      phone: (phone) => phone.length >= 1 && phone.length < 30,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const cleanUpBeforeClose = () => {
    setSnackbar({
      open: false,
      severity: 'success',
      text: 'Placeholder',
    });
    setFields({
      name: '',
      mname: '',
      lname: '',
      phone: '',
    });
    setReload(true);
    onNo();
    window.location.reload(true);
  };
  const updateFields = (field, value) => {
    console.log(field, value);
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...fields,
    };
    newFields[field] = cleanString;
    setFields(newFields);
    handleValidations(field, cleanString);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = {
      name: fields.name,
      mname: fields.mname,
      lname: fields.lname,
      phone: fields.phone,
      id: data.clientId,
    };
    try {
      const { success, data, message } = await ClientsApi
        .updateNames(body);
      if (success === 1 && message === 'ok') {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
        setTimeout(() => {
          cleanUpBeforeClose();
        }, 1500);
      }
      console.log(data);
      if (success === 1 && data.errors) {
        setSnackbar({
          open: true,
          severity: 'error',
          text: data.errors,
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };
  return (
    <ReactModal
      isOpen={isOpen}
      className="dialog-base-modal"
      overlayClassName="dialog-base-modal__overlay"
      shouldCloseOnOverlayClick
      onRequestClose={onNo}
    >
      <div className="dialog-base-modal__content ">
        <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <form onSubmit={handleSubmit}>
            <div className="dialog-base-modal__content ">
              <Box>
                <div>
                  <span className="titleLabel">EDITAR DATOS DEL CLIENTE: {data.name}</span>
                </div>
                <span className='texto'>
                Sólo se modificaran los campos que edite, la información que no cambie se mantendrá.
                </span>
                <div className="w100 marginTop20">
                  <TextField
                    className="w100"
                    id="name"
                    label="Nombre del cliente"
                    value={fields.name}
                    onChange={(e) => updateFields('name', e.target.value)}
                  />
                </div>
                <div className="w100 marginTop20">
                  <TextField
                    className="w100"
                    id="lname"
                    label="Apellido del cliente"
                    value={fields.lname}
                    onChange={(e) => updateFields('lname', e.target.value)}
                  />
                </div>
                <div className="w100 marginTop20">
                  <TextField
                    className="w100"
                    id="phone"
                    label="Telefono del cliente"
                    value={fields.phone}
                    onChange={(e) => updateFields('phone', e.target.value)}
                  />
                </div>
              </Box>
            </div>
            <div className="flexend">
              <Button
                className="cancelBtn"
                variant="contained"
                color="secondary"
                type="button"
                onClick={onNo}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={handleSubmit}
                disabled={!validations.name && !validations.lname && !validations.mname && !validations.phone}
              >
                Guardar
              </Button>
            </div>
          </form>
        </MuiPickersUtilsProvider>
      </div>
    </ReactModal>
  );
};
DialogEditClient.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  setReload: PropTypes.func,

  // eslint-disable-next-line react/forbid-prop-types

};
DialogEditClient.defaultProps = {
  isOpen: false,
  onNo: '',
  data: '',
  setReload: () => { },
};

export default DialogEditClient;
