import React, { useState, useEffect } from 'react';
import { Button, Box, TextField } from '@material-ui/core';
// eslint-disable-next-line import/named
import * as AuthApi from '../../../api/auth';

import './ForgotPasswordForm.scss';

import emailRegex from '../../../utils/emailRegex';

const ForgotPasswordForm = () => {
  const [isFormTouched, setFormIsTouched] = useState(false);
  const [fields, setFields] = useState({
    email: '',
  });
  const [validations, setValidations] = useState({
    email: true,
  });

  const [status, setStatus] = useState({
    error: null,
    isLoading: false,
    isValid: false,
    message: null,
    success: null,
  });

  useEffect(() => {
    if (fields.email.length > 0) {
      setFormIsTouched(true);
    }
  }, [fields]);

  const areValidFields = validations.email && isFormTouched;

  const handleValidations = (field, value) => {
    const mapValidations = {
      email: (email) => emailRegex.test(email),
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

  const updateFields = (field, value) => {
    const newFields = {
      ...fields,
    };
    newFields[field] = value;
    setFields(newFields);
    handleValidations(field, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (areValidFields && !status.isLoading) {
      setStatus({
        ...status,
        isLoading: true,
        error: null,
      });

      try {
        const response = await AuthApi.forgotPassword(fields);
        console.log(response);
        if (response.success === 0 && response.data.resetToken) {
          setStatus({
            ...status,
            isLoading: false,
            error: false,
            message: response.message,
            success: true,
          });
        }
        if (response.success === 0 && response.data.error) {
          setStatus({
            ...status,
            isLoading: false,
            error: response.data.error,
            success: false,
          });
        }
        if (response.success === 0 && !response.data.resetToken && !response.data.error) {
          setStatus({
            ...status,
            isLoading: false,
            error: response.message,
            success: false,
          });
        }
        if (response.success === 1) {
          setStatus({
            ...status,
            isLoading: false,
            error: response.message,
            success: false,
          });
        }
      } catch (error) {
        console.log(error);
        setStatus({
          ...status,
          isLoading: false,
          error: JSON.stringify(error),
          success: false,

        });
      }
    }
  };

  if (status.success === true) {
    return (
      <main className="m5">
        {status.message}
      </main>
    );
  }

  return (
    <Box
      component="div"
      className="mt-0"
    >
      <form className="w100 dFlex flex-column" onSubmit={handleSubmit}>
        <TextField
          error={!isFormTouched ? false : !validations.email}
          id="email"
          label="Correo electrónico"
          onChange={(e) => updateFields('email', e.target.value)}
          value={fields.email}
        />
        {status.success === false && (
          <Box
            component="div"
            className="mt-2 dFlex flex-column align-center justify-center color-error"
          >
            {status.error}
          </Box>
        )}
        {status.success === true && (
          <Box
            component="div"
            className="mt-2 dFlex flex-column align-center justify-center"
          >
            {status.message}
          </Box>
        )}
        <Box
          component="div"
          className="mt-5 dFlex flex-column align-center justify-center"
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!areValidFields}
          >
            RECUPERAR
          </Button>
        </Box>
      </form>
    </Box>
  );
};
export default ForgotPasswordForm;
