/* eslint-disable */
import { React, useState } from 'react';
import PropTypes, { any } from 'prop-types';
import './DialogEditDrinks.scss';
import ReactModal from 'react-modal';
import {
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core/';
import * as ClientsApi from '../../api/restaurants/clients';
import SnackbarAlert from '../SnackbarAlert';
ReactModal.setAppElement('#root');
const DialogEditDrinks = ({
  isOpen, onNo, data, current, setReload, clientId,
}) => {

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  const createBooleansArray = () => {
    var booleans = [];
    for (let i = 0; i < data.length; i += 1) {
      var found = false;
      var j = 0;
      while (j < current.length && found === false) {
        if( current[j].name=== data[i].name){
          found=true;
        }
        j += 1;
      }
      booleans.push(found);

    }
    return booleans;
  };
  const cleanUpBeforeClose = () => {
    setSnackbar({
      open: false,
      severity: 'success',
      text: "Placeholder",
    });
    setReload(true);
    onNo();
  };
  const [checkedState, setCheckedState] = useState({
    booleans: createBooleansArray(),
  });

  const handleOnChange = (position) => {
    const aux = checkedState.booleans;
    aux[position] = !aux[position];
    setCheckedState({ booleans: aux });
  };

  const handleSubmit = async (event) => {
    const formData = new FormData(event.currentTarget);
    event.preventDefault();
    var options = [];
    for (let i=0; i < data.length ; i+=1) {
      var element={};
      element.key = data[i].name;
      element.value = data[i].encryptId;
      element.boolean =checkedState.booleans[i];
      options.push(element);
    }
    const body = {
      data: options,
      id: clientId,
    };
    try {
      console.log('se manda');
      console.log(options);
      const { success, message, data } = await ClientsApi
        .updateDrinks(body);
      console.log('llega');
      console.log(data);
      if (success == 1) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: "Exito",
        });
        setTimeout(() => {
          cleanUpBeforeClose();
        }, 1500);
      } else {

      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      className="dialog-base-modal"
      overlayClassName="dialog-base-modal__overlay"
      shouldCloseOnOverlayClick
      onRequestClose={onNo}
    >
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <form onSubmit={handleSubmit}>
        <div className="dialog-base-modal__content ">
          {data.map((element, index) => {
            if (element.name !== 'OTROS') {
              return (
                <div key={element.name} className="dFlex w100 spaceBetween">
                  <div className="titleLabel" onClick={() => handleOnChange(index)}>
                    {element.name}
                  </div>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox color="primary" name={element.name} value={element.encryptId} checked={checkedState.booleans[index]} onChange={() => handleOnChange(index)} />} />
                  </FormGroup>
                </div>
              );
            }
            return '';
          })}
          <div className="flexend">
            <Button
              className="cancelBtn"
              variant="contained"
              color="secondary"
              type="button"
              onClick={onNo}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              Guardar Cambios
            </Button>
          </div>
        </div>
      </form>
    </ReactModal>
  );
};

DialogEditDrinks.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  data: any,
  current: PropTypes.objectOf(PropTypes.any).isRequired,
  clientId: PropTypes.objectOf(PropTypes.any).isRequired,
  setReload: PropTypes.func,
};
DialogEditDrinks.defaultProps = {
  isOpen: false,
  onNo: '',
  data: [],
  setReload: () => { },

};

export default DialogEditDrinks;
