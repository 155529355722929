import moment from 'moment';
import 'moment/locale/es';

const prepareCommentsTable = async (data: any) => {
  const rawData = data;
  const preparedData = [];
  rawData.forEach((element) => {
    const tableData = {
      id: element.id,
      name: `${element.client.firstName} ${element.client.middleName} ${element.client.lastName}`,
      photo: element.client.photo,
      reservationDate: moment(element.reservationDate).locale('es').format('DD MMMM yyyy'),
      numberPeople: element.numberPeople,
      comment: element.comment !== null ? element.comment : 'Sin datos',
      reply: element.reply,
    };
    preparedData.push(tableData);
  });
  return preparedData;
};

export default prepareCommentsTable;
