/* eslint max-len: 0 */

import React from 'react';

import {
  Box,
  Container,
  Typography,
} from '@material-ui/core/';

import * as AuthApi from '../../api/auth';

import TheMenu from '../../components/TheMenu';

import './Privacy.scss';

const Privacy = () => (
  <>
    { AuthApi.getAccessToken() && (<TheMenu setIsCalendarOpen={() => { }} />)}
    <Container
      className="restaurant layout px-0"
    >
      <Box
        component="div"
        className="privacy__header pr-1 pl-1 dFlex flex-row align-center justify-between"
      >
        <Typography variant="h5" className="font__commutter">
          Aviso de privacidad
        </Typography>
      </Box>
      <Box
        component="div"
        className="privacy__text-header pr-1 pl-1 dFlex flex-row align-center justify-between"
      >
        <div className="dFlex flex-column">
          <Typography variant="body1" className="restaurant__title font__roboto pb-4">
            1. Aspectos Generales.
            <br />
            <br />
            La presente Aplicación pertenece a la razón social PUERTA 21, S.R.L. (“PUERTA 21”), sociedad constituida y organizada de acuerdo a las leyes de la República Dominicana.
            <br />
            <br />
            Los términos y condiciones que aquí se establecen, representan el acuerdo legal (el “Acuerdo” o los “Términos de Uso”) entre usted (el “Usuario”) y PUERTA 21, esta última como Intermediaria de los Servicios (según se describen más adelante). Al aceptar la invitación para descargar la aplicación de PUERTA 21 (“La Plataforma”) y registrarse como Usuario de los Servicios, usted declara y reconoce haber leído completamente y aceptar incondicionalmente, a todas las cláusulas que aquí se establecen. PUERTA 21 es una aplicación móvil diseñada con el fin de brindar la experiencia un club privado, a sus miembros, quienes tendrán la oportunidad de acceder a la Plataforma una vez registrados y/o mediante invitación, para que desde la Plataforma pueda el Usuario realizar, de manera preferencial, la reserva de mesas en cualquiera de los Restaurantes (conforme se define más adelante) que forman parte de la Plataforma, y a su vez, compartir sus intereses y opiniones con los demás usuarios registrados, actuando como una red social tanto online como offline. Al usar o acceder a los Servicios o al hacer clic en “acepto” o “apruebo” este Acuerdo, el Usuario admite que ha leído, comprende y acepta obligarse por lo dispuesto en este Acuerdo. PUERTA 21 se reserva el derecho de modificar estos Términos de uso en cualquier momento, en su totalidad o en parte. Por lo tanto, es responsabilidad del Usuario consultar periódicamente la última versión de los Términos de uso publicados y aceptar las actualizaciones que se produzcan en la plataforma. Se considera que los Usuarios aceptan la última versión, con cada nuevo uso de la Plataforma.
            <br />
            <br />
            2. Reservas en Restaurantes.
            <br />
            <br />
            PUERTA 21 pone a disposición del Usuario servicios de reservacións en restaurantes (los “Servicios de Reservación”) mediante la Plataforma, con el objetivo de asistir al Usuario en asegurar reservaciones en los restaurantes participantes de terceros (cada uno, en lo sucesivo un “Restaurante”). En respuesta a la solicitud hecha, a través de la Plataforma, por un Usuario para obtener una reservación en un Restaurante, PUERTA 21 se contacta con la base de datos computarizada del Restaurante para reservaciones. La disponibilidad de las reservaciones, el tiempo de espera estimado o el lugar en la fila en una lista de espera se determinan al momento de la solicitud del Usuario y según la información que el Restaurante le brinda a PUERTA 21. Una vez que el Usuario efectúa la reservación o la solicitud de lista de espera por medio de la Plataforma, PUERTA 21 proporcionará al Usuario la confirmación a través de la Plataforma, vía correo electrónico u otros mensajes electrónicos, según lo haya acordado el Usuario. Al usar los Servicios, el Usuario acepta recibir las confirmaciones de reservación, actualizaciones, modificaciones y/o cancelaciones por la Plataforma, correo electrónico u otros mensajes electrónicos.
            <br />
            <br />
            3. Registro, Contraseña y Responsabilidad.
            <br />
            <br />
            Navegación la Plataforma.
            <br />
            <br />
            (a) Previo a obtener acceso a la plataforma, y disfrutar de sus beneficios, el Usuario Invitado deberá completar un formulario creado para esos fines, ingresar los datos personales que le sean requeridos y la dirección/URL de sus redes sociales (Instagram, Facebook, LinkedIn) existentes, con la finalidad de verificarlo y aceptar o no la membresía o registro en la Plataforma.
            <br />
            <br />
            (b)Su cuenta de Usuario no debe incluir el nombre de otra persona con la intención de suplantar a esa persona, o ser ofensivo, vulgar u obsceno, o contrario a la moral y a las buenas costumbres.
            <br />
            <br />
            (c) Su nombre de Usuario y contraseña a son personales y, por tanto es el único responsable de mantener la confidencialidad de sus datos para el inicio de sesión en la Plataforma, del uso y acceso a los Servicios con su nombre de Usuario y o contraseña. Siempre tendrá habilitada la opción de recuperar su contraseña haciendo uso de su correo electrónico sin perjuicio que nos notifique inmediatamente al darse cuenta de la pérdida, robo o uso no autorizado de cualquier contraseña, de usuario o dirección de correo electrónico.
            <br />
            <br />
            (d)  El Usuario no queda autorizado a transferir o vender el acceso a su cuenta.
            <br />
            <br />
            (e)  PUERTA 21 no ser responsable de cualquier daño relacionado con la divulgación de su nombre de Usuario o contraseña, o el uso que cualquier persona de a su nombre de Usuario o contraseña.
            <br />
            <br />
            (f)  El Usuario no puede usar la cuenta de otro usuario sin contar con el permiso de este último.
            <br />
            <br />
            (g)  PUERTA 21 se reserva el derecho de requerir que el Usuario cambie su nombre de usuario y o contraseña si a su discreción entiende que su cuenta ya no es segura o si recibe una queja respecto de su nombre de usuario que viole los derechos de otra persona.
            <br />
            <br />
            (h) PUERTA 21 se reserva el derecho rechazar el registro, cancelar su cuenta o no permitir el acceso a los Servicios, a modo de referencia, m s no limitativo, en caso incumpla las condiciones de los Términos de Uso y de sus políticas o actúe en forma contraria a lo dispuesto en ellas, incurra en posibles infracciones de caracter legal y/o efectúe un uso inadecuado de la Plataforma.
            <br />
            <br />
            4. Información del Usuario.
            <br />
            <br />
            Para fines de brindar una mejor experiencia al Usuario, PUERTA 21 recopilará ciertas informaciones, correspondientes, pero no limitadas a:
            <br />
            <br />
            (a)  Datos personales (como su nombre completo y fecha de nacimiento);
            <br />
            <br />
            (b)  Información de contacto (como dirección de correo electrónico, número de teléfono, redes sociales);
            <br />
            <br />
            (c)  Información acerca de preferencias (Detalles de reservas de restaurantes actuales y anteriores; preferencias de comidas; restaurantes favoritos; solicitudes especiales de restaurantes; actividad gastronómica, incluida la frecuencia, tipo de restaurante y cancelaciones de reservas);
            <br />
            <br />
            (d)Información de la cuenta (como la configuración de la cuenta y las contraseñas);
            <br />
            <br />
            (e)  Datos de redes sociales (si elige vincular su cuenta de la Plataforma con una cuenta de redes sociales, podemos recopilar información personal como nombre, edad, sexo, fotografía y otra información personal relacionada con su cuenta de redes sociales);
            <br />
            <br />
            (f)  Sus contactos y fotos (en su teléfono móvil);
            <br />
            <br />
            (g)  Sus preferencias (por encuestas y respuestas de marketing);
            <br />
            <br />
            (h)  Comunicaciones que nos envía (como atención al cliente, mensajes o comunicaciones a nosotros o con restaurantes, a través de la plataforma);
            <br />
            <br />
            (i)  Reseñas y contenido de restaurantes (incluidas reseñas, fotos, un alias de su elección que puede usar al enviar reseñas y otra información que proporcione a través de la plataforma);
            <br />
            <br />
            (j)  Otra información que pueda proporcionar (incluida otra información que proporcione sobre usted o sobre otros, a través de la Plataforma o a la que nos sea proporcionada, a través de plataformas de terceros).
            <br />
            <br />
            Cuando el Uusario utiliza o visita la Plataforma, autoriza a PUERTA 21 a recolectar información de este directamente (por ejemplo, cuando busca o hace una reserva en línea). También queda autorizada a generar información sobre el Usuario (por ejemplo, información sobre su dispositivo mientras usa nuestra aplicación móvil).
            <br />
            <br />
            En algunos casos, también PUERTA 21 obtiene información personal de terceros (por ejemplo, restaurantes, socios comerciales, empresas de nuestro grupo u otros terceros).
            <br />
            <br />
            De igual forma, PUERTA 21 utiliza la información del Usuario para proporcionar los Servicios, adaptar su experiencia, mostrarle reservas realizadas a través de la Plataforma y empresas relacionadas, enviarle comunicaciones de marketing, ofrecerle publicidad y ofertas más relevantes que puedan ser de interés y para otros propósitos del Usuario.
            <br />
            <br />
            Así mismo, mediante el uso de la Plataforma, el Usuario autoriza a PUERTA 21 a compartir su información con restaurantes y afiliados de restaurantes; con empresas relacionadas; con proveedores de servicios (incluidos los procesadores de pagos); con otros socios comerciales (incluidos socios publicitarios); con servicios de redes sociales; con servicios o aplicaciones de terceros que utiliza para iniciar sesión en su cuenta en la Plataforma. Por igual se autoriza a utilizar la información para proporcionar y mejorar los Servicios, para fines de marketing, y facilitar ofertas y publicidad de otros servicios que puedan interesarle.
            <br />
            <br />
            PUERTA 21 mantendrá medidas de seguridad técnicas, administrativas y físicas diseñadas para proteger la información del Usuario. De igual manera, quedará autorizada a retener información del Usuario mientras su cuenta esté activa y por un período de tiempo a partir de entonces para permitirle reactivar su cuenta sin pérdida de información, y según sea necesario.
            <br />
            <br />
            El Usuario reconoce que la Plataforma utiliza cookies y tecnologías similares para ayudar a PUERTA 21 a proporcionar los Servicios, y ofrecerle una experiencia al Usuario más personalizada y comercializar los productos y Servicios y los de terceros.
            <br />
            <br />
            5. Propiedad Intelectual
            <br />
            <br />
            Todos los derechos de propiedad intelectual referidos al contenido y dise o de los Servicios son de propiedad de PUERTA 21 o de terceros (y en aquellos casos en que sea de propiedad de terceros PUERTA 21 cuenta con las licencias necesarias para utilizarlos con relación a los Servicios).
            <br />
            <br />
            El Usuario no puede utilizar o reproducir ni permitir que nadie use o reproduzca cualquier marca registrada, nombre o logotipo que figure en los Servicios sin la autorización previa y por escrito de PUERTA 21.
            <br />
            <br />
            El software que opera la Plataforma y todas las aplicaciones digitales que se adhieren a este, no podrán ser usados o destinados por los Usuarios para usos distintos a los usos permitidos bajo estos Términos de Uso. El Usuario no podrá copiar, realizar ingeniería inversa, modificar o realizar cualquier transacción con el software de nuestros Servicios.
            <br />
            <br />
            6. Uso del Contenido.
            <br />
            <br />
            Los Servicios que se ofrecen en esta Plataforma se encuentran disponibles solo para aquellas personas que puedan celebrar contratos legalmente vinculantes de acuerdo a lo establecido por la ley aplicable del país desde donde se usa la Plataforma.
            <br />
            <br />
            Al acceder a la Plataforma, declara ser mayor de 18 a os de edad y ue se encuentra facultado a asumir obligaciones vinculantes con respecto a cualquier tipo de responsabilidad que se produzca por el uso que efectúe de esta Plataforma y los Servicios.
            <br />
            <br />
            El contenido que posee esta Plataforma se ha preparado con la intención de brindar información sobre los Restaurantes y el servicio de reservas en los Restaurantes (en adelante, las “Reservas”), así como también, como una red social privada entre los Usuarios registrados.
            <br />
            <br />
            Ciertas informaciones contenidas en la Plataforma han sido propocionadas directamente por los Restaurantes (tales como disponibilidad y tiempo de las Reservas, precios, horarios y días de servicio, código de vestimenta, área para fumadores y no fumadores, mínimo de personas por mesa, etc.), por lo que en caso de encontrar errores o falencias en la información provista, PUERTA 21 no será responsable por las mismas, ni los daños que el uso o dependencia en ellas pueda causa.
            <br />
            <br />
            El Usuario podr emitir opiniones, comentarios, valoraciones, correos electrónicos, sugerencias y preguntas concernientes a, o relacionadas a las informaciones ofrecidas en la Plataforma, siempre y cuando, el contenido no sea, de manera enunciativa y no limitativa, ilegal, amenazante, obsceno, racista, difamatorio, calumnioso, pornográfico y que no infrinja lo establecido en los Términos de Uso y demás políticas que forman parte integrante de los Términos de Uso.
            <br />
            <br />
            Se prohíbe expresamente la utilización de esta Plataforma con fines comerciales. El Usuario no podrá ingresar a la Plataforma por medio de técnicas de acceso automatizadas o de scraping u otro dispositivo automático, salvo que cuente con nuestra autorización previa por escrito. Tampoco podrá utilizar cualquier dispositivo o software que interfiera o intente interferir con el funcionamiento de la Plataforma.
            <br />
            <br />
            El Usuario no deber utilizar la Plataforma, para, a modo de referencia, m s no limitativo: enviar mensajes directos, ni en el foro, ni correos electrónicos masivos (spam) con contenido amenazante, abusivo, hostil, ultra ante, di amatorio, vulgar, obsceno o injurioso publicar contenido falso, incorrecto, en a oso, calumnioso o difamatorio recopilar información de los usuarios alentar, incitar o dirigir a otros usuarios a in rin ir los presentes de Términos de Uso o al una disposición legal para llevar a cabo algún acto ilegal o delictivo sacar una venta a indebida, ilegítima o de mala fe; interferir con la capacidad de otros usuarios de acceder o utilizar la Plataforma. Además, acepta que no copiará, reproducirá, modificará, crear derivaciones, distribuir o mostrar públicamente algún contenido de la Plataforma usar un lengua explícito, abusivo, amenazante, obsceno, vulgar, racista, ni cualquier lenguaje que se considere inapropiado o en contra de la moral y las buenas costumbres anunciar o proporcionar enlaces a sitios que contengan material ilegal u otro contenido que pueda dañar o deteriorar la red personal o computadora de otro usuario.
            <br />
            <br />
            Los Servicios y contenidos de PUERTA 21 se ofrecen únicamente para uso personal del Usuario y para los fines descritos en este Acuerdo. Está prohibido cualquiera y todos los otros usos. PUERTA 21 expresamente se reserva todos los derechos y recursos conforme a las leyes aplicables.
            <br />
            <br />
            PUERTA 21 se reserva el derecho, a su exclusiva discreción, de rehusarse a brindar servicios, terminar cuentas, eliminar o editar contenidos, cancelar reservaciones o negar acceso a los Servicios. El Usuario acepta no hacer, incurrir o causar ninguna de las siguientes actuaciones (y no permitir que los terceros lo hagan):
            <br />
            <br />
            <br />
            <br />
            (1)Usar enlace profundo, robot, spider, scraper u otro dispositivo, proceso o medio automático o manual para acceder, copiar, buscar o controlar alguna parte de los Servicios o de los Contenidos de PUERTA 21, salvo que PUERTA 21 lo autorice expresamente;
            <br />
            <br />
            (2)tomar medidas que impongan o puedan imponer (según la exclusiva consideración de PUERTA 21) una carga desproporcionada o excesivamente grande en los Servicios o en la infraestructura de PUERTA 21;
            <br />
            <br />
            (3)utilizar dispositivos, software o rutas que interfieran o intenten interferir con la funcionalidad de los Servicios;
            <br />
            <br />
            (4)alquilar, rentar, copiar, facilitar acceso o sublicenciar una parte de los Servicios o de los contenidos de PUERTA 21 a terceros;
            <br />
            <br />
            (5)usar alguna parte de los Servicios o de los contenidos de PUERTA 21 para proporcionar o incorporar alguna parte de los Servicios o de los contenidos de PUERTA 21 en algún producto o servicio prestado a un tercero;
            <br />
            <br />
            (6)hacer ingeniería inversa, descompilar, desarmar o, en su defecto, procurar obtener el código fuente o interfaces de programas de aplicaciones (API, por sus siglas en inglés) no públicos para los Servicios, salvo que la ley aplicable lo permita expresamente (y, en ese caso, sólo mediante previo aviso a PUERTA 21);
            <br />
            <br />
            (7)modificar los Servicios o los contenidos de PUERTA 21 o crear un producto derivado de cualquiera de los anteriormente descritos;
            <br />
            <br />
            (8)eliminar o cubrir los avisos sobre propiedad exclusiva o de otro tipo incluidos en los Servicios o en los ontenidos de PUERTA 21;
            <br />
            <br />
            (9)usar los Servicios o los contenidos de PUERTA 21 con fines ilegales; o
            <br />
            <br />
            (10) difundir públicamente información sobre el funcionamiento de los Servicios o de los contenidos de PUERTA 21, o acceder o usar los Servicios o los Contenidos de PUERTA 21 para realizar análisis competitivos o evaluaciones comparativas.Las tarifas y valores mostrados en la Plataforma son referenciales.
            <br />
            <br />
            7. Sitios Web de Terceros
            <br />
            <br />
            El Usuario reconoce que en la Plataforma se proporcionan referencias a otros sitios web, sobre los cuales libera de toda responsabilidad a PUERTA 21 sobre el acceso, uso, y contenido en los mismos. Todas las conexiones que se proveen a otros sitios, tienen como único objetivo brindarles mayores referencias sobre los Restaurantes. Las políticas y procedimientos que describimos a u no se aplican a los servicios de terceros. Le sugerimos ponerse en contacto con estos sitios directamente para obtener información sobre sus términos y condiciones, políticas de privacidad, entre otros.
            <br />
            <br />
            8. Cambios en los Servicios
            <br />
            <br />
            PUERTA 21 se reserva el derecho a realizar cambios discrecionales a los Servicios en cualquier momento, incluyendo sin limitarse a la suspensión, modificación, adición o remoción de Servicios en cualquier momento y la restricción en el uso y acceso a los mismos. Se notificarán los cambios en la Plataforma y/o los Servicios a través del mantenimiento del servidor, actualización de la aplicación, aviso o correo electrónico. En caso de que el Usuario no esté de acuerdo con cualquiera de los cambios efectuados, podrá dejar de usar inmediatamente los Servicios y la cuenta. Si continúa utilizando la cuenta después que se hayan efectuado los cambios en los Servicios se considerará dicho uso como su aceptación total a los cambios efectuados.
            <br />
            <br />
            9. Cesión Contractual
            <br />
            <br />
            El Usuario reconoce y acepta que PUERTA 21 podrá ceder cualquier posición contractual o cualquiera de sus derechos u obligaciones bajo este Acuerdo, o vender sus activos a cualquier tercero sin necesidad de informarle y obtener el consentimiento previo y expreso del Usuario.
            <br />
            <br />
            10. Limitación de Responsabilidad e Indemnidad
            <br />
            <br />
            Los Servicios proporcionados por PUERTA 21 en la Plataforma se ofrecen sin ninguna clase de garantía. PUERTA 21 no garantiza que los accesos a enlaces (vínculos) de terceras partes en la Plataforma estén libres de o no sean afectados con virus u otros elementos contaminantes o destructivos.
            <br />
            <br />
            Salvo en los casos de culpa inexcusable y dolo, PUERTA 21 (ni ningunos de sus empleados, proveedores y/o licenciatarios) serán responsables por el lucro cesante o da o moral ue pueda sur ir directa o indirectamente del uso de (o no uso) de los Servicios, aun en los casos cuando PUERTA 21 haya sido advertido de la posibilidad de ue pueden sur ir tales da os.
            <br />
            <br />
            A requerimiento previo de PUERTA 21, el Usuario acepta defender, indemnizar y mantener indemne a PUERTA 21 (incluyendo a sus empleados, proveedores y licenciatarios) de cualquier reclamación y gastos, incluyendo honorarios razonables de abogados, relacionados con cualquier violación suya de estos Términos de Uso o por su uso de cualquiera de los Servicios en la Plataforma.
            <br />
            <br />
            Los Restaurantes y comerciantes son los únicos responsables de la relación que mantienen con el Usuario y de cualquiera y todas las reclamaciones, lesiones, enfermedades, daños y perjuicios, responsabilidades y costos (las “Reclamaciones”) que sufra el Usurio y/o sus acompañantes, como resultado de su relación (o la de ese beneficiario) con un Restaurante o proveedor al que se acceda por la Plataforma, o de su visita a un Restaurante, o proveniente de una promoción, oferta, producto o servicio de un Restaurante o proveedor. Los Usuarios deberán resolver todos sus conflictos directamente con los Restaurantes. En tal sentido el Usuario libera a PUERTA 21 de cualquiera y todas esas Reclamaciones.
            <br />
            <br />
            Asimismo, respecto a la supresión o modificación de la información consignada para las reservas en Restaurantes que se ponen a su disposición a través de la Plataforma y de cualquier cancelación o modificación efectuada por los Restaurantes sobre sus Reservas así como por la experiencia que el Usuario tenga en estos, el Usuario reconoce y acepta mantener a PUERTA 21 indemne (incluyendo a sus directivos, socios y empleados) de cualquier reclamación y gastos, incluyendo honorarios razonables de abogados, relacionados con cualquier demanda, denuncia y en general cualquier acción de carácter legal, administrativo y/o judicial, relacionados a los Servicios brindados a través de la Plataforma o por la violación de derechos de propiedad intelectual relacionado a contenido o material publicitario proporcionado por los Restaurantes.
            <br />
            <br />
            10. Fuerza Mayor
            <br />
            <br />
            PUERTA 21 no será responsable o se considerará que se encuentra en incumplimiento de cualquier retraso o falla en el rendimiento o la interrupción en la prestación de los Servicios que pueda resultar directa o indirectamente de cualquier causa o circunstancia más allá de su control razonable, incluyendo, pero sin limitarse a fallas en los equipos o las líneas de comunicación electrónica o mecánica, teléfono u otros problemas de interconexión, virus informáticos, el acceso no autorizado, robo, errores del operador, clima severo, terremotos o desastres naturales, huelgas u otros problemas laborales, guerras, o restricciones gubernamentales.
            <br />
            <br />
            11. Terminación.
            <br />
            <br />
            PUERTA 21 podrá suspender la capacidad de un Usuario de usar cualquiera o todos los elementos de los Servicios, o podrá terminar este Acuerdo con validez inmediata, sin previo aviso ni explicación. Sin perjuicio de lo anterior, PUERTA 21 podrá suspender el acceso a los Servicios de un Usuario si considera que ha violado alguna parte de este Acuerdo. El Usuario acepta que PUERTA 21 no será responsable ante este por la terminación de este Acuerdo ni por los efectos de ninguna terminación de este Acuerdo. El Usuario siempre tendrá la opción de discontinuar su uso de los Servicios en cualquier momento. El Usuario entiende que la terminación de su cuenta en la Plataforma podrá incluir la eliminación del contenido almacenado en ella, por lo que PUERTA 21 no tendrá ningún tipo de responsabilidad en absoluto sobre dicha acción, y la información eliminada.
            <br />
            <br />
            12. Ley Aplicable y n Aplicable.
            <br />
            <br />
            Cualquier disputa que se produzca con relación a la validez, aplicación o interpretación de estos Términos de Uso, y/o cualquiera de sus acuerdas que se incluyan a este por referencia o modificación posterior, ser resuelta por los Tribunales de la República Dominicana quienes tendrán jurisdicción exclusiva para conocer de los mismos. Así mismo, el Usuario reconoce que este Acuerdo se celebra, rige y se interpreta de conformidad con las leyes de la República Dominicana, y en concordancia con las siguientes legislaciones y sus modificaciones: (i) Ley General de Protección de los Derechos al Consumidor o
            <br />
            <br />
            Usuario, núm. 358-05, (ii) Ley No. 172-13 que tiene por objeto la protección integral de los datos personales asentados en archivos, registros públicos, bancos de datos u otros medios técnicos de tratamiento de datos destinados a dar informes, sean estos públicos o privados, y (iii) Ley No.126-02 sobre Comercio Electrónico,. Documentos y Firmas Digitales de la República Dominicana.
          </Typography>
        </div>
      </Box>
    </Container>
  </>
);

export default Privacy;
