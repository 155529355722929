class TokenManager {
  localStorageKey = null;

  constructor(tokenType) {
    if (tokenType === 'access') {
      this.localStorageKey = process.env.REACT_APP_ACCESS_TOKEN_KEY;
    }
    if (tokenType === 'email') {
      this.localStorageKey = process.env.REACT_APP_ACCESS_EMAIL;
    }
    if (tokenType === 'role') {
      this.localStorageKey = process.env.REACT_APP_ROLE_KEY;
    }
    if (tokenType === 'token_type') {
      this.localStorageKey = process.env.REACT_APP_TOKEN_TYPE_KEY;
    }
    if (tokenType === 'maintain_session') {
      this.localStorageKey = process.env.REACT_APP_MAINTAIN_SESSION_KEY;
    }
    if (tokenType === 'expired_token') {
      this.localStorageKey = process.env.REACT_APP_EXPIRED_TOKEN_KEY;
    }
    if (tokenType === 'restaurant_eid') {
      this.localStorageKey = process.env.REACT_APP_ACCESS_RESTAURANT_EID;
    }
    if (tokenType === 'restaurant_name') {
      this.localStorageKey = process.env.REACT_APP_ACCESS_RESTAURANT_NAME;
    }
  }

  getToken = () => window.localStorage.getItem(this.localStorageKey)

  setToken = (token) => window.localStorage.setItem(this.localStorageKey, token);

  removeToken = () => window.localStorage.removeItem(this.localStorageKey);
}

export default TokenManager;
