import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import {
  Button,
  InputLabel,
  InputAdornment,
  Input,
  IconButton,
  Typography,
} from '@material-ui/core/';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as StaffApi from '../../api/restaurants/staff';
import isTruthy from '../../utils/isTruthy';
import SnackbarAlert from '../SnackbarAlert';
import createPassword from '../../utils/createPassword';
import passwordRegex from '../../utils/passwordRegex';
import emojiRegex from '../../utils/emojiRegex';

import './DialogChangePassword.scss';

ReactModal.setAppElement('#root');

const DialogChangePassword = ({ isOpen, onNo }) => {
  const [fields, setFields] = useState({
    password: '',
    newPassword: '',
    showPassword: false,
    showNewPassword: false,
  });
  const [validations, setValidations] = useState({
    password: false,
    newPassword: false,
  });
  const [hasEditedField, setHasEditedField] = useState(false);
  const [isSamePassword, setIsSamePassword] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  const handleClickShowPassword = (field: string) => {
    setFields({ ...fields, [field]: !fields[field] });
  };

  const handleValidations = (field, value) => {
    const mapValidations = {
      password: (password) => password.length > 7,
      newPassword: (newPassword) => passwordRegex.test(newPassword),
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    newValidations.isSamePassword = fields.newPassword === fields.password;
    setValidations(newValidations);
    setHasEditedField(true);
  };

  const updateFields = (field, value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...fields,
    };
    newFields[field] = cleanString;
    setFields(newFields);
    setIsSamePassword((newFields.password.length > 2 && newFields.newPassword.length > 2)
      && (newFields.password === newFields.newPassword));
    handleValidations(field, cleanString);
  };

  const cleanUpBeforeClose = () => {
    setFields({
      password: '',
      newPassword: '',
      showPassword: false,
      showNewPassword: false,
    });
    setValidations({
      password: false,
      newPassword: false,
    });
    setHasEditedField(false);
    onNo();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      password: fields.password,
      new_password: fields.newPassword,
      new_password_confirmation: fields.newPassword,
    };
    try {
      const { success, message } = await StaffApi.changeUserPassword(body);
      if (isTruthy(success)) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
        setTimeout(() => {
          cleanUpBeforeClose();
        }, 1500);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };

  const renderModal = () => (
    <ReactModal
      isOpen={isOpen}
      className="dialog-changePassword-modal"
      overlayClassName="dialog-changePassword-modal__overlay"
    >
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <div className="dialog-changePassword-modal__content ">
        <div className="dialog-changePassword-modal__text-container">
          <div className="dialog-changePassword-modal__title">
            <Typography variant="h5" gutterBottom>
              CONTRASEÑA
            </Typography>
          </div>
          <div className="dialog-changePassword-modal__body">
            <form className="w100 mt-2" onSubmit={handleSubmit} autoComplete="off">
              <div className="flex-column">
                <div>
                  <InputLabel
                    htmlFor="password"
                  >
                    Contraseña actual
                  </InputLabel>
                  <Input
                    autoFocus
                    className="w100"
                    error={hasEditedField && !validations.password}
                    id="password"
                    label="Contraseña"
                    onChange={(e) => updateFields('password', e.target.value)}
                    type={fields.showPassword ? 'text' : 'password'}
                    value={fields.password}
                    endAdornment={
                      (

                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('showPassword')}
                          >
                            {fields.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>

                      )
                    }
                  />
                </div>
                <div className="mt-2">
                  <InputLabel
                    htmlFor="password"
                  >
                    Nueva contraseña
                  </InputLabel>
                  <Input
                    className="w100"
                    error={hasEditedField && !validations.newPassword}
                    id="newPassword"
                    label="Nueva Contraseña"
                    onChange={(e) => updateFields('newPassword', e.target.value)}
                    type={fields.showNewPassword ? 'text' : 'password'}
                    value={fields.newPassword}
                    endAdornment={
                      (

                        <InputAdornment position="end">
                          <Button
                            variant="text"
                            color="primary"
                            type="button"
                            onClick={() => updateFields('newPassword', createPassword())}
                          >
                            Generar
                          </Button>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('showNewPassword')}
                          >
                            {fields.showNewPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>

                      )
                    }
                  />
                  {isSamePassword && (
                    <div className="color-error pt-1">
                      La contraseña no puede ser igual a la anterior.
                    </div>
                  )}
                </div>
              </div>
              <div className="dialog-changePassword-modal__cta-container mt-2">
                <Button
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={() => { cleanUpBeforeClose(); }}
                >
                  <span className="underline">
                    Cancelar
                  </span>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!validations.password || !validations.newPassword || isSamePassword}
                >
                  Guardar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ReactModal>
  );

  return (
    <>
      {renderModal()}
    </>
  );
};

DialogChangePassword.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
};
DialogChangePassword.defaultProps = {
  isOpen: false,
  onNo: '',
};

export default DialogChangePassword;
