/* eslint-disable */
import React, { useState, useEffect } from 'react';
// lodash is imported from create-react-app, but we don't have it on our project dependecies.
// eslint-disable-next-line import/no-extraneous-dependencies
// import {
//   // isEmpty,
//   throttle,
// } from 'lodash';

import { navigate } from '@reach/router';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
  Hidden,
  Popper,
  FormGroup,
  FormControlLabel,
  Tabs,
  Tab,
  Badge,
} from '@material-ui/core/';
import { Switch } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ReportIcon from '@material-ui/icons/Report';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import userContainsWord from '../../utils/userContainsWord';
import ReservationsContext from '../Reservations/ReservationsContext/ReservationsContext';
import TableErrors from '../../components/TableErrors';
import * as ReservationsApi from '../../api/restaurants/reservations';
import prepareReservationsTabs from '../../utils/prepareReservationsTabs';
import prepareReservationsTable from '../../utils/prepareReservationsTableComments';
// import prepareTablePaginator from '../../utils/prepareTablePaginator';
import isTruthy from '../../utils/isTruthy';
import TheMenu from '../../components/TheMenu';
import ReservationsTabs from '../../components/ReservationsTabs';
import ReservationsTable from '../../components/ReservationsTableComentsUser';
import ReservationsAccordion from '../../components/CommentsAccordionreponse';
import ReservationsDatepicker from '../../components/ReservationsDatepicker';
import DialogBlockDay from '../../components/DialogBlockDay';
import DialogBlockPardepan from '../../components/DialogBlockPardepan';

import DialogTotal from '../../components/DialogTotal';
import DialogTotal2 from '../../components/DialogTotal2';

import DialogFilterStatus from '../../components/DialogFilterStatus';
import DialogUnBlockDay from '../../components/DialogUnBlockDay';
import DialogUnBlockPardepan from '../../components/DialogUnBlockPardepan';
import 'antd/dist/antd.css';
import './Reservations.scss';
import ReactExport from "react-data-export";
import { checkPropTypes } from 'prop-types';
import { consoleSandbox } from '@sentry/utils';
import * as CommentsApi from '../../api/restaurants/comments';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const currentCallConfig = () => {
  const retrieveReservationsConfig = localStorage.getItem('ReservationsConfig');
  const retrieveConfigDate = localStorage.getItem('ReservationsConfigDate');
  if (retrieveConfigDate === null) { localStorage.setItem('ReservationsConfigDate', moment().format('YYYY-MM-DD hh:mm a')); }
  const retrieveConfigZone = localStorage.getItem('ReservationsConfigZone');
  const retrieveConfigSortCategory = localStorage.getItem('ReservationsConfigSortCategory');
  const retrieveConfigSortDirection = localStorage.getItem('ReservationsConfigSortDirection');
  const config = {
    lastPage: retrieveReservationsConfig !== null
      ? JSON.parse(retrieveReservationsConfig).lastPage : 1,
    currentPage: retrieveReservationsConfig !== null
      ? JSON.parse(retrieveReservationsConfig).currentPage : 1,
    perPage: retrieveReservationsConfig !== null
      ? Number(JSON.parse(retrieveReservationsConfig).perPage)
      : 100,
    total: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).total : 0,
    from: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).from : 1,
    to: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).to : 5,
    options: [5, 25, 100],
    date: retrieveConfigDate !== null ? retrieveConfigDate.substring(0, 10) : moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
    time: retrieveConfigDate !== null ? retrieveConfigDate.substring(11, retrieveConfigDate.length) : moment(new Date(), 'hh:mm a').format('hh:mm a'),
    zone: retrieveConfigZone !== null ? retrieveConfigZone : 'all',
    sortCategory: retrieveConfigSortCategory !== null ? retrieveConfigSortCategory : 'fecha',
    sortDirection: retrieveConfigSortDirection !== null ? retrieveConfigSortDirection : 'asc',
    search: '',
  };
  return config;
};

const Reservations = () => {
  // const throttleTime = 200;
  const [state, setState] = useState({
    loading: true,
    error: null,
    reservations: [],
    pardepanBlocked: [],
    reservationsBlocked: { reservations: [], cancelled: [] },
    capacity: [],
    paginator: {},
    currentFilter: 'ENCUESTA A LOS CLIENTES',
    reservationsVisible: true,
  });
  const [dialogBlockday, setDialogBlockDay] = useState(false);
  const [dialogBlockPardepan, setDialogBlockPardepan] = useState(false);
  const [dialogUnBlockPardepan, setDialogUnBlockPardepan] = useState(false);

  const [dialogUnBlockday, setDialogUnBlockDay] = useState(false);
  const [exportDataDay, setExportDataDay] = useState([]);
  const [exportDataNight, setExportDataNight] = useState([]);
  const [showingWaitingList, setShowingWaitingList] = useState(false);
  const [showingDeletedList, setShowingDeletedList] = useState(false);
  const [FilterStatusDialog, setDialogFilterStatus] = useState(false);
  // const [reservations, setReservations] = useState(state.reservations);
  const [title, setTitle] = useState('ENCUESTA REALIZADA POR LOS CLIENTES');
  // sets the number of pending reservations in the month to 0
  const [pending, setPending] = useState(0);
  const [automatic, setAutomatic] = useState(0);
  const [filterdReservations, setFilteredReservations] = useState(state.reservations);
  const [apiCallConfig, setApiCallConfig] = useState(currentCallConfig());
  const [reload, setReload] = useState(false);
  const [totalDialog, setTotalDialog] = useState(false);
  const [totalDialog2, setTotalDialog2] = useState(false);

  const [totalDialogData, setTotalDialogData] = useState({ total: 0, walkin: 0, asistido: 0, cancelado: 0 });
  const [totalDialogData2, setTotalDialogData2] = useState({ total: 0, walkin: 0, asistido: 0, cancelado: 0 });

  const [activeIndex, setActiveIndex] = React.useState(0);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const openTotalDialog = () => {
    setTotalDialog(true);
  };
  const closeTotalDialog = () => {
    setTotalDialog(false);
  };
  const openTotalDialog2 = () => {
    setTotalDialog2(true);
  };
  const closeTotalDialog2 = () => {
    setTotalDialog2(false);
  };
  const openFilterDialog = () => {
    setDialogFilterStatus(true);
  };
  const closeFilterDialog = () => {
    setDialogFilterStatus(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openPopper = Boolean(anchorEl);

  var multiDataSet2 = [
    {
      columns: [
        { title: "NOMBRE", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "MESA", width: { wch: 10 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "ZONA", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "FECHA", width: { wch: 15 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "HORA", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "ASISTENTES", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "COMENTARIOS", width: { wch: 40 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width
        { title: "CONTACTO", width: { wch: 35 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "ESTATUS", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "00000000" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width  
      ], data: [

      ]
    },
    {
      columns: [
        { title: "DÍA", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FF000000" } } } },//pixels width 
        { title: "", width: { wch: 10 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "", width: { wch: 15 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "", width: { wch: 40 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "", width: { wch: 35 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
      ],
      data: exportDataDay

    }, {
      columns: [
        { title: "NOCHE", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFE6E6E6" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FF000000" } } } },//pixels width 
        { title: "", width: { wch: 10 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "", width: { wch: 15 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "", width: { wch: 25 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "", width: { wch: 40 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "", width: { wch: 35 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 
        { title: "", width: { wch: 20 }, style: { fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } }, font: { name: 'arial', sz: "14", bold: true, color: { rgb: "FFFFFFFF" } } } },//pixels width 

      ],
      data: exportDataNight
    }
  ];
  const [nameForFilter, setNameForFilter] = useState('');
  const [selectedDate, setSelectedDate] = useState(moment(new Date(), 'YYYY-MM-DD').locale('es').format('DD MMMM YYYY'));

  const openDialogBlockDay = () => {
    setDialogBlockDay(true);
  };
  const closeDialogBlockDay = () => {
    setDialogBlockDay(false);
  };

  const openDialogUnBlockDay = () => {
    setDialogUnBlockDay(true);
  };
  const closeDialogUnBlockDay = () => {
    setDialogUnBlockDay(false);
  };

  const openDialogBlockPardepan = () => {
    setDialogBlockPardepan(true);
  };
  const closeDialogBlockPardepan = () => {
    setDialogBlockPardepan(false);
  };

  const openDialogUnBlockPardepan = () => {
    setDialogUnBlockPardepan(true);
  };
  const closeDialogUnBlockPardepan = () => {
    setDialogUnBlockPardepan(false);
  };
  const switchHandler = (value) => {
    if (value) {
      openDialogBlockDay(true);
    } else {
      openDialogUnBlockDay(true);
    }
  };
  const switchHandler2 = (value) => {
    if (value) {
      openDialogBlockPardepan(true);
    } else {
      openDialogUnBlockPardepan(true);
    }
  };
  const [isFilteringByName, setIsFilteringByName] = useState({
    isFiltering: false,
    filterCount: 0,
    currentPage: 1,
  });
  const [isFilteringByStatus, setIsFilteringByStatus] = useState({
    isFiltering: false,
    booleans: [],
  });
  const renderLoading = () => (
    <div>Loading...</div>
  );
  const reloadpage = () => {
    setReload(true);
  };
  useEffect(() => {
    const timer = setTimeout(() => reloadpage(), 5 * 60 * 1000);
    //return () => clearTimeout(timer);
  }, []);
  const renderError = () => {
    const errorData = {
      message: state.error,
      action: () => true,
      buttonText: 'Intentar de nuevo',
      image: 'broken',
    };
    return (
      <TableErrors data={errorData} action={setReload} />
    );
  };
  const renderNoResults = () => {
    const errorData = {
      image: 'noresults',
      message: 'NO HAY RESERVAS PARA EL EL FILTRO QUE SELECCIONÓ',
      message2: 'Por favor, dele clic a “Filtrar por Estatus” y seleccione otro filtro.'
    };
    return (
      <TableErrors data={errorData} />
    );
  };
const renderNoPending = () => {
  const errorData = {
    image: 'noresults',
    message: 'NO HAY RESERVAS PENDIENTES PARA EL FILTRO QUE SELECCIONÓ ',
    message2: ''
  };
  return (
    <TableErrors data={errorData} />
  );
};
const renderNoReservation = () => {
  const errorData = {
    message: 'Aún no tienes ninguna reservación.',
    action: () => navigate('/crear-reservacion'),
    buttonText: 'NUEVA RESERVACIÓN',
    image: 'reservations',
  };
  return (
    <TableErrors data={errorData} />
  );
};
const renderReservationBlocked = () => {

  const errorData = {
    image: 'reservationsblocked',
    name: state.reservationsBlocked.reservations[0].name,
    reason: state.reservationsBlocked.reservations[0].reason,
    email: state.reservationsBlocked.reservations[0].email,
    phone: state.reservationsBlocked.reservations[0].phone,
    people: state.reservationsBlocked.reservations[0].people,
    checkIn: state.reservationsBlocked.reservations[0].checkIn,
    checkOut: state.reservationsBlocked.reservations[0].checkOut,
  };
  return (
    <TableErrors data={errorData} />
  );
};

useEffect(() => {
  if (reload) {
    setReload(false);
  }
  const fetchReservations = async () => {
    const newConfig = currentCallConfig();
    setSelectedDate(moment(currentCallConfig().date).locale('es').format('YYYY-MM-DD'));

    if (isFilteringByName.isFiltering && isFilteringByName.filterCount === 0) {
      newConfig.search = nameForFilter;
      newConfig.currentPage = isFilteringByName.currentPage;
    }
    if (isFilteringByName.isFiltering && isFilteringByName.filterCount > 0) {
      newConfig.search = nameForFilter;
    }
    try {
      //const resp = await ReservationsApi.getTotalReservationsPerMonth(moment(currentCallConfig().date).format('YYYY'), moment(currentCallConfig().date).format('MM'));
      //   const aux = await ReservationsApi.getAllDayReservations(newConfig);
      let { success, data } = await ReservationsApi
        .getReservationsFast(newConfig);
      if (isTruthy(success)) {
        setPending(data.total);
        setAutomatic(data.countAutomatic);
        console.log(data);
        await prepareReservationsTable(data.reservations.data).then((tableData) => {
          // setReservations(tableData);
          setFilteredReservations(tableData.filter(reservation => (reservation.status !== "ELIMINADO" && reservation.status !== "LISTA DE ESPERA" && reservation.status !== "RECHAZADO" && reservation.status !== "CANCELADO POR EL CLIENTE") || (reservation.type === "waitinglist" && reservation.status !== "EN ESPERA")));
          const newCallConfig = {
            currentPage: data.currentPage,
            from: data.from,
            perPage: data.perPage,
            lastPage: data.lastPage,
            to: data.to,
            total: data.total,
          };
          setState({
            loading: false,
            error: null,
            reservations: tableData,
            pardepanBlocked: (data.pardepanBlocked !== null) ? data.pardepanBlocked : [],
            reservationsBlocked: (data.allDay !== null) ? data.allDay : { reservations: [] },
            reservationsVisible: data.visible,
            capacity: prepareReservationsTabs(data.reservations.capacity),
            paginator: newCallConfig,
            currentFilter: 'Reservas confirmadas'
          });
          //CONTACTO	CATEGORIA	NOMBRE	MESA	ZONA	HORA	ASISTENTES	COMENTARIOS
          // const datareservations = tableData.map(elt => [ (elt.email) ? elt.email : elt.phone, (elt.categories.length > 0) ? elt.categories.map((category) => category.string).join(',') : 'Sin datos', elt.name, elt.tableNumber,elt.table,elt.date,elt.time,elt.people,elt.status,elt.comments]);
          //const dataday= datareservations.filter(person => person < 60);
          //setExportDataDay(dataday);
          //console.log(dataday)
          // eslint-disable-next-line no-param-reassign
          const emptydata = [
            [
              { value: "SIN RESERVAS", style: { font: { sz: "24", bold: true } } },
              { value: "", style: { font: { bold: true } } },
              { value: "", style: { font: { bold: true } } },
              { value: "", style: { font: { bold: true } } },
              { value: "", style: { font: { bold: true } } },
              { value: "", style: { font: { bold: true } } },
              { value: "", style: { font: { bold: true } } },
              { value: "", style: { font: { bold: true } } },
              { value: "", style: { font: { bold: true } } },
              { value: "", style: { font: { bold: true } } },
            ]
          ];
          const dia = tableData.filter(person => (moment('05:00 pm', 'hh:mm A') >= moment(person.time, 'hh:mm A')));
          const dayreservations = dia.map(elt => [elt.name, elt.tableNumber, elt.table, elt.date, elt.time, elt.people, elt.comments, (elt.email) ? elt.email : elt.phone, (elt.categories.length > 0) ? elt.categories.map((category) => category.string).join(',') : 'Sin datos', elt.status]);
          if (dia.length > 0) {
            setExportDataDay(dayreservations);
          } else {
            setExportDataDay(emptydata);
          }
          const noche = tableData.filter(person => (moment('05:00 pm', 'hh:mm A') < moment(person.time, 'hh:mm A')));
          const nightreservations = noche.map(elt => [elt.name, elt.tableNumber, elt.table, elt.date, elt.time, elt.people, elt.comments, (elt.email) ? elt.email : elt.phone, (elt.categories.length > 0) ? elt.categories.map((category) => category.string).join(',') : 'Sin datos', elt.status]);
          if (noche.length > 0) {
            setExportDataNight(nightreservations);
          } else {
            setExportDataNight(emptydata);
          }

          const walkin = tableData.filter(person => person.email === 'client@kokonutstudio.com');

          const asistido = tableData.filter(person => person.status === 'SE HA IDO' || person.status === 'HA ASISTIDO');
          const totales = tableData.filter(person => person.status === 'SE HA IDO' || person.status === 'HA ASISTIDO');
          const result = asistido.reduce((total, currentValue) => total = total + currentValue.people, 0);
          const result2 = walkin.reduce((total, currentValue) => total = total + currentValue.people, 0);
          const result3 = tableData.length;
          const result4 = walkin.reduce((total, currentValue) => total = total + 1, 0);
          setTotalDialogData({
            total: result,
            walkin: result2,
            asistido: result,
          });
          setTotalDialogData2({
            total: result3,
            walkin: result4,
            asistido: result3,
          });
          localStorage.setItem('ReservationsConfig', JSON.stringify(newCallConfig));
        });

      } else {
        setExportDataDay([]);
        setExportDataNight([]);
        setState({
          loading: false, error: data.error, reservationsVisible: true, reservationsBlocked: { reservations: [], cancelled: [] }, reservations: [], pardepanBlocked: [], capacity: [], paginator: {}
        });
      }

    } catch (error) {
      setExportDataDay([]);
      setExportDataNight([]);
      setState({
        loading: false, error: error.message, reservationsVisible: true, reservationsBlocked: { reservations: [], cancelled: [] }, reservations: [], pardepanBlocked: [], capacity: [], paginator: {}
      });
    }
  };

  fetchReservations();
}, [apiCallConfig, reload, nameForFilter, isFilteringByName]);

const prepareMonthData = (data) => {

};
const manageFilterByStatus = (booleans) => {
  if (booleans[5]) {

    //setFilteredReservations(state.reservations);
    resetFilter();

  } else {
    var reservations = [];

    if (booleans[0]) {
      let noshow = state.reservations.filter(res => res.status === "NO SHOW");
      reservations = noshow;
    }
    if (booleans[1]) {
      let canceled = state.reservations.filter(res => res.status === "CANCELADO");
      reservations = canceled;
    }

    if (booleans[2]) {
      let show = state.reservations.filter(res => res.status === "HA ASISTIDO");
      reservations = show;
    }
    if (booleans[3]) {
      let left = state.reservations.filter(res => res.status === "SE HA IDO");
      reservations = left;
    }
    if (booleans[4]) {
      let pending = state.reservations.filter(res => res.status === "EN ESPERA");
      reservations = pending;
    }

    setFilteredReservations(reservations);
  }

}

const manageFilterByName = (value: string) => {
  // Validar si se recibe un string mayor que cero
  // Si si, se comienzan validaciones, si no,
  // se resetean los valores de IsFilteringByName && nameForFilter
  if (value.trim().length > 0) {
    if (value !== nameForFilter) {
      const retrieveReservationsConfig = JSON.parse(localStorage.getItem('ReservationsConfig'));
      retrieveReservationsConfig.currentPage = 1;
      localStorage.setItem('ReservationsConfig', JSON.stringify(retrieveReservationsConfig));
      setIsFilteringByName({
        currentPage: 1,
        filterCount: 1,
        isFiltering: true,
      });
    } else {
      // Si filterCount es 0 significa que se llamará el servicio de reservaciones
      // desde la primer página
      if (isFilteringByName.filterCount === 0) {
        const retrieveReservationsConfig = JSON.parse(localStorage.getItem('ReservationsConfig'));
        retrieveReservationsConfig.currentPage = 1;
        localStorage.setItem('ReservationsConfig', JSON.stringify(retrieveReservationsConfig));
      }
      setIsFilteringByName({
        ...isFilteringByName,
        isFiltering: true,
        filterCount: isFilteringByName.filterCount + 1,
      });
    }
    setNameForFilter(value);
  } else {
    const retrieveReservationsConfig = JSON.parse(localStorage.getItem('ReservationsConfig'));
    retrieveReservationsConfig.currentPage = 1;
    localStorage.setItem('ReservationsConfig', JSON.stringify(retrieveReservationsConfig));
    setIsFilteringByName({
      isFiltering: false,
      filterCount: 0,
      currentPage: 1,
    });
    setNameForFilter('');
  }
};
const resetFilter = () => {

  setActiveIndex(3);
  setShowingWaitingList(false);
  setTitle('Reservas Confirmadas');
  const res = state.reservations.filter(reservation => (reservation.status !== "ELIMINADO" && reservation.status !== "LISTA DE ESPERA" && reservation.status !== "RECHAZADO" && reservation.status !== "CANCELADO POR EL CLIENTE") || (reservation.type === "waitinglist" && reservation.status !== "EN ESPERA"));
  setFilteredReservations(res);

};
const renderDatePicker = () => (
  <ReservationsContext.Provider value={{ apiCallConfig, setApiCallConfig }}>
    <ReservationsDatepicker
      isOpen={isCalendarOpen}
      setIsOpen={setIsCalendarOpen}
      apiCallConfig={apiCallConfig}
      setNewDate={setApiCallConfig}
    />
  </ReservationsContext.Provider>
);
const renderTab = () => (
  <Box
    component="div"
    className="w100 dFlex flex-row align-center justify-between"
  >
  </Box>
);
const renderTable = () => (
  <>
    <Hidden xsDown>
      <Box
        component="div"
        className="reservations-table__box w100 dFlex flex-row align-center justify-between"
      >
        <ReservationsContext.Provider value={
          { apiCallConfig, filterdReservations, setApiCallConfig, title, selectedDate }
        }
        >
          <ReservationsTable setReload={setReload} />
        </ReservationsContext.Provider>
      </Box>
    </Hidden>
    <Hidden smUp>
      <Box
        component="div"
        className="reservations-table__box w100 dFlex flex-row align-center justify-between"
      >
        <ReservationsContext.Provider value={
          { apiCallConfig, filterdReservations, setApiCallConfig, title, selectedDate }
        }
        >
          <ReservationsAccordion />
        </ReservationsContext.Provider>
      </Box>
    </Hidden>
  </>
);
const renderDialogBlockDay = () => (
  <DialogBlockDay
    isOpen={dialogBlockday}
    onNo={closeDialogBlockDay}
    date={selectedDate}
    setReload={setReload}
  />
);
const renderDialogUnBlockDay = () => (
  <DialogUnBlockDay
    isOpen={dialogUnBlockday}
    onNo={closeDialogUnBlockDay}
    date={selectedDate}
    setReload={setReload}
  />
);
const renderDialogBlockPardepan = () => (
  <DialogBlockPardepan
    isOpen={dialogBlockPardepan}
    onNo={closeDialogBlockPardepan}
    date={selectedDate}
    setReload={setReload}
  />
);

const renderDialogUnBlockPardepan = () => (
  <DialogUnBlockPardepan
    isOpen={dialogUnBlockPardepan}
    onNo={closeDialogUnBlockPardepan}
    date={selectedDate}
    setReload={setReload}
  />
);
return (
  <>
    {!state.loading && renderDialogBlockDay()}
    {!state.loading && renderDialogUnBlockDay()}
    {!state.loading && renderDialogBlockPardepan()}
    {!state.loading && renderDialogUnBlockPardepan()}
    <DialogTotal
      isOpen={totalDialog}
      onNo={closeTotalDialog}
      data={totalDialogData}
    />
    <DialogTotal2
      isOpen={totalDialog2}
      onNo={closeTotalDialog2}
      data={totalDialogData2}
    />
    <DialogFilterStatus
      isOpen={FilterStatusDialog}
      onNo={closeFilterDialog}
      filtering={setIsFilteringByName}
      filterbystatus={manageFilterByStatus}
    />
    <TheMenu />
    {!state.loading && renderDatePicker()}

    <Container className="reservations layout px-0 containerFluid"
    >
      <Box
        component="div"
        className="reservations__tabs-container dFlex flex-column align-center justify-between"
      >
         {renderTab()} {/* Renderizar siempre la función renderTab() */}
         {renderTable()} {/* Renderizar siempre la función renderTable() */}
          {state.loading && renderLoading()}
          {!state.loading && state.capacity.length > 0 && state.reservationsBlocked.reservations.length === 0 && renderTab()}
          {!state.loading && !state.error  && state.reservations.length > 0 && !showingWaitingList }   
      </Box>
    </Container>
  </>
);
};

export default Reservations;
