import axios from 'axios';

import TokenManager from './TokenManager';

const client = async (endpoint, { body, ...customConfig } = {}) => {
  const token = new TokenManager('access').getToken();
  const headers = { 'content-type': 'application/json' };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const method = customConfig.method ?? body ? 'POST' : 'GET';
  const config = {
    method,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = body;
  }

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log(error);
      if (error.toJSON().message === 'Network Error') {
        return {
          data: {
            data: {
              error: 'Verifica tu conexión a internet',
            },
            success: 0,
          },
        };
      }
      if (error.response.status === 400) {
        return error.response;
      }
      if (error.response.status === 401) {
        return error.response;
      }
      if (error.response.status === 404) {
        return error.response;
      }
      if (error.response.status === 500) {
        return Promise.reject(error);
      }
      return Promise.reject(error);
    },
  );

  console.log(process.env.REACT_APP_API_URL);
  let apiResponse;
  switch (config.method) {
    case 'POST':
      apiResponse = axios.post(`${process.env.REACT_APP_API_URL}/${endpoint}`, config.body, config).then((res) => {
        console.log(res);
        return res.data;
      });

      break;
    case 'GET':
      apiResponse = axios.get(`${process.env.REACT_APP_API_URL}/${endpoint}`, config).then((res) => {
        console.log(res);
        return res.data;
      });

      break;
    default:
      apiResponse = axios.post(`${process.env.REACT_APP_API_URL}/${endpoint}`, config.body, config).then((res) => {
        console.log(res);
        return res.data;
      });
      break;
  }

  const jsonApiResponse = await apiResponse;

  return jsonApiResponse;
};

export default client;
