const prepareOffersTable = async (data: any) => {
  const rawData = data;
  const preparedData = [];
  rawData.forEach((element) => {
    const tableData = {
      id: element.id,
      title: element.title,
      description: element.description,
      type: element.type,
      dates: element.dates,
      start_date: element.startDate,
      end_date: element.endDate,
      repeats: element.repeats,
      status: element.status,
    };
    preparedData.push(tableData);
  });
  return preparedData;
};

export default prepareOffersTable;
