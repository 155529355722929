import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import {
  Button,
  Box,
  Grid,
  Hidden,
  Typography,
  ImageList,
  ImageListItem,
  Backdrop,
  CircularProgress,
  makeStyles,
} from '@material-ui/core/';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { useDropzone } from 'react-dropzone';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import RestaurantContext from '../../layouts/Restaurant/RestaurantContext/RestaurantContext';
import './RestaurantImages.scss';
import * as RestaurantsApi from '../../api/restaurants/restaurants';
import isTruthy from '../../utils/isTruthy';
import SnackbarAlert from '../SnackbarAlert';
import ImageThumbnail from '../ImageThumbnail';
import PlaceholderImageThumbnail from '../PlaceholderImageThumbnail';

const RestaurantImages = ({ setReload }) => {
  const { photoData } = useContext(RestaurantContext);
  const [photos, setPhotos] = useState();
  const [numberOfPhotos, setNumberOfPhotos] = useState();
  const [placeholdersPhotos, setPlaceholdersPhotos] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  // Configuracion para el backdrop/loader
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));
  const classes = useStyles();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  // Se usa para cuando se sube archivo en click
  const inputFile = useRef(null);

  // Para el dropzone de drag files
  const [hasFiles, setHasFiles] = useState(false);
  const {
    acceptedFiles, fileRejections,
    getRootProps,
  } = useDropzone(
    {
      accept: 'image/png, image/jpg, image/jpeg',
      maxFiles: 20 - numberOfPhotos,
      maxSize: 10000000,
      noKeyboard: true,
      onDropAccepted: () => setHasFiles(true),
    },
  );
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path}
      {' '}
      -
      {' '}
      {file.size}
      {' '}
      bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path}
      {' '}
      -
      {file.size}
      {' '}
      bytes
      <ul>
        {errors.map((e) => <li key={e.code}>{e.message}</li>)}
      </ul>

    </li>
  ));

  useEffect(() => {
    const renderPlacecholder = () => {
      setNumberOfPhotos(photoData.length);
      const neededPlaceholders = 20 - photoData.length;
      const array = [];
      if (neededPlaceholders > 0) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < neededPlaceholders; index++) {
          array.push(index);
        }
      }
      setPlaceholdersPhotos(array);
    };
    setPhotos(photoData);
    renderPlacecholder();
    setIsLoading(false);
  }, [photoData]);

  const updateViewData = (id) => {
    const newData = photos;
    photos.forEach((element, index) => {
      if (id === element.id) {
        delete newData[index];
      }
    });
    const renderPlacecholder = () => {
      const neededPlaceholders = 20 - (numberOfPhotos - 2);
      const array = [];
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < neededPlaceholders; index++) {
        array.push(index);
      }
      setNumberOfPhotos(numberOfPhotos - 1);
      setPlaceholdersPhotos(array);
    };
    setPhotos(newData);
    renderPlacecholder();
  };

  const deleteImage = async (id) => {
    setOpenBackdrop(true);
    try {
      const { success, message } = await RestaurantsApi
        .deletePhoto({ photo_eid: id });
      if (isTruthy(success) && success === 1) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
        setTimeout(() => {
          setReload(id);
          updateViewData(id);
          handleCloseBackdrop();
          // setReload(true);
        }, 1000);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
        handleCloseBackdrop();
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
      handleCloseBackdrop();
    }
  };

  const onAddImageClick = () => {
    inputFile.current.click();
  };

  const uploadOneImage = async (event) => {
    console.log(event);
    console.log(event.target.files);
    const file = event.target.files[0];
    if (event.target.files[0].size > 10000000) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: 'El archivo no debe exceder 10Mb',
      });
    }
    if (
      (event.target.files[0].type === 'image/jpg'
        || event.target.files[0].type === 'image/jpeg'
        || event.target.files[0].type === 'image/png')
      && event.target.files[0].size <= 10000000
    ) {
      const formData = new FormData();
      formData.append('image', file, 'image');
      console.log(formData.values());
      setOpenBackdrop(true);
      try {
        const { success, message } = await RestaurantsApi
          .uploadOnePhoto(formData);
        if (isTruthy(success) && success === 1) {
          setSnackbar({
            open: true,
            severity: 'success',
            text: message,
          });
          setTimeout(() => {
            handleCloseBackdrop();
            setReload(true);
          }, 1000);
        } else {
          setSnackbar({
            open: true,
            severity: 'error',
            text: message,
          });
          handleCloseBackdrop();
        }
      } catch (error) {
        setSnackbar({
          open: true,
          severity: 'error',
          text: error,
        });
        handleCloseBackdrop();
      }
    }
  };

  const uploadImagePromise = (image) => new Promise((resolve, reject) => {
    RestaurantsApi.uploadOnePhoto(image).then(
      (response: any) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
    );
  });

  const discardImages = () => {
    console.log('evento');
    setHasFiles(false);
  };

  const saveMultipleImages = async () => {
    console.log(acceptedFiles);
    setOpenBackdrop(true);
    const arrayOfPromises = [];
    acceptedFiles.forEach((element) => {
      const file = element;
      const formData = new FormData();
      formData.append('image', file, 'image');
      arrayOfPromises.push(uploadImagePromise(formData));
    });

    await Promise.all(arrayOfPromises).then(
      (responses: any) => {
        let hasError = false;
        let errorMessage;
        responses.forEach((element) => {
          if (element.error) {
            hasError = true;
            errorMessage = element.error;
          }
        });
        console.log(responses);
        setOpenBackdrop(false);
        setSnackbar({
          open: true,
          severity: !hasError ? 'success' : 'error',
          text: !hasError ? 'Fotos cargadas correctamente' : errorMessage,
        });
        setTimeout(() => {
          handleCloseBackdrop();
          if (!hasError) {
            setReload(true);
          }
        }, 1000);
      },
      (error) => {
        console.log(error);
        setOpenBackdrop(false);
      },
    );
  };

  const renderView = () => (
    <>
      <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {hasFiles && (
        <div className="restaurantImages__files-container">
          <Typography variant="h6">Archivos válidos</Typography>
          <ul>{files}</ul>
          {
            fileRejectionItems.length > 0 && (
              <>
                <Typography variant="h6">Archivos rechazados</Typography>
                <ul>{fileRejectionItems}</ul>
              </>
            )
          }
          <div className="restaurantImages__cta-container">
            <Button
              variant="contained"
              color="secondary"
              type="button"
              onClick={() => { discardImages(); }}
            >
              CANCELAR
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={() => { saveMultipleImages(); }}
            >
              GUARDAR IMÁGENES VÁLIDAS
            </Button>
          </div>
        </div>
      )}
      <Hidden xsDown>
        <div className="restaurantImages__group">
          <ImageList rowHeight={160} cols={5}>
            {numberOfPhotos > 0 && photos.map((element) => (
              <ImageListItem key={uuid()} cols={1} className="restaurant-image__item">
                <ImageThumbnail data={element} deleteImage={deleteImage} />
              </ImageListItem>
            ))}
            {numberOfPhotos < 20 && (
              <ImageListItem className="restaurantImages__dropzone-container">
                <div className="restaurantImages__dropzone">
                  <div
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getRootProps({ className: 'dropzone' })}
                  >
                    <PhotoLibraryIcon />
                    <div>
                      <small>
                        Arrastra aquí hasta
                        {' '}
                        {20 - numberOfPhotos}
                        {' '}
                        {numberOfPhotos === 19 ? 'imágen' : 'imágenes'}
                      </small>
                    </div>
                  </div>
                </div>
              </ImageListItem>
            )}
            {numberOfPhotos < 20 && placeholdersPhotos.map(() => (
              <ImageListItem key={uuid()} cols={1} onClick={() => onAddImageClick()} className="restaurant-image__item">
                <PlaceholderImageThumbnail />
              </ImageListItem>

            ))}
          </ImageList>
        </div>
      </Hidden>
      <Hidden smUp>
        <div className="restaurantImages__group">
          <ImageList rowHeight={160} cols={3}>
            {numberOfPhotos > 0 && photos.map((element) => (
              <ImageListItem key={uuid()} cols={1} className="restaurant-image__item">
                <ImageThumbnail data={element} deleteImage={deleteImage} />
              </ImageListItem>
            ))}
            {numberOfPhotos < 20 && placeholdersPhotos.map(() => (
              <ImageListItem key={uuid()} cols={1} onClick={() => onAddImageClick()} className="restaurant-image__item">
                <PlaceholderImageThumbnail />
              </ImageListItem>

            ))}
          </ImageList>
        </div>
      </Hidden>
      <input type="file" accept=".jpg, .jpeg, .png" onChange={uploadOneImage} id="file" ref={inputFile} style={{ display: 'none' }} />
    </>
  );

  return (
    <>
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <Box
        component="div"
        className="dFlex flex-column w100 mb-3"
      >
        <Grid container>
          <Grid item xs={12} md={4} className="restaurantImages-left-grid">
            <div className="pr-3 ml-1">
              <Typography variant="h6">
                AGREGA LAS FOTOGRAFÍAS DEL RESTAURANTE
              </Typography>
              <div>
                <Typography variant="body1">
                  Para agregar las fotografías da clic en el botón de
                  &quot;+ Agregar fotografía&quot;
                  o arrástrala
                  de tu ordenador y suéltala en la cuadrícula.
                  {/* <br />
                  <small>
                    Acepta archivos tipo .jpg, .jpeg o .png. Cada archivo debe ser menor a 1MB.
                  </small> */}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={8} className="restaurantImages-right-grid">
            {!isLoading && renderView()}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

RestaurantImages.propTypes = {
  setReload: PropTypes.func,
};

RestaurantImages.defaultProps = {
  setReload: () => { },
};

export default RestaurantImages;
