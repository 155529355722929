const dictionary = {
  userType: {
    0: 'admin',
    admin: 0,
    1: 'general_user',
    generalUser: 1,
    2: 'manager_user',
    managerUser: 2,
    3: 'hostess_user',
    hostessUser: 3,
  },
};

export default dictionary;
