// import { createMuiTheme } from '@material-ui/core';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { esES } from '@material-ui/core/locale';

import lightBlue from '@material-ui/core/colors/lightBlue';

const black = 'rgba(0,0,0,1)';
const white = 'rgba(256,256,256,1)';
const primaryFont = 'Roboto';
const secondaryFont = 'CommutersSans';

export default createMuiTheme({
  palette: {
    primary: {
      main: black,
    },
    secondary: {
      main: white,
    },
  },
  typography: {
    fontFamily: primaryFont,
    button: {
      fontFamily: secondaryFont,
    },
    h1: {
      fontFamily: secondaryFont,
    },
    h2: {
      fontFamily: secondaryFont,
    },
    h3: {
      fontFamily: secondaryFont,
    },
    h4: {
      fontFamily: secondaryFont,
    },
    h5: {
      fontFamily: secondaryFont,
    },
    h6: {
      fontFamily: secondaryFont,
    },
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        cursor: 'pointer',
        fontFamily: secondaryFont,
        fontSize: '.9rem',
      },
    },
    MuiCheckbox: {
      root: {
        color: black,
        '&$checked': {
          color: black,
        },
      },
    },
    MuiTypography: {
      root: {
        body1: {
          fontFamily: primaryFont,
        },
        body2: {
          fontSize: '.7rem',
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: white,
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: black,
        border: '1px solid rgba(0,0,0,.2)',
        padding: '5px 10px',
      },
      toolbarBtnSelected: {
        color: black,
        border: '1px solid #000000',
      },

    },
    MuiPickersTimePickerToolbar: {
      separator: {
        border: '0',
      },
      ampmLabel: {
        fontFamily: secondaryFont,
        color: black,
        backgroundColor: white,
      },
      toolbarBtnSelected: {
        color: white,
        border: '1px solid #000000',
      },
      toolbarAmpmLeftPadding: {
        paddingLeft: 10,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: 'black',
      },
    },
    MuiPickersDay: {
      day: {
        color: black,
      },
      daySelected: {
        backgroundColor: black,
      },
      dayDisabled: {
        color: lightBlue['100'],
      },
      current: {
        color: lightBlue['900'],
      },
    },
    MuiPickersClock: {
      clock: {
        backgroundColor: 'none',
        border: '1px solid #000000',
      },
    },
  },
}, esES);
