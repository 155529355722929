import moment from 'moment';
import 'moment/locale/es';

const prepareChargesTable = async (data: any) => {
  const rawData = data;
  const preparedData = [];
  rawData.forEach((element) => {
    const tableData = {
      image: element.photo,
      name: `${element.clientName}`,
      reservationDate: moment(element.reservationDate).locale('es').format('DD MMMM yyyy'),
      people: element.people,
      bill: element.bill,
      comission: element.commissionToPay,
    };
    preparedData.push(tableData);
  });
  return preparedData;
};

export default prepareChargesTable;
