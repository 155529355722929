import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@material-ui/core/';
import { v4 as uuid } from 'uuid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import CommentsContext from '../../layouts/Comments/CommentsContext/CommentsContext';
import DialogCommentResponse from '../DialogCommentResponse';
import MenuTableOptions from '../MenuTableOptions';
import './CommentsAccordion.scss';

const CommentsAccordion = ({ commentDelete, commentReply }) => {
  const { filteredComments } = useContext(CommentsContext);
  const [commentResponseDialog, setCommentResponseDialog] = useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [selectedComment, setSelectedComment] = useState({});
  const openResponseDialog = (row) => {
    setSelectedComment(row);
    setTimeout(() => {
      setCommentResponseDialog(true);
    }, 200);
  };

  const closeCommentResponseDialog = () => {
    setSelectedComment({});
    setState({ ...state, top: false });
    setCommentResponseDialog(false);
  };

  const saveResponse = (id, reply) => {
    commentReply(id, reply);
  };

  const menuOptions = (id) => [
    { key: 1, string: 'ELIMINAR', action: () => commentDelete(id) },
    // { key: 2, string: 'CANCELAR', action: null },
  ];
  const renderAccordion = () => (
    <div className="staff-accordion__container mb-3">
      {filteredComments
        .map((item) => (
          <div
            key={uuid()}
          >
            <DialogCommentResponse
              isOpen={commentResponseDialog}
              onNo={closeCommentResponseDialog}
              data={selectedComment}
              saveResponse={saveResponse}
            />
            <Accordion
              className="staff-accordion"
            >
              <AccordionSummary
                className="staff-accordion__summary"
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {item.photo !== null && (
                  <div className="staff-accordion__picture">
                    <div>
                      <svg viewBox="50 50 75px 75px" width="75" height="75">
                        <defs>
                          <mask id="mask">
                            <circle fill="#FFFFFF" cx="40%" cy="58%" r="23" />
                            <rect fill="#ffffff" x="9.7%" y="65%" width="45.5" height="40" />
                          </mask>
                        </defs>
                        <image mask="url(#mask)" xlinkHref={`data:image/png;base64,${item.photo}`} width="50" height="100" />
                      </svg>
                    </div>
                  </div>
                )}
                <div className={item.photo !== null ? 'staff-accordion__info w75' : 'staff-accordion__info w100'}>
                  <div className="dFlex flex-row">
                    <Typography className="staff-accordion__name ">
                      {item.name}
                    </Typography>
                  </div>
                  <div>
                    <div className="font__roboto">
                      {item.reservationDate}
                    </div>
                    <div className="font__roboto">
                      Número de personas:
                      {item.numberPeople}
                    </div>
                  </div>
                  <div>
                    <div className="font__roboto">
                      {item.comment}
                    </div>
                  </div>
                  <div className="w100 dFlex justify-end">
                    {item.comment !== 'Sin datos'
                      && (
                        <Button
                          variant="contained"
                          color="secondary"
                          type="button"
                          onClick={() => openResponseDialog(item)}
                        >
                          <span className="nowrap">
                            {item.reply ? 'VER RESPUESTA' : 'RESPONDER'}
                          </span>
                        </Button>
                      )}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="comments-accordion__expansion" />
                <MenuTableOptions id={item.id} options={menuOptions(item.id)} />
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
    </div>
  );

  return (
    <>
      {renderAccordion()}
    </>
  );
};

CommentsAccordion.propTypes = {
  commentDelete: PropTypes.func.isRequired,
  commentReply: PropTypes.func.isRequired,
};

export default CommentsAccordion;
