import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const SnackbarAlert = ({ snackbar, setSnackbar }) => {
  const [snackbarData, setSnackbarData] = useState(snackbar);

  const snackbarHandleClose = () => {
    setSnackbarData({ ...snackbarData, open: false });
    setSnackbar({ ...snackbarData, open: false });
  };

  useEffect(() => {
    setSnackbarData(snackbar);
  }, [snackbar]);

  return (
    <div className="w100">
      <Snackbar
        open={snackbarData.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={snackbarHandleClose}
      >
        <MuiAlert
          severity={snackbarData.severity}
          variant="filled"
          onClick={snackbarData.navigateTo ? () => navigate(snackbarData.navigateTo) : null}
        >
          {snackbarData.text}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

SnackbarAlert.propTypes = {
  snackbar: PropTypes.shape({
    open: PropTypes.bool,
    severity: PropTypes.string,
    text: PropTypes.string,
    navigateTo: PropTypes.string,
  }),
  setSnackbar: PropTypes.func,
};
SnackbarAlert.defaultProps = {
  snackbar: {
    open: false,
    severity: 'success',
    text: 'Placeholder',
    navigateTo: null,
  },
  setSnackbar: () => { },
};

export default SnackbarAlert;
