import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import {
  Typography,
  Button,
} from '@material-ui/core/';

import './DialogDeleteOffers.scss';

ReactModal.setAppElement('#root');

const DialogDeleteOffers = ({
  isOpen, onYes, onNo, data,
}) => (
  <ReactModal
    isOpen={isOpen}
    className="dialog-delete-offers-modal"
    overlayClassName="dialog-delete-offers-modal__overlay"
  >
    <div className="dialog-delete-offers-modal__content ">
      <div className="dialog-delete-offers-modal__text-container">
        <div className="dialog-delete-offers-modal__title">
          <Typography variant="h6" gutterBottom>
            ¿Estás seguro de borrar la oferta
            {' '}
            {data.id}
            ?
          </Typography>
        </div>
        <div className="dialog-delete-offers-modal__body" />
      </div>
      <div className="dialog-delete-offers-modal__cta-container">
        <Button
          variant="contained"
          color="secondary"
          type="button"
          onClick={onNo}
        >
          <span className="underline">
            Mantener
          </span>
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={onYes}
        >
          Si, eliminar
        </Button>
      </div>
    </div>
  </ReactModal>
);

DialogDeleteOffers.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  onYes: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.string,
  }),
};
DialogDeleteOffers.defaultProps = {
  isOpen: false,
  onNo: '',
  onYes: '',
  data: {
    email: '',
    id: '',
  },
};

export default DialogDeleteOffers;
