import client from '../utils/client';

// Muestra a todos los staff
const showAllStaff = (config) => client(`restaurant/staff?page=${config.currentPage ? config.currentPage : 1}&pagesize=${config.perPage ? config.perPage : 10}`)
  .then((response) => response);

// Muestra un miembro del staff
const showOneStaff = (id) => client(`restaurant/staff/${id}`)
  .then((response) => response);

// Crea un staff
const createStaff = (body) => client('restaurant/staff', { body })
  .then((response) => response);

// Edita un staff
const editStaff = (id, body) => client(`restaurant/staff/${id}`, { body, method: 'PUT' })
  .then((response) => response);

// Elimina un staff
const deleteStaff = (id, body) => client(`restaurant/staff/${id}`, { body, method: 'DELETE' })
  .then((response) => response);

// Cambia el password del usuario autenticado
const changeUserPassword = (body) => client('restaurant/profile/password', { body, method: 'PUT' })
  .then((response) => response);

export {
  showAllStaff,
  showOneStaff,
  createStaff,
  changeUserPassword,
  editStaff,
  deleteStaff,
};
