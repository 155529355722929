import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import './DialogClientImage.scss';

ReactModal.setAppElement('#root');

const DialogClientImage = ({ isOpen, onNo, image }) => (
  <ReactModal
    isOpen={isOpen}
    className="dialog-clientImage-modal"
    overlayClassName="dialog-clientImage-modal__overlay"
    shouldCloseOnOverlayClick
    onRequestClose={onNo}

  >
    <div className="dialog-clientImage-modal__content ">
      <img src={image} className="img-fluid" alt="Foto cliente" />
    </div>
  </ReactModal>
);

DialogClientImage.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.any,
};
DialogClientImage.defaultProps = {
  isOpen: false,
  onNo: '',
  image: '',
};

export default DialogClientImage;
