/* eslint-disable */
import React from 'react';

import PropTypes from 'prop-types';
import './DialogHistory.scss';
import ReactModal from 'react-modal';
import {
  Button,
} from '@material-ui/core/';

ReactModal.setAppElement('#root');

const DialogHistory = ({
  isOpen, onNo, data,
}) => (
  <ReactModal
    isOpen={isOpen}
    className="dialog-email-modal"
    overlayClassName="dialog-email-modal__overlay"
    shouldCloseOnOverlayClick
    onRequestClose={onNo}
  >
    <div className="dialog-email-modal__content ">
      <div className="dialog-email-modal__content__header">
        <h3 className="dialog-email-modal__content__header__title title">
          COMENTARIO  {(data.status_modified_by!==null)?data.status_modified_by:'sin datos'}
        </h3>

      </div>
      <div className="dialog-email-modal__content__body">
        <div className='mb-1'>
          {(data.created_by!==null)?data.created_by:'sin datos'}
        </div>
        <div className='mb-1'>
         
          {' '}
       
        </div>
        <div className='mb-1'>

          {' '}
       
        </div>
        <div className='mb-1'>
       
          {' '}
        
        </div>
      </div>
    </div>
  </ReactModal>
);

DialogHistory.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  data: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
};
DialogHistory.defaultProps = {
  isOpen: false,
  onNo: '',
  data: '',
};
export default DialogHistory;
