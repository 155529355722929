/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close'; import './ImageThumbnail.scss';

const ImageThumbnail = ({ data, deleteImage }) => {
  const [image] = useState(data);
  return (
    <div className="image-thumbnail dFlex justify-center align-center">
      <div className="image-thumbnail-mask">
        <IconButton
          aria-label="more"
          aria-controls="menu"
          aria-haspopup="true"
          color="secondary"
          appearence="outline"
          onClick={() => deleteImage(data.id)}
          className="image-thumbnail__delete-icon"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <img src={image.photo} alt={image.id} width="100%" height="auto" />
    </div>
  );
};

ImageThumbnail.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    order: PropTypes.number,
    photo: PropTypes.string,
  }).isRequired,
  deleteImage: PropTypes.func.isRequired,
};

export default ImageThumbnail;
