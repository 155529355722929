import client from '../utils/client';

// Muestra a todos los staff
const showAllComments = (config) => client(`restaurant/comments?page=${config.currentPage ? config.currentPage : 1}&pagesize=${config.perPage ? config.perPage : 10}`)
  .then((response) => response);

const showClientSurvey = () => client('restaurant/commentsurvey')
  .then((response2) => response2);

const listClientSurvey = () => client('restaurant/commentsurveylist')
  .then((response) => response);

// Adds a reply to a comment
const replyToComment = (id, body) => client(`restaurant/comments/reply/${id}`, { body })
  .then((response) => response);

// Delete a comment
const deleteComment = (id) => client(`restaurant/comments/${id}`, {
  method: 'DELETE',
})
  .then((response) => response);

const updateseen = (id) => client(`restaurant/updateseen/${id}`, {
  method: 'POST',
})
  .then((response) => response);

export {
  deleteComment,
  replyToComment,
  showAllComments,
  showClientSurvey,
  listClientSurvey,
  updateseen,
};
