/* eslint-disable */
import React, { useState, useEffect } from 'react';

import { navigate } from '@reach/router';
import {
  Box,
  Button,
  Container,
  Grid,
  // Hidden,
  IconButton,
  TextField,
  Typography,
  Hidden,
  Popper,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core/';
import { Switch } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import ReportIcon from '@material-ui/icons/Report';
// import userContainsWord from '../../utils/userContainsWord';
import ReservationsContext from './ReservationsContext/ReservationsContext';
import TableErrors from '../../components/TableErrors';
import * as ReservationsApi from '../../api/restaurants/reservations';
import prepareReservationsTabs from '../../utils/prepareReservationsTabs';
import prepareReservationsTable from '../../utils/prepareReservationsTable';
// import prepareTablePaginator from '../../utils/prepareTablePaginator';
import isTruthy from '../../utils/isTruthy';
import TheMenu from '../../components/TheMenu';
import ReservationsTabs from '../../components/ReservationsTabs';
import ReservationsTable from '../../components/ReservationsTable';
import ReservationsAccordion from '../../components/ReservationsAccordion';
import TablePaginationCustom from '../../components/TablePaginationCustom';
import ReservationsDatepicker from '../../components/ReservationsDatepicker';
import DialogBlockDay from '../../components/DialogBlockDay';
import DialogTotal from '../../components/DialogTotal';
import DialogUnBlockDay from '../../components/DialogUnBlockDay';
import 'antd/dist/antd.css';
import './Pending.scss';
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const currentCallConfig = () => {
  const retrieveReservationsConfig = localStorage.getItem('ReservationsConfig');
  const retrieveConfigDate = localStorage.getItem('ReservationsConfigDate');
  if (retrieveConfigDate === null) { localStorage.setItem('ReservationsConfigDate', moment().format('YYYY-MM-DD hh:mm a')); }
  const retrieveConfigZone = localStorage.getItem('ReservationsConfigZone');
  const retrieveConfigSortCategory = localStorage.getItem('ReservationsConfigSortCategory');
  const retrieveConfigSortDirection = localStorage.getItem('ReservationsConfigSortDirection');
  const config = {
    lastPage: retrieveReservationsConfig !== null
      ? JSON.parse(retrieveReservationsConfig).lastPage : 1,
    currentPage: retrieveReservationsConfig !== null
      ? JSON.parse(retrieveReservationsConfig).currentPage : 1,
    perPage: retrieveReservationsConfig !== null
      ? Number(JSON.parse(retrieveReservationsConfig).perPage)
      : 100,
    total: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).total : 0,
    from: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).from : 1,
    to: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).to : 5,
    options: [5, 25, 100],
    date: retrieveConfigDate !== null ? retrieveConfigDate.substring(0, 10) : moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
    time: retrieveConfigDate !== null ? retrieveConfigDate.substring(11, retrieveConfigDate.length) : moment(new Date(), 'hh:mm a').format('hh:mm a'),
    zone: retrieveConfigZone !== null ? retrieveConfigZone : 'all',
    sortCategory: retrieveConfigSortCategory !== null ? retrieveConfigSortCategory : 'fecha',
    sortDirection: retrieveConfigSortDirection !== null ? retrieveConfigSortDirection : 'asc',
    search: '',
  };
  return config;
};

const Pending = () => {
  
  return (
    <>
      <Container
        className="reservations layout px-0"
      >
        <span>Aqui va la lista de espera</span>
      </Container>
    </>
  );
};

export default Pending;
