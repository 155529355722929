import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import './RecoverPassword.scss';
import RecoverPasswordForm from './RecoverPasswordForm';
import UnauthenticatedSidebar from '../../components/UnauthenticatedSidebar';

const RecoverPassword = ({ token }) => {
  const [state, setState] = useState({
    loading: true, error: null, token: null,
  });
  useEffect(() => {
    const confirmMail = async () => {
      setState({
        loading: false, error: false, token,
      });
    };
    if (token) {
      confirmMail();
    } else {
      setState({
        loading: false, error: true, token: 'No se recibió token',
      });
    }
  }, [token]);

  const renderUsersContent = () => {
    if (state.loading) {
      return <div>Loading...</div>;
    }
    if (state.error) {
      return <div>No se recibió token</div>;
    }

    return (
      <Box component="div" className="recover-password layout">
        <Container fixed height="100">
          <Grid container spacing={2}>
            <UnauthenticatedSidebar />
            <Hidden smDown>
              <Grid item xs={12} md={2} />
            </Hidden>

            <Grid item xs={12} md={4} className="dFlex flex-column align-center justify-center">
              <Box component="div">
                <div className="logotitulo">
                  Puerta 2I
                </div>
                <div className="logosubtitulo">
                  Luxury Lifestyle Club
                </div>
              </Box>
              <Box
                component="div"
                className="mt-2 text-center"
              >
                <Typography variant="h5" gutterBottom>
                  Establece una nueva contraseña
                  {' '}

                </Typography>
              </Box>
              <Box
                component="div"
                className="w100"
              >
                <RecoverPasswordForm token={token} />
              </Box>
              <Box
                component="div"
                className="mt-2"
              >
                <Button onClick={() => { navigate('/'); }} variant="contained" color="secondary">
                  Volver al inicio de sesión
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  return renderUsersContent();
};

RecoverPassword.propTypes = {
  token: PropTypes.string,
};
RecoverPassword.defaultProps = {
  token: '',
};
export default RecoverPassword;
