/* eslint-disable */
import React, { useState, useContext } from 'react';

import {
  Box,
  Tabs,
  Tab,
} from '@material-ui/core/';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import './ReservationsTabs.scss';
import ReservationsContext from '../../layouts/Reservations/ReservationsContext/ReservationsContext';

const ReservationsTabs = () => {
  const { apiCallConfig, state, setApiCallConfig } = useContext(ReservationsContext);
  // const [value, setValue] = useState(0);
  const [zone, setZone] = useState(apiCallConfig);
  const tabData = state.capacity;
  const reservations = state.reservations.filter(reservation => (reservation.status === "EN ESPERA" || reservation.status === "HA ASISTIDO" || reservation.status === "SE HA IDO" || reservation.status === "ACEPTADO"));
  const handleChange = (event, newValue) => {
    const updateConfig = JSON.parse(localStorage.getItem('ReservationsConfig'));
    updateConfig.currentPage = 1;
    updateConfig.lastPage = null;
    updateConfig.from = 1;
    localStorage.setItem('ReservationsConfig', JSON.stringify(updateConfig));
    localStorage.setItem('ReservationsConfigZone', newValue);
    setZone({ ...zone, zone: newValue });
    setApiCallConfig({
      ...zone,
      zone: newValue,
      currentPage: 1,
      lastPage: null,
      from: 1,
    });
  };

  const getColors = (percentage) => {
    if (percentage > 80) {
      return 'reservation-tabs__numbers color-no-avaliability';
    } if (percentage < 80 && percentage > 50) {
      return 'reservation-tabs__numbers color-mid-avaliability';
    } if (percentage < 50) {
      return 'reservation-tabs__numbers color-full-avaliability';
    }
    return 'reservation-tabs__numbers color-full-avaliability';
  };
  const gettotal = (name) => {
    let total = 0;
    if (name === 'Total') {
      total = reservations.reduce((accumulator, currentValue) => accumulator + currentValue.people, 0);
    } else {
      let res = reservations.filter(res => res.table === name);
      total = res.reduce((accumulator, currentValue) => accumulator + currentValue.people, 0);
    }
    return total;
  }
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
        <Box
          component="div"
          className="reservations__tabs-box  dFlex flex-column w100"
        >
          <Box
            component="div"
            className="w100"
          >
            <Tabs
              className="w100 tabbuttons"
              variant="scrollable"
              scrollButtons="off"
              value={zone.zone}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
            >
              {
                tabData.map((tab, index) => (
                  <Tab
                    className={`order-${(tabData.length - index)}`}
                    value={tab.filterName}
                    key={tab.filterName}
                    label={(
                      <div className="reservations__tab-titles">
                        <span className="font__commutter">
                          {tab.tableName === 'Barra' ? `${tab.tableName} DE BAR` : tab.tableName}
                          :&nbsp;
                        </span>
                        {/* Descomentar si se requiere NO pintar los colores nuevamente */}
                        {/* <span className="reservation-tabs__numbers">{tab.people}</span> */}
                        <span className={getColors(tab.percentage)}>{gettotal(tab.tableName)}</span>
                        <span className="reservation-tabs__numbers">
                        </span>
                      </div>
                    )}
                  />
                ))
              }
            </Tabs>
          </Box>
        </Box>
      </MuiPickersUtilsProvider>
    </>
  );
};
// ReservationsTabs.propTypes = {
//   // tabData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   // currentZone: PropTypes.shape({}).isRequired,
//   // setCurrentZone: PropTypes.func.isRequired,
// };
export default ReservationsTabs;
