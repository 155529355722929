import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';

import {
  Button,
  Box,
  Checkbox,
  TextField,
  CircularProgress,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import * as ClientsApi from '../../../api/restaurants/clients';

import './ReservationsCreateFormOne.scss';

import SnackbarAlert from '../../../components/SnackbarAlert';

import emailRegex from '../../../utils/emailRegex';
import emojiRegex from '../../../utils/emojiRegex';

const ReservationsCreateFormOne = ({ nextStep, stepOneValues }) => {
  const [isFormTouched, setFormIsTouched] = useState(false);
  const [validateFields, setValidateFields] = useState(false);
  const [fields, setFields] = useState({
    email: '',
    name: '',
    middleName: '',
    lastName: '',
    phone: '',
    comments: '',
    categories: null,
    tableNumber: '',
  });
  const [validations, setValidations] = useState({
    email: false,
    // name: false,
    // middleName: false,
    // // lastName: false,
    phone: false,
    // categories: false,
    // comments: true,
  });

  const [status, setStatus] = useState({
    error: null,
    isLoading: false,
    isValid: false,
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const catalogCategory = JSON.parse(localStorage.getItem('catalogCategory'));
  const prepareCategories = () => {
    const categoriesList = {};
    catalogCategory.forEach((element) => {
      categoriesList[element.name] = false;
    });
    return categoriesList;
  };

  const [categories, setCategories] = useState(prepareCategories);
  const isEmailValid = (validations.email) && isFormTouched;
  const isPhoneValid = (validations.phone) && isFormTouched;
  const [hasCheckedEmail, setHasCheckedEmail] = useState(false);

  const [emailExists, setEmailExists] = useState(false);

  useEffect(() => {
    if (fields.email.length > 0 || fields.phone.length > 0) {
      setFormIsTouched(true);
    }
    if (hasCheckedEmail && isFormTouched) {
      setValidateFields(true);
    }
  }, [fields, hasCheckedEmail, isFormTouched]);

  const areFieldsValid = () => (
    Object.values(validations).every((item) => item)
  );

  const handleValidations = (field, value) => {
    const mapValidations = {
      email: (email) => emailRegex.test(email),
      name: (name) => name.length >= 1 && name.length <= 30,
      middleName: (middleName) => middleName.length >= 1 && middleName.length <= 30,
      lastName: () => true,
      tableNumber: () => true,
      phone: () => true,
      comments: (comments) => comments.length < 250,
      // categories: (listCategories) => listCategories.length > 0,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

  const updateFields = (field, value) => {
    console.log(field, value);
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...fields,
    };
    newFields[field] = cleanString;
    setFields(newFields);
    handleValidations(field, cleanString);
  };

  const updateCategoryField = (field, value) => {
    const newFields = {
      ...fields,
    };
    newFields[field] = value;
    setFields(newFields);
    // handleValidations(field, value);
  };

  const getCategoriesSelected = (cats) => {
    const categoriesNames = Object.keys(cats);
    const selectedCategories = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < categoriesNames.length; index++) {
      const element = cats[categoriesNames[index]];
      if (element) {
        // eslint-disable-next-line no-self-assign
        selectedCategories.push(categoriesNames[index]);
      }
    }
    return selectedCategories;
  };

  const handleCategoriesSelection = (event) => {
    setCategories({ ...categories, [event.target.name]: event.target.checked });
    updateCategoryField('categories', getCategoriesSelected({ ...categories, [event.target.name]: event.target.checked }));
  };

  const setDataIfEmailExists = (emailData) => {
    console.log('emailData');
    console.log(emailData);
    const userPhone = emailData.cellPhone;
    const userCategories = emailData.categories;
    const categoriesValues = categories;
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < userCategories.length; index++) {
      const element = userCategories[index].name;
      console.log(element);
      categoriesValues[element] = userCategories[index].status === 1;
    }

    setFields({
      email: emailData.email,
      name: emailData.firstName,
      middleName: emailData.middleName,
      lastName: emailData.lastName !== undefined ? emailData.lastName : '',
      phone: userPhone ? userPhone.replace(/\D/g, '') : '',
      categories: emailData.categories,
      comments: emailData.comments !== undefined ? emailData.comments : '',
      clientId: emailData.id,
    });
    setEmailExists(true);
    setCategories(categoriesValues);
    setValidations({
      email: true,
      name: true,
      middleName: true,
      lastName: true,
      phone: true,
      // comments: true,
      // categories: true,
    });
  };

  const removeDataIfEmailNotExists = () => {
    setCategories(prepareCategories);
    setFields({
      ...fields,
      name: '',
      middleName: '',
      lastName: '',
      categories: null,
      comments: '',
    });
    setEmailExists(false);
    setValidations({
      email: true,
      name: false,
      middleName: true,
      lastName: true,
      phone: true,
      // comments: true,
      // categories: false,
    });
  };

  const handleEmailSearch = async () => {
    console.log(isEmailValid ? fields.email : fields.phone);
    if ((isEmailValid || isPhoneValid) && !status.isLoading) {
      setStatus({
        ...status,
        isLoading: true,
        error: null,
      });

      try {
        setStatus({
          ...status,
          isLoading: true,
        });
        const {
          success, data, message, isStaff,
        } = await ClientsApi.searchClientByEmailOrPhone((
          isEmailValid ? fields.email : fields.phone));
        if (success === 0 && !isStaff) {
          setStatus({
            ...status,
            isLoading: false,
            error: true,
            message,
            success: false,
          });
          removeDataIfEmailNotExists();
          setHasCheckedEmail(true);
        }
        if (success === 1) {
          setStatus({
            ...status,
            isLoading: false,
            error: false,
            message: data,
            success: false,
          });
          setDataIfEmailExists(data);
          setHasCheckedEmail(true);
        }
        if (success === 0 && isStaff) {
          setStatus({
            ...status,
            isLoading: false,
            error: true,
            message,
            success: false,
          });
          removeDataIfEmailNotExists();
          setHasCheckedEmail(false);
          setSnackbar({
            open: true,
            severity: 'error',
            text: message,
          });
        }
        // // setStatus({
        // //   ...status,
        // //   isLoading: false,
        // //   error: message,
        // // });
      } catch (error) {
        console.log(error);
        setStatus({
          ...status,
          isLoading: false,
          error: false,
          message: null,
          success: false,
        });
        removeDataIfEmailNotExists();
        setHasCheckedEmail(true);
      }
    }
  };

  const checkIfEmailChanges = (value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    if (hasCheckedEmail && fields.email !== cleanString) {
      removeDataIfEmailNotExists();
      setHasCheckedEmail(false);
    }
    updateFields('email', cleanString);
  };
  const checkIfPhoneChanges = (value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    if (hasCheckedEmail && fields.phone !== cleanString) {
      removeDataIfEmailNotExists();
      setHasCheckedEmail(false);
    }
    updateFields('phone', cleanString);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fields.comments === ' ') {
      const fieldsNoComments = fields;
      delete fieldsNoComments.comments;
      stepOneValues(fieldsNoComments);
    } else {
      stepOneValues({ ...fields });
    }
    nextStep(2);
  };

  return (
    <Box
      component="div"
      className="mt-5"
    >
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <div className="w-100">
        <span className="disclaimer">
          Antes de continuar, por favor proporcione el correo electrónico o número de teléfono.
        </span>
      </div>
      <div className="w100">
        <TextField
          className="w100"
          error={!isFormTouched ? false : !validations.email}
          id="email"
          label="Correo electrónico*"
          onChange={(e) => checkIfEmailChanges(e.target.value)}
          value={fields.email}
        />
      </div>

      <div className="w100 marginTop20">
        <TextField
          className="w100"
          error={!isFormTouched ? false : !validations.phone}
          id="phone"
          label="Teléfono*"
          onChange={(e) => checkIfPhoneChanges(e.target.value)}
          value={fields.phone}

        />
      </div>
      <div className="w100">
        <Button
          className="resaltado"
          variant="contained"
          color="secondary"
          type="button"
          disabled={!isEmailValid && !isPhoneValid}
          onClick={() => { handleEmailSearch(); }}
        >
          Buscar Cliente
        </Button>
      </div>
      <form className="w100 dFlex flex-column mb-4 reservationsCreateForm" autoComplete="off">
        <div className="w100">
          <TextField
            className="w100"
            error={!validateFields ? false : !validations.name}
            id="name"
            label="Nombre*"
            onChange={(e) => updateFields('name', e.target.value)}
            value={fields.name}
            disabled={emailExists || !hasCheckedEmail}
          />
        </div>
        <div className="reservationsCreateForm__lastNames-group mt-1">
          <TextField
            error={!validateFields ? false : !validations.middleName}
            id="middleName"
            label="Primer Apellido*"
            onChange={(e) => updateFields('middleName', e.target.value)}
            value={fields.middleName}
            disabled={emailExists || !hasCheckedEmail}
          />
          <TextField
            id="lastName"
            label="Segundo Apellido*"
            onChange={(e) => updateFields('lastName', e.target.value)}
            value={fields.lastName}
            disabled={emailExists || !hasCheckedEmail}
          // error={!isFormTouched ? false : !validations.lastName}
          />
        </div>
        <div className="w100 mt-1">
          <TextField
            className="w100"
            id="comments"
            label="Comentarios"
            onChange={(e) => updateFields('comments', e.target.value)}
            value={fields.comments}
            disabled={!hasCheckedEmail}
          />
        </div>
        <div className="w100 mt-1">
          <FormControl component="fieldset">
            <FormLabel component="legend">Categoría</FormLabel>
            <FormGroup className="dFlex flex-row">
              {catalogCategory.map((category) => (
                <FormControlLabel
                  key={uuid()}
                  label={(category.name === 'Blacklist') ? 'No deseado' : category.name}
                  control={(
                    <Checkbox
                      checked={categories[category.name]}
                      name={category.name}
                      disableRipple
                      disabled={emailExists || !hasCheckedEmail}
                      color="primary"
                      onChange={(e) => { handleCategoriesSelection(e); }}
                    />
                  )}
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
        <div className="w100 mt-1">
          <TextField
            className="w100"
            id="tableNumber"
            label="Mesa"
            onChange={(e) => updateFields('tableNumber', e.target.value)}
            value={fields.tableNumber}
            disabled={!hasCheckedEmail}
          />
        </div>
        <Box
          component="div"
          className="mt-3 dFlex flex-column align-center justify-center"
        >
          {status.isLoading && (
            <CircularProgress color="primary" className="mb-2" />
          )}
          {/* {status.error === true && (
          <Box
            component="div"
            className="mt-2 dFlex flex-column align-center justify-center color-error"
          >
            {status.message}
          </Box>
          )} */}
          <div className="reservationsCreateForm__button-group">
            <Button
              variant="contained"
              color="secondary"
              type="button"
              onClick={() => { navigate('/reservaciones'); }}
            >
              <span className="underline">
                CANCELAR
              </span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="button"
              disabled={!areFieldsValid() || (!hasCheckedEmail)}
              onClick={(e) => { handleSubmit(e); }}
            >
              SIGUIENTE
            </Button>
          </div>
        </Box>
      </form>
    </Box>
  );
};

ReservationsCreateFormOne.propTypes = {
  nextStep: PropTypes.func.isRequired,
  stepOneValues: PropTypes.func.isRequired,
};
export default ReservationsCreateFormOne;
