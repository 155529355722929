import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { navigate } from '@reach/router';
// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { capitalize } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import ClientsContext from '../../layouts/Clients/ClientsContext/ClientsContext';
import IconPeople from '../../images/default.jpeg';
import './ClientsTable.scss';
import * as ClientsApi from '../../api/restaurants/clients';

const headCells = [{
  key: '1',
  id: 'fullName',
  label: 'FOTO',
  disablePadding: false,
  sortable: true,
},
{
  key: '2',
  id: 'numberEmail',
  label: 'CONTACTO',
  disablePadding: false,
  sortable: true,
},
{
  key: '3',
  id: 'fullName',
  label: 'Cliente',
  disablePadding: false,
  sortable: true,
}, {
  key: '4',
  id: 'visits',
  label: 'Número de visitas',
  disablePadding: false,
  sortable: true,
},
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={uuid()}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
            {!headCell.sortable && (
              <div>
                {headCell.label}
              </div>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    borderBottom: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const currentCallConfig = () => {
  const retrieveClientsConfig = localStorage.getItem('ClientsConfig');
  const config = {
    lastPage: retrieveClientsConfig !== null
      ? JSON.parse(retrieveClientsConfig).lastPage : 1,
    currentPage: 1,
    perPage: retrieveClientsConfig !== null
      ? Number(JSON.parse(retrieveClientsConfig).perPage)
      : 5,
    total: retrieveClientsConfig !== null ? JSON.parse(retrieveClientsConfig).total : 0,
    from: retrieveClientsConfig !== null ? JSON.parse(retrieveClientsConfig).from : 1,
    to: retrieveClientsConfig !== null ? JSON.parse(retrieveClientsConfig).to : 5,
    options: [5, 25, 100],
  };
  return config;
};

const EnhancedTable = () => {
  const classes = useStyles();
  const { filteredClients, typeOfClient } = useContext(ClientsContext);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('firstName');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    const fetchClients = async () => {
      const newConfig = currentCallConfig();
      const newPage = page === 0 ? 1 : page;
      try {
        const response = await ClientsApi.showAllClientsPages('general-public', newConfig, newPage);
        setTotalPages(response.data.total);
      } catch (error) {
        console.log(error);
      }
    };

    fetchClients();
  }, [page]);
  // const [pagination] = useState(apiCallConfig);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    localStorage.setItem('ReservationsConfigSortCategory', property);
    localStorage.setItem('ReservationsConfigSortDirection', isAsc ? 'desc' : 'asc');
  };
  const renderTable = () => {
    const sortedClients = stableSort(filteredClients, getComparator(order, orderBy));
    const emptyRows = rowsPerPage
    - Math.min(rowsPerPage, sortedClients.length - page * rowsPerPage);
    return (
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={0}>
          <TableContainer>
            <Table
              className={`${classes.table} clients-table`}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={filteredClients.length}
              />
              <TableBody>
                {(rowsPerPage > 0
                  ? sortedClients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : sortedClients
                ).map((client) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={uuid()}
                  >
                    <TableCell key={uuid()} align="left" className="row-name">
                      <div className="content">
                        <div>
                          <button type="button" tabIndex={-1} key={uuid()} className="photocontainer" width="100px">
                            {(client.image === null || client.image === '') ? (
                              <>
                                <img src={IconPeople} alt="" width="100%" />
                              </>
                            ) : (
                              <>
                                <img src={client.image} alt="" width="100%" />
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell key={uuid()} align="left">
                      <div>
                        {client.phone}
                        <br />
                        {client.email}
                      </div>
                    </TableCell>
                    <TableCell key={uuid()} align="left" className="row-name">
                      <div className="content">
                        {client.image !== null ? (
                          <div style={{ width: '50px', height: '50px', display: 'none' }}>
                            <svg viewBox="50 50 50px 50px" width="50" height="50">
                              <defs>
                                <mask id="mask">
                                  <circle fill="#FFFFFF" cx="50%" cy="45%" r="12.2" />
                                  <rect fill="#ffffff" x="26%" y="45%" width="24.15" height="21" />
                                </mask>
                              </defs>
                              <image mask="url(#mask)" xlinkHref={`${client.image}`} width="50" height="50" style={{ opacity: 0 }} />
                            </svg>
                          </div>
                        ) : (
                          <div style={{ width: '50px', height: '50px', display: 'none' }}>
                            <svg viewBox="50 50 50px 50px" width="50" height="50">
                              <defs>
                                <mask id="mask">
                                  <circle fill="#FFFFFF" cx="50%" cy="45%" r="12.2" />
                                  <rect fill="#ffffff" x="26%" y="45%" width="24.15" height="21" />
                                </mask>
                              </defs>
                              <image mask="url(#mask)" xlinkHref={IconPeople} width="50" height="50" border="1px solid black" style={{ opacity: 0 }} />
                            </svg>
                          </div>
                        )}
                        <button
                          className="clients-table__name-button capitalized"
                          type="button"
                          onClick={() => navigate(`/cliente/${typeOfClient}/${client.id}`)}
                        >
                          {(client.firstName != null) && capitalize(client.firstName.toLowerCase())}
                          {' '}
                          {(client.middleName != null)
                            && capitalize(client.middleName.toLowerCase())}
                          {' '}
                          {(client.lastName != null) && capitalize(client.lastName.toLowerCase())}
                        </button>
                      </div>
                    </TableCell>
                    <TableCell key={uuid()} align="left">{client.visits}</TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalPages}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </Paper>
      </div>
    );
  };

  return (
    <>
      {renderTable()}
    </>
  );
};
export default EnhancedTable;
