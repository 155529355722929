import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import StarIcon from '@material-ui/icons/Star';
import './TheMenuOptions.scss';
import { navigate } from '@reach/router';
import TokenManager from '../../api/utils/TokenManager';
import DialogCreateRestaurant from '../DialogCreateRestaurant';
import { useAuth } from '../../context/auth-context';

const ITEM_HEIGHT = 30;

const TheMenuOptions = ({ options, color }) => {
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  async function cambiarcuenta(email, name, eid, password) {
    try {
      const response = await auth.login({ email, password, checkbox: true });
      console.log(response);
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  }
  const restaurantName = new TokenManager('restaurant_name').getToken();
  const extras = JSON.parse(localStorage.getItem('extras'));
  const handleClose = () => {
    setAnchorEl(null);
  };

  const manageClick = (action) => {
    handleClose();
    action();
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const [createRestaurantDialog, setCreateRestaurantDialog] = useState(false);
  const openCreateRestaurantDialog = () => {
    setCreateRestaurantDialog(true);
  };
  const crearCuenta = () => {
    handleClose2();
    openCreateRestaurantDialog();
  };

  const closeCreateRestaurantDialog = () => {
    setCreateRestaurantDialog(false);
  };
  return (
    <>
      <DialogCreateRestaurant
        isOpen={createRestaurantDialog}
        onNo={closeCreateRestaurantDialog}
      />
      <div className="menu-changePassword-options">
        <span className="restaurantName">{restaurantName}</span>
        <IconButton
          aria-label="more"
          aria-controls="menu2"
          aria-haspopup="true"
          color={color}
          onClick={handleClick2}
          className="menu-changePassword__button"
        >
          <StarIcon />
        </IconButton>
        <Menu
          id="menu2"
          anchorEl={anchorEl2}
          open={open2}
          onClose={handleClose2}
          PaperProps={{
            style: {
              border: '1px solid #000',
              boxShadow: 'none',
              maxHeight: ITEM_HEIGHT * 10,
              width: '15rem',
            },
          }}
        >
          <MenuItem className="menu-changePassword-options__menu-item topRestaurantName">
            <div className="dflex">
              <span>
                {restaurantName}
              </span>
            </div>
          </MenuItem>
          <Divider />
          {extras !== null && extras !== undefined
            && extras.map((row) => (
              <>
                {row.name !== restaurantName && row.password !== '' && row.password !== null && (
                  <MenuItem onClick={() => cambiarcuenta(row.email, row.name, row.eid, row.password)} className="menu-changePassword-options__menu-item topRestaurantName" key={row.id}>
                    {row.name}
                  </MenuItem>
                )}
              </>
            ))}
          <MenuItem className="menu-changePassword-options__menu-item topRestaurantName" onClick={() => crearCuenta()}>
            <b>Crear Cuenta</b>
          </MenuItem>
          <Divider />
          <div>
            <p style={{ padding: '10px' }}>
              Sí no puedes ver tu cuenta principal en la lista, contáctanos
              para actualizar tu información de la cuenta.
            </p>
          </div>
        </Menu>
        <IconButton
          aria-label="more"
          aria-controls="menu"
          aria-haspopup="true"
          color={color}
          onClick={handleClick}
          className="menu-changePassword__button"
        >
          <AccountCircleIcon />
        </IconButton>
        <Menu
          id="menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              border: '1px solid #000',
              boxShadow: 'none',
              maxHeight: ITEM_HEIGHT * 4,
              width: '15rem',
            },
          }}
        >
          {options.map((option) => (
            <MenuItem className="menu-changePassword-options__menu-item" key={option.key} onClick={option.action ? () => manageClick(option.action) : handleClose}>
              {option.string}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </>
  );
};

TheMenuOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    string: PropTypes.string,
    action: PropTypes.func,
  })).isRequired,
  color: PropTypes.string.isRequired,
};

export default TheMenuOptions;
