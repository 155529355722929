import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import {
  Box,
  Grid,
  Container,
  // IconButton,
  // Hidden,
  Typography,
} from '@material-ui/core/';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import TableErrors from '../../components/TableErrors';
import SnackbarAlert from '../../components/SnackbarAlert';

import * as ReservationsApi from '../../api/restaurants/reservations';

import isTruthy from '../../utils/isTruthy';

import prepareReservationEditData from '../../utils/prepareReservationEditData';
import TheMenu from '../../components/TheMenu';
import ReservationsEditFormOne from './ReservationsEditFormOne';
import ReservationsEditFormTwo from './ReservationsEditFormTwo';

import './ReservationsEdit.scss';

const ReservationsEdit = ({ id, table, date }) => {
  const [state, setState] = useState({
    loading: true,
    error: null,
    message: null,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const [currentEditStep, setCurrentEditStep] = useState(1);
  const [currentReservationData, setCurrentReservationData] = useState(null);
  const [newReservationData, setNewReservationData] = useState(null);
  const [numberPeople, setNumberPeople] = useState();
  const [avaliabilityData, setAvaliabilityData] = useState({});
  const [selectedOption, setSelectedOption] = useState();
  console.log(newReservationData, numberPeople);

  useEffect(() => {
    const fetchReservation = async () => {
      try {
        const { success, message, data } = await ReservationsApi
          .getOneReservation(id);
        if (isTruthy(success)) {
          setCurrentReservationData(prepareReservationEditData(data));
          setNumberPeople(data.numberPeople);
          setState({
            loading: false,
            error: null,
            message: null,
          });
        } else {
          setState({
            loading: false, error: message, message: null,

          });
        }
      } catch (error) {
        console.log(error);
        setState({
          loading: false, error, message: null,

        });
      }
    };

    fetchReservation();
  }, [id]);

  const updateReservation = async (selectedOptionData) => {
    console.log(selectedOption);
    const ReservationData = {
      number_people: numberPeople,
      date: `${selectedOptionData.date} ${selectedOptionData.hour}`,
      table_type_eid: selectedOptionData.tableId,
      comments: newReservationData.comments,
      table_number: newReservationData.tableNumber,
      type: newReservationData.type,
    };
    try {
      const { success, data, message } = await ReservationsApi
        .editReservation(id, ReservationData);
      if (isTruthy(success)) {
        setState({
          loading: false,
          error: false,
          message: data,
        });
        setSnackbar({
          open: true,
          severity: 'success',
          text: message === 'ok' ? 'La reservación se actualizó de forma correcta' : message,
        });
        setTimeout(() => {
          navigate('/reservaciones');
        }, 2000);
      } else {
        setState({
          loading: false,
          error: true,
          message,
        });
      }
    } catch (error) {
      console.log(error);
      setState({
        loading: false,
        error: true,
        message: error,
      });
    }
  };

  const renderLoading = () => (
    <div>Loading...</div>
  );
  const renderFormOne = () => (
    <Container className={currentEditStep === 1 ? '' : 'dNone'} fixed height="100">
      <Grid container height="100" spacing={2}>
        <Grid item xs={12} md={4} className="reservations-create__left-grid dFlex flex-column align-start justify-start">
          <Box
            component="div"
            className="w100"
          >
            <div className="mb-1">
              <Typography variant="h5">
                FECHA Y HORA
              </Typography>
            </div>
            <Typography variant="body1">
              Completa los datos solicitados para encontrar las opciones
              más cercanas a lo que busca el comensal.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} className="reservations-create__right-grid dFlex flex-column align-start justify-start">
          <Box
            component="div"
            className="w100"
          >
            <ReservationsEditFormOne
              currentReservationData={currentReservationData}
              setNewReservationData={setNewReservationData}
              setNumberPeople={setNumberPeople}
              setAvaliabilityData={setAvaliabilityData}
              nextStep={setCurrentEditStep}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
  const renderFormTwo = () => (

    <Container fixed height="100">
      <Grid container height="100" spacing={2}>
        <Grid item xs={12} md={4} className="reservations-create__left-grid dFlex flex-column align-start justify-start">
          <Box
            component="div"
            className="w100"
          >
            <div className="mb-1">
              <Typography variant="h5">
                ELIGE EL SITIO
              </Typography>
            </div>
            <Typography variant="body1">
              Elige los días y el horario en que estarás brindando atención
              a los comensales.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} className="reservations-create__right-grid dFlex flex-column align-start justify-start">
          <Box
            component="div"
            className="w100"
          >
            <Typography variant="body1">
              Conforme a la fecha, hora y número de personas seleccionadas,
              estos son los horarios y zonas disponibles
            </Typography>
            <ReservationsEditFormTwo
              avaliabilityData={avaliabilityData}
              nextStep={updateReservation}
              prevStep={setCurrentEditStep}
              numberPeople={numberPeople}
              setSelectedOption={setSelectedOption}
              table={table}
              previousDateRaw={date}
              currentDateRaw={newReservationData.dateRaw}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );

  const renderError = () => {
    const errorData = {
      message: state.message,
      action: () => { navigate('/reservaciones'); },
      buttonText: 'Regresar',
      image: 'broken',
    };
    return (
      <TableErrors data={errorData} action={() => navigate('/reservaciones')} />
    );
  };

  return (
    <>
      <TheMenu setIsCalendarOpen={() => { }} />
      <Container
        className="reservations-create layout px-0"
      >
        <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
        <Box
          component="div"
          className="reservations-create__header pr-1 pl-1 dFlex flex-row align-center justify-between"
        >
          <div className="dFlex flex-row align-center justify-start">
            {/* <IconButton
              onClick={() => { navigate('/reservaciones'); }}
            >
              <ArrowBackIosIcon />
            </IconButton> */}
            <Typography variant="h6" className="reservations-create__title font__commutter">
              EDITAR RESERVACIÓN
            </Typography>
          </div>
        </Box>

        <Box
          component="div"
          className="dFlex flex-column align-center justify-between"
        >
          {state.loading && renderLoading()}
          {!state.loading && state.error && renderError()}
          {!state.loading
            && !state.error
            && renderFormOne()}
          {!state.loading
            && !state.error
            && currentEditStep === 2
            && renderFormTwo()}
        </Box>
      </Container>
    </>
  );
};

ReservationsEdit.propTypes = {
  id: PropTypes.string,
  table: PropTypes.string,
  date: PropTypes.string,
};
ReservationsEdit.defaultProps = {
  id: '',
  table: '',
  date: '',
};

export default ReservationsEdit;
