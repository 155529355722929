import React from 'react';
// eslint-disable-next-line import/named
import { useAuth } from '../auth-context';

const UserContext = React.createContext();

const UserProvider = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <UserContext.Provider value={useAuth().data} {...props} />
);

const useUser = () => React.useContext(UserContext);

export { UserProvider, useUser };
