/* eslint-disable */
import { React, useState } from 'react';
import PropTypes from 'prop-types';
import './DialogBlockDay.scss';
import ReactModal from 'react-modal';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import { format, isPast } from 'date-fns';
import ReportIcon from '@material-ui/icons/Report';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
  Button,
  Box,
  TextField,
  Typography,
} from '@material-ui/core/';
import emojiRegex from '../../utils/emojiRegex';
import emailRegex from '../../utils/emailRegex';
import phoneRegex from '../../utils/phoneRegex';
import timeRegex from '../../utils/timeRegex12H';
import namesRegex from '../../utils/namesRegex';
import * as ReservationsApi from '../../api/restaurants/reservations';
import SnackbarAlert from '../SnackbarAlert';

ReactModal.setAppElement('#root');
const DialogBlockDay = ({
  isOpen, onNo, date, setReload
}) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  const [fields, setFields] = useState({
    email: '',
    name: '',
    phone: '',
    reason: '',
    date: date,
    time: format(new Date(), 'hh:mm a'),
    people: '',
  });
  const [validations, setValidations] = useState({
    email: false,
    name: false,
    date: true,
    time: true,
    people: false,
    phone: false,
    //: false,
    // categories: false,
    // comments: true,
  });
  const areFieldsValid = () => (
    Object.values(validations).every((item) => item)
  );
  const handleValidations = (field, value) => {
    const mapValidations = {
      name: (name) => namesRegex.test(name) && name.length > 3 && name.length < 30,
      email: (email) => emailRegex.test(email),
      phone: (phone) => phoneRegex.test(phone),
      date: (date) => date.length > 0,
      time: (time) => time.length > 0,
      people: (people) => Number(people) > 0,
      reason: (reason) => reason.length < 250,
      // categories: (listCategories) => listCategories.length > 0,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };
  const [selectedTime, setSelectedTime] = useState(moment().utc());
  const handleTimeChange = (time, value) => {
    if (timeRegex.test(value)) {
      const choosenTime = format(time, 'hh:mm a');
      const choosenDate = fields.date;
      const choosenDateTime = `${choosenDate} ${choosenTime}`;
      if (isPast(new Date(choosenDateTime))) {
        alert('no puedes escoger una hora antes de la hora actual');
      } else {
        setSelectedTime(time);
        updateFields('time', choosenTime);
      }
    } else {
      setValidations({ ...validations, time: false });
      console.log(time);
    }
  };
  const updateFields = (field, value) => {
    console.log(field, value);
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...fields,
    };
    newFields[field] = cleanString;
    setFields(newFields);
    handleValidations(field, cleanString);
  };
  const cleanUpBeforeClose = () => {
    setFields({
      email: '',
      name: '',
      phone: '',
      reason: '',
      date: date,
      time: format(new Date(), 'hh:mm a'),
      people: '',
    });
    setValidations({
      email: false,
      name: false,
      date: true,
      time: true,
      people: false,
      phone: false,
    });
    setSnackbar({
      open: false,
      severity: 'success',
      text: "Dia Bloqueado",
    });
    setReload(true);
    onNo();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const timet = moment(`1988-01-08 ${fields.time}`, 'YYYY-MM-DD hh:mm a').format('HH:mm');
    const datet = `${format(new Date(date), 'yyyy-MM-dd')} ${timet}`;
    const body = {
      email: fields.email,
      name: fields.name,
      phone: fields.phone,
      reason: fields.reason,
      date: date,
      time: timet,
      people: fields.people,
    };
    try {
      const { success, message, data } = await ReservationsApi
        .saveAllDayReservation(body);
      if (success == 1) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: "DIA BLOQUEADO",
        });
        setTimeout(() => {
          cleanUpBeforeClose();
        }, 1500);
      } else {

      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }

  };
  const [isFormTouched, setFormIsTouched] = useState(false);
  const isEmailValid = (validations.email) && isFormTouched;
  const isPhoneValid = (validations.phone) && isFormTouched;
  const [hasCheckedEmail, setHasCheckedEmail] = useState(false);

  const checkIfEmailChanges = (value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    updateFields('email', cleanString);
  };
  const checkIfPhoneChanges = (value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    updateFields('phone', cleanString);
  };
  return (
    <ReactModal
      isOpen={isOpen}
      className="dialog-base-modal"
      overlayClassName="dialog-base-modal__overlay"
      shouldCloseOnOverlayClick
      onRequestClose={onNo}
    >
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
        <form onSubmit={handleSubmit}>

          <div className="dialog-base-modal__content ">
            <div className="texto-dialogBlockDay">
            No será posible realizar reservas en 
el dia que bloquee. Existe la 
posibilidad de desbloquear el dia y 
volver a realizar una reserva.
            </div>
            <Box>
              <div className="w100">
                <TextField
                  className="w100"
                  error={!validations.name}
                  id="name"
                  label="¿Quién Reserva?*"
                  value={fields.name}
                  onChange={(e) => updateFields('name', e.target.value)}
                />
              </div>
              <div className="w100 marginTop20">
                <TextField
                  className="w100"
                  error={!validations.email}
                  id="email"
                  label="Correo electrónico*"
                  value={fields.email}
                  onChange={(e) => updateFields('email', e.target.value)}
                />
              </div>
              <div className="w100 marginTop20">
                <TextField
                  className="w100"
                  error={!validations.phone}
                  id="phone"
                  label="Teléfono*"
                  onChange={(e) => updateFields('phone', e.target.value)}
                  value={fields.phone}

                />
              </div>
              <div className="w100 marginTop20">
                <TextField
                  className="w100"
                  error={!validations.reason}
                  id="reason"
                  label="Motivo del Evento*"
                  value={fields.reason}
                  onChange={(e) => updateFields('reason', e.target.value)}
                />
              </div>
              <div className="w100 marginTop20">
                <TextField
                  className="w100"
                  error={!validations.people}
                  id="people"
                  label="Número de asistentes"
                  value={fields.people}
                  onChange={(e) => updateFields('people', e.target.value)}
                />
              </div>
         
              <div className="w100 marginTop20">
                <TextField
                  className="w100"
                  error={!validations.date}
                  id="date"
                  label="Fecha de la reserva:"
                  value={date}
                  readOnly={true}

                />
              </div>
              <div className="reservations-alerts__badge dFlex flex-row align-center justify-between">
                <ReportIcon />
                <Typography
                  variant="body2"
                  className="pl-1"
                >
                  <b>Nota:</b> Ir a calendario para modificar la fecha de la reserva. 
                </Typography>
              </div>
              <div className="w100">
                <KeyboardTimePicker
                  ampm
                  margin="normal"
                  id="time-picker"
                  format="hh:mm a"
                  label="¿En qué horario?"
                  value={selectedTime}
                  onChange={handleTimeChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  invalidDateMessage="No es un formato válido"
                  keyboardIcon={<AccessTimeIcon />}
                  cancelLabel="CANCELAR"
                  okLabel="OK"
                />
              </div>
            </Box>
            <div className="flexend">
              <Button
                className="cancelBtn"
                variant="contained"
                color="secondary"
                type="button"
                onClick={onNo}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={handleSubmit}
                disabled={!areFieldsValid()}
              >
                Guardar
              </Button>
            </div>
          </div>
        </form>
      </MuiPickersUtilsProvider>
    </ReactModal>
  );
};
DialogBlockDay.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  date: PropTypes.instanceOf(String).isRequired,
  setReload: PropTypes.func,

  // eslint-disable-next-line react/forbid-prop-types

};
DialogBlockDay.defaultProps = {
  isOpen: false,
  onNo: '',
  setReload: () => { },
};

export default DialogBlockDay;
