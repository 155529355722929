/* eslint-disable */



import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography,
} from '@material-ui/core/';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';

import RestaurantContext from '../../layouts/Restaurant/RestaurantContext/RestaurantContext';

import './RestaurantAutomatic.scss';
import * as RestaurantsApi from '../../api/restaurants/restaurants';
import isTruthy from '../../utils/isTruthy';
import SnackbarAlert from '../SnackbarAlert';
import NumberIncrements from '../NumberIncrements';
import DialogCreateZone from '../DialogCreateZone';

const RestaurantAutomatic = ({ setReload, idData }) => {
  /*  const [acceptAutomaticReservation, setAcceptAutomaticReservation] = useState(false);
  const [automaticReservationsData, setAutomaticReservationsData] = useState(); */
  const { acceptAutomaticReservation, automaticReservationsData } = useContext(RestaurantContext);
  const [changedData, setChangedData] = useState();
  const [totalCapacity, setTotalCapacity] = useState(0);
  const [isFieldActive, setIsFieldActive] = useState();
  const [isFieldExclusive, setIsFieldExclusive] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [hasEdit, setHasEdit] = useState(false);
  const [reload, setLocalReload] = useState(false);
  const [createZoneDialog, setCreateZoneDialog] = useState(false);
  const [isautomatic, setIsAutomatic] = useState(false);
  //automatica data initialize with capacity=1 , exclusive=false
  const [automaticData, setAutomaticData] = useState({ capacity: 0, exclusive: false });

  const openCreateZoneDialog = () => {
    setCreateZoneDialog(true);
  };
  const closeCreateZoneDialog = () => {
    setCreateZoneDialog(false);
  };
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
    }, 2000);
  };

  useEffect(() => {
    setAutomaticData({ ...automaticData, capacity: automaticReservationsData });
    if(acceptAutomaticReservation==0){
      setIsAutomatic(false);
    }else{
      setIsAutomatic(true);
    }


    const prepareCurrentData = (catalog) => {
      const preparedData = [];
      const isActiveField = {};
      const isExclusiveField = {};
      setChangedData(preparedData);
      setLocalReload(false);
    };

    const fetchCatalogs = async () => {
      try {
        const body = {
          id: idData,
        };
        const { success, data } = await RestaurantsApi
          .tabletypes(body);
        console.log('response');
        console.log(data);
        console.log(idData);

        if (isTruthy(success)) {
          prepareCurrentData(data);
        }
        setIsLoading(false);
      } catch (error) {
        alert(error);
        setIsLoading(false);
      }
    };
    //

    fetchCatalogs();

  }, [reload, idData, acceptAutomaticReservation, automaticReservationsData]);

  const changeCheckbox = () => {
    setIsAutomatic(!isautomatic);

  };

  const changeCheckbox2 = (item) => {
    setIsFieldExclusive({ ...isFieldExclusive, [item.name]: !isFieldExclusive[item.name] });
  };

  const updateAutomatic = (data) => {
    console.log(data);
    //setAutomaticData(data);
    setAutomaticData({ ...automaticData, capacity: data.capacity });
    setChangedData(data);
    setHasEdit(true);
  };

  const saveChanges = async () => {
    var visAutomatic = 0;
    var vautomatic = 0;
    if(isautomatic){
        visAutomatic=1;
    }else{
      visAutomatic=0;
    }
    vautomatic = automaticData.capacity;
    try {
      const { success, message } = await RestaurantsApi.editAutomatic({ vautomatic,visAutomatic });
      if (success) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
        setTimeout(() => {
          setReload(true);
        }, 1000);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };

  const discardChanges = () => {
    setLocalReload(true);
    setHasEdit(false);
  };

  const renderView = () => (
    <>
      <div className="RestaurantAutomatic-form-container mr-2">
        <div style={{ color: 'black', fontFamily: 'roboto', fontSize: '16px' }}>
          <span>
            Esta funcionalidad te permite <b>controlar el número de personas dentro de una reserva de Reserva Ya!</b> que entran
            a la plataforma siendo automáticamente aceptadas. Es decir, las reservas que no necesitan ser contestadas por
            el restaurante (aceptadas o rechazadas). Generalmente los restaurantes suelen pedir un depósito para las
            reservas grandes (ejemplo reservas de 20 personas) y por ello prefieren que dichas reservas no se acepten
            automáticamente. Sin embargo, las reservas pequeñas (ejemplo 2 personas) no necesitan dicha revisión.
            <br />
          </span>
        </div>
        <div className="RestaurantAutomatic__form">

          <div className="RestaurantAutomatic__form__checkbox">
            <div>
              <FormControlLabel
                key={uuid()}
                label="Activar funcionalidad"
                control={(
                  <Checkbox
                    checked={isautomatic}
                    name={'isautomatic'}
                    disableRipple
                    color="primary"
                    onChange={() => { changeCheckbox(); }}
                  />
                )}
              />
            </div>
            <div key={uuid()} className="restaurantCapacity-area-option">
              <div  className="textoContador" style={{ color: 'gray', fontFamily: 'roboto', padding: '10px' }} >
                A partir de ese número las reservas ya no se aceptan autom&aacute;ticamente, sino
                que el restaurante la contesta manualmente dándole clic a la opción
                “aceptar” o ”rechazar” y el cliente recibirá un email automático
                informándole si su reserva ha sido aceptada o lamentablemente no hay
                disponibilidad.
              </div>
              <div>
                <NumberIncrements
                  data={automaticData}
                  setNumber={updateAutomatic}
                  isActive={isautomatic}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="RestaurantAutomatic__cta-container">
          <Button
            variant="contained"
            color="secondary"
            type="button"
            onClick={() => { discardChanges(); }}
          >
            CANCELAR
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => { saveChanges(); }}
    
          >
            GUARDAR
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <>
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <Box
        component="div"
        className="dFlex flex-column w100 mb-5"
      >
        <Grid container>
          <Grid item xs={12} md={4} className="RestaurantAutomatic-left-grid">
            <div className="pr-3 ml-1">
              <Typography variant="h6">
                Reservas automáticas
              </Typography>
              <div>
                <Typography variant="body1">
                  Elige si deseas que las reservas se acepten automáticamente o no.
                </Typography>
              </div>
            </div>

          </Grid>
          <Grid item xs={12} md={8} className="RestaurantAutomatic-right-grid">
            {changedData && !isLoading && renderView()}
          </Grid>
        </Grid>
      </Box>
      <DialogCreateZone
        isOpen={createZoneDialog}
        onNo={closeCreateZoneDialog}
        setReload={setReload}
      />
    </>
  );
};

RestaurantAutomatic.propTypes = {
  setReload: PropTypes.func,
  idData: PropTypes.string,
};

RestaurantAutomatic.defaultProps = {
  setReload: () => { },
  idData: '',
};

export default RestaurantAutomatic;
