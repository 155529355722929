import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  InputLabel,
  InputAdornment,
  Input,
  IconButton,
  Select,
  MenuItem,
  Typography,
  TextField,
} from '@material-ui/core/';
import { v4 as uuid } from 'uuid';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TableErrors from '../../components/TableErrors';
import SnackbarAlert from '../../components/SnackbarAlert';
import * as CatalogsApi from '../../api/catalogs';
import * as StaffApi from '../../api/restaurants/staff';

import isTruthy from '../../utils/isTruthy';
import createPassword from '../../utils/createPassword';
import passwordRegex from '../../utils/passwordRegex';
import TheMenu from '../../components/TheMenu';

import './StaffEdit.scss';

import TokenManager from '../../api/utils/TokenManager';
import emailRegex from '../../utils/emailRegex';
import emojiRegex from '../../utils/emojiRegex';

const StaffEdit = ({ id }) => {
  const loggedEmail = new TokenManager('email').getToken();
  const [state, setState] = useState({
    loading: true,
    error: false,
    success: false,
    message: '',
    staffData: {},
  });
  const [fields, setFields] = useState({
    email: '',
    password: '',
    role_id: '',
    showPassword: true,
  });
  const [validations, setValidations] = useState({
    email: true,
    password: true,
    role_id: true,
  });
  const [roles, setRoles] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const [isEdited, setIsEdited] = useState(false);
  const [hasEditedPassword, setHasEditedPassword] = useState(false);

  const areFieldsValid = () => {
    if (hasEditedPassword) {
      return validations.email && validations.role_id && validations.password;
    }
    return validations.email && validations.role_id;
  };

  useEffect(() => {
    setState({
      loading: true,
      error: false,
      success: false,
      message: '',
      staffData: {},
    });
    const fetchStaffData = async () => {
      try {
        const { success, data } = await StaffApi
          .showOneStaff(id);
        if (isTruthy(success)) {
          setFields({
            email: data.email,
            password: ' ',
            role_id: data.role,
            showPassword: true,
          });
          setState({
            loading: false,
            error: false,
            success: true,
            message: '',
            staffData: data,
          });
        } else {
          setState({
            loading: false, error: true, success: false, message: data.error, staffData: {},

          });
        }
      } catch (error) {
        console.log(error);
        setState({
          loading: false, error: true, success: false, message: error.message, staffData: {},
        });
      }
    };
    const fetchCatalogs = async () => {
      try {
        const { success, data } = await CatalogsApi
          .catalogRole();
        if (isTruthy(success)) {
          const filteredRoles = [];
          data.forEach((element) => {
            if (element.name !== 'Administrador') {
              filteredRoles.push(element);
            }
          });
          setRoles(filteredRoles);
        }
      } catch (error) {
        alert(error);
      }
    };
    fetchCatalogs();
    fetchStaffData();
  }, [id]);

  const handleClickShowPassword = () => {
    setFields({ ...fields, showPassword: !fields.showPassword });
  };

  const handleValidations = (field, value) => {
    const mapValidations = {
      email: (email) => emailRegex.test(email)
        && email.length <= 80,
      password: (password) => passwordRegex.test(password)
        && password.length > 5
        && password.length <= 16,
      role_id: (role) => role.length > 2 && role.length < 30,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

  const updateFields = (field, value) => {
    if (value !== state.staffData[field]) {
      setIsEdited(true);
    }
    if (field === 'password') {
      setHasEditedPassword(true);
    }
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...fields,
    };
    newFields[field] = cleanString;
    setFields(newFields);
    handleValidations(field, cleanString);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const getRoleEncryptId = () => {
      let encryptId;
      roles.forEach((element) => {
        if (element.name === fields.role_id) {
          encryptId = element.encryptId;
        }
      });
      return encryptId;
    };
    const body = {
      email: fields.email,
      role_eid: getRoleEncryptId(),
    };
    if (hasEditedPassword) {
      body.password = fields.password.trim();
    }
    try {
      const { success, message } = await StaffApi.editStaff(id, body);
      if (success === 1) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message === 'OK' ? 'Información actualizada con éxito' : message,
        });
        setTimeout(() => {
          navigate('/staff');
        }, 1500);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };

  const renderLoading = () => (
    <div>Loading...</div>
  );

  const renderView = () => (
    <>
      <Grid container>
        <Hidden smDown>
          <Grid item xs={12} md={3} />
        </Hidden>
        <Grid item xs={12} md={6} className="pr-2 pl-2">
          <Box component="div" className="staff-edit-form__container">
            <form className="w100" autoComplete="off" onSubmit={areFieldsValid() && handleSubmit}>
              <div className="staff-edit-form__field-container">
                <TextField
                  className="w100"
                  error={!validations.email}
                  id="email"
                  label="Correo electrónico"
                  onChange={(e) => updateFields('email', e.target.value)}
                  value={fields.email}
                  length="80"
                  disabled={loggedEmail === fields.email}
                />
              </div>
              <div className="staff-edit-form__field-container flex-column">
                <InputLabel
                  htmlFor="password"
                >
                  Contraseña
                </InputLabel>
                <Input
                  className="w100"
                  error={!validations.password}
                  id="password"
                  label="Contraseña"
                  length="16"
                  onChange={(e) => updateFields('password', e.target.value)}
                  type={fields.showPassword ? 'text' : 'password'}
                  value={fields.password}
                  endAdornment={
                    (
                      <>
                        <InputAdornment position="end">
                          <Hidden xsUp>
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {fields.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </Hidden>
                          <Button
                            variant="primary"
                            color="secondary"
                            type="button"
                            onClick={() => updateFields('password', createPassword())}
                          >
                            Generar
                          </Button>
                        </InputAdornment>
                      </>
                    )
                  }
                />
              </div>
              <div className="staff-edit-form__field-container flex-column w100">
                <InputLabel id="demo-simple-select-label">Rol</InputLabel>
                <Select
                  className="w100"
                  id="role-id-select"
                  value={fields.role_id}
                  onChange={(e) => updateFields('role_id', e.target.value)}
                  disabled={loggedEmail === fields.email}
                >
                  {
                    // eslint-disable-next-line no-confusing-arrow
                    roles.map((role) => role.name !== 'Usuario' ? <MenuItem key={uuid()} value={role.name}>{role.name}</MenuItem> : null)
                  }
                </Select>
              </div>
              <div className="staff-edit-form__buttons-group mt-4">
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  onClick={() => { navigate('/staff'); }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={
                    !isEdited || !areFieldsValid()
                  }
                >
                  Editar
                </Button>
              </div>
            </form>
          </Box>
        </Grid>
      </Grid>
    </>
  );

  const renderError = () => {
    const errorData = {
      message: state.message,
      action: () => { navigate('/staff'); },
      buttonText: 'Regresar',
      image: 'broken',
    };
    return (
      <TableErrors data={errorData} action={() => navigate('/staff')} />
    );
  };
  return (
    <>
      <TheMenu setIsCalendarOpen={() => { }} />
      <Container
        className="staff-edit layout px-0"
      >
        <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
        <Box
          component="div"
          className="staff-edit__header pr-1 pl-1 dFlex flex-row align-center justify-between"
        >
          <div className="dFlex flex-row align-center justify-start">
            <Typography variant="h6" className="staff-edit__title font__commutter">
              EDITAR STAFF
            </Typography>
          </div>
        </Box>
        <Box
          component="div"
          className="w100 dFlex flex-column align-center justify-between"
        >
          {state.loading && renderLoading()}
          {!state.loading && state.error && renderError()}
          {!state.loading
            && !state.error
            && renderView()}

        </Box>
      </Container>
    </>
  );
};

StaffEdit.propTypes = {
  id: PropTypes.string,
};
StaffEdit.defaultProps = {
  id: '',
};

export default StaffEdit;
