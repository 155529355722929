import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  TextField,
  CircularProgress,
  Hidden,
} from '@material-ui/core';
import { format, isPast } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import dateRegex from '../../../utils/dateRegex';
import timeRegex from '../../../utils/timeRegex12H';
import getFirstErrorMessage from '../../../utils/getFirstErrorMessage';

import SnackbarAlert from '../../../components/SnackbarAlert';
import * as ReservationsApi from '../../../api/restaurants/reservations';

import './ReservationsCreateFormTwo.scss';

const ReservationsCreateFormTwo = ({ nextStep, setAvaliabilityData, setNumberPeople }) => {
  const [isFormTouched, setFormIsTouched] = useState(false);
  const [fields, setFields] = useState({
    date: format(new Date(), 'yyyy-MM-dd'),
    time: format(new Date(), 'hh:mm a'),
    numberPeople: '',

  });
  const [validations, setValidations] = useState({
    date: true,
    time: true,
    numberPeople: false,
  });

  const [status, setStatus] = useState({
    error: null,
    isLoading: false,
    isValid: false,
  });

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(moment().utc());
  const maxReservationDate = moment().add(1, 'year').format('yyyy-MM-DD');

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  useEffect(() => {
    if (fields.numberPeople.length > 0) {
      setFormIsTouched(true);
    }
  }, [fields]);

  const areFieldsValid = () => (
    Object.values(validations).every((item) => item)
  );

  const handleValidations = (field, value) => {
    const mapValidations = {
      date: (date) => date.length > 0,
      time: (time) => time.length > 0,
      numberPeople: (numberPeople) => Number(numberPeople) > 0,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

  const updateFields = (field, value) => {
    const newFields = {
      ...fields,
    };
    newFields[field] = value;
    setFields(newFields);
    handleValidations(field, value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // if (dateRegex.test(date)) {
    // }
    updateFields('date', format(new Date(date), 'yyyy-MM-dd'));
  };

  const handleTimeChange = (time, value) => {
    console.log(timeRegex.test(value));
    if (timeRegex.test(value)) {
      const choosenTime = format(time, 'hh:mm a');
      const choosenDate = fields.date;
      const choosenDateTime = `${choosenDate} ${choosenTime}`;
      if (isPast(new Date(choosenDateTime))) {
        alert('no puedes escoger una hora antes de la hora actual');
      } else {
        setSelectedTime(time);
        updateFields('time', choosenTime);
      }
    } else {
      setValidations({ ...validations, time: false });
      console.log(time);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (areFieldsValid && !status.isLoading) {
      setStatus({
        ...status,
        isLoading: true,
        error: null,
      });
      const time = moment(`1988-01-08 ${fields.time}`, 'YYYY-MM-DD hh:mm a').format('HH:mm');
      console.log(time);
      const date = `${format(new Date(), 'yyyy-MM-dd')} ${time}`;
      try {
        setStatus({
          ...status,
          isLoading: true,
        });
        const { success, data, message } = await ReservationsApi
          .getAvaliability(date, fields.numberPeople);
        if (success === 0) {
          setStatus({
            ...status,
            isLoading: false,
            error: true,
            message,
            success: false,
          });
          setSnackbar({
            open: true,
            severity: 'error',
            text: getFirstErrorMessage(message),
          });
        }
        if (success === 1) {
          setStatus({
            ...status,
            isLoading: false,
            error: false,
            message: data,
            success: false,
          });
          setAvaliabilityData(data);
          setNumberPeople(fields.numberPeople);
          nextStep(3);
        }
      } catch (error) {
        console.log(error.message);
        setSnackbar({
          open: true,
          severity: 'error',
          text: error.message,
        });
      }
    }
  };

  return (
    <Box
      component="div"
      className="mt-5"
    >
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      {' '}
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
        <form className="w100 dFlex flex-column mb-4 reservationsCreateForm" onSubmit={handleSubmit} autoComplete="off">
          <div className="w100">
            <TextField
              className="w100"
              error={!isFormTouched ? false : !validations.numberPeople}
              id="numberPeople"
              label="¿Cuántas personas asisten?*"
              onChange={(e) => updateFields('numberPeople', e.target.value)}
              value={fields.numberPeople}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="hiden">
            <div className="w100">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="¿Cuándo quieren reservar?"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk
                invalidDateMessage="No es un formato válido"
                maxDate={maxReservationDate}
                maxDateMessage="No puedes escoger una fecha mayor a un año"
                disablePast
              />
            </div>
            <div className="w100">
              <KeyboardTimePicker
                ampm
                margin="normal"
                id="time-picker"
                format="hh:mm a"
                label="¿En qué horario?"
                value={selectedTime}
                onChange={handleTimeChange}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                invalidDateMessage="No es un formato válido"
                keyboardIcon={<AccessTimeIcon />}
                cancelLabel="CANCELAR"
                okLabel="OK"

              />
            </div>
          </div>
          <Box
            component="div"
            className="mt-3 dFlex flex-column align-center justify-center"
          >
            {status.isLoading && (
              <CircularProgress color="primary" className="mb-2" />
            )}
            {/* {status.error === true && (
          <Box
            component="div"
            className="mt-2 dFlex flex-column align-center justify-center color-error"
          >
            {status.message}
          </Box>
          )} */}
            <Hidden xsDown>
              <div className="reservationsCreateForm__button-group">
                <Button
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={() => { navigate('/reservaciones'); }}
                >
                  <span className="underline">
                    CANCELAR
                  </span>
                </Button>
                <div className="dFlex">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!areFieldsValid()}
                  >
                    SIGUIENTE
                  </Button>
                </div>
              </div>
            </Hidden>
            <Hidden smUp>
              <div className="reservationsCreateForm__button-group-mobile mt-1">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!areFieldsValid()}
                  className="w100"
                >
                  SIGUIENTE
                </Button>
                <div className="dFlex justify-between">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={() => { navigate('/reservaciones'); }}
                  >
                    <span className="underline">
                      CANCELAR
                    </span>
                  </Button>
                </div>
              </div>
            </Hidden>
          </Box>
        </form>
      </MuiPickersUtilsProvider>
    </Box>
  );
};

ReservationsCreateFormTwo.propTypes = {
  nextStep: PropTypes.func.isRequired,
  setAvaliabilityData: PropTypes.func.isRequired,
  setNumberPeople: PropTypes.func.isRequired,
};
export default ReservationsCreateFormTwo;
