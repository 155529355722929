/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormGroup,
  FormControlLabel,
  Hidden,
  IconButton,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

import { v4 as uuid } from 'uuid';
import * as ReservationsApi from '../../../api/restaurants/reservations';

import SnackbarAlert from '../../../components/SnackbarAlert';

import './ReservationsCreateFormThree.scss';

const ReservationsCreateFormThree = ({
  nextStep, avaliabilityData, numberPeople, stepOneValues,
}) => {
  const [fields, setFields] = useState({
    selectedOption: '',
  });
  const [validations, setValidations] = useState({
    selectedOption: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoriesList, setCategoriesList] = useState();

  const [status, setStatus] = useState({
    error: null,
    isLoading: false,
    isValid: false,
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  useEffect(() => {
    const localCategoriesCatalogList = JSON.parse(localStorage.getItem('catalogCategory'));
    const ProcesedCategories = [];
    localCategoriesCatalogList.forEach((element) => {
      ProcesedCategories[element.name] = element.encryptId;
    });
    setCategoriesList(ProcesedCategories);
  }, []);

  const prepareScrollCount = () => {
    const count = {};
    avaliabilityData.forEach((element) => {
      count[element.table] = 0;
    });
    return count;
  };
  const prepareAccordeonExpanse = () => {
    const accordionState = {};
    avaliabilityData.forEach((element) => {
      accordionState[element.table] = false;
    });
    return accordionState;
  };

  const [scrollCount, setScrollCount] = useState(prepareScrollCount);
  const [accordeonExpansed, setAccordeonExpansed] = useState(prepareAccordeonExpanse);

  const areFieldsValid = () => (
    Object.values(validations).every((item) => item)
  );

  const handleValidations = (field, value) => {
    const mapValidations = {
      selectedOption: (option) => option.avalable === true,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

  const updateFields = (field, value, id) => {
  
    console.log(value);
    const newFields = {
      ...fields,
    };
    newFields[field] = value;
    setFields(newFields);
    handleValidations(field, value);
    if (id) {
      setTimeout(() => {
        document.getElementById(`${id}-options`).scrollLeft += scrollCount[id];
      }, 10);
    }
  };

  const scrollHorizontal = (id, direction) => {
    const updateCount = scrollCount;
    if (direction === 'right') {
      document.getElementById(`${id}-options`).scrollLeft += 240;
      // eslint-disable-next-line operator-assignment
      updateCount[id] = updateCount[id] + 240;
    }
    if (direction === 'left') {
      document.getElementById(`${id}-options`).scrollLeft += -240;
      // eslint-disable-next-line operator-assignment
      updateCount[id] = updateCount[id] - 240 < 0 ? 0 : updateCount[id] - 240;
    }
    console.log(updateCount);
    setScrollCount(updateCount);
  };

  const manageAccordionExpansion = (expanded, areaName) => {
    const area = areaName;
    const currentValues = prepareAccordeonExpanse();
    setAccordeonExpansed({ ...currentValues, [area]: expanded });
  };

  const getEncryptIdForCategories = () => {
    if (stepOneValues.categories) {
      const categoriesIDs = [];
      stepOneValues.categories.forEach((element, index) => {
        categoriesIDs[index] = categoriesList[element];
      });
      return categoriesIDs;
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (areFieldsValid && !status.isLoading) {
      const date = `${fields.selectedOption.date} ${fields.selectedOption.hour}`;
      let reservationData;

      reservationData = {
        date,
        number_people: numberPeople,
        table_number: stepOneValues.tableNumber,
        table_type_id: fields.selectedOption.tableId,
      };

      if (stepOneValues.comments) {
        reservationData.comments = stepOneValues.comments;
      }
      setIsSubmitting(true);
      setStatus({
        ...status,
        isLoading: true,
        error: null,
      });
      try {
        setStatus({
          ...status,
          isLoading: true,
        });
        const message = await ReservationsApi
          .createWalkIn(reservationData);
          console.log('MENSAJE');
          console.log(message);
        // Successfull
        if (message.message === 'ok') {
          setStatus({
            ...status,
            isLoading: false,
            error: false,
            message,
            success: false,
          });
          setSnackbar({
            open: true,
            severity: 'success',
            text: 'La reservación fue creada de forma exitosa',
          });
          setIsSubmitting(false);
        }else{
          setStatus({
            ...status,
            isLoading: false,
            error: false,
            message,
            success: false,
          });
          setSnackbar({
            open: true,
            severity: 'error',
            text: 'Error al crear la reservación',
          });
          setIsSubmitting(false);
        }
        nextStep(3);
        setTimeout(() => {
          navigate('/reservaciones');
        }, 1500);
      } catch (error) {
        console.log(error.message);
        setIsSubmitting(false);
        setStatus({
          ...status,
          isLoading: false,
          error: true,
          message: error.message,
          success: false,
        });
        setSnackbar({
          open: true,
          severity: 'error',
          text: error.message,
        });
      }
    }
  };

  const areaHasAvaliability = (schedules) => {
    let avaliableHours = 0;
    schedules.forEach((element) => {
      if (element.avalable === true) {
        avaliableHours += 1;
      }
    });
    if (avaliableHours > 0) {
      return true;
    }
    return false;
  };

  const previousStep = () => {
    nextStep(2);
  };

  const renderNoData = () => <div className="font__roboto">No se encuentran horarios disponibles para este sitio</div>;

  const renderLoading = () => (
    <CircularProgress color="primary" className="mb-2" />
  );

  const renderData = (area) => (
    <>
      <div>
        <IconButton
          onClick={() => { scrollHorizontal(area.table, 'left'); }}
        >
          <ArrowBackIosIcon />
        </IconButton>
      </div>
      <FormGroup id={`${area.table}-options`} className="reservationsCreateForm__select-column-container">
        <div className="dFlex flex-row align-center justify-start">
          <div className="dFlex flex-row">
            {area.schedule.map((option) => {
              if (option.avalable) {
                return (
                  <div key={uuid()} className="reservationsCreateForm__checkbox-container">
                    <FormControlLabel
                      label={moment(`1988-01-08 ${option.hour}`).format('hh:mm a')}
                      control={(
                        <Checkbox
                          id={`${option.hour}-${area.table}`}
                          key={uuid()}
                          checked={
                            fields.selectedOption.hour === option.hour
                            && fields.selectedOption.table === area.table
                          }
                          name={option.hour}
                          disableRipple
                          color="primary"
                          onChange={() => { updateFields('selectedOption', { ...option, table: area.table, tableId: area.tableTypeId }, area.table); }}
                        />
                      )}
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </FormGroup>
      <div>
        <IconButton
          onClick={() => { scrollHorizontal(area.table, 'right'); }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </>
  );

  const renderDataAccordion = (area) => (
    <>
      <Accordion
        key={uuid()}
        expanded={accordeonExpansed[area.table]}
        onChange={(event, expanded) => { manageAccordionExpansion(expanded, area.table); }}
      >
        <AccordionSummary
          expandIcon={areaHasAvaliability(area.schedule) && <ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="reservations-accordion__header">
            <div className="dFlex flex-row font__roboto">
              {area.table}
            </div>
            {!areaHasAvaliability(area.schedule) && renderNoData()}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="reservations-accordion__expansion">
            {area.schedule.map((option) => {
              if (option.avalable) {
                return (
                  <div key={uuid()} className="reservationsCreateForm__accordion-container">
                    <FormControlLabel
                      label={moment(`1988-01-08 ${option.hour}`).format('hh:mm a')}
                      control={(
                        <Checkbox
                          id={`${option.hour}-${area.table}`}
                          key={uuid()}
                          checked={
                            fields.selectedOption.hour === option.hour
                            && fields.selectedOption.table === area.table
                          }
                          name={option.hour}
                          disableRipple
                          color="primary"
                          onChange={() => { updateFields('selectedOption', { ...option, table: area.table, tableId: area.tableTypeId }); }}
                        />
                      )}
                    />
                  </div>
                );
              }
              return null;
            })}
            {numberPeople > area.totalCapacity && renderNoData()}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );

  return (
    <Box
      component="div"
    >
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <form className="w100 dFlex flex-column mb-4 reservationsCreateForm" onSubmit={handleSubmit} autoComplete="off">
        <div className="w100 mt-1 dFlex flex-column">
          <FormControl className="w-100" component="fieldset">
            {/* <FormLabel component="legend">Categoría</FormLabel> */}
            {avaliabilityData.map((area) => (
              <div key={uuid()}>
                <Hidden xsDown>
                  <div className="reservationsCreateForm__areas dFlex flex-row align-center">
                    <div className="reservationsCreateForm__area-column font__commutter">
                      {area.table === 'Barra' ? 'Barra de bar' : area.table}
                    </div>
                    {!areaHasAvaliability(area.schedule)
                      && renderNoData()}
                    {areaHasAvaliability(area.schedule) && renderData(area)}
                  </div>
                </Hidden>
                <Hidden smUp>
                  {renderDataAccordion(area)}
                </Hidden>
              </div>
            ))}
          </FormControl>
        </div>
        <div className="dFlex align-end justify-end">
          {status.isLoading && renderLoading()}
        </div>
        <Hidden xsDown>
          <div className="reservationsCreateForm__button-group mt-1">
            <Button
              variant="contained"
              color="secondary"
              type="button"
              onClick={() => { navigate('/reservaciones'); }}
            >
              <span className="underline">
                CANCELAR
              </span>
            </Button>
            <div className="dFlex">
              <Button
                variant="contained"
                color="secondary"
                type="button"
                onClick={() => { previousStep(); }}
              >
                <span className="underline">
                  PASO ANTERIOR
                </span>
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={(!areFieldsValid() && !isSubmitting)
                  || (areFieldsValid() && isSubmitting)}
              >
                SIGUIENTE
              </Button>
            </div>
          </div>
        </Hidden>
        <Hidden smUp>
          <div className="reservationsCreateForm__button-group-mobile mt-1">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={(!areFieldsValid() && !isSubmitting) || (areFieldsValid() && isSubmitting)}
              className="w100"
            >
              SIGUIENTE
            </Button>
            <div className="dFlex justify-between">
              <Button
                variant="contained"
                color="secondary"
                type="button"
                onClick={() => { previousStep(); }}
              >
                <span className="underline">
                  PASO ANTERIOR
                </span>
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="button"
                onClick={() => { navigate('/reservaciones'); }}
              >
                <span className="underline">
                  CANCELAR
                </span>
              </Button>
            </div>
          </div>
        </Hidden>
      </form>
    </Box>
  );
};

ReservationsCreateFormThree.propTypes = {
  nextStep: PropTypes.func.isRequired,
  avaliabilityData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  numberPeople: PropTypes.number.isRequired,
  stepOneValues: PropTypes.shape({
    name: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    comments: PropTypes.string,
    clientId: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.object),
    tableNumber: PropTypes.string,
  }).isRequired,
};
export default ReservationsCreateFormThree;
