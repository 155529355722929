import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography,
} from '@material-ui/core/';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import InfoIcon from '@material-ui/icons/Info';

import RestaurantContext from '../../layouts/Restaurant/RestaurantContext/RestaurantContext';

import './RestaurantCapacity.scss';
import * as RestaurantsApi from '../../api/restaurants/restaurants';
import isTruthy from '../../utils/isTruthy';
import SnackbarAlert from '../SnackbarAlert';
import NumberIncrements from '../NumberIncrements';
import DialogCreateZone from '../DialogCreateZone';

const RestaurantCapacity = ({ setReload, idData }) => {
  const { capacityData } = useContext(RestaurantContext);
  const [changedData, setChangedData] = useState();
  const [totalCapacity, setTotalCapacity] = useState(0);
  const [isFieldActive, setIsFieldActive] = useState();
  const [isFieldExclusive, setIsFieldExclusive] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [hasEdit, setHasEdit] = useState(false);
  const [reload, setLocalReload] = useState(false);
  const [createZoneDialog, setCreateZoneDialog] = useState(false);
  const openCreateZoneDialog = () => {
    setCreateZoneDialog(true);
  };
  const closeCreateZoneDialog = () => {
    setCreateZoneDialog(false);
  };
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
    }, 2000);
  };

  useEffect(() => {
    const prepareCurrentData = (catalog) => {
      const preparedData = [];
      const isActiveField = {};
      const isExclusiveField = {};
      catalog.forEach((element) => {
        const option = {
          name: element.name,
          id: element.encryptId,
          isActive: false,
          capacity: 0,
          isExclusive: element.exclusive,
        };
        capacityData.forEach((area) => {
          if (area.type.name === element.name) {
            option.isActive = true;
            option.capacity = area.capacity;
            option.initialCapacity = area.capacity;
          }
        });
        preparedData.push(option);
      });
      let newTotalCapacity = 0;
      preparedData.forEach((element) => {
        isActiveField[element.name] = element.isActive;
        isExclusiveField[element.name] = element.isExclusive;
        if (element.capacity) {
          newTotalCapacity += element.capacity;
        }
      });
      setTotalCapacity(newTotalCapacity);
      setIsFieldActive(isActiveField);
      setIsFieldExclusive(isExclusiveField);
      setChangedData(preparedData);
      setLocalReload(false);
    };

    const fetchCatalogs = async () => {
      try {
        const body = {
          id: idData,
        };
        const { success, data } = await RestaurantsApi
          .tabletypes(body);
        console.log('response');
        console.log(data);
        console.log(idData);

        if (isTruthy(success)) {
          prepareCurrentData(data);
        }
        setIsLoading(false);
      } catch (error) {
        alert(error);
        setIsLoading(false);
      }
    };
    fetchCatalogs();
  }, [capacityData, reload, idData]);

  const getCurrentTotal = () => {
    let newTotalCapacity = 0;
    changedData.forEach((element) => {
      if (element.capacity) {
        newTotalCapacity += element.capacity;
      }
    });
    setTotalCapacity(newTotalCapacity);
  };

  const changeCheckbox = (item) => {
    if (isFieldActive[item.name]) {
      setTotalCapacity(totalCapacity - item.capacity);
    } else {
      setTotalCapacity(totalCapacity + item.capacity);
    }
    setIsFieldActive({ ...isFieldActive, [item.name]: !isFieldActive[item.name] });
  };

  const changeCheckbox2 = (item) => {
    setIsFieldExclusive({ ...isFieldExclusive, [item.name]: !isFieldExclusive[item.name] });
  };

  const updateCapacity = (data) => {
    const newData = changedData;
    if (data.capacity > 0) {
      newData.forEach((element) => {
        if (element.name === data.name && data.initialCapacity !== data.capacity) {
          setHasEdit(true);
          // eslint-disable-next-line no-param-reassign
          element = data;
        }
      });
      getCurrentTotal();
    }
  };

  const saveChanges = async () => {
    const capacity = [];
    changedData.forEach((element) => {
      if (isFieldActive[element.name]) {
        const item = {
          table_type_eid: element.id,
          capacity: element.capacity,
          exclusive: isFieldExclusive[element.name],
        };
        capacity.push(item);
      }
    });
    try {
      const { success, message } = await RestaurantsApi.editCapacity({ capacity });
      if (success) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
        setTimeout(() => {
          setReload(true);
        }, 1000);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };

  const discardChanges = () => {
    setLocalReload(true);
    setHasEdit(false);
  };

  const renderView = () => (
    <>
      <div className="restaurantCapacity-form-container mr-2">
        <div className="info-message-container font__roboto dFlex flex-row justify-start align-center">
          <InfoIcon className="info-icon-tooltip mr-1" />
          <p>
            Las reservas realizadas hasta el día de hoy
            no se verán afectadas con los cambios que realices
          </p>
        </div>
        {changedData.map((item) => (
          <div key={uuid()} className="restaurantCapacity-area-option">
            <div>
              {item.name !== 'Barra' && (
                <>
                  <div>
                    <FormControlLabel
                      key={uuid()}
                      label={item.name}
                      control={(
                        <Checkbox
                          checked={isFieldActive[item.name]}
                          name={item.name}
                          disableRipple
                          color="primary"
                          onChange={() => { changeCheckbox(item); }}
                        />
                      )}
                    />
                  </div>
                  <div style={{ marginTop: '-10px' }}>
                    <FormControlLabel
                      key={uuid()}
                      label="¿Es una Area Especial?"
                      control={(
                        <Checkbox
                          checked={isFieldExclusive[item.name]}
                          name={item.name}
                          color="primary"
                          onChange={() => { changeCheckbox2(item); }}
                        />
                      )}
                    />
                  </div>
                </>
              )}
              {item.name === 'Barra' && (
                (

                  <div className="dFlex flex-row justify-center align-center">
                    <FormControlLabel
                      key={uuid()}
                      label={(
                        <span>
                          Barra de bar
                        </span>
                      )}
                      control={(
                        <Checkbox
                          checked={isFieldActive[item.name]}
                          name={item.name}
                          disableRipple
                          color="primary"
                          onChange={() => { changeCheckbox(item); }}
                        />
                      )}
                    />
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <Tooltip
                        open={openTooltip}
                        onClose={handleTooltipClose}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="La barra de bar se encuentra frente al barman"
                        placement="top"
                      >
                        <InfoIcon onClick={handleTooltipOpen} className="info-icon-tooltip" />
                      </Tooltip>
                    </ClickAwayListener>
                  </div>
                )
              )}
            </div>
            <div>
              <NumberIncrements
                data={item}
                setNumber={updateCapacity}
                isActive={isFieldActive[item.name]}
              />
            </div>
          </div>
        ))}
        <div className="restaurantCapacity__totalCapacity mr-2">
          <p>
            Total:
            {' '}
            <span>
              {totalCapacity}
            </span>
          </p>
        </div>
        <div style={{ color: 'red', fontFamily: 'roboto', fontSize: '16px' }}>
          <span>
            En las zonas especiales a pesar de que haya capacidad sólo se puede
            hacer una reserva a la vez.
            <br />
            De esta forma, diferentes comensales que realizan diferentes reservas
            no coinciden en la misma área a la vez.
            <br />
            Es decir,
            diferentes clientes no comparten area.
          </span>
        </div>
        <div className="restaurantCapacity__cta-container">
          <Button
            variant="contained"
            color="secondary"
            type="button"
            onClick={() => { discardChanges(); }}
            disabled={!hasEdit}
          >
            CANCELAR
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => { saveChanges(); }}
            disabled={!hasEdit}
          >
            GUARDAR
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <>
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <Box
        component="div"
        className="dFlex flex-column w100 mb-5"
      >
        <Grid container>
          <Grid item xs={12} md={4} className="restaurantCapacity-left-grid">
            <div className="pr-3 ml-1">
              <Typography variant="h6">
                INGRESA LA CAPACIDAD ACTUAL
              </Typography>
              <div>
                <Typography variant="body1">
                  Elige las secciones que hay en el restaurante e
                  indica cuantas personas pueden estar en el área
                </Typography>
              </div>
            </div>
            <div className="mt-2 ml-1 mb-1">
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={() => openCreateZoneDialog()}
              >
                CREAR ZONA ADICIONAL
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={8} className="restaurantCapacity-right-grid">
            {changedData && !isLoading && renderView()}
          </Grid>
        </Grid>
      </Box>
      <DialogCreateZone
        isOpen={createZoneDialog}
        onNo={closeCreateZoneDialog}
        setReload={setReload}
      />
    </>
  );
};

RestaurantCapacity.propTypes = {
  setReload: PropTypes.func,
  idData: PropTypes.string,
};

RestaurantCapacity.defaultProps = {
  setReload: () => { },
  idData: '',
};

export default RestaurantCapacity;
