/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';

import { navigate } from '@reach/router';
import {
  Box,
  Container,
  Grid,
  Button,
  Hidden,
  FormControl,
  InputLabel,
  NativeSelect,
  // IconButton,
  Typography,
} from '@material-ui/core/';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TheMenu from '../../components/TheMenu';
import './Estadisticas.scss';
import { format, isPast, set } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Chart from 'chart.js/auto';
import * as ReservationsApi from '../../api/restaurants/reservations';
import { Link } from 'react-router-dom';

//pie chart

const Estadisticas2 = () => {
  const [diasbloqueados, setDiasbloqueados] = useState([]);
  const [selectedYear, setSelectedYear] = useState('2023');

  const chartRef = useRef(null);
  let chartInstance; // Variable to hold the chart instance
  const [loading, setLoading] = useState(false); // Agrega el estado de carga
  const [chartData, setChartData] = useState([0,0,0,0]);

  // Define myChart outside of useEffect
  function updatechart2(newData, labelbs,combinedArray) {
    let canvasholder = document.getElementById('canvasholder');
    let thecanvas = document.getElementById('thecanvas');
    // Empty the canvas
    canvasholder.removeChild(thecanvas);
    // Create new canvas
    let canvas = document.createElement('canvas');
    canvas.id = 'thecanvas';
    canvasholder.appendChild(canvas);
   // const combinedData = reservaya.map((value, index) => value + newData[index]);
    // Create new chart

  const alwaysShowTooltip = {
    id: 'alwaysShowTooltip',
    afterDraw(chart, args, options) {
      const { ctx } = chart;
      ctx.save();
      console.log(chart);
      chart.data.datasets.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
          
          const { x, y } = datapoint.tooltipPosition();
          //console.log(datapoint.tooltipPosition());
          //console.log(chart.data.label  s);
          const text = chart.data.datasets[i].data[index];
         const width = 20;
          const textWidth = ctx.measureText(text).width;
          console.log(textWidth);
          ctx.fillStyle = 'transparent';
          ctx.fillRect(x-((textWidth +10) /2),y-25,textWidth +10 ,30);
          ctx.restore();
  
          ctx.beginPath();
          ctx.moveTo(x,y);
          ctx.lineTo(x - 5, y-5);
          ctx.lineTo(x-5,y-5);
          ctx.fill();
          ctx.restore();
          
         ctx.font = '15px Arial';
         if (i === 0) {
          // Primer dataset
          ctx.fillStyle = '#067066'; // Establece el color para el primer dataset
        } else if (i === 1) {
          // Segundo dataset
          ctx.fillStyle = '#FE5000'; // Establece el color para el segundo dataset
        }

         ctx.fillText(text,x-(textWidth /2) ,y-10);
         ctx.restore();
  
  
        
        });
      });
      ctx.restore();
    },
  };
  
    const ctx = canvas.getContext('2d');
    const chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labelbs, // Replace 'labelbs' with the array of labels for the x-axis
          datasets: [
              {
                  label: 'Reservas realizadas por el cliente v\xEDa Reserva Ya!',
                  data: newData, // Replace 'newData' with the array of values for the y-axis
                  borderColor: "#067066",
                  pointBackgroundColor: "#067066",
                  backgroundColor: 'transparent',
                  fill: false,
              },
              {
                label: 'Reservas apuntadas manualmente por la host en la plataforma',
                data: combinedArray ,
                borderColor: '#FFBF05',
                pointBackgroundColor: '#FFBF05',
                backgroundColor: "transparent",
                fill: false,
            },
          ],
      },
      options: {
          scales: {
              x: {
                  display: true,
                  title: {
                      display: true,
                      text: 'Mes',
                  },
              },
              y: {
                  display: true,
                  title: {
                      display: true,
                      text: 'Reservas',
                  },
              },
          },
          responsive: true,
          plugins: {
              legend: {
                  position: window.innerWidth > 950 ? 'right' : 'bottom', // 
                  maxWidth: 390,
                  itemWrap: true,
                  display: true,
              },
              datalabels: {
                  align: 'left',
                  color: function(context) {
                      return context.dataset.borderColor;
                  },
                  backgroundColor: '#ffffff',
                  anchor: 'end',
                  padding: 3,
                  display: function(context) {
                      return context.dataset.data[context.dataIndex] !== 0;
                  },
                  font: {
                      weight: 'bold',
                  },
                  formatter: Math.round,
                  display: true,
              },
          },
          title: {
              display: true,
              text: 'Usuarios por mes',
          },
      },  plugins: [alwaysShowTooltip],
      responsive: true,
      maintainAspectRatio: false,
    });
}
//make useeffect to call the api with todays date

  useEffect(() => {
    //start date mudst be 02-01-2021
    const start='2021-01-02';
    const year = format(new Date(), 'yyyy');
    const end = format(new Date(), 'yyyy-MM-dd');
    fetchnumberreservations(start, end,year);
  }, [selectedYear]);
  const [state, setState] = useState({
    loading: true,
    error: null,
    success: false,
  });
  

  const [fields, setFields] = useState({

    startDate: format(new Date(), 'yyyy-MM-dd'),
  
    endDate: format(new Date(), 'yyyy-MM-dd'),
    //pendiente, aceptadas, canceladas, rechazadas
    pending: 0,
    accepted: 0,
    cancelled: 0,
    rejected: 0,


  });

  const renderLoading = () => (
    <div>Loading...</div>
  );
  const reservastotales = () => {
    navigate('/estadisticas');
  };

  const fetchnumberreservations = async (start,end,year) => {
    try {
      setLoading(true); // Set loading state to true
   
        const info2 = {
          year: selectedYear,
        };
        let { success3, data2 } = await ReservationsApi.puerta21usersPlatform (info2);

        let { success2, labels } = await ReservationsApi.puerta21usersPlatform(info2);
      

        const info = {
          start_date: start,
          end_date: end,
          currentPage: 1,
          perPage: 100,
        };
    
          let { success4, year } = await ReservationsApi.puerta21usersPlatform(info2);
          let { success5, data3 } = await ReservationsApi.puerta21usersPlatform(info2);
          console.log("pruebaa");
          console.log(data3);
        // Obtén todos los arreglos de data3 y súmalos en un único arreglo sumado
        const combinedArray = [];

          for (let i = 0; i < 12; i++) {
            let sum = 0;
            
            for (const array of Object.values(data3)) {
              sum += array[i];
            }
            
            combinedArray.push(sum);
          }
          console.log("gg");
          console.log(combinedArray);
          
      
          //set filds 
          console.log("heere");
 
          console.log(year);
        setFields({
        
          paderpan: data2.pardepan,
    
          labelts:labels,
        });
  
      
        const newChartData =  data2.pardepan;
        const labelst =  labels;

    
    
      
      updatechart2(newChartData,labelst, combinedArray);
      
      renderLoading(false); // Establece el estado de carga en false
      }catch (error) {
        console.log(error);
        renderLoading(false); // Establece el estado de carga en false
        setState({
          loading: false, error: error.message, reservationsVisible: true, reservationsBlocked: { reservations: [], cancelled: [] }, reservations: [], pardepanBlocked: [], capacity: [], paginator: {}
        });
      }
    }
        

    const renderEstadistica = () => (
      <Container className='' fixed height="100">
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <Grid container height="100" spacing={2} className='align-left' justifyContent="flex-start">
            <Grid item xs={12} md={0}></Grid>
            <Box sx={{ minWidth: 130 }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Seleccione el año
                </InputLabel>
                <NativeSelect
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>
          <Grid container height="100" spacing={4} className='pt-2'  >
            <Grid item xs={12} md={10} id='canvasholder' style={{ padding:0}} >
              <canvas id='thecanvas' ref={chartRef}  />
            </Grid>
        
          </Grid>
        </MuiPickersUtilsProvider>
      </Container>
  );
  return (
    <>
      <TheMenu setIsCalendarOpen={() => { }} />
      <Container
        className="reservations-create layout px-0"
      >
        <Box
          component="div"
          className="reservations-create__header pr-1 pl-1 dFlex flex-row justify-between align-center"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
         
          <Grid container spacing={2}>
      <Grid item xs={12}>
      <Typography variant="h6" className="reservations-create__title font__commutter">
            ESTADÍSTICAS
          </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            <Button
               variant="contained" color="primary"
               
            >
               Gráfica  USUARIOS EN LA PLATAFORMA
            </Button>
          </Grid>
          <Grid item>
            <Button 
              onClick={() => { reservastotales(); }}
              variant="contained"
            className="custom-button"
            style={{
              backgroundColor: '#ffffff',
              borderColor: '#000000',
              color: 'black',
             }}
            >
              Gráfica de reservas
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
        </Box>
     
        <Box
          component="div"
          className="dFlex flex-column align-center justify-between"
        >
         {renderEstadistica()}
        </Box>
      </Container>
    </>
  );
};

export default Estadisticas2;
