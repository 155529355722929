import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@material-ui/core/';
import { v4 as uuid } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import MenuTableOptions from '../MenuTableOptions';
import CommentsContext from '../../layouts/Comments/CommentsContext/CommentsContext';
import DialogCommentResponse from '../DialogCommentResponse';

import './CommentsTable.scss';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [{
  key: '1',
  id: 'name',
  label: 'CLIENTE',
  disablePadding: false,
  sortable: true,
}, {
  key: '2',
  id: 'reservationDate',
  label: 'FECHA DE RESERVACIÓN',
  disablePadding: false,
  sortable: true,
}, {
  key: '3',
  id: 'numberPeople',
  label: 'NÚMERO DE PERSONAS',
  disablePadding: false,
  sortable: true,
}, {
  key: '4',
  id: 'comment',
  label: 'COMENTARIO',
  disablePadding: false,
  sortable: true,
}, {
  key: '5',
  id: 'menu',
  disablePadding: true,
  sortable: false,
},
];

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={uuid()}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
            {!headCell.sortable && (
              <div>
                {headCell.label}
              </div>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    borderBottom: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const EnhancedTable = ({ commentDelete, commentReply }) => {
  const classes = useStyles();
  const { filteredComments } = useContext(CommentsContext);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('firstName');
  // const [pagination] = useState(apiCallConfig);
  const [commentResponseDialog, setCommentResponseDialog] = useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [selectedComment, setSelectedComment] = useState({});

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    localStorage.setItem('ReservationsConfigSortCategory', property);
    localStorage.setItem('ReservationsConfigSortDirection', isAsc ? 'desc' : 'asc');
    // setApiCallConfig({
    //   ...pagination,
    //   sortCategory: property,
    //   sortDirection: isAsc ? 'desc' : 'asc',
    // });
  };

  const menuOptions = (id) => [
    { key: 1, string: 'ELIMINAR', action: () => commentDelete(id) },
    // { key: 2, string: 'CANCELAR', action: null },
  ];

  const openResponseDialog = (row) => {
    console.log('clicked open response dialog');
    console.log(row.id);
    setSelectedComment(row);
    setCommentResponseDialog(true);
  };

  const closeCommentResponseDialog = () => {
    setState({ ...state, top: false });
    setCommentResponseDialog(false);
    setSelectedComment({});
  };

  const saveResponse = (id, reply) => {
    commentReply(id, reply);
  };

  const renderTable = () => (
    <div className={classes.root}>
      <DialogCommentResponse
        isOpen={commentResponseDialog}
        onNo={closeCommentResponseDialog}
        data={selectedComment}
        saveResponse={saveResponse}
      />
      <Paper className={classes.paper} elevation={0}>
        <TableContainer>
          <Table
            className={`${classes.table} commments-table`}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredComments.length}
            />
            <TableBody>
              {stableSort(filteredComments, getComparator(order, orderBy))
                .map((row) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={uuid()}
                  >
                    <TableCell key={uuid()} align="left" className="row-name">
                      <div className="content">
                        {row.photo !== null && (
                          <div>
                            <svg width="50" height="50">
                              <defs>
                                <mask id="mask">
                                  <circle fill="#FFFFFF" cx="50%" cy="45%" r="12.2" />
                                  <rect fill="#ffffff" x="26%" y="45%" width="24.15" height="21" />
                                </mask>
                              </defs>
                              <image mask="url(#mask)" xlinkHref={`data:image/png;base64,${row.photo}`} width="50" height="50" />
                            </svg>
                          </div>
                        )}
                        {row.name}
                      </div>
                    </TableCell>
                    <TableCell key={uuid()} align="left">{row.reservationDate}</TableCell>
                    <TableCell key={uuid()} align="left">{row.numberPeople}</TableCell>
                    <TableCell key={uuid()} align="left" className="row-comment">{row.comment !== null ? row.comment : 'Sin datos'}</TableCell>
                    <TableCell key={uuid()} align="left" className="row-menu dFlex flex-row justify-end">
                      {row.comment !== 'Sin datos'
                        && (
                          <Button
                            variant="contained"
                            color="secondary"
                            type="button"
                            onClick={() => openResponseDialog(row)}
                          >
                            <span className="nowrap">
                              {row.reply ? 'VER RESPUESTA' : 'RESPONDER'}
                            </span>
                          </Button>
                        )}
                      <MenuTableOptions id={row.id} options={menuOptions(row.id)} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  return (
    <>
      {renderTable()}
    </>
  );
};

EnhancedTable.propTypes = {
  commentDelete: PropTypes.func.isRequired,
  commentReply: PropTypes.func.isRequired,
};

export default EnhancedTable;
