/* eslint-disable import/no-named-as-default-member */
// eslint-disable-next-line import/no-named-as-default
import moment from 'moment';
import client from '../utils/client';

// eslint-disable-next-line import/no-named-as-default
// import TokenManager from './utils/TokenManager';

// Obtiene las reservaciones de un restaurante
// Parametro Zone (obligatorio) acepta opciones: 'all,bar,barra,salon-privado,interior,terraza`
// Parametro Date es string opcional con formato 'YYYY-MM-DD'
const getReservations = (data) => client(`restaurant/reservations/${data.zone ? data.zone : 'all'}/${data.date ? data.date : moment().format('yyyy-MM-DD')}/${data.time ? moment(`${data.date} ${data.time}`).format('HH:mm') : moment().format('hh:mm')}?page=${data.currentPage ? data.currentPage : 1}&pagesize=${data.perPage ? data.perPage : 100}&orderBy=${data.sortCategory ? data.sortCategory : 'fecha'}+${data.sortDirection ? data.sortDirection : 'asc'}${data.search ? `&search=${data.search}` : ''}`)
  .then((response: any) => response);

const getReservationsFast = (data) => client(`restaurant/reservationsfast/${data.zone ? data.zone : 'all'}/${data.date ? data.date : moment().format('yyyy-MM-DD')}/${data.time ? moment(`${data.date} ${data.time}`).format('HH:mm') : moment().format('hh:mm')}?page=${data.currentPage ? data.currentPage : 1}&pagesize=${data.perPage ? data.perPage : 100}&orderBy=${data.sortCategory ? data.sortCategory : 'fecha'}+${data.sortDirection ? data.sortDirection : 'asc'}${data.search ? `&search=${data.search}` : ''}`)
  .then((response: any) => response);

const getAllDayReservations = (data) => client(`restaurant/allday-reservations/${data.zone ? data.zone : 'all'}/${data.date ? data.date : moment().format('yyyy-MM-DD')}/${data.time ? moment(`${data.date} ${data.time}`).format('HH:mm') : moment().format('hh:mm')}?page=${data.currentPage ? data.currentPage : 1}&pagesize=${data.perPage ? data.perPage : 100}&orderBy=${data.sortCategory ? data.sortCategory : 'fecha'}+${data.sortDirection ? data.sortDirection : 'asc'}${data.search ? `&search=${data.search}` : ''}`)
  .then((response: any) => response);

// Obtiene las reservaciones de un restaurante de un mes
const getReservationsPerMonth = (year, month) => client(`restaurant/reservations-permonth/${year}/${month}`)
  .then((response: any) => response);

// Obtiene las reservaciones bloqueadas de un restaurante de un mes
const getReservationsBlocked = (year, month) => client(`bloqueados/dias/${year}/${month}`)
  .then((response: any) => response);
// obtiene el total de walkin y pardepan del mes
const getTotalReservationsPerMonth = (year, month) => client(`restaurant/total-reservations-permonth/${year}/${month}`)
  .then((response: any) => response);
// Crea una reservación a un restaurante
const createReservation = (body: object) => client('restaurant/reservations', { body })
  .then((response: any) => {
    console.log(response);
    return response;
  });
// Crea una reservación a un restaurante sin client_id
const createReservationNoClient = (body: object) => client('restaurant/reservations/create', { body })
  .then((response: any) => {
    console.log(response);
    return response;
  });
const createWalkIn = (body: object) => client('restaurant/WalkIn', { body })
  .then((response: any) => {
    console.log(response);
    return response;
  });
// Consigue la disponibbilidad de un restaurante/ area de restaurante
const getAvaliability = (date: string, numberPeople: Number, event: boolean, startDate: string, startTime: string, endDate: string, endTime: string) => client('restaurant/reservation-availability',
  {
    body: {
      date,
      number_people: numberPeople,
      event,
      start_date: `${startDate} ${startTime}`,
      end_date: `${endDate} ${endTime}`,
    },
  })
  .then((response: any) => response);

//  Cambia el status de una reserva
const changeReservationStatus = (data) => client('restaurant/reservations/change-status',
  {
    body: data,
  })
  .then((response: any) => response);

//  Cancela el status de una reserva
const cancelReservation = (id) => client(`restaurant/reservations/cancel/${id}`,
  { method: 'POST' })
  .then((response: any) => response);

// Obtiene una reservacion por id
const getOneReservation = (id) => client(`restaurant/reservations-show/${id}`)
  .then((response: any) => response);

// Edita una reservación existente método PUT
const editReservation = (id, data) => client(`restaurant/reservations/${id}`,
  {
    body: data,
    method: 'PUT',
  })
  .then((response: any) => response);
const saveAllDayReservation = (data) => client('restaurant/alldayreservations/save',
  {
    body: data,
    method: 'POST',
  })
  .then((response: any) => response);
const removeAllDayReservation = (data) => client('restaurant/alldayreservations/remove',
  {
    body: data,
    method: 'POST',
  })
  .then((response: any) => response);
const savePardepanBlock = (data) => client('restaurant/pardepanBlock/save',
  {
    body: data,
    method: 'POST',
  })
  .then((response: any) => response);
const removePardepanBlock = (data) => client('restaurant/pardepanBlock/remove',
  {
    body: data,
    method: 'POST',
  })
  .then((response: any) => response);

const getReservesGraph = (data) => client('restaurant/getReservesGraph',
  {
    body: data,
    method: 'POST',
  }).then((response: any) => response);

const puerta21usersPlatform = (data) => client('restaurant/puerta21usersPlatform',
  {
    body: data,
    method: 'POST',
  }).then((response: any) => response);

export {
  removeAllDayReservation,
  saveAllDayReservation,
  savePardepanBlock,
  removePardepanBlock,
  getReservations,
  getReservationsFast,
  getAllDayReservations,
  getReservationsPerMonth,
  getTotalReservationsPerMonth,
  createReservation,
  createReservationNoClient,
  createWalkIn,
  getAvaliability,
  changeReservationStatus,
  cancelReservation,
  getOneReservation,
  editReservation,
  getReservesGraph,
  puerta21usersPlatform,
  getReservationsBlocked,
};
