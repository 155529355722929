import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import './PlaceholderImageThumbnail.scss';

const PlaceholderImageThumbnail = () => (
  <div className="placeholder-thumbnail dFlex flex-column justify-center align-center pointer">
    <AddIcon />
    <small className="pt-1">Cargar fotografía</small>
  </div>
);
export default PlaceholderImageThumbnail;
