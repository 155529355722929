/* eslint-disable */
import { React, useState } from 'react';
import PropTypes from 'prop-types';
import './DialogCreateZone.scss';
import ReactModal from 'react-modal';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import emojiRegex from '../../utils/emojiRegex';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  Button,
  Box,
  TextField,
  Typography,
} from '@material-ui/core/';
import namesRegex from '../../utils/namesRegex';
import * as RestaurantsApi from '../../api/restaurants/restaurants';
import SnackbarAlert from '../SnackbarAlert';

ReactModal.setAppElement('#root');
const DialogCreateZone = ({
  isOpen, onNo, date, setReload,
}) => {
  const [validations, setValidations] = useState({
    name: false,
  });
  const [fields, setFields] = useState({
    name: '',
  });
  const areFieldsValid = () => (
    Object.values(validations).every((item) => item)
  );
  const handleValidations = (field, value) => {
    const mapValidations = {
      name: (name) => namesRegex.test(name) && name.length > 3 && name.length < 30,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const cleanUpBeforeClose = () => {
    setSnackbar({
      open: false,
      severity: 'success',
      text: 'Placeholder',
    });
    setReload(true);
    onNo();
  };
  const updateFields = (field, value) => {
    console.log(field, value);
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...fields,
    };
    newFields[field] = cleanString;
    setFields(newFields);
    handleValidations(field, cleanString);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = {
      name: fields.name,
    };
    try {
      const { success } = await RestaurantsApi
        .CreateZone(body);
      if (success === 1) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: 'ZONA CREADA',
        });
        setTimeout(() => {
          cleanUpBeforeClose();
        }, 1500);
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };
  return (
    <ReactModal
      isOpen={isOpen}
      className="dialog-base-modal"
      overlayClassName="dialog-base-modal__overlay"
      shouldCloseOnOverlayClick
      onRequestClose={onNo}
    >
      <div className="dialog-base-modal__content ">
        <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <form onSubmit={handleSubmit}>
          <div className="dialog-base-modal__content ">
            <Box>
              <div className="w100 marginTop20">
                <TextField
                  className="w100"
                  error={!validations.name}
                  id="name"
                  label="Nombre De La Zona"
                  value={fields.name}
                  onChange={(e) => updateFields('name', e.target.value)}
                />
              </div>
            </Box>
          </div>
            <div className="flexend">
              <Button
                className="cancelBtn"
                variant="contained"
                color="secondary"
                type="button"
                onClick={onNo}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={handleSubmit}
              >
                Guardar
              </Button>
            </div>
          </form>
        </MuiPickersUtilsProvider>
      </div>
    </ReactModal>
  );
};
DialogCreateZone.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  date: PropTypes.instanceOf(String).isRequired,
  setReload: PropTypes.func,

  // eslint-disable-next-line react/forbid-prop-types

};
DialogCreateZone.defaultProps = {
  isOpen: false,
  onNo: '',
  setReload: () => { },
};

export default DialogCreateZone;
