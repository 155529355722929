import moment from 'moment';
import 'moment/locale/es';
import { catalogCategory } from '../../api/catalogs';

const prepareCategories = (categories) => {
  if (categories.length > 0) {
    const categoriesUserList = [];
    categories.forEach((element) => {
      const categorie = {
        string: element.name,
      };
      categoriesUserList.push(categorie);
    });
    return (categoriesUserList);
  }
  return [];
};
let categoriesCatalogList;

const prepareCatalogsCategories = async () => {
  const localCategoriesCatalogList = localStorage.getItem('catalogCategory');
  if (localCategoriesCatalogList === null || localCategoriesCatalogList === 'undefined') {
    await catalogCategory().then((response) => {
      categoriesCatalogList = response.data;
      localStorage.setItem('catalogCategory', JSON.stringify(response.data));
    });
  } else {
    categoriesCatalogList = JSON.parse(localCategoriesCatalogList);
  }
  console.log(categoriesCatalogList);
};

const prepareReservationsTable = async (data: any) => {
  const rawData = data;
  const preparedData = [];
  await prepareCatalogsCategories().then(() => {
    rawData.forEach((element) => {
      console.log(element);
      console.log('el elemento es');
      console.log(element.visits);
      const tableData = {
        name: element.name ? element.name : 'Sin datos',
        phone: element.phone ? element.phone : 'Sin datos',
        email: element.email ? element.email : 'Sin datos',
        people: element.people,
        status: element.status,
        user_id: element.linkid,
        linkid: element.linkid,
        statusId: element.statusId,
        tableNumber: element.tableNumber,
        table: element.table,
        id: element.id,
        key: element.id,
        date: moment(element.reservationDate).locale('es').format('DD MMMM yyyy'),
        dateRaw: element.reservationDate,
        time: moment(element.reservationDate).locale('es').format('hh:mm a'),
        comments: element.comments,
        categories: prepareCategories(element.categories),
        clientStatus: element.clientStatus,
        type: element.type,
        birthday: element.birthdate,
        photo: element.photo,
        start_date: element.startDate,
        end_date: element.endDate,
        created_by: element.createdBy,
        modified_by: element.modifiedBy,
        status_modified_by: element.statusModifiedBy,
        deleted_by: element.deletedBy,
        visits: element.visits,
      };
      preparedData.push(tableData);
    });
    return preparedData;
  });
  return preparedData;
};

export default prepareReservationsTable;
