/* eslint-disable */
import { React, useState } from 'react';
import PropTypes from 'prop-types';
import './DialogUnBlockDay.scss';
import ReactModal from 'react-modal';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import { format, isPast } from 'date-fns';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  Button,
} from '@material-ui/core/';
import * as ReservationsApi from '../../api/restaurants/reservations';
import SnackbarAlert from '../SnackbarAlert';

ReactModal.setAppElement('#root');
const DialogUnBlockDay = ({
  isOpen, onNo, date, setReload
}) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const cleanUpBeforeClose = () => {
    setSnackbar({
      open: false,
      severity: 'success',
      text: "Placeholder",
    });
    setReload(true);
    onNo();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = {
      date: date,
    };
    try {
      const { success, message, data } = await ReservationsApi
        .removeAllDayReservation(body);
      if (success == 1) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: "DIA DESBLOQUEADO",
        });
        setTimeout(() => {
          cleanUpBeforeClose();
        }, 1500);
      } else {

      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }

  };
  return (
    <ReactModal
      isOpen={isOpen}
      className="dialog-base-modal"
      overlayClassName="dialog-base-modal__overlay"
      shouldCloseOnOverlayClick
      onRequestClose={onNo}
    >
      <div className="dialog-base-modal__content ">
        <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <form onSubmit={handleSubmit}>
            <div className="warningTitle">
              {"¿QUIERES DESBLOQUEAR ESTE DÍA?"}
            </div>
            <div className="warningDisclaimer">
              {"Las reservaciones que fueron canceladas no seran recuperadas."}
            </div>
            <div className="flexend">
              <Button
                className="cancelBtn"
                variant="contained"
                color="secondary"
                type="button"
                onClick={onNo}
              >
                Mantener
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={handleSubmit}
              >
                Desbloquear
              </Button>
            </div>
          </form>
        </MuiPickersUtilsProvider>
      </div>
    </ReactModal >
  );
};
DialogUnBlockDay.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  date: PropTypes.instanceOf(String).isRequired,
  setReload: PropTypes.func,

  // eslint-disable-next-line react/forbid-prop-types

};
DialogUnBlockDay.defaultProps = {
  isOpen: false,
  onNo: '',
  setReload: () => { },
};

export default DialogUnBlockDay;
