import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Box,
  InputAdornment,
  IconButton,
  InputLabel,
  Input,
  CircularProgress,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// eslint-disable-next-line import/named
import * as AuthApi from '../../../api/auth';

import './RecoverPasswordForm.scss';

const RecoverPasswordForm = ({ token }) => {
  const [isFormTouched, setFormIsTouched] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    passwordConfirmation: false,
  });
  const [fields, setFields] = useState({
    password: '',
    passwordConfirmation: '',
    token,
  });
  const [validations, setValidations] = useState({
    password: false,
    passwordConfirmation: false,
    token: true,
  });

  const [status, setStatus] = useState({
    error: null,
    isLoading: false,
    isValid: false,
    message: null,
    success: null,
  });

  useEffect(() => {
    if (fields.passwordConfirmation.length > 0 || fields.password.length > 0) {
      setFormIsTouched(true);
    }
  }, [fields]);

  const areValidFields = (validations.password && validations.passwordConfirmation)
    && isFormTouched;

  const handleValidations = (field, value) => {
    const passwordRegexValidations = RegExp(/^.*[A-Z].*$/); // Contains at least 1 upper case letter
    const mapValidations = {
      password: (password) => password.length >= 8 && password.length <= 12
        && passwordRegexValidations.test(password),
      passwordConfirmation: (passwordConfirmation) => passwordConfirmation === fields.password
        && passwordConfirmation.length > 0,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };

  const handleClickShowPassword = () => {
    setShowPassword({ ...showPassword, password: !showPassword.password });
  };
  const handleClickShowPasswordConfirmation = () => {
    setShowPassword({ ...showPassword, passwordConfirmation: !showPassword.passwordConfirmation });
  };

  const updateFields = (field, value) => {
    const newFields = {
      ...fields,
    };
    const valueTrimmedSpaces = value.replaceAll(' ', '');
    newFields[field] = valueTrimmedSpaces;
    setFields(newFields);
    handleValidations(field, valueTrimmedSpaces);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (areValidFields && !status.isLoading) {
      setStatus({
        ...status,
        isLoading: true,
        error: null,
      });

      try {
        const response = await AuthApi.resetPassword(fields);
        console.log(response);
        setStatus({
          ...status,
          isLoading: true,
        });
        if (response.success === 0) {
          setStatus({
            ...status,
            isLoading: false,
            error: false,
            message: response.message,
            success: true,
          });
        } else {
          setStatus({
            ...status,
            isLoading: false,
            error: response.message,
            success: false,

          });
        }
      } catch (error) {
        setStatus({
          ...status,
          isLoading: false,
          error,
          success: false,
        });
      }
    }
  };

  if (status.success === true) {
    return (
      <main className="m5">
        {status.message}
      </main>
    );
  }

  return (
    <Box
      component="div"
      className="mt-4"
    >
      <form className="w100 dFlex flex-column" onSubmit={handleSubmit}>
        <Box
          component="div"
          className="w100"
        >
          <InputLabel htmlFor="password">Contraseña</InputLabel>
          <Input
            className="w100"
            error={!isFormTouched ? false : !validations.password}
            id="password"
            label="Contraseña"
            onChange={(e) => updateFields('password', e.target.value)}
            type={showPassword.password ? 'text' : 'password'}
            value={fields.password}
            endAdornment={
              (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword()}
                  >
                    {showPassword.password ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        </Box>
        <Box
          component="div"
          className="w100 mt-2"
        >
          <InputLabel htmlFor="passwordConfirmation">Confirmación de contraseña</InputLabel>
          <Input
            className="w100"
            error={!isFormTouched ? false : !validations.passwordConfirmation}
            id="passwordConfirmation"
            label="Contraseña"
            onChange={(e) => updateFields('passwordConfirmation', e.target.value)}
            type={showPassword.passwordConfirmation ? 'text' : 'password'}
            value={fields.passwordConfirmation}
            endAdornment={
              (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPasswordConfirmation()}
                  >
                    {showPassword.passwordConfirmation ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        </Box>
        {status.success === false && (
          <Box
            component="div"
            className="mt-2 dFlex flex-column align-center justify-center color-error"
          >
            {status.error}
          </Box>
        )}
        <Box
          component="div"
          className="mt-5 dFlex flex-column align-center justify-center"
        >
          {status.isLoading && (
            <CircularProgress color="primary" className="mb-2" />
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!areValidFields}
          >
            Guardar
          </Button>
        </Box>
      </form>
    </Box>
  );
};

RecoverPasswordForm.propTypes = {
  token: PropTypes.string,
};
RecoverPasswordForm.defaultProps = {
  token: '',
};
export default RecoverPasswordForm;
