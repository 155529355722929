/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { navigate } from '@reach/router';
// import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/es';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import AddIcon from '@material-ui/icons/Add';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import { Tooltip, withStyles, Button } from '@material-ui/core/';
import Zoom from '@material-ui/core/Zoom';
import MuiAlert from '@material-ui/lab/Alert';
import * as CatalogsApi from '../../api/catalogs';
import * as ReservationsApi from '../../api/restaurants/reservations';
import MenuTableOptions from '../MenuTableOptions';
import MenuReservationStatus from '../MenuReservationStatus';
import prepareCategoryList from '../../utils/prepareCategoryList';
import ReservationsContext from '../../layouts/Reservations/ReservationsContext/ReservationsContext';
import DialogCancelReservation from '../DialogCancelReservation';
import DialogEditClient from '../DialogEditClient';
import DialogImage from '../DialogImage';
import DialogHistory from '../DialogComment';
import * as CommentsApi from '../../api/restaurants/comments';
import Iconcake from '../../images/errorIcons/cake.png';
import Iconstar from '../../images/categoryIcons/estrella.png';
import Iconwaiting from '../../images/categoryIcons/waiting.png';
import Iconevent from '../../images/categoryIcons/evento.png';
import IconPeople from '../../images/categoryIcons/people.png';
import './ReservationsTable.scss';
import isTruthy from '../../utils/isTruthy';
import DefaulImage from '../../images/default.jpeg';
import { IoStar } from "react-icons/io5";
import 'moment/locale/es';
import Accordion from '@material-ui/core/Accordion';
import Phone from '@material-ui/icons/Phone';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DialogEmail from '../DialogEmail';
import './ReservationsAccordion.scss';

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'black !important',
    color: 'rgba(255, 255, 255, 1)',
    border: 'solid 1px rgb(0,0,0)',
    boxShadow: 'none',
    fontSize: 11,
  },
}))(Tooltip);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const renderStars = (rating, starSize) => {
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      const starStyle = {
        color: '#ffd51e',
        fontSize: starSize,
        marginRight: '2px',
        lineHeight: '0.8'
      };
      stars.push(<IoStar key={i} style={starStyle} />);
    } else {
      // Estrella inactiva
      const starStyle = {
        color: '#d8d8d8',
        fontSize: starSize,
        marginRight: '2px',
        lineHeight: '0.8'
      };
      stars.push(<IoStar key={i} style={starStyle} />);
    }
  }

  return stars;
};


const headCells = [
  {
    key: '1',
    id: 'photo',
    disablePadding: false,
    label: 'FOTO',
    sortable: true,
  },
  {
    key: '2',
    id: 'tableNumber',
    disablePadding: false,
    label: 'CONTACTO',
    sortable: true,
  }, {
    key: '3',
    id: 'categoria',
    label: 'CATEGORÍA',
    disablePadding: false,
    sortable: false,
  }, {
    key: '4',
    id: 'nombre',
    label: 'NOMBRE',
    disablePadding: false,
    sortable: true,
  },
  {
    key: '5',
    id: 'mesa',
    label: 'MESA',
    disablePadding: false,
    sortable: true,
  }, {
    key: '6',
    id: 'zona',
    label: 'ZONA',
    disablePadding: false,
    sortable: true,
  }, {
    key: '7',
    id: 'fecha',
    label: 'HORA',
    disablePadding: false,
    sortable: true,
  }, {
    key: '8',
    id: 'asistentes',
    label: 'ASISTENTES',
    disablePadding: false,
    sortable: true,
  }, {
    key: '9',
    id: 'status',
    label: 'ESTATUS',
    disablePadding: false,
    sortable: true,
  }, {
    key: '10',
    id: 'comments',
    label: 'COMENTARIOS',
    disablePadding: false,
    sortable: false,
  }, 
  {
    key: '11',
    id: 'visitas',
    label: 'VISITAS',
    disablePadding: false,
    sortable: false,
  },
  {
    key: '12',
    id: 'menu',
    label: 'EDITAR',
    disablePadding: false,
    sortable: false,
  },
  {
    key: '13',
    id: 'modalhistory',
    label: ' ',
    disablePadding: false,
    sortable: false,
  },
];

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={uuid()}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >

                {(headCell.label !== 'ASISTENTES') ? headCell.label
                  : (
                    <LightTooltip title="Esta es la cantidad de asistentes" TransitionComponent={Zoom} placement="top-center">
                      <span className="clientname capitalized">
                        <img src={IconPeople} alt="asistentes" className="iconpeople" />
                      </span>
                    </LightTooltip>
                  )}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
            {!headCell.sortable && (
              <div>
                {headCell.label}
              </div>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    borderBottom: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));
const Modal = ({  data }) => {
  return (
    <div className={`modal  'open' : ''}`}>
      <div className="modal-content">
        <h2>Modal Title</h2>
        <p>{data}</p>
       
      </div>
    </div>
  );
};

const EnhancedTable = () => {
  const classes = useStyles();
  const {
    apiCallConfig,
    filterdReservations, setApiCallConfig,
    title,
  } = useContext(ReservationsContext);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = React.useState(apiCallConfig.sortDirection);
  const [orderBy, setOrderBy] = React.useState(apiCallConfig.sortCategory);
  const [pagination] = useState(apiCallConfig);
  const [catalogReservationStatus, setCatalogReservationStatus] = useState({});
  const [cancelDialog, setCancelDialog] = useState(false);
  const [imageDialog, setImageDialog] = useState(false);
  const [historyDialog, setHistoryDialog] = useState(false);
  const [historyDialogData, setHistoryDialogData] = useState({ created_by: '', status_modified_by: '', modified_by: '', deleted_by: ''  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState('');
  const [EditClientDialog, setEditClientDialog] = useState(false);
  const [editClientDialogData, setEditClientDialogData] = useState({ name: '', clientId: null });
  const [imageDialogData, setImageDialogData] = useState('');

  const openModal = (data) => {
    setModalData(data);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalData('');
  };
  const handleCommentClick = (commentId) => {
    CommentsApi.updateseen(commentId)
      .then((response) => {
        console.log('Seen update successful:', response);
      })
      .catch((error) => {
        console.error('Error updating seen:', error);
      });
  
    // Aquí puedes agregar cualquier otra lógica que necesites al abrir el comentario
  };

  const openPopup = (data,data2,id) => {
    CommentsApi.updateseen(id)
    .then((response) => {
      console.log(response);
      // Abre la ventana emergente después de actualizar isSeen
      const popupWindow = window.open('', '_blank', 'width=400,height=300');
      popupWindow.document.write(`
        <html>
          <head>
            <title>Popup Window</title>
          </head>
          <body>
            <h2>COMENTARIO ${data2}</h2>
            <p>${data}</p>
            <button onClick="window.close()">Close</button>
          </body>
        </html>
      `);
      popupWindow.document.close();
    })
    .catch((error) => {
      console.error('Error:', error);
      // Maneja el error aquí si es necesario
    });
  };


  
  const [cancelDialogData, setCancelDialogData] = useState({ name: '', reservationId: null });
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  const snackbarHandleClose = () => {
    setSnackbar({ open: false });
  };
  const div = document.getElementById('scrollingdiv');
  // const hasHorizontalScrollbar = div.scrollWidth > div.clientWidth;
  const btncontainer = document.getElementById('buttonsContainer');

  const scroll = (scrollOffset) => {
    div.scrollLeft += scrollOffset;
  };
  const [isMounted, setIsMounted] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  useEffect(() => {
    CommentsApi.listClientSurvey()
    .then((response) => {
      if (isMounted) {
        console.log('Response:', response);
        console.log('Data:', response.data);
        console.log('Data2:', response.data.reviewsid);
        setReviews({
          reviews: response.data.reviews,
          reviewsid: response.data.reviewsid,
          infoClient2: response.data.infoClient2,
          combinedData: response.data.combinedData,
        });
        console.log('herde');
        console.log(setReviews);
        setAverageRating(response.data.averageRating);
        setLoading(false);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      setLoading(false); // Actualiza el estado de carga en caso de error
    });


  return () => {
    isMounted = false;
  };
   
  }, []);

  const closeCancelDialog = () => {
    setCancelDialog(false);
  };
  const closeImageDialog = () => {
    setImageDialog(false);
  };
  const closeHistoryDialog = () => {
    setHistoryDialog(false);
  };
  const closeEditClientDialog = () => {
    setEditClientDialog(false);
  };

  const openCancelDialog = (reservationId, name) => {
    setCancelDialogData({ name, reservationId });
    setCancelDialog(true);
  };
  const openImageDialog = (image) => {
    setImageDialogData(image);
    setImageDialog(true);
  };
  const openHistoryDialog = (created_by, status_modified_by, modified_by, deleted_by,id) => {
    // Llamada a la función API para actualizar el parámetro
    CommentsApi.updateseen(id)
      .then((response) => {
        console.log(response);
        setHistoryDialogData({ created_by, status_modified_by, modified_by, deleted_by });
        setHistoryDialog(true);
      })
      .catch((error) => {
        console.error('Error:', error);
        // Maneja el error aquí si es necesario
      });
  };
  const openEditClientDialog = (name, clientId) => {
    setEditClientDialogData({ name, clientId });
    setEditClientDialog(true);
  };
  const renderNightRow = () => (
    <TableRow>
      <TableCell colSpan={13} align="left" className="limiterRow">
        NOCHE
        {' '}
        <span className="guion">-</span>
        {' '}
        <span className="fontAndaleMono">
          {title}
        </span>
      </TableCell>
    </TableRow>
   );
   const renderAccordion = () => (
    <div className="accordion-container">
      <TableCell colSpan={13} align="left" className="limiterRow">
        <span className="guion"></span>
        <span className="fontAndaleMono">
          {title}
        </span>
        </TableCell>
        <TableCell>
        <div className="">
          <span className="guion"></span>
          <span style={{ fontWeight: 'bold', fontFamily: 'CommutersSans, sans-serif', whiteSpace: 'pre-line' }} >
            Promedio de calificaciones de usuarios:
          </span>
        </div>
        <div style={{ marginTop: '10px' }}>
          {renderStars(averageRating, '24px')}
        </div>
      </TableCell>
      {reviews.reviews.slice().reverse().map((row, index) => (
        <Accordion key={row.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <TableCell align="right" padding="9px">
              <div style={{ padding: '9px' }}>
                <button type="button" onClick={() => openImageDialog((reviews.combinedData.slice().reverse()[index].photo === null || reviews.combinedData[index].photo === '') ? DefaulImage : reviews.combinedData[index].photo)} tabIndex={-1} key={uuid()} className="photocontainer" style={{ width: '100px' }}>
                  {(reviews.combinedData.slice().reverse()[index].photo === null || reviews.combinedData[index].photo === '') ? (
                    <>
                      
                      <img src={reviews.combinedData.slice().reverse()[index].photo || DefaulImage} alt="" style={{ width: '100%' }} />                    </>
                  ) : (
                    <>
                      <img src={reviews.combinedData.slice().reverse()[index].photo || DefaulImage} alt="" style={{ width: '100%' }} />                    </>
                  )}
                </button>
                <br />
                <div style={{ paddingTop: '10px', paddingLeft: '9px' }}>
                  {(row.comment === null || row.comment === '') ? (
                    <Button
                      variant="contained"
                      color="default"
                      type="submit"
                      className="btnBorrar"
                      style={{ borderRadius: 50, backgroundColor: "#888888", color: "white", width: 150, height: 37 }}
                    >
                      NO HAY
                    </Button>
                  ) : (
                    <Button
                      onClick={() => openHistoryDialog(row.comment, reviews.reviewsid[index].name, reviews.reviewsid[index].name, reviews.reviewsid[index].name, row.id)}
                      variant="contained"
                      color={row.isSeen === 0 ? "white" : "primary"}
                      type="submit"
                      className="btnBorrar"
                      style={{
                        borderRadius: 50,
                        backgroundColor: row.isSeen === 0 ? "white" : undefined,
                        border: row.isSeen === 0 ? "1px solid black" : undefined,
                        width: 150,
                        height: 37
                      }}
                    >
                      {row.isSeen === 0 ? "Leído" : "CLIC PARA LEER"}
                    </Button>
                  )}
                </div>
              </div>
            </TableCell>
            <TableCell>
            <div  className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-4">
              <span style={{ fontWeight: 'bold', fontFamily: 'CommutersSans, sans-serif', whiteSpace: 'pre-line' }}>NOMBRE:</span>              
                {reviews.combinedData.slice().reverse()[index]?.firstName} </div>
              <div style={{ whiteSpace: 'pre-line' }} className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-4">

                     <span style={{ fontWeight: 'bold', fontFamily: 'CommutersSans, sans-serif', whiteSpace: 'pre-line' }}>CONTACTO:</span>
                {reviews.combinedData.slice().reverse()[index]?.cellPhoneData}   {reviews.combinedData.slice().reverse()[index]?.cellPhone} {reviews.combinedData.slice().reverse()[index]?.email}
            </div>
              <div style={{ whiteSpace: 'pre-line' }} className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-4">
              <span style={{ fontWeight: 'bold', fontFamily: 'CommutersSans, sans-serif', whiteSpace: 'pre-line' }}> DÍA:</span>
                {new Date(row.createdAt).toLocaleDateString()}
              </div>
              <div style={{ whiteSpace: 'pre-line' }} className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-4">
              <span style={{ fontWeight: 'bold', fontFamily: 'CommutersSans, sans-serif', whiteSpace: 'pre-line' }}> HORA:</span>

                {new Date(row.createdAt).toLocaleTimeString()}
              </div>
              {renderStars(row.rating, '27px')}</TableCell>
          </AccordionSummary>
          <AccordionDetails>
          <TableCell colSpan={6} onClick={() => handleCommentClick(row.id)}>
            COMENTARIO {reviews.combinedData[index]?.firstName}  {(reviews.combinedData[index]?.middleName || '').toUpperCase()}:
            {row.comment}
          </TableCell>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
  
  return (
    <div className="table-container" style={{ fontSize: '14px' }}>
      {!loading && renderAccordion()}
    </div>
  );
};

// EnhancedTable.propTypes = {
//   tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   newPagination: PropTypes.shape({
//     currentPage: PropTypes.number,
//     perPage: PropTypes.number,
//     total: PropTypes.number,
//     from: PropTypes.number,
//     to: PropTypes.number,
//     options: PropTypes.arrayOf(PropTypes.number),
//     date: PropTypes.string,
//     zone: PropTypes.string,
//     sortCategory: PropTypes.string,
//     sortDirection: PropTypes.string,
//   }).isRequired,
//   setApiCallConfig: PropTypes.func.isRequired,
// };

export default EnhancedTable;
