import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
  Hidden,
} from '@material-ui/core/';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import {
  differenceInHours,
  format,
  isAfter,
  isBefore,
  isEqual,
} from 'date-fns';
import moment from 'moment';

import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import InfoIcon from '@material-ui/icons/Info';

import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import RestaurantContext from '../../layouts/Restaurant/RestaurantContext/RestaurantContext';
import timeRegex from '../../utils/timeRegex';

import './RestaurantSchedule.scss';
import * as CatalogsApi from '../../api/catalogs';
import * as RestaurantsApi from '../../api/restaurants/restaurants';
import isTruthy from '../../utils/isTruthy';
import SnackbarAlert from '../SnackbarAlert';

const RestaurantSchedule = ({ setReload }) => {
  const { scheduleData } = useContext(RestaurantContext);
  const [changedObjectData, setChangedObjectData] = useState();
  const [isFieldActive, setIsFieldActive] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [hasEdit, setHasEdit] = useState(false);
  const [reload, setLocalReload] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  useEffect(() => {
    const prepareCurrentData = (catalog) => {
      const preparedData = [];
      const preparedObjectData = {};
      const isActiveField = {};
      catalog.forEach((element) => {
        const option = {
          name: element.name,
          id: element.encryptId,
          status: false,
          opening: '',
          closing: '',
          breakStart: '',
          breakEnd: '',
        };
        console.log('tamales');
        console.log(scheduleData);
        if (scheduleData.length > 0) {
          scheduleData.forEach((d) => {
            if (d.day.name === option.name) {
              const arrayOpening = d.opening.split(':');
              const arrayClosing = d.closing.split(':');
              const arrayBreakStart = d.breakStart.split(':');
              const arrayBreakEnd = d.breakEnd.split(':');
              option.status = d.status;
              option.opening = new Date('01', '01', '2000', arrayOpening[0], arrayOpening[1], 0);
              option.closing = new Date('01', '01', '2000', arrayClosing[0], arrayClosing[1], 0);
              option.breakStart = new Date('01', '01', '2000', arrayBreakStart[0], arrayBreakStart[1], 0);
              option.breakEnd = new Date('01', '01', '2000', arrayBreakEnd[0], arrayBreakEnd[1], 0);
            }
          });
        } else {
          option.status = 0;
          option.opening = new Date('01', '01', '2000', '00', '00', 0);
          option.closing = new Date('01', '01', '2000', '00', '00', 0);
          option.breakStart = new Date('01', '01', '2000', '00', '00', 0);
          option.breakEnd = new Date('01', '01', '2000', '00', '00', 0);
        }
        preparedData.push(option);
        preparedObjectData[option.name] = option;
      });
      preparedData.forEach((element) => {
        // eslint-disable-next-line no-unneeded-ternary
        isActiveField[element.name] = element.status === 1 ? true : false;
      });
      setIsFieldActive(isActiveField);
      setChangedObjectData(preparedObjectData);
      setLocalReload(false);
    };

    const fetchCatalogs = async () => {
      try {
        const { success, data } = await CatalogsApi
          .catalogDay();
        if (isTruthy(success)) {
          prepareCurrentData(data);
        }
        setIsLoading(false);
      } catch (error) {
        alert(error);
        setIsLoading(false);
      }
    };
    fetchCatalogs();
  }, [scheduleData, reload]);

  const changeCheckbox = (item) => {
    setIsFieldActive({ ...isFieldActive, [item.name]: !isFieldActive[item.name] });
    if (isFieldActive[item.name]) {
      setHasEdit(true);
    }
  };

  const handleTimeChange = (time, value, field, day) => {
    if (timeRegex.test(value)) {
      const splitTime = value.split(':');
      const choosenDateTime = new Date('01', '01', '2000', splitTime[0], splitTime[1]);
      const newData = changedObjectData[day];
      const breakenddt = moment(changedObjectData[day].breakEnd, 'YYYY-MM-DD hh:mm a');
      const breakstartdt = moment(changedObjectData[day].breakStart, 'YYYY-MM-DD hh:mm a');
      const choosendt = moment(choosenDateTime, 'YYYY-MM-DD hh:mm a');
      const handleClosing = () => {
        if (differenceInHours(choosenDateTime, changedObjectData[day].opening) < 3) {
          alert('Debe haber mínimo tres horas de diferencia entre apertura y cierre');
        }
        if (isBefore(choosenDateTime, changedObjectData[day].opening)
          || isEqual(choosenDateTime, changedObjectData[day].opening)) {
          alert('No puedes escoger una hora antes o igual de la hora de apertura');
        }
        if (isAfter(choosenDateTime, changedObjectData[day].opening)
          && differenceInHours(choosenDateTime, changedObjectData[day].opening) >= 3) {
          newData.closing = choosenDateTime;
          setChangedObjectData({ ...changedObjectData, [day]: newData });
          setHasEdit(true);
        }
      };
      const handleOpening = () => {
        console.log(differenceInHours(changedObjectData[day].closing, choosenDateTime));
        if (differenceInHours(changedObjectData[day].closing, choosenDateTime) < 3) {
          alert('Debe haber mínimo tres horas de diferencia entre apertura y cierre');
        }
        if (isAfter(choosenDateTime, changedObjectData[day].closing)
          || isEqual(choosenDateTime, changedObjectData[day].closing)) {
          alert('No puedes escoger una hora despues o igual de la hora de cierre');
        }
        if (isBefore(choosenDateTime, changedObjectData[day].closing)
          && differenceInHours(changedObjectData[day].closing, choosenDateTime) >= 3) {
          newData.opening = choosenDateTime;
          setChangedObjectData({ ...changedObjectData, [day]: newData });
          setHasEdit(true);
        }
      };
      const handleBreakStart = () => {
        console.log('chosen', choosendt);
        console.log('breakend', breakenddt);
        console.log('breakstart', breakstartdt);
        newData.breakStart = choosenDateTime;
        setChangedObjectData({ ...changedObjectData, [day]: newData });
        setHasEdit(true);
      };
      const handleBreakEnd = () => {
        if (moment(choosendt).isBefore(breakstartdt)) {
          alert('No puedes escoger una hora antes de la hora de inicio de descanso');
        } else {
          newData.breakEnd = choosenDateTime;
          setChangedObjectData({ ...changedObjectData, [day]: newData });
          setHasEdit(true);
        }
      };

      switch (field) {
        case 'opening':
          handleOpening();
          break;
        case 'closing':
          handleClosing();
          break;
        case 'breakStart':
          handleBreakStart();
          break;
        case 'breakEnd':
          handleBreakEnd();
          break;
        default:
          handleClosing();
          break;
      }
    } else {
      // setValidations({ ...validations, time: false });
      console.log(time);
    }
  };

  const saveChanges = async () => {
    const serviceDays = [];
    Object.keys(changedObjectData).forEach((element) => {
      if (isFieldActive[element]) {
        const item = {
          day_eid: changedObjectData[element].id,
          opening: format(changedObjectData[element].opening, 'HH:mm:ss'),
          closing: format(changedObjectData[element].closing, 'HH:mm:ss'),
          breakStart: format(changedObjectData[element].breakStart, 'HH:mm:ss'),
          breakEnd: format(changedObjectData[element].breakEnd, 'HH:mm:ss'),
          status: true,
        };
        serviceDays.push(item);
      } else {
        const item = {
          day_eid: changedObjectData[element].id,
          opening: '00:00:01',
          closing: '23:59:59',
          breakStart: '00:00:00',
          breakEnd: '00:00:00',
          status: false,
        };
        serviceDays.push(item);
      }
    });
    try {
      const { success, message } = await RestaurantsApi
        .editServiceDay({ service_days: serviceDays });
      if (success) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
        setTimeout(() => {
          setReload(true);
        }, 1000);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };

  const discardChanges = () => {
    setHasEdit(false);
    setLocalReload(true);
  };

  const renderView = () => (
    <>
      <Hidden xsDown>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <div className="restaurantSchedule-form-container mr-2">
            <div className="info-message-container mb-1 font__roboto dFlex flex-row justify-start align-center">
              <InfoIcon className="info-icon-tooltip mr-1" />
              <p>
                Las reservas realizadas hasta el día de hoy
                no se verán afectadas con los cambios que realices
              </p>
            </div>
            <div className="dflex justify-content-end textCenter">
              <div className="column66 dFlex flex-row" />
              <div className="column66 dFlex flex-row textCenter">
                <b>HORAS DE RESERVA</b>
              </div>
              <div className="column66 dFlex flex-row textCenter">
                <b>HORAS BLOQUEADAS</b>
              </div>
            </div>
            {
              // eslint-disable-next-line no-return-assign
              Object.keys(changedObjectData).map((element, i) => (
                <div key={uuid()} className="restaurantSchedule-area-option">
                  <div className="column33 dFlex flex-column">
                    {i === 0 ? <small className="font__commutter">DÍA</small> : ''}
                    <FormControlLabel
                      key={uuid()}
                      label={changedObjectData[element].name}
                      control={(
                        <Checkbox
                          checked={isFieldActive[element]}
                          name={changedObjectData[element].name}
                          disableRipple
                          color="primary"
                          onChange={() => { changeCheckbox(changedObjectData[element]); }}
                        />
                      )}
                    />
                  </div>
                  <div className="column66 dFlex flex-row">
                    <div className="w50 dFlex flex-column">
                      {i === 0 ? <small className="font__commutter textCenter">APERTURA</small> : ''}
                      <KeyboardTimePicker
                        ampm
                        margin="normal"
                        id="time-picker-opening"
                        format="HH:mm"
                        value={changedObjectData[element].opening}
                        onChange={(time, value) => { handleTimeChange(time, value, 'opening', changedObjectData[element].name); }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                        className="restaurant-schedule__time-picker"
                        invalidDateMessage="No es un formato válido"
                        keyboardIcon={<AccessTimeIcon />}
                        InputProps={{ readOnly: true }}
                        inputVariant="standard"
                        InputAdornmentProps={{ position: 'start' }}
                        cancelLabel="CANCELAR"
                        okLabel="OK"
                        disabled={!isFieldActive[element]}
                      />
                    </div>
                    <div className="w50 dFlex flex-column">
                      {i === 0 ? <small className="font__commutter textCenter">CIERRE</small> : ''}
                      <KeyboardTimePicker
                        ampm
                        margin="normal"
                        id="time-picker-closing"
                        format="HH:mm"
                        value={changedObjectData[element].closing}
                        onChange={(time, value) => { handleTimeChange(time, value, 'closing', changedObjectData[element].name); }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                        className="restaurant-schedule__time-picker"
                        invalidDateMessage="No es un formato válido"
                        keyboardIcon={<AccessTimeIcon />}
                        InputProps={{ readOnly: true }}
                        inputVariant="standard"
                        InputAdornmentProps={{ position: 'start', border: 0 }}
                        cancelLabel="CANCELAR"
                        okLabel="OK"
                        disabled={!isFieldActive[element]}
                      />
                    </div>
                    <div className="w50 dFlex flex-column dashedBorderLeft">
                      {i === 0 ? <small className="font__commutter textCenter">INICIO </small> : ''}
                      <KeyboardTimePicker
                        ampm
                        margin="normal"
                        id="time-picker-closing"
                        format="HH:mm"
                        value={changedObjectData[element].breakStart}
                        onChange={(time, value) => { handleTimeChange(time, value, 'breakStart', changedObjectData[element].name); }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                        className="restaurant-schedule__time-picker"
                        invalidDateMessage="No es un formato válido"
                        keyboardIcon={<AccessTimeIcon />}
                        InputProps={{ readOnly: true }}
                        inputVariant="standard"
                        InputAdornmentProps={{ position: 'start', border: 0 }}
                        cancelLabel="CANCELAR"
                        okLabel="OK"
                        disabled={!isFieldActive[element]}
                      />
                    </div>
                    <div className="w50 dFlex flex-column">
                      {i === 0 ? <small className="font__commutter textCenter">FIN</small> : ''}
                      <KeyboardTimePicker
                        ampm
                        margin="normal"
                        id="time-picker-closing"
                        format="HH:mm"
                        value={changedObjectData[element].breakEnd}
                        onChange={(time, value) => { handleTimeChange(time, value, 'breakEnd', changedObjectData[element].name); }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                        className="restaurant-schedule__time-picker"
                        invalidDateMessage="No es un formato válido"
                        keyboardIcon={<AccessTimeIcon />}
                        InputProps={{ readOnly: true }}
                        inputVariant="standard"
                        InputAdornmentProps={{ position: 'start', border: 0 }}
                        cancelLabel="CANCELAR"
                        okLabel="OK"
                        disabled={!isFieldActive[element]}
                      />
                    </div>
                  </div>
                </div>
              ))
            }
            <div className="restaurantSchedule__cta-container">
              <Button
                variant="contained"
                color="secondary"
                type="button"
                onClick={() => { discardChanges(); }}
                disabled={!hasEdit}
              >
                CANCELAR
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={() => { saveChanges(); }}
                disabled={!hasEdit}
              >
                GUARDAR
              </Button>
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </Hidden>
      <Hidden smUp>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <div className="restaurantSchedule-form-container mr-2">
            <div className="info-message-container mb-1 font__roboto dFlex flex-row justify-start align-center">
              <InfoIcon className="info-icon-tooltip mr-1" />
              <p>
                Las reservas realizadas hasta el día de hoy
                no se verán afectadas con los cambios que realices
              </p>
            </div>
            <div className="dflex justify-content-start">
              <div className="column66 dFlex flex-row">
                <b>HORAS DE RESERVA</b>
              </div>
            </div>
            <div className="mb-2 mt-2">
              {
                // eslint-disable-next-line no-return-assign
                Object.keys(changedObjectData).map((element, i) => (
                  <div key={uuid()} className="restaurantSchedule-area-option">
                    <div className="column33 dFlex flex-column">
                      {i === 0 ? <small className="font__commutter">DÍA</small> : ''}
                      <FormControlLabel
                        key={uuid()}
                        label={changedObjectData[element].name}
                        control={(
                          <Checkbox
                            checked={isFieldActive[element]}
                            name={changedObjectData[element].name}
                            disableRipple
                            color="primary"
                            onChange={() => { changeCheckbox(changedObjectData[element]); }}
                          />
                        )}
                      />
                    </div>
                    <div className="column66 dFlex flex-row">
                      <div className="w50 dFlex flex-column">
                        {i === 0 ? <small className="font__commutter textCenter">APERTURA</small> : ''}
                        <KeyboardTimePicker
                          ampm
                          margin="normal"
                          id="time-picker-opening"
                          format="HH:mm"
                          value={changedObjectData[element].opening}
                          onChange={(time, value) => { handleTimeChange(time, value, 'opening', changedObjectData[element].name); }}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                          className="restaurant-schedule__time-picker"
                          invalidDateMessage="No es un formato válido"
                          keyboardIcon={<AccessTimeIcon />}
                          InputProps={{ readOnly: true }}
                          inputVariant="standard"
                          InputAdornmentProps={{ position: 'start' }}
                          cancelLabel="CANCELAR"
                          okLabel="OK"
                          disabled={!isFieldActive[element]}
                        />
                      </div>
                      <div className="w50 dFlex flex-column">
                        {i === 0 ? <small className="font__commutter textCenter">CIERRE</small> : ''}
                        <KeyboardTimePicker
                          ampm
                          margin="normal"
                          id="time-picker-closing"
                          format="HH:mm"
                          value={changedObjectData[element].closing}
                          onChange={(time, value) => { handleTimeChange(time, value, 'closing', changedObjectData[element].name); }}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                          className="restaurant-schedule__time-picker"
                          invalidDateMessage="No es un formato válido"
                          keyboardIcon={<AccessTimeIcon />}
                          InputProps={{ readOnly: true }}
                          inputVariant="standard"
                          InputAdornmentProps={{ position: 'start', border: 0 }}
                          cancelLabel="CANCELAR"
                          okLabel="OK"
                          disabled={!isFieldActive[element]}
                        />
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="dflex justify-content-start">
              <div className="column66 dFlex flex-row">
                <b>HORAS BLOQUEADAS</b>
              </div>
            </div>
            <div className="mb-2 mt-2">
              {
                // eslint-disable-next-line no-return-assign
                Object.keys(changedObjectData).map((element, i) => (
                  <div key={uuid()} className="restaurantSchedule-area-option">
                    <div className="column33 dFlex flex-column">
                      {i === 0 ? <small className="font__commutter">DÍA</small> : ''}
                      <FormControlLabel
                        key={uuid()}
                        label={changedObjectData[element].name}
                        control={(
                          <Checkbox
                            checked={isFieldActive[element]}
                            name={changedObjectData[element].name}
                            disableRipple
                            color="primary"
                            onChange={() => { changeCheckbox(changedObjectData[element]); }}
                          />
                        )}
                      />
                    </div>
                    <div className="column66 dFlex flex-row">
                      <div className="w50 dFlex flex-column">
                        {i === 0 ? <small className="font__commutter textCenter">INICIO </small> : ''}
                        <KeyboardTimePicker
                          ampm
                          margin="normal"
                          id="time-picker-closing"
                          format="HH:mm"
                          value={changedObjectData[element].breakStart}
                          onChange={(time, value) => { handleTimeChange(time, value, 'breakStart', changedObjectData[element].name); }}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                          className="restaurant-schedule__time-picker"
                          invalidDateMessage="No es un formato válido"
                          keyboardIcon={<AccessTimeIcon />}
                          InputProps={{ readOnly: true }}
                          inputVariant="standard"
                          InputAdornmentProps={{ position: 'start', border: 0 }}
                          cancelLabel="CANCELAR"
                          okLabel="OK"
                          disabled={!isFieldActive[element]}
                        />
                      </div>
                      <div className="w50 dFlex flex-column">
                        {i === 0 ? <small className="font__commutter textCenter">FIN</small> : ''}
                        <KeyboardTimePicker
                          ampm
                          margin="normal"
                          id="time-picker-closing"
                          format="HH:mm"
                          value={changedObjectData[element].breakEnd}
                          onChange={(time, value) => { handleTimeChange(time, value, 'breakEnd', changedObjectData[element].name); }}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                          className="restaurant-schedule__time-picker"
                          invalidDateMessage="No es un formato válido"
                          keyboardIcon={<AccessTimeIcon />}
                          InputProps={{ readOnly: true }}
                          inputVariant="standard"
                          InputAdornmentProps={{ position: 'start', border: 0 }}
                          cancelLabel="CANCELAR"
                          okLabel="OK"
                          disabled={!isFieldActive[element]}
                        />
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="restaurantSchedule__cta-container">
              <div className="margintopfix">
                <Button
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={() => { discardChanges(); }}
                  disabled={!hasEdit}
                >
                  CANCELAR
                </Button>
              </div>
              <Button
                className="mt-0"
                variant="contained"
                color="primary"
                type="button"
                onClick={() => { saveChanges(); }}
                disabled={!hasEdit}
              >
                GUARDAR
              </Button>
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </Hidden>
    </>
  );

  return (
    <>
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <Box
        component="div"
        className="dFlex flex-column w100 mb-5"
      >
        <Grid container>
          <Grid item xs={12} md={4} className="restaurantSchedule-left-grid">
            <div className="pr-3 ml-1">
              <Typography variant="h6">
                HORARIOS DE ATENCIÓN
              </Typography>
              <div>
                <Typography variant="body1">
                  Elige los días y el horario en que estarás brindando atención a los comensales.
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={8} className="restaurantSchedule-right-grid">
            {changedObjectData && !isLoading && renderView()}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

RestaurantSchedule.propTypes = {
  setReload: PropTypes.func,
};

RestaurantSchedule.defaultProps = {
  setReload: () => { },
};

export default RestaurantSchedule;
