import client from '../utils/client';

// Muestra a todos los Offers
const showAllOffers = (config) => client(`restaurant/offers?page=${config.currentPage ? config.currentPage : 1}&pagesize=${config.perPage ? config.perPage : 10}`)
  .then((response) => response);

// Muestra un miembro del Offers
const showOneOffers = (id) => client(`restaurant/offers/${id}`)
  .then((response) => response);

// Crea un Offers
const createOffers = (body) => client('restaurant/offers', { body })
  .then((response) => response);

// Edita un Offers
const editOffers = (id, body) => client(`restaurant/offers/${id}`, { body, method: 'PUT' })
  .then((response) => response);

// Elimina un Offers
const deleteOffers = (id, body) => client(`restaurant/offers/${id}`, { body, method: 'DELETE' })
  .then((response) => response);

// Cambia el password del usuario autenticado
const changeUserPassword = (body) => client('restaurant/profile/password', { body, method: 'PUT' })
  .then((response) => response);

export {
  showAllOffers,
  showOneOffers,
  createOffers,
  changeUserPassword,
  editOffers,
  deleteOffers,
};
