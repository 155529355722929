import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
} from '@material-ui/core/';
import loadScript from '../../utils/loadScript';
import AddressSuggestions from './AddressSuggestions';
import useGooglePlaces from './useGooglePlaces';
import emojiRegex from '../../utils/emojiRegex';
import './AddressField.scss';

const AddressField = ({ onChange, isValid, defaultValue }) => {
  const [addressFields, setAddressFields] = useState({
    address: '',
    latitude: null,
    longitude: null,
  });

  const [isSuggestionSelected, setIsSuggestionSelected] = useState(false);
  const { suggestions, status } = useGooglePlaces(addressFields.address);

  useEffect(() => {
    if (!window.google) {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&language=es-419`);
    }
  }, []);

  const updateAddress = (e) => {
    const cleanString = e.target.value.replace(emojiRegex, '').replace('  ', ' ');
    setIsSuggestionSelected(false);
    setAddressFields({ ...addressFields, address: cleanString });
    onChange({ ...addressFields, address: cleanString });
  };

  const updateAddressWithSuggestion = (place) => {
    setIsSuggestionSelected(true);

    const suggestionFields = {
      address: place.formatted_address,
      latitude: `${place.geometry.location.lat()}`,
      longitude: `${place.geometry.location.lng()}`,
    };

    setAddressFields(suggestionFields);
    onChange(suggestionFields);
  };

  const handleSelectSuggestion = (suggestion) => {
    const detailsService = new window.google.maps.places.PlacesService(document.createElement('div'));
    const request = {
      placeId: suggestion.place_id,
      language: 'es-419',
      fields: ['formatted_address', 'geometry'],
    };

    detailsService.getDetails(request, updateAddressWithSuggestion);
  };

  return (
    <div className="address-field">
      <TextField
        className="w100"
        id="address"
        name="address"
        defaultValue={defaultValue}
        error={!isValid}
        label="Dirección actual"
        value={addressFields.address}
        onChange={updateAddress}
      />
      {status === 'OK' && !isSuggestionSelected && (
        <AddressSuggestions suggestions={suggestions} onSelect={handleSelectSuggestion} />
      )}
    </div>
  );
};

AddressField.propTypes = {
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  defaultValue: PropTypes.string,
};

AddressField.defaultProps = {
  onChange: () => { },
  isValid: true,
  defaultValue: '',
};

export default AddressField;
