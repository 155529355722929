import React from 'react';
import { Redirect } from '@reach/router';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import FadeTransitionRouter from '../../router/FadeTransitionRouter';

import Login from '../../layouts/Login';
import ForgotPassword from '../../layouts/ForgotPassword';
import RecoverPassword from '../../layouts/RecoverPassword';
import NotFound from '../../layouts/NotFound';
import Terms from '../../layouts/Terms';
import Privacy from '../../layouts/Privacy';

const UnauthenticatedApp = () => {
  const renderRedirectionFromAuthenticatedRoutes = () => (
    <>
      <Redirect from="/reservations" to="/" noThrow />
    </>
  );

  return (
    <Box component="div" className="unauthenticated-app">
      <Container className="global-container" fixed>
        <FadeTransitionRouter>
          {renderRedirectionFromAuthenticatedRoutes()}
          <Login path="/" />
          <ForgotPassword path="/olvide-password" />
          <RecoverPassword path="/recuperar-password/:token" noThrow />
          <NotFound default />
          <Terms path="/terms" />
          <Privacy path="/privacy" />
        </FadeTransitionRouter>
      </Container>
    </Box>
  );
};

export default UnauthenticatedApp;
