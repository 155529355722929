import React, { useState, useEffect } from 'react';
// import { navigate } from '@reach/router';
import {
  Box,
  Container,
  Grid,
  // Hidden,
  // IconButton,
  Typography,
} from '@material-ui/core/';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ReservationsCreateFormOne from './ReservationsCreateFormOne';
import ReservationsCreateFormTwo from './ReservationsCreateFormTwo';
import ReservationsCreateFormThree from './ReservationsCreateFormThree';
import TheMenu from '../../components/TheMenu';
import './ReservationsCreate.scss';

const ReservationsCreate = () => {
  const [state, setState] = useState({
    loading: true,
    error: null,
    success: false,
  });
  const [step, setStep] = useState(2);
  const [stepOneValues, setStepOneValues] = useState({ waitinglist: false, event: false });
  const [avaliabilityData, setAvaliabilityData] = useState({});
  const [numberPeople, setNumberPeople] = useState();
  const renderLoading = () => (
    <div>Loading...</div>
  );
  const renderStepOne = () => (
    <Container className={step === 1 ? '' : 'dNone'} fixed height="100">
      <Grid container height="100" spacing={2}>
        <Grid item xs={12} md={4} className="reservations-create__left-grid dFlex flex-column align-start justify-start">
          <Box
            component="div"
            className="w100"
          >
            <div className="mb-1">
              <Typography variant="h5">
                DATOS DEL CLIENTE
              </Typography>
            </div>
            <Typography variant="body1">
              Elige los días y el horario en que estarás brindando atención
              a los comensales.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} className="reservations-create__right-grid dFlex flex-column align-start justify-start">
          <Box
            component="div"
            className="w100"
          >
            <ReservationsCreateFormOne nextStep={setStep} stepOneValues={setStepOneValues} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
  const renderStepTwo = () => (
    <Container className={step === 2 ? '' : 'dNone'} fixed height="100">
      <Grid container height="100" spacing={2}>
        <Grid item xs={12} md={4} className="reservations-create__left-grid dFlex flex-column align-start justify-start">
          <Box
            component="div"
            className="w100"
          >
            <div className="mb-1">
              <Typography variant="h5">
                FECHA Y HORA
              </Typography>
            </div>
            <Typography variant="body1">
              Para realizar una reserva añadir el número de teléfono
              <br />
              o correo electrónico.
              No es necesario rellenar ambos.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} className="reservations-create__right-grid dFlex flex-column align-start justify-start">
          <Box
            component="div"
            className="w100"
          >
            <ReservationsCreateFormTwo
              nextStep={setStep}
              setAvaliabilityData={setAvaliabilityData}
              setNumberPeople={setNumberPeople}
              stepOneValues={setStepOneValues}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>

  );
  const renderStepThree = () => (
    <Container className={step === 3 ? '' : 'dNone'} fixed height="100">
      <Grid container height="100" spacing={2}>
        <Grid item xs={12} md={4} className="reservations-create__left-grid dFlex flex-column align-start justify-start">
          <Box
            component="div"
            className="w100"
          >
            <div className="mb-1">
              <Typography variant="h5">
                ELIGE EL SITIO
              </Typography>
            </div>
            <Typography variant="body1">
              Elige los días y el horario en que estarás brindando atención
              a los comensales.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} className="reservations-create__right-grid dFlex flex-column align-start justify-start">
          <Box
            component="div"
            className="w100"
          >
            <Typography variant="body1">
              Conforme a la fecha, hora y número de personas seleccionadas,
              estos son los horarios y zonas disponibles
            </Typography>
            <ReservationsCreateFormThree
              nextStep={setStep}
              avaliabilityData={avaliabilityData}
              numberPeople={Number(numberPeople)}
              stepOneValues={stepOneValues}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );

  useEffect(() => {
    setState({
      loading: false,
      error: null,
      success: false,
    });
  }, []);

  return (
    <>
      <TheMenu setIsCalendarOpen={() => { }} />
      <Container
        className="reservations-create layout px-0"
      >
        <Box
          component="div"
          className="reservations-create__header pr-1 pl-1 dFlex flex-row align-center justify-between"
        >
          <div className="dFlex flex-row align-center justify-start">
            {/* <IconButton
              onClick={() => { navigate('/reservaciones'); }}
            >
              <ArrowBackIosIcon />
            </IconButton> */}
            <Typography variant="h6" className="reservations-create__title font__commutter">
              NUEVA RESERVACIÓN
            </Typography>
          </div>
        </Box>

        <Box
          component="div"
          className="dFlex flex-column align-center justify-between"
        >
          {state.loading && renderLoading()}
          {!state.loading && renderStepOne()}
          {!state.loading && renderStepTwo()}
          {!state.loading && step === 3 && renderStepThree()}
        </Box>
      </Container>
    </>
  );
};

export default ReservationsCreate;
