const prepareRestaurant = async (data: any) => {
  const preparedData = {
    description: {
      id: data.id,
      name: data.name,
      description: data.description.description,
      phone: data.description.phone,
      cheffName: data.description.cheffName,
      address: data.address.address,
      addressId: data.address.id,
      latitude: data.address.latitude,
      longitude: data.address.longitude,
      kitchens: data.kitchens,
      extraServices: data.extraServices,
      cost: data.cost,
      paymentMethod: data.description.paymentMethod,
      sector: data.sectorId,
      sectors: data.sectors,
    },
    photos: data.photos,
    capacity: data.capacity,
    menu: data.menu,
    link: data.link,
    specialmenu: data.specialMenu,
    schedule: data.schedule,
    cover: data.cover,
    acceptAutomaticReservation: data.acceptAutomaticReservation,
    automaticReservations: data.automaticReservations,
    offers: data.offers,
    hasOffer: data.hasOffer,
  };
  console.log('preparedData offers');
  console.log(preparedData.offers);
  return preparedData;
};

export default prepareRestaurant;
