import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import {
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core/';
import { v4 as uuid } from 'uuid';
import * as CatalogsApi from '../../api/catalogs';
import * as RestaurantsApi from '../../api/restaurants/restaurants';
import isTruthy from '../../utils/isTruthy';
import SnackbarAlert from '../SnackbarAlert';

import './DialogEditRestaurantKitchens.scss';

ReactModal.setAppElement('#root');

const DialogEditRestaurantKitchens = ({
  isOpen, onNo, data,
}) => {
  const [title] = useState('Tipo de comida');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);
  const [kitchenCatalogs, setKitchenCatalogs] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  useEffect(() => {
    if (isOpen) {
      setIsLoading(false);
      const prepareKitchens = (rawCatalogKichensData) => {
        const preparedKitchensData = [];
        rawCatalogKichensData.forEach((element) => {
          const isKitchen = data.value.findIndex((kitchen) => kitchen.name === element.name);
          // eslint-disable-next-line no-param-reassign
          element.status = isKitchen !== -1 ? 1 : 0;

          preparedKitchensData.push(element);
        });
        setKitchenCatalogs(preparedKitchensData);
        setIsLoadingCatalogs(false);
      };
      const fetchCatalogs = async () => {
        try {
          const Response = await CatalogsApi
            .catalogKitchen();
          if (isTruthy(Response.success)) {
            prepareKitchens(Response.data);
          }
        } catch (error) {
          alert(error);
        }
      };
      fetchCatalogs();
    }
  }, [data, isOpen]);

  const submitChange = async (values) => {
    const body = {
      kitchen_eid: values.encryptId,
      status: values.status === 1 ? 0 : 1,
    };
    try {
      const { success, message } = await RestaurantsApi.editKitchen(body);
      if (isTruthy(success)) {
        const updatedKitchenData = kitchenCatalogs;
        updatedKitchenData.forEach((element) => {
          if (element.name === values.name) {
            // eslint-disable-next-line no-param-reassign
            element.status = body.status;
          }
        });
        setKitchenCatalogs(updatedKitchenData);
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };

  const renderModal = () => (
    <ReactModal
      isOpen={isOpen}
      className="dialog-edit-restaurant-description-modal"
      overlayClassName="dialog-edit-restaurant-description-modal__overlay"
    >
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <div className="dialog-edit-restaurant-description-modal__content ">
        <div className="dialog-edit-restaurant-description-modal__text-container">
          <div className="dialog-edit-restaurant-description-modal__title">
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </div>
          <div className="dialog-edit-restaurant-description-modal__body">
            <div className="dialog-edit-restaurant-kitchens-checkboxes__container">
              {isLoadingCatalogs && (
                <>
                  Loading...
                </>
              )}
              {kitchenCatalogs.map((item) => (
                <div
                  key={uuid()}
                  className="dialog-edit-restaurant-kitchens-checkbox-container"
                >
                  <div className="color-grey">
                    {item.name}
                  </div>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        // eslint-disable-next-line no-unneeded-ternary
                        checked={item.status === 0 ? false : true}
                        name={item.name}
                        disableRipple
                        color="primary"
                        onChange={() => { submitChange(item); }}
                      />
                    )}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="dialog-edit-restaurant-description-modal__cta-container">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={onNo}
          >
            GUARDAR
          </Button>
        </div>
      </div>
    </ReactModal>
  );

  return (
    <>
      {!isLoading && renderModal()}
    </>
  );
};

DialogEditRestaurantKitchens.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.shape({
    field: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  }),
};
DialogEditRestaurantKitchens.defaultProps = {
  isOpen: false,
  onNo: '',
  data: {
    field: '',
    value: {},
  },
};

export default DialogEditRestaurantKitchens;
