import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import {
  Button,
  TextField,
  Typography,
} from '@material-ui/core/';

import './DialogEditRestaurantDescription.scss';

import phoneRegex from '../../utils/phoneRegex';
import emojiRegex from '../../utils/emojiRegex';

import AddressField from '../AddressField';

ReactModal.setAppElement('#root');

const DialogEditRestaurantDescription = ({
  isOpen, onYes, onNo, data,
}) => {
  const [title, setTitle] = useState();
  const [fields, setFields] = useState();
  const [hasEditedField, setHasEditedField] = useState(false);
  const [validations, setValidations] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [coordinates, setCoordinaties] = useState();
  useEffect(() => {
    const getTitle = () => {
      switch (data.field) {
        case 'description':
          return 'Descripción';
        case 'address':
          return 'Dirección';
        case 'phone':
          return 'Teléfono';
        case 'cheffName':
          return 'Chef';
        case 'kitchen':
          return 'Tipo de comida';
        case 'cost':
          return 'Rango de precio';
        case 'paymentMethod':
          return 'Métodos de pago';
        default:
          return 'Hubo un error al recibir el dato';
      }
    };
    setTitle(getTitle());
    setFields({ [data.field]: data.value });
    setValidations({ [data.field]: true });
    setIsLoading(false);
  }, [data]);

  const handleValidations = (field, value) => {
    const mapValidations = {
      description: (description) => description.trim().length > 4
        && description.trim().length <= 250,
      address: (address) => address.trim().length > 3,
      phone: (phone) => phoneRegex.test(phone),
      cheffName: (cheffName) => cheffName.length > 3 && cheffName.length < 30,
      kitchen: (kitchen) => kitchen.length > 2 && kitchen.length < 30,
      cost: (cost) => cost.length > 3 && cost.length < 30,
      paymentMethod: (pm) => pm.length > 3 && pm.length < 100,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setHasEditedField(true);
    setValidations(newValidations);
  };

  const updateFields = (field, value) => {
    const newFields = {
      ...fields,
    };
    newFields[field] = value;
    setFields(newFields);
    handleValidations(field, value);
  };

  const updateTextFields = (field, value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...fields,
    };
    newFields[field] = cleanString;
    setFields(newFields);
    handleValidations(field, cleanString);
  };

  const confirmEdit = (e) => {
    e.preventDefault();
    if (data.field !== 'address') {
      onYes({
        field: data.field,
        value: fields[data.field],
      });
    } else {
      onYes({
        field: data.field,
        value: {
          address: fields.address,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
        },
      });
    }
  };

  const getField = () => {
    switch (data.field) {
      case 'description':
        return (
          <div>
            <TextField
              className="w100"
              error={!validations.description}
              id="description"
              value={fields.description}
              label="Descripción"
              onChange={(e) => updateTextFields(data.field, e.target.value)}
              multiline
              maxRows={4}
            />
          </div>
        );
      case 'address':
        return (
          <div>
            <AddressField
              defaultValue={fields.address}
              onChange={({ address, latitude, longitude }) => {
                updateTextFields('address', address);
                if (latitude && longitude) {
                  setCoordinaties({
                    latitude, longitude,
                  });
                }
              }}
              isValid={validations.address}
            />
          </div>
        );
      case 'phone':
        return (
          <div>
            <TextField
              className="w100"
              error={!validations.phone}
              id="phone"
              value={fields.phone}
              label="Teléfono"
              onChange={(e) => updateFields(data.field, e.target.value)}
            />
          </div>
        );
      case 'cheffName':
        return (
          <div>
            <TextField
              className="w100"
              error={!validations.cheffName}
              id="cheffName"
              value={fields.cheffName}
              label="Nombre del chef"
              onChange={(e) => updateTextFields(data.field, e.target.value)}
            />
          </div>
        );
      case 'kitchen':
        return 'Tipo de comida';
      case 'cost':
        return 'Rango de precio';
      case 'paymentMethod':
        return (
          <div>
            <TextField
              className="w100"
              error={!validations.paymentMethod}
              id="paymentMethod"
              value={fields.paymentMethod}
              label="Métodos de pago"
              onChange={(e) => updateTextFields(data.field, e.target.value)}
            />
          </div>
        );
      default:
        return 'Hubo un error al recibir el dato';
    }
  };

  const renderModal = () => (
    <ReactModal
      isOpen={isOpen}
      className="dialog-edit-restaurant-description-modal"
      overlayClassName="dialog-edit-restaurant-description-modal__overlay"
    >
      <form onSubmit={validations[data.field] && hasEditedField && confirmEdit} autoComplete="off" className="dialog-edit-restaurant-description-modal__content ">
        <div className="dialog-edit-restaurant-description-modal__text-container">
          <div className="dialog-edit-restaurant-description-modal__title">
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </div>
          <div className="dialog-edit-restaurant-description-modal__body mb-2 mt-2">
            {getField()}
          </div>
        </div>
        <div className="dialog-edit-restaurant-description-modal__cta-container">
          <Button
            variant="contained"
            color="secondary"
            type="button"
            onClick={onNo}
          >
            <span className="underline">
              CANCELAR
            </span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={fields[data.field] === data.value || !validations[data.field]}
          >
            GUARDAR
          </Button>
        </div>
      </form>
    </ReactModal>
  );

  return (
    <>
      {!isLoading && renderModal()}
    </>
  );
};

DialogEditRestaurantDescription.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  onYes: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.shape({
    field: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  }),
};
DialogEditRestaurantDescription.defaultProps = {
  isOpen: false,
  onNo: '',
  onYes: '',
  data: {
    field: '',
    value: {},
  },
};

export default DialogEditRestaurantDescription;
