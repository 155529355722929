import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import {
  Button,
  TextField,
  Typography,
} from '@material-ui/core/';
import SnackbarAlert from '../SnackbarAlert';
import emojiRegex from '../../utils/emojiRegex';

import './DialogCommentResponse.scss';

ReactModal.setAppElement('#root');

const DialogCommentResponse = ({
  isOpen, onNo, data, saveResponse,
}) => {
  const [fields, setFields] = useState({
    reply: data.reply === null || data.reply === undefined ? '' : data.reply,
  });
  const [validations, setValidations] = useState({
    reply: data.reply !== null,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  // const [hasEditedField, setHasEditedField] = useState(false);

  useEffect(() => {
    setFields({
      reply: data.reply === null || data.reply === undefined ? '' : data.reply,
    });
  }, [data]);

  const handleValidations = (field, value) => {
    const mapValidations = {
      reply: (reply) => reply.length > 3 && reply.length < 250,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
    // setHasEditedField(true);
  };

  const updateTextFields = (field, value) => {
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...fields,
    };
    newFields[field] = cleanString;
    setFields(newFields);
    handleValidations(field, cleanString);
  };

  const renderModal = () => (
    <ReactModal
      isOpen={isOpen}
      className="dialog-commentResponse-modal"
      overlayClassName="dialog-commentResponse-modal__overlay"
    >
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <div className="dialog-commentResponse-modal__content ">
        <div className="dialog-commentResponse-modal__text-container">
          <div className="dialog-commentResponse-modal__title">
            <Typography variant="h5" gutterBottom>
              {data.comment}
            </Typography>
          </div>
          <div className="dialog-commentResponse-modal__body">
            <form className="w100 mt-2" autoComplete="off">
              <div className="flex-column">
                <TextField
                  className="w100"
                  error={!validations.reply}
                  id="description"
                  value={fields.reply}
                  label="Tu respuesta"
                  onChange={(e) => updateTextFields('reply', e.target.value)}
                  multiline
                  maxRows={4}
                  disabled={typeof data.reply === 'string'}
                />
              </div>
              <div className="dialog-commentResponse-modal__cta-container mt-2">
                <Button
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={() => { onNo(); }}
                >
                  <span className="underline">
                    {data.reply !== null ? 'Cerrar' : 'CANCELAR'}
                  </span>
                </Button>
                {data.reply === null
                  ? (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!validations.reply}
                      onClick={() => saveResponse(
                        data.id,
                        { reply: fields.reply },
                      )}
                    >
                      RESPONDER
                    </Button>
                  ) : ''}
              </div>
            </form>
          </div>
        </div>
      </div>
    </ReactModal>
  );

  return (
    <>
      {renderModal()}
    </>
  );
};

DialogCommentResponse.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  saveResponse: PropTypes.func.isRequired,
};

DialogCommentResponse.defaultProps = {
  isOpen: false,
  onNo: '',
  data: {},
};

export default DialogCommentResponse;
