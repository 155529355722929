import React from 'react';

import PropTypes from 'prop-types';
import './DialogEmail.scss';
import ReactModal from 'react-modal';
import {
  Button,
} from '@material-ui/core/';

ReactModal.setAppElement('#root');

const DialogImage = ({
  isOpen, onNo, data,
}) => (
  <ReactModal
    isOpen={isOpen}
    className="dialog-email-modal"
    overlayClassName="dialog-email-modal__overlay"
    shouldCloseOnOverlayClick
    onRequestClose={onNo}
  >
    <div className="dialog-email-modal__content ">
      <div className="titulo">
        {data.name}
      </div>
      <span className="correo">
        <span className="labeltitle">EMAIL: </span>
        {(data.email === 'Sin datos') ? '' : data.email}
      </span>
      <div className="telefono">
        <span className="labeltitle">TELÉFONO: </span>
        {(data.phone === 'Sin datos') ? '' : data.phone}
      </div>
      <div className="flexend">
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={onNo}
        >
          CERRAR
        </Button>
      </div>
    </div>
  </ReactModal>
);

DialogImage.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),

};
DialogImage.defaultProps = {
  isOpen: false,
  onNo: '',
  data: {
    name: '',
    email: '',
    phone: '',
  },
};
export default DialogImage;
