import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import ChargesContext from '../../layouts/Charges/ChargesContext/ChargesContext';

import './ChargesTable.scss';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [{
  key: '1',
  id: 'name',
  label: 'Cliente',
  disablePadding: false,
  sortable: true,
}, {
  key: '2',
  id: 'reservationDate',
  label: 'Fecha de reservación',
  disablePadding: false,
  sortable: true,
}, {
  key: '3',
  id: 'people',
  label: 'Número de personas',
  disablePadding: false,
  sortable: true,
}, {
  key: '4',
  id: 'bill',
  label: 'Costo de la cuenta',
  disablePadding: false,
  sortable: true,
}, {
  key: '5',
  id: 'comission',
  label: 'Comisión a pagar',
  disablePadding: false,
  sortable: true,
},
];

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={uuid()}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
            {!headCell.sortable && (
              <div>
                {headCell.label}
              </div>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    borderBottom: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const EnhancedTable = () => {
  const classes = useStyles();
  const { filteredCharges } = useContext(ChargesContext);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('firstName');
  // const [pagination] = useState(apiCallConfig);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    localStorage.setItem('ReservationsConfigSortCategory', property);
    localStorage.setItem('ReservationsConfigSortDirection', isAsc ? 'desc' : 'asc');
    // setApiCallConfig({
    //   ...pagination,
    //   sortCategory: property,
    //   sortDirection: isAsc ? 'desc' : 'asc',
    // });
  };

  const renderTable = () => (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <TableContainer>
          <Table
            className={`${classes.table} charges-table`}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredCharges.length}
            />
            <TableBody>
              {stableSort(filteredCharges, getComparator(order, orderBy))
                .map((row) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={uuid()}
                  >
                    <TableCell key={uuid()} align="left" className="row-name">
                      <div className="content">
                        {row.image !== undefined && (
                          <div>
                            <svg viewBox="50 50 50px 50px" width="50" height="50">
                              <defs>
                                <mask id="mask">
                                  <circle fill="#FFFFFF" cx="50%" cy="45%" r="12.2" />
                                  <rect fill="#ffffff" x="26%" y="45%" width="24.15" height="21" />
                                </mask>
                              </defs>
                              <image mask="url(#mask)" xlinkHref={`data:image/png;base64,${row.image}`} width="50" height="50" />
                            </svg>
                          </div>
                        )}
                        {row.name}
                      </div>
                    </TableCell>
                    <TableCell key={uuid()} align="left">{row.reservationDate}</TableCell>
                    <TableCell key={uuid()} align="left">
                      {row.people}
                    </TableCell>
                    <TableCell key={uuid()} align="left">
                      $
                      {row.bill}
                      {' USD'}
                    </TableCell>
                    <TableCell key={uuid()} align="left">
                      $
                      {row.comission}
                      {' USD'}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  return (
    <>
      {renderTable()}
    </>
  );
};

// EnhancedTable.propTypes = {
//   tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   newPagination: PropTypes.shape({
//     currentPage: PropTypes.number,
//     perPage: PropTypes.number,
//     total: PropTypes.number,
//     from: PropTypes.number,
//     to: PropTypes.number,
//     options: PropTypes.arrayOf(PropTypes.number),
//     date: PropTypes.string,
//     zone: PropTypes.string,
//     sortCategory: PropTypes.string,
//     sortDirection: PropTypes.string,
//   }).isRequired,
//   setApiCallConfig: PropTypes.func.isRequired,
// };

export default EnhancedTable;
