// lodash is imported from create-react-app, but we don't have it on our project dependecies.
// eslint-disable-next-line import/no-extraneous-dependencies
import { escapeRegExp } from 'lodash';
import removeAccents from 'remove-accents';

const chargesTableFilter = (charge, word) => {
  const regexToCheck = new RegExp(escapeRegExp(removeAccents(word)), 'i');
  return (
    removeAccents((charge.name ?? '')).match(regexToCheck)
    || removeAccents((charge.reservationDate ?? '')).match(regexToCheck)
    || charge.bill.toString().match(regexToCheck)
    || charge.comission.toString().match(regexToCheck)
    || charge.people.toString().match(regexToCheck)
  );
};

export default chargesTableFilter;
