import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { initializeApp } from 'firebase/app';
import addNotification from 'react-push-notification';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useUser } from '../context/user-context';
import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import theme from '../styles/theme';

const App = () => {
  const user = useUser();
  const config = {
    apiKey: 'AIzaSyBNC0Mz88mDjZT9TBHFVlUL732HJj9vZOc',
    authDomain: 'puerta21notification.firebaseapp.com',
    databaseURL: 'https://project-id.firebaseio.com',
    projectId: 'puerta21notification',
    storageBucket: 'puerta21notification.appspot.com',
    messagingSenderId: '211443053793',
    appId: '1:211443053793:web:66d1d06f23f369c36bf3a0',
    measurementId: 'G-E7GZPD7GB8',
  };
  const app = initializeApp(config);
  const messaging = getMessaging(app);
  // check if bowser support notification
  if ('Notification' in window) {
    // request permission
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('se ejecuta');
        getToken(messaging).then((currentToken) => {
          console.log('enters');
          if (currentToken) {
            // Send the token to your server and update the UI if necessary

            localStorage.setItem('firebase_token', currentToken);
            // ...
          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });
      }
    });
  }
  onMessage(messaging, (payload) => {
    let soundInterval;
    let contador = 0;
    try {
      console.log('Message received. ', payload);

      addNotification({
        title: payload.notification.title,
        message: payload.notification.body,
        theme: 'darkblue',
        native: true,
        icon: '/images/PHOTO-192x192.jpg',
        vibrate: [200, 100],
        duration: 10000,
      });
      // +++NOTIFICATION BROWSER SOUND
      soundInterval = setInterval(() => {
        const src = '/sound/notification.mp3';
        const audio = new Audio(src);
        audio.play();
        contador += 1;
        if (contador === 4) {
          clearInterval(soundInterval);
        }
      }, 2000);
    } catch (err) {
      console.log('Caught error: ', err);
    }
  });

  return (
    <ThemeProvider theme={theme}>
      {(user.token && user.maintainSession === 'true') || (user.token && user.maintainSession === 'false' && user.hasSameSession)
        ? <AuthenticatedApp />
        : <UnauthenticatedApp />}
    </ThemeProvider>
  );
};

export default App;
