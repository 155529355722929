import client from '../utils/client';
import clientDownloadFile from '../utils/clientDownloadFile';

//  Busca clientes por email
const searchClientByEmailOrPhone = (emailorphone) => client(`restaurant/search/clients/${emailorphone}`)
  .then((response) => response);
//  Muestra a un cliente por su id
const showClient = (type, encryptId) => client(`restaurant/clients/${type}/${encryptId}`)
  .then((response) => response);
// muesta todos los clientes
const showAllClientsForSearch = () => client('restaurant/clients/all')
  .then((response) => response);
// Muestra a todos los clientes, el paámetro type puede ser 'puerta21' || "general-public"
const showAllClients = (type, config) => client(`restaurant/clients/${type !== null ? type : 'puerta21'}?page=${config.currentPage ? config.currentPage : 1}&pagesize=${config.perPage ? config.perPage : 10}`)
  .then((response) => response);

const categorieClientes = (id) => client(`categorie/clientes/${id}`)
  .then((response) => response);

// Edita las preferencias de un cliente
const editPreferences = (id, body) => client(`restaurant/clients/preferences/${id}`, { body, method: 'PUT' })
  .then((response) => response);

// Edita las categorías de un cliente
const editCategories = (body) => client('restaurant/clients/category', { body, method: 'PUT' })
  .then((response) => response);

const exportClients = (type, search) => clientDownloadFile(`restaurant/clients/export/${type}${search !== '' ? `/${search}` : ''}`,
  {
    method: 'GET',
    headers: {
      'Content-Disposition': 'attachment; filename="filename.xlsx"; filename*="filename.xlsx"',
      'Content-Type': 'application/octet-stream',
    },
  })
  .then((response) => response);
const updateReqs = (body) => client('restaurant/clients/update/NutritionalRequirements',
  {
    body,
    method: 'PUT',
  })
  .then((response) => response);
const updateDrinks = (body) => client('restaurant/clients/update/Drinks',
  {
    body,
    method: 'PUT',
  })
  .then((response) => response);
const showAllClientsPages = (type, config, pagesnumber) => client(`restaurant/clients/pages/${pagesnumber}/${type !== null ? type : 'puerta21'}?page=${config.currentPage ? config.currentPage : 1}&pagesize=${config.perPage ? config.perPage : 10}`)
  .then((response) => response);
const updateAllergies = (body) => client('restaurant/clients/update/Allergies',
  {
    body,
    method: 'PUT',
  })
  .then((response) => response);
const updateTables = (body) => client('restaurant/clients/update/Tables',
  {
    body,
    method: 'PUT',
  })
  .then((response) => response);
const updateNames = (body) => client('restaurant/clients/update/Names',
  {
    body,
    method: 'PUT',
  })
  .then((response) => response);
export {
  updateNames,
  updateReqs,
  updateAllergies,
  updateDrinks,
  updateTables,
  searchClientByEmailOrPhone,
  showClient,
  showAllClients,
  showAllClientsForSearch,
  showAllClientsPages,
  editPreferences,
  editCategories,
  exportClients,
  categorieClientes,
};
