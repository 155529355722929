import { React, useState } from 'react';
import PropTypes from 'prop-types';
import './DialogCreateRestaurant.scss';
import ReactModal from 'react-modal';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  Button,
  Box,
  TextField,
  InputLabel,
  InputAdornment,
  Input,
  IconButton,
} from '@material-ui/core/';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import emojiRegex from '../../utils/emojiRegex';
import createPassword from '../../utils/createPassword';
import * as RestaurantsApi from '../../api/restaurants/restaurants';
import SnackbarAlert from '../SnackbarAlert';
import TokenManager from '../../api/utils/TokenManager';

ReactModal.setAppElement('#root');
const DialogCreateRestaurant = ({
  isOpen, onNo,
}) => {
  const [validations, setValidations] = useState({
    name: false,
    email: false,
    password: false,
  });
  const [fields, setFields] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [finished, setFinished] = useState(false);
  const handleValidations = (field, value) => {
    const mapValidations = {
      name: (name) => /^[a-zA-Z0-9\s]{3,30}$/.test(name),
      password: (password) => password.length > 3 && password.length < 12,
      email: (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email),
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  const handleClickShowPassword = (field: string) => {
    setFields({ ...fields, [field]: !fields[field] });
  };
  const updateFields = (field, value) => {
    console.log(field, value);
    const cleanString = value.replace(emojiRegex, '').replace('  ', ' ');
    const newFields = {
      ...fields,
    };
    newFields[field] = cleanString;
    setFields(newFields);
    handleValidations(field, cleanString);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const useremail = new TokenManager('email').getToken();

    const body = {
      name: fields.name,
      owner_email: useremail,
      email: fields.email,
      password: fields.password,
    };
    try {
      const { success, data } = await RestaurantsApi
        .CreateRestaurant(body);
      if (success === 1) {
        setFinished(true);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: data,
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };
  return (
    <ReactModal
      isOpen={isOpen}
      className="dialog-base-modal"
      overlayClassName="dialog-base-modal__overlay"
      shouldCloseOnOverlayClick
      onRequestClose={onNo}
    >
      <div className="dialog-base-modal__content ">
        <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          {!finished && (
            <>
              <form onSubmit={handleSubmit}>
                <div className="dialog-base-modal__content ">
                  <div className="h3 w-100 title">
                    Crear restaurante
                  </div>
                  <Box>
                    <div className="w100 marginTop20">
                      <TextField
                        className="w100"
                        error={!validations.name}
                        id="name"
                        label="Nombre del nuevo restaurante"
                        value={fields.name}
                        onChange={(e) => updateFields('name', e.target.value)}
                      />
                    </div>
                  </Box>
                  <Box>
                    <div className="w100 marginTop20">
                      <TextField
                        className="w100"
                        error={!validations.email}
                        id="email"
                        label="Email del nuevo restaurante"
                        value={fields.email}
                        onChange={(e) => updateFields('email', e.target.value)}
                      />
                    </div>
                  </Box>
                  <small>
                    Asegurese de que el correo que ingrese
                    no este en uso dentro de la plataforma
                  </small>
                  <div className="mt-2">
                    <InputLabel
                      htmlFor="password"
                    >
                      Contraseña
                    </InputLabel>
                    <Input
                      className="w100"
                      error={!validations.password}
                      id="password"
                      label="Contraseña"
                      onChange={(e) => updateFields('password', e.target.value)}
                      type={fields.password ? 'text' : 'password'}
                      value={fields.password}
                      endAdornment={
                        (

                          <InputAdornment position="end">
                            <Button
                              variant="text"
                              color="primary"
                              type="button"
                              onClick={() => updateFields('password', createPassword())}
                            >
                              Generar
                            </Button>
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('showPassword')}
                            >
                              {fields.showNewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>

                        )
                      }
                    />
                    <span>
                      Asegurese de anotar su contraseña en un lugar
                      seguro antes de continuar
                    </span>
                  </div>
                </div>
                <div className="flexend">
                  <Button
                    className="cancelBtn"
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={onNo}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={handleSubmit}
                    disabled={!validations.name}
                  >
                    Guardar
                  </Button>
                </div>
              </form>
            </>
          )}
          {finished && (
            <>
              <span>
                Su nuevo restaurante ha sido creado con exito,
                sin embargo tendrá que salir
                de su cuenta y volver a iniciar sesión para que su
                nueva configuración tenga efecto
              </span>
            </>
          )}

        </MuiPickersUtilsProvider>
      </div>
    </ReactModal>
  );
};
DialogCreateRestaurant.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onNo: PropTypes.any,

  // eslint-disable-next-line react/forbid-prop-types

};
DialogCreateRestaurant.defaultProps = {
  isOpen: false,
  onNo: '',
};

export default DialogCreateRestaurant;
