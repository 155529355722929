import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import './NumberIncrements.scss';

const NumberIncrements = ({ data, setNumber, isActive }) => {
  const [currentNumber, setCurrentNumber] = useState(data.capacity);

  const manageIncrements = (operation: boolean) => {
    let newNumber;
    if (operation) {
      newNumber = currentNumber + 1;
    } else {
      newNumber = currentNumber - 1 >= 0 ? currentNumber - 1 : 0;
    }
    const newData = data;
    newData.capacity = newNumber;
    setCurrentNumber(newNumber);
    setNumber(newData);
  };

  return (
    <>
      <div className="number-increments">
        <div className={isActive ? 'number-increments__number' : 'number-increments__number is-disabled'}>
          {currentNumber}
        </div>
        <div className="number-increments__buttonGroup">
          <Button
            variant="contained"
            color="secondary"
            type="button"
            disabled={!isActive}
            onClick={() => { manageIncrements(true); }}
          >
            <ExpandLessIcon />
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="button"
            disabled={!isActive}
            onClick={() => { manageIncrements(false); }}
          >
            <ExpandMoreIcon />
          </Button>
        </div>
      </div>
    </>
  );
};

NumberIncrements.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    isActive: PropTypes.bool,
    capacity: PropTypes.number,
  }).isRequired,
  setNumber: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default NumberIncrements;
