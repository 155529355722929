import React, { useContext } from 'react';
import { v4 as uuid } from 'uuid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ChargesContext from '../../layouts/Charges/ChargesContext/ChargesContext';
import './ChargesAccordion.scss';

const ChargesAccordion = () => {
  const { filteredCharges } = useContext(ChargesContext);
  const renderAccordion = () => (
    <div className="charges-accordion__container mb-3">
      {filteredCharges.map((item) => (
        <Accordion key={uuid()}>
          <AccordionSummary
            className="charges-accordion__summary"
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {item.image !== undefined && (
              <div className="charges-accordion__picture">
                <div>
                  <svg viewBox="50 50 75px 75px" width="75" height="75">
                    <defs>
                      <mask id="mask">
                        <circle fill="#FFFFFF" cx="40%" cy="58%" r="23" />
                        <rect fill="#ffffff" x="9.7%" y="65%" width="45.5" height="40" />
                      </mask>
                    </defs>
                    <image mask="url(#mask)" xlinkHref={`data:image/png;base64,${item.image}`} width="100" height="100" />
                  </svg>
                </div>
              </div>
            )}
            <div className="charges-accordion__info">
              <Typography className="charges-accordion__name ">
                {item.name}
              </Typography>
              <div>
                <div className="font__roboto">
                  {`${item.people} personas`}
                </div>
                <div className="font__roboto">
                  {item.reservationDate}
                </div>
                <div className="font__roboto">
                  {`Cuenta: $${item.bill} USD • Comisión: $${item.comission} USD`}
                </div>
              </div>
            </div>
          </AccordionSummary>
        </Accordion>
      ))}
    </div>
  );

  return (
    <>
      {renderAccordion()}
    </>
  );
};

export default ChargesAccordion;
